import store from '../store'
import { getAllBonusObjectsByName, provideBonusObjectByName, getAllBonusPointsByName } from './bonus.service'
import { getAllMalusObjectsByName, getAllMalusPointsByName } from './malus.service'

function getSpellPoints() {
  return getStartSpellPoints() + getAdditionalSpellPoints() - getUsedSpellPoints()
}
function getStartSpellPoints() {
  return parseInt(store.state.configuration.spellPoints)
}
function getUsedSpellPoints() {
  return parseInt(store.state.character.spells.length)
}
function getAdditionalSpellPoints() {
  const numberOfBonusSpells = getAllBonusObjectsByName('spells').length
  const bonusSpellPoints = getAllBonusPointsByName('spellPoints')
  const malusSpellPoints = getAllMalusPointsByName('spellPoints')
  return numberOfBonusSpells + bonusSpellPoints - malusSpellPoints
}
function isBonusSpell(spellName) {
  const bonusSpells = getAllBonusObjectsByName('spells')
  const malusSpells = getAllMalusObjectsByName('spells')
  return bonusSpells.filter(bonusSpellName => !malusSpells.includes(bonusSpellName)).includes(spellName)
}
function getSpellForName(spellName) {
  return store.state.spells.find(spell => spell.name === spellName)
}
function getCurrentSpells() {
  return store.state.character.spells.map(spellName => getSpellForName(spellName))
}
function removeOldAndAddNewSpellsByReference(oldReference, newReference) {
  if (oldReference !== null && oldReference !== undefined) {
    provideBonusObjectByName(oldReference, 'spells').forEach(talentName => removeSpell(talentName))
  }
  if (newReference !== null && newReference !== undefined) {
    provideBonusObjectByName(newReference, 'spells').forEach(talentName => addSpell(talentName))
  }
}
function addSpell(spellName) {
  store.dispatch('addCharacterSpell', spellName)
}
function removeSpell(spellName) {
  store.dispatch('removeCharacterSpell', spellName)
}
export { getSpellPoints, getCurrentSpells, getSpellForName, removeSpell, addSpell, isBonusSpell, removeOldAndAddNewSpellsByReference }
