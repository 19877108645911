import store from '../store'
import { provideBonusObjectByName } from './bonus.service'

function getLanguageForName(languageName) {
  return store.getters.languages.find(language => language.name === languageName)
}
function getCurrentLanguages() {
  return store.getters.characterLanguages.map(languageName => getLanguageForName(languageName))
}
function removeOldAndAddNewLanguagesByReference(oldReference, newReference) {
  if (oldReference !== null && oldReference !== undefined) {
    provideBonusObjectByName(oldReference, 'languages').forEach(language => removeLanguage(language.name))
  }
  if (newReference !== null && newReference !== undefined) {
    provideBonusObjectByName(newReference, 'languages').forEach(language => addLanguage(language.name))
  }
}
function addLanguage(languageName) {
  store.dispatch('addCharacterLanguage', languageName)
}
function removeLanguage(languageName) {
  store.dispatch('removeCharacterLanguage', languageName)
}
export { addLanguage, removeLanguage, getCurrentLanguages, getLanguageForName, removeOldAndAddNewLanguagesByReference }
