import store from '../store'
import { getCurrentGender } from './gender.service'
import { getCurrentCulture } from './culture.service'
import { getCurrentClazz } from './clazz.service'
import { getCurrentCraft } from './craft.service'
import { getCurrentTalents } from './talent.service'
import { getCurrentImpact } from './impact.service'
import { getCurrentRings, getCurrentAmulets, getCurrentArmor, getCurrentShield, getCurrentMeleeWeapons, getCurrentRangedWeapons } from './items.service'

function getValueOfObjectAttributeByName(objectAttributeName, listObjectAttributeName) {
  const currentGender = getCurrentGender()
  const currentCulture = getCurrentCulture()
  const currentClazz = getCurrentClazz()
  const currentCraft = getCurrentCraft()
  const currentTalents = getCurrentTalents()
  const currentRings = getCurrentRings()
  const currentAmulets = getCurrentAmulets()
  const currentArmor = getCurrentArmor()
  const currentShield = getCurrentShield()
  const currentMeleeWeapons = getCurrentMeleeWeapons()
  const currentRangedWeapons = getCurrentRangedWeapons()
  const currentDependencies = getCurrentImpact()
  const genderAttributes = currentGender !== undefined && currentGender[objectAttributeName] !== undefined ? currentGender[objectAttributeName] : []
  const genderLevelAttributes =
    currentGender !== undefined && currentGender.levels !== undefined ? currentGender.levels.filter(level => level[objectAttributeName] !== undefined && level.value <= parseInt(store.state.character.personality.level)) : []
  const cultureAttributes = currentCulture !== undefined && currentCulture[objectAttributeName] !== undefined ? currentCulture[objectAttributeName] : []
  const cultureLevelAttributes =
    currentCulture !== undefined && currentCulture.levels !== undefined ? currentCulture.levels.filter(level => level[objectAttributeName] !== undefined && level.value <= parseInt(store.state.character.personality.level)) : []
  const clazzAttributes = currentClazz !== undefined && currentClazz[objectAttributeName] !== undefined ? currentClazz[objectAttributeName] : []
  const clazzLevelAttributes = currentClazz !== undefined && currentClazz.levels !== undefined ? currentClazz.levels.filter(level => level[objectAttributeName] !== undefined && level.value <= parseInt(store.state.character.personality.level)) : []
  const craftingAttributes = currentCraft !== undefined && currentCraft[objectAttributeName] !== undefined ? currentCraft[objectAttributeName] : []
  const craftingLevelAttributes = currentCraft !== undefined && currentCraft.levels !== undefined ? currentCraft.levels.filter(level => level[objectAttributeName] !== undefined && level.value <= parseInt(store.state.character.personality.level)) : []
  const craftingSkillLevelAttributes =
    currentCraft !== undefined && currentCraft.skillLevels !== undefined
      ? currentCraft.skillLevels.filter(skillLevels => skillLevels[objectAttributeName] !== undefined && skillLevels.value <= parseInt(store.state.character.bodySkills.craft) + parseInt(this.bodySkillsPointsBonus.craft))
      : []
  const talentAttributes = currentTalents.filter(talent => talent[objectAttributeName] !== undefined).flatMap(talent => talent[objectAttributeName])
  const talentLevelsAttributes = currentTalents
    .filter(talent => talent.levels !== undefined)
    .flatMap(talent => talent.levels)
    .filter(level => level[objectAttributeName] !== undefined && level.value <= parseInt(store.state.character.personality.level))
    .map(level => level[objectAttributeName])
  const dependenciesAttributes = currentDependencies.filter(dependency => dependency[objectAttributeName] !== undefined).flatMap(dependency => dependency[objectAttributeName])
  const meleeWeaponAttributes = currentMeleeWeapons.filter(weaponValue => weaponValue[objectAttributeName] !== undefined).flatMap(weaponValue => weaponValue[objectAttributeName])
  const meleeWeaponLevelsAttributes = currentMeleeWeapons
    .filter(weapon => weapon.levels !== undefined)
    .flatMap(weapon => weapon.levels)
    .filter(level => level[objectAttributeName] !== undefined && level.value <= parseInt(store.state.character.personality.level))
    .map(level => level[objectAttributeName])
  const rangedWeaponAttributes = currentRangedWeapons.filter(weaponValue => weaponValue[objectAttributeName] !== undefined).flatMap(weaponValue => weaponValue[objectAttributeName])
  const rangedWeaponLevelsAttributes = currentRangedWeapons
    .filter(weapon => weapon.levels !== undefined)
    .flatMap(weapon => weapon.levels)
    .filter(level => level[objectAttributeName] !== undefined && level.value <= parseInt(store.state.character.personality.level))
    .map(level => level[objectAttributeName])
  const armorAttributes = currentArmor.filter(armorValue => armorValue[objectAttributeName] !== undefined).flatMap(armorValue => armorValue[objectAttributeName])
  const armorLevelsAttributes = currentArmor
    .filter(armor => armor.levels !== undefined)
    .flatMap(armor => armor.levels)
    .filter(level => level[objectAttributeName] !== undefined && level.value <= parseInt(store.state.character.personality.level))
    .map(level => level[objectAttributeName])
  const shieldAttributes = currentShield.filter(shieldValue => shieldValue[objectAttributeName] !== undefined).flatMap(shieldValue => shieldValue[objectAttributeName])
  const shieldLevelsAttributes = currentShield
    .filter(shield => shield.levels !== undefined)
    .flatMap(shield => shield.levels)
    .filter(level => level[objectAttributeName] !== undefined && level.value <= parseInt(store.state.character.personality.level))
    .map(level => level[objectAttributeName])
  const ringsAttributes = currentRings.filter(ringsValue => ringsValue[objectAttributeName] !== undefined).flatMap(ringsValue => ringsValue[objectAttributeName])
  const ringsLevelsAttributes = currentRings
    .filter(rings => rings.levels !== undefined)
    .flatMap(rings => rings.levels)
    .filter(level => level[objectAttributeName] !== undefined && level.value <= parseInt(store.state.character.personality.level))
    .map(level => level[objectAttributeName])
  const amuletsAttributes = currentAmulets.filter(amuletsValue => amuletsValue[objectAttributeName] !== undefined).flatMap(amuletsValue => amuletsValue[objectAttributeName])
  const amuletsLevelsAttributes = currentAmulets
    .filter(amulets => amulets.levels !== undefined)
    .flatMap(amulets => amulets.levels)
    .filter(level => level[objectAttributeName] !== undefined && level.value <= parseInt(store.state.character.personality.level))
    .map(level => level[objectAttributeName])
  return (
    getValueOfObjectAttributeFromList(genderAttributes, 'name', listObjectAttributeName, 'value') +
    getValueOfListAttributeFromList(genderLevelAttributes, objectAttributeName, 'name', listObjectAttributeName, 'value') +
    getValueOfObjectAttributeFromList(cultureAttributes, 'name', listObjectAttributeName, 'value') +
    getValueOfListAttributeFromList(cultureLevelAttributes, objectAttributeName, 'name', listObjectAttributeName, 'value') +
    getValueOfObjectAttributeFromList(talentAttributes, 'name', listObjectAttributeName, 'value') +
    getValueOfListAttributeFromList(talentLevelsAttributes, objectAttributeName, 'name', listObjectAttributeName, 'value') +
    getValueOfObjectAttributeFromList(clazzAttributes, 'name', listObjectAttributeName, 'value') +
    getValueOfListAttributeFromList(clazzLevelAttributes, objectAttributeName, 'name', listObjectAttributeName, 'value') +
    getValueOfObjectAttributeFromList(craftingAttributes, 'name', listObjectAttributeName, 'value') +
    getValueOfListAttributeFromList(craftingLevelAttributes, objectAttributeName, 'name', listObjectAttributeName, 'value') +
    getValueOfListAttributeFromList(craftingSkillLevelAttributes, objectAttributeName, 'name', listObjectAttributeName, 'value') +
    getValueOfObjectAttributeFromList(dependenciesAttributes, 'name', listObjectAttributeName, 'value') +
    getValueOfObjectAttributeFromList(meleeWeaponAttributes, 'name', listObjectAttributeName, 'value') +
    getValueOfListAttributeFromList(meleeWeaponLevelsAttributes, objectAttributeName, 'name', listObjectAttributeName, 'value') +
    getValueOfObjectAttributeFromList(rangedWeaponAttributes, 'name', listObjectAttributeName, 'value') +
    getValueOfListAttributeFromList(rangedWeaponLevelsAttributes, objectAttributeName, 'name', listObjectAttributeName, 'value') +
    getValueOfObjectAttributeFromList(armorAttributes, 'name', listObjectAttributeName, 'value') +
    getValueOfListAttributeFromList(armorLevelsAttributes, objectAttributeName, 'name', listObjectAttributeName, 'value') +
    getValueOfObjectAttributeFromList(shieldAttributes, 'name', listObjectAttributeName, 'value') +
    getValueOfListAttributeFromList(shieldLevelsAttributes, objectAttributeName, 'name', listObjectAttributeName, 'value') +
    getValueOfObjectAttributeFromList(ringsAttributes, 'name', listObjectAttributeName, 'value') +
    getValueOfListAttributeFromList(ringsLevelsAttributes, objectAttributeName, 'name', listObjectAttributeName, 'value') +
    getValueOfObjectAttributeFromList(amuletsAttributes, 'name', listObjectAttributeName, 'value') +
    getValueOfListAttributeFromList(amuletsLevelsAttributes, objectAttributeName, 'name', listObjectAttributeName, 'value')
  )
}
function getValueOfObjectAttribute(objectAttributeName) {
  const currentGender = getCurrentGender()
  const currentCulture = getCurrentCulture()
  const currentClazz = getCurrentClazz()
  const currentCraft = getCurrentCraft()
  const currentTalents = getCurrentTalents()
  const currentRings = getCurrentRings()
  const currentAmulets = getCurrentAmulets()
  const currentArmor = getCurrentArmor()
  const currentShield = getCurrentShield()
  const currentMeleeWeapons = getCurrentMeleeWeapons()
  const currentRangedWeapons = getCurrentRangedWeapons()
  const currentDependencies = getCurrentImpact()

  const genderPointsValue = currentGender !== undefined && currentGender[objectAttributeName] !== undefined ? currentGender[objectAttributeName] : 0
  const genderLevelPoints = currentGender !== undefined && currentGender.levels !== undefined ? currentGender.levels.filter(level => level[objectAttributeName] !== undefined && level.value <= parseInt(store.state.character.personality.level)) : []
  const genderLevelPointsValue = genderLevelPoints.length > 0 ? genderLevelPoints.map(level => level[objectAttributeName]).reduce((accumulator, currentValue) => accumulator + currentValue) : 0
  const culturePointsValue = currentCulture !== undefined && currentCulture[objectAttributeName] !== undefined ? currentCulture[objectAttributeName] : 0
  const cultureLevelPoints =
    currentCulture !== undefined && currentCulture.levels !== undefined ? currentCulture.levels.filter(level => level[objectAttributeName] !== undefined && level.value <= parseInt(store.state.character.personality.level)) : []
  const cultureLevelPointsValue = cultureLevelPoints.length > 0 ? cultureLevelPoints.map(level => level[objectAttributeName]).reduce((accumulator, currentValue) => accumulator + currentValue) : 0
  const clazzPointsValue = currentClazz !== undefined && currentClazz[objectAttributeName] !== undefined ? currentClazz[objectAttributeName] : 0
  const clazzLevelPoints = currentClazz !== undefined && currentClazz.levels !== undefined ? currentClazz.levels.filter(level => level[objectAttributeName] !== undefined && level.value <= parseInt(store.state.character.personality.level)) : []
  const clazzLevelPointsValue = clazzLevelPoints.length > 0 ? clazzLevelPoints.map(level => level[objectAttributeName]).reduce((accumulator, currentValue) => accumulator + currentValue) : 0
  const craftingPointsValue = currentCraft !== undefined && currentCraft[objectAttributeName] !== undefined ? currentCraft[objectAttributeName] : 0
  const craftingLevelPoints = currentCraft !== undefined && currentCraft.levels !== undefined ? currentCraft.levels.filter(level => level[objectAttributeName] !== undefined && level.value <= parseInt(store.state.character.personality.level)) : []
  const craftingLevelPointsValue = craftingLevelPoints.length > 0 ? craftingLevelPoints.map(level => level[objectAttributeName]).reduce((accumulator, currentValue) => accumulator + currentValue) : 0
  const craftingSkillLevelPoints =
    currentCraft !== undefined && currentCraft.skillLevels !== undefined
      ? currentCraft.skillLevels.filter(skillLevel => skillLevel[objectAttributeName] !== undefined && skillLevel.value <= parseInt(store.state.character.bodySkills.craft) + parseInt(this.bodySkillsPointsBonus.craft))
      : []
  const craftingSkillLevelPointsValue = craftingSkillLevelPoints.length > 0 ? craftingSkillLevelPoints.map(skillLevel => skillLevel[objectAttributeName]).reduce((accumulator, currentValue) => accumulator + currentValue) : 0
  const talentPoints = store.state.character.talents.map(talent => getValueOfObjectAttributeFromList(store.state.talents, 'name', talent, objectAttributeName))
  const talentPointsValue = talentPoints.length > 0 ? talentPoints.reduce((accumulator, currentValue) => accumulator + currentValue) : 0
  const talentLevelsPoints = currentTalents
    .filter(talent => talent.levels !== undefined)
    .flatMap(talent => talent.levels)
    .filter(level => level[objectAttributeName] !== undefined && level.value <= parseInt(store.state.character.personality.level))
    .map(level => level[objectAttributeName])
  const talentLevelsPointsValue = talentLevelsPoints.length > 0 ? talentLevelsPoints.reduce((accumulator, currentValue) => accumulator + currentValue) : 0
  const dependency = currentDependencies !== undefined && currentDependencies[objectAttributeName] !== undefined ? currentDependencies[objectAttributeName] : 0
  const dependentLevelPoints = currentDependencies
    .filter(dependency => dependency.levels !== undefined)
    .flatMap(dependency => dependency.levels)
    .filter(level => level[objectAttributeName] !== undefined && level.value <= parseInt(store.state.character.personality.level))
    .map(level => level[objectAttributeName])
  const dependentLevelPointsValue = dependentLevelPoints.length > 0 ? dependentLevelPoints.reduce((accumulator, currentValue) => accumulator + currentValue) : 0
  const meleeWeaponPoints = store.state.character.inventory.weapons.melee.filter(weapon => weapon !== '').map(weapon => getValueOfObjectAttributeFromList(store.state.items.weapons.melee, 'name', weapon, objectAttributeName))
  const meleeWeaponPointsValue = meleeWeaponPoints.length > 0 ? meleeWeaponPoints.reduce((accumulator, currentValue) => accumulator + currentValue) : 0
  const meleeWeaponLevelsPoints = currentMeleeWeapons
    .filter(weapon => weapon.levels !== undefined)
    .flatMap(weapon => weapon.levels)
    .filter(level => level[objectAttributeName] !== undefined && level.value <= parseInt(store.state.character.personality.level))
    .map(level => level[objectAttributeName])
  const meleeWeaponLevelsPointsValue = meleeWeaponLevelsPoints.length > 0 ? meleeWeaponLevelsPoints.reduce((accumulator, currentValue) => accumulator + currentValue) : 0
  const rangedWeaponPoints = store.state.character.inventory.weapons.ranged.filter(weapon => weapon !== '').map(weapon => getValueOfObjectAttributeFromList(store.state.items.weapons.ranged, 'name', weapon, objectAttributeName))
  const rangedWeaponPointsValue = rangedWeaponPoints.length > 0 ? rangedWeaponPoints.reduce((accumulator, currentValue) => accumulator + currentValue) : 0
  const rangedWeaponLevelsPoints = currentRangedWeapons
    .filter(weapon => weapon.levels !== undefined)
    .flatMap(weapon => weapon.levels)
    .filter(level => level[objectAttributeName] !== undefined && level.value <= parseInt(store.state.character.personality.level))
    .map(level => level[objectAttributeName])
  const rangedWeaponLevelsPointsValue = rangedWeaponLevelsPoints.length > 0 ? rangedWeaponLevelsPoints.reduce((accumulator, currentValue) => accumulator + currentValue) : 0
  const armorPoints = store.state.character.inventory.armor.filter(armor => armor !== '').map(armor => getValueOfObjectAttributeFromList(store.state.items.armor, 'name', armor, objectAttributeName))
  const armorPointsValue = armorPoints.length > 0 ? armorPoints.reduce((accumulator, currentValue) => accumulator + currentValue) : 0
  const armorLevelsPoints = currentArmor
    .filter(armor => armor.levels !== undefined)
    .flatMap(armor => armor.levels)
    .filter(level => level[objectAttributeName] !== undefined && level.value <= parseInt(store.state.character.personality.level))
    .map(level => level[objectAttributeName])
  const armorLevelsPointsValue = armorLevelsPoints.length > 0 ? armorLevelsPoints.reduce((accumulator, currentValue) => accumulator + currentValue) : 0
  const shieldPoints = store.state.character.inventory.shield.filter(shield => shield !== '').map(shield => getValueOfObjectAttributeFromList(store.state.items.shield, 'name', shield, objectAttributeName))
  const shieldPointsValue = shieldPoints.length > 0 ? shieldPoints.reduce((accumulator, currentValue) => accumulator + currentValue) : 0
  const shieldLevelsPoints = currentShield
    .filter(shield => shield.levels !== undefined)
    .flatMap(shield => shield.levels)
    .filter(level => level[objectAttributeName] !== undefined && level.value <= parseInt(store.state.character.personality.level))
    .map(level => level[objectAttributeName])
  const shieldLevelsPointsValue = shieldLevelsPoints.length > 0 ? shieldLevelsPoints.reduce((accumulator, currentValue) => accumulator + currentValue) : 0
  const ringsPoints = store.state.character.inventory.jewellery.rings.filter(rings => rings !== '').map(rings => getValueOfObjectAttributeFromList(store.state.items.jewellery.rings, 'name', rings, objectAttributeName))
  const ringsPointsValue = ringsPoints.length > 0 ? ringsPoints.reduce((accumulator, currentValue) => accumulator + currentValue) : 0
  const ringsLevelsPoints = currentRings
    .filter(rings => rings.levels !== undefined)
    .flatMap(rings => rings.levels)
    .filter(level => level[objectAttributeName] !== undefined && level.value <= parseInt(store.state.character.personality.level))
    .map(level => level[objectAttributeName])
  const ringsLevelsPointsValue = ringsLevelsPoints.length > 0 ? ringsLevelsPoints.reduce((accumulator, currentValue) => accumulator + currentValue) : 0
  const amuletsPoints = store.state.character.inventory.jewellery.amulets.filter(amulets => amulets !== '').map(amulets => getValueOfObjectAttributeFromList(store.state.items.jewellery.amulets, 'name', amulets, objectAttributeName))
  const amuletsPointsValue = amuletsPoints.length > 0 ? amuletsPoints.reduce((accumulator, currentValue) => accumulator + currentValue) : 0
  const amuletsLevelsPoints = currentAmulets
    .filter(amulets => amulets.levels !== undefined)
    .flatMap(amulets => amulets.levels)
    .filter(level => level[objectAttributeName] !== undefined && level.value <= parseInt(store.state.character.personality.level))
    .map(level => level[objectAttributeName])
  const amuletsLevelsPointsValue = amuletsLevelsPoints.length > 0 ? amuletsLevelsPoints.reduce((accumulator, currentValue) => accumulator + currentValue) : 0
  return (
    parseInt(genderPointsValue) +
    parseInt(genderLevelPointsValue) +
    parseInt(culturePointsValue) +
    parseInt(cultureLevelPointsValue) +
    parseInt(clazzPointsValue) +
    parseInt(clazzLevelPointsValue) +
    parseInt(talentPointsValue) +
    parseInt(talentLevelsPointsValue) +
    parseInt(dependency) +
    parseInt(dependentLevelPointsValue) +
    parseInt(craftingPointsValue) +
    parseInt(craftingLevelPointsValue) +
    parseInt(craftingSkillLevelPointsValue) +
    parseInt(meleeWeaponPointsValue) +
    parseInt(meleeWeaponLevelsPointsValue) +
    parseInt(rangedWeaponPointsValue) +
    parseInt(rangedWeaponLevelsPointsValue) +
    parseInt(armorPointsValue) +
    parseInt(armorLevelsPointsValue) +
    parseInt(shieldPointsValue) +
    parseInt(shieldLevelsPointsValue) +
    parseInt(ringsPointsValue) +
    parseInt(ringsLevelsPointsValue) +
    parseInt(amuletsPointsValue) +
    parseInt(amuletsLevelsPointsValue)
  )
}
function getValueOfObjectAttributeFromList(list, attributeIdentifierName, identifier, attributeValueName) {
  const entries = list.filter(entry => entry[attributeIdentifierName] !== undefined && entry[attributeValueName] !== undefined && entry[attributeIdentifierName] === identifier)
  return entries.length > 0 ? entries.map(entry => entry[attributeValueName]).reduce((accumulator, currentValue) => accumulator + currentValue) : 0
}
function getValueOfListAttributeFromList(list, objectAttributeName, attributeIdentifierName, identifier, attributeValueName) {
  const entries = list.map(levels => getValueOfObjectAttributeFromList(levels[objectAttributeName], attributeIdentifierName, identifier, attributeValueName))
  return entries.length > 0 ? entries.reduce((accumulator, currentValue) => accumulator + currentValue) : 0
}
function capitalize(s) {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}
export { getValueOfObjectAttribute, getValueOfObjectAttributeByName, getValueOfObjectAttributeFromList, getValueOfListAttributeFromList, capitalize }
