<template>
  <v-card outlined>
    <v-app-bar class="primary">
      <v-toolbar-title>
        {{ $t('components.CharacterGenerator.bodySkills.label') + ' ( KF / ' + $store.getters.characterBodySkillPoints + ' )' }}
      </v-toolbar-title>
    </v-app-bar>
    <v-container fluid>
      <v-row style="padding-top: 25px">
        <v-col>
          <Value
            :title="$t('components.CharacterGenerator.bodySkills.athletics')"
            :value="$store.getters.characterBodySkills.athletics"
            :value-bonus="$store.getters.characterBodySkillsBonus.athletics"
            :min="$store.getters.characterBodySkillsMin.athletics"
            :max="$store.getters.characterBodySkillsMax.athletics"
            :value-name="'athletics'"
            @changed-value="valueChange"
          />
          <Value
            :title="$t('components.CharacterGenerator.bodySkills.acrobatics')"
            :value="$store.getters.characterBodySkills.acrobatics"
            :value-bonus="$store.getters.characterBodySkillsBonus.acrobatics"
            :min="$store.getters.characterBodySkillsMin.acrobatics"
            :max="$store.getters.characterBodySkillsMax.acrobatics"
            :value-name="'acrobatics'"
            @changed-value="valueChange"
          />
          <Value
            :title="$t('components.CharacterGenerator.bodySkills.craft')"
            :value="$store.getters.characterBodySkills.craft"
            :value-bonus="$store.getters.characterBodySkillsBonus.craft"
            :min="$store.getters.characterBodySkillsMin.craft"
            :max="$store.getters.characterBodySkillsMax.craft"
            :value-name="'craft'"
            @changed-value="valueChange"
          />
          <Value
            :title="$t('components.CharacterGenerator.bodySkills.medicine')"
            :value="$store.getters.characterBodySkills.medicine"
            :value-bonus="$store.getters.characterBodySkillsBonus.medicine"
            :min="$store.getters.characterBodySkillsMin.medicine"
            :max="$store.getters.characterBodySkillsMax.medicine"
            :value-name="'medicine'"
            @changed-value="valueChange"
          />
          <Value
            :title="$t('components.CharacterGenerator.bodySkills.stealth')"
            :value="$store.getters.characterBodySkills.stealth"
            :value-bonus="$store.getters.characterBodySkillsBonus.stealth"
            :min="$store.getters.characterBodySkillsMin.stealth"
            :max="$store.getters.characterBodySkillsMax.stealth"
            :value-name="'stealth'"
            @changed-value="valueChange"
          />
          <Value
            :title="$t('components.CharacterGenerator.bodySkills.cooking')"
            :value="$store.getters.characterBodySkills.cooking"
            :value-bonus="$store.getters.characterBodySkillsBonus.cooking"
            :min="$store.getters.characterBodySkillsMin.cooking"
            :max="$store.getters.characterBodySkillsMax.cooking"
            :value-name="'cooking'"
            @changed-value="valueChange"
          />
          <Value
            :title="$t('components.CharacterGenerator.bodySkills.mechanics')"
            :value="$store.getters.characterBodySkills.mechanics"
            :value-bonus="$store.getters.characterBodySkillsBonus.mechanics"
            :min="$store.getters.characterBodySkillsMin.mechanics"
            :max="$store.getters.characterBodySkillsMax.mechanics"
            :value-name="'mechanics'"
            @changed-value="valueChange"
          />
          <Value
            :title="$t('components.CharacterGenerator.bodySkills.wildLife')"
            :value="$store.getters.characterBodySkills.wildLife"
            :value-bonus="$store.getters.characterBodySkillsBonus.wildLife"
            :min="$store.getters.characterBodySkillsMin.wildLife"
            :max="$store.getters.characterBodySkillsMax.wildLife"
            :value-name="'wildLife'"
            @changed-value="valueChange"
          />
          <EmptyValue />
          <EmptyValue />
          <EmptyValue />
          <EmptyValue />
          <EmptyValue />
          <EmptyValue />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import Value from '@/components/content/Value'
import EmptyValue from '@/components/content/EmptyValue'
import { setBodySkill } from '@/services/bodySkills.service'

export default {
  name: 'BodySkillsView',
  components: { Value, EmptyValue },
  methods: {
    valueChange(skill) {
      setBodySkill(skill.name, skill.value)
    }
  }
}
</script>
