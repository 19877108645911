<template>
  <v-card outlined>
    <v-app-bar class="primary">
      <v-toolbar-title>
        {{ $t('components.CharacterGenerator.talents.label') + ' (' + talentPoints + ')' }}
      </v-toolbar-title>
    </v-app-bar>
    <v-container fluid>
      <v-expansion-panels class="secondary" focusable accordion>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>{{ $t('components.CharacterGenerator.talents.select') }}</h3>
            <template v-slot:actions>
              <Availability :selectable="usualTalents.length" :selected="selectedUsualTalents.length" />
              <v-icon color="primary"> $expand </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <Talent :header="$t('components.CharacterGenerator.talents.select')" :talents="usualTalents" :selected-talents="selectedUsualTalents" />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>{{ $t('components.CharacterGenerator.talents.selectSkillFocus') }}</h3>
            <template v-slot:actions>
              <Availability :selectable="skillFocusTalents.length" :selected="selectedSkillFocusTalents.length" />
              <v-icon color="primary"> $expand </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <Talent :header="$t('components.CharacterGenerator.talents.selectSkillFocus')" :talents="skillFocusTalents" :selected-talents="selectedSkillFocusTalents" />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>{{ $t('components.CharacterGenerator.talents.selectWeaponFocus') }}</h3>
            <template v-slot:actions>
              <Availability :selectable="weaponFocusTalents.length" :selected="selectedWeaponFocusTalents.length" />
              <v-icon color="primary"> $expand </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <Talent :header="$t('components.CharacterGenerator.talents.selectWeaponFocus')" :talents="weaponFocusTalents" :selected-talents="selectedWeaponFocusTalents" />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-if="$store.state.character.personality.caster && $store.state.character.personality.clazz !== 'runeFighter'">
          <v-expansion-panel-header>
            <h3>{{ $t('components.CharacterGenerator.talents.selectMagicFocus') }}</h3>
            <template v-slot:actions>
              <Availability :selectable="magicFocusTalents.length" :selected="selectedMagicFocusTalents.length" />
              <v-icon color="primary"> $expand </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <Talent :header="$t('components.CharacterGenerator.talents.selectMagicFocus')" :talents="magicFocusTalents" :selected-talents="selectedMagicFocusTalents" />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-if="$store.state.character.personality.caster && $store.state.character.personality.clazz === 'runeFighter'">
          <v-expansion-panel-header>
            <h3>{{ $t('components.CharacterGenerator.talents.selectRuneFocus') }}</h3>
            <template v-slot:actions>
              <Availability :selectable="runeFocusTalents.length" :selected="selectedRuneFocusTalents.length" />
              <v-icon color="primary"> $expand </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <Talent :header="$t('components.CharacterGenerator.talents.selectRuneFocus')" :talents="runeFocusTalents" :selected-talents="selectedRuneFocusTalents" />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-if="$store.state.character.personality.clazz.startsWith('sacred')">
          <v-expansion-panel-header>
            <h3>{{ $t('components.CharacterGenerator.talents.selectSacred') }}</h3>
            <template v-slot:actions>
              <Availability :selectable="sacredTalents.length" :selected="selectedSacredTalents.length" />
              <v-icon color="primary"> $expand </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <Talent :header="$t('components.CharacterGenerator.talents.selectSacred')" :talents="sacredTalents" :selected-talents="selectedSacredTalents" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
  </v-card>
</template>

<script>
import { getTalentForName } from '@/services/talent.service'
import { notAllDependenciesReached } from '@/services/dependency.service'
import Talent from '@/components/content/Talent'
import Availability from '@/components/content/Availability'

export default {
  name: 'Talents',
  components: { Availability, Talent },
  computed: {
    talentPoints() {
      if (this.isDilettanteMagician() && this.hasNotSelectedTriggerMoment()) {
        return this.$store.getters.characterTalentPoints - 1
      }
      return this.$store.getters.characterTalentPoints
    },
    selectedUsualTalents() {
      return this.$store.getters.characterTalents
        .map(talent => getTalentForName(talent))
        .filter(
          talent =>
            !talent.name.startsWith('skillFocus') &&
            !talent.name.startsWith('weaponFocus') &&
            !talent.name.startsWith('weaponSpecialist') &&
            !talent.name.startsWith('weaponMaster') &&
            !talent.name.startsWith('magicFocus') &&
            !talent.name.startsWith('magicAffinity') &&
            !talent.name.startsWith('magicSpecialization') &&
            !talent.name.startsWith('magicWeaver') &&
            !talent.name.startsWith('metaMagic') &&
            !talent.name.startsWith('rune') &&
            !talent.name.startsWith('sacred') &&
            !talent.name.startsWith('potence') &&
            !talent.name.startsWith('triggerMoment')
        )
    },
    usualTalents() {
      return this.$store.getters.talents
        .filter(
          talent =>
            !talent.name.startsWith('skillFocus') &&
            !talent.name.startsWith('weaponFocus') &&
            !talent.name.startsWith('weaponSpecialist') &&
            !talent.name.startsWith('weaponMaster') &&
            !talent.name.startsWith('magicFocus') &&
            !talent.name.startsWith('magicAffinity') &&
            !talent.name.startsWith('magicSpecialization') &&
            !talent.name.startsWith('magicWeaver') &&
            !talent.name.startsWith('metaMagic') &&
            !talent.name.startsWith('magicVirtuose') &&
            !talent.name.startsWith('rune') &&
            !talent.name.startsWith('sacred') &&
            !talent.name.startsWith('potence') &&
            !talent.name.startsWith('triggerMoment')
        )
        .filter(talent => this.$store.getters.configuration.showDisabledTalents || !this.selectAbleCharacterTalentIsDisabled(talent))
    },
    selectedSkillFocusTalents() {
      return this.$store.getters.characterTalents.map(talent => getTalentForName(talent)).filter(talent => talent.name.startsWith('skillFocus'))
    },
    skillFocusTalents() {
      return this.$store.getters.talents.filter(talent => talent.name.startsWith('skillFocus')).filter(talent => this.$store.getters.configuration.showDisabledTalents || !this.selectAbleCharacterTalentIsDisabled(talent))
    },
    selectedWeaponFocusTalents() {
      return this.$store.getters.characterTalents.map(talent => getTalentForName(talent)).filter(talent => talent.name.startsWith('weaponFocus') || talent.name.startsWith('weaponSpecialist') || talent.name.startsWith('weaponMaster'))
    },
    weaponFocusTalents() {
      return this.$store.getters.talents
        .filter(talent => talent.name.startsWith('weaponFocus') || talent.name.startsWith('weaponSpecialist') || talent.name.startsWith('weaponMaster'))
        .filter(talent => this.$store.getters.configuration.showDisabledTalents || !this.selectAbleCharacterTalentIsDisabled(talent))
    },
    selectedMagicFocusTalents() {
      return this.$store.getters.characterTalents
        .map(talent => getTalentForName(talent))
        .filter(
          talent =>
            talent.name.startsWith('magicFocus') ||
            talent.name.startsWith('magicAffinity') ||
            talent.name.startsWith('magicSpecialization') ||
            talent.name.startsWith('magicWeaver') ||
            talent.name.startsWith('metaMagic') ||
            talent.name.startsWith('magicVirtuose')
        )
    },
    magicFocusTalents() {
      return this.$store.getters.talents
        .filter(
          talent =>
            talent.name.startsWith('magicFocus') ||
            talent.name.startsWith('magicAffinity') ||
            talent.name.startsWith('magicSpecialization') ||
            talent.name.startsWith('magicWeaver') ||
            talent.name.startsWith('metaMagic') ||
            talent.name.startsWith('magicVirtuose')
        )
        .filter(talent => this.$store.getters.configuration.showDisabledTalents || !this.selectAbleCharacterTalentIsDisabled(talent))
    },
    runeFocusTalents() {
      return this.$store.getters.talents
        .filter(talent => talent.name.startsWith('rune') || talent.name.startsWith('potence'))
        .filter(talent => this.$store.getters.configuration.showDisabledTalents || !this.selectAbleCharacterTalentIsDisabled(talent))
    },
    selectedRuneFocusTalents() {
      return this.$store.getters.characterTalents.map(talent => getTalentForName(talent)).filter(talent => talent.name.startsWith('rune') || talent.name.startsWith('potence'))
    },
    sacredTalents() {
      return this.$store.getters.talents.filter(talent => talent.name.startsWith('sacred')).filter(talent => this.$store.getters.configuration.showDisabledTalents || !this.selectAbleCharacterTalentIsDisabled(talent))
    },
    selectedSacredTalents() {
      return this.$store.getters.characterTalents.map(talent => getTalentForName(talent)).filter(talent => talent.name.startsWith('sacred'))
    }
  },
  methods: {
    isTriggerMoment(talent) {
      return talent.name.startsWith('triggerMoment')
    },
    isDilettanteMagician() {
      return this.$store.state.character.personality.clazz.startsWith('dilettanteMagician')
    },
    hasNotSelectedTriggerMoment() {
      return this.$store.getters.characterTalents.filter(characterTalent => characterTalent.startsWith('triggerMoment')).length === 0
    },
    selectAbleCharacterTalentIsDisabled(talent) {
      const talentIsActive = this.$store.getters.characterTalents.filter(characterTalent => characterTalent === talent.name).length > 0
      const isNotMultiple = talent.multiple === undefined || !talent.multiple
      const notEnougthTalentPoints = this.$store.getters.characterTalentPoints <= 0
      return notEnougthTalentPoints || (talentIsActive && isNotMultiple) || notAllDependenciesReached(talent.dependencies) || (this.isDilettanteMagician() && this.hasNotSelectedTriggerMoment() && !this.isTriggerMoment(talent))
    }
  }
}
</script>
