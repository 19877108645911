import store from '../store'

function getGenderForName(genderName) {
  return store.getters.genders.find(gender => gender.name === genderName)
}
function getCurrentGender() {
  return getGenderForName(store.getters.characterGender)
}
function setGender(genderName) {
  store.dispatch('setCharacterGender', genderName)
}
export { getCurrentGender, getGenderForName, setGender }
