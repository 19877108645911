import store from '../store'
import { getAllBonusPointsByName, getBonusObjectByObjectNameAndAttributeNames } from './bonus.service'
import { getCurrentAttributes } from './attribute.service'

function getCurrentBaseValues() {
  const baseValues = getBaseValues()
  const baseValuesBonus = getBaseValuesBonus()
  return {
    attack: baseValues.attack + baseValuesBonus.attack,
    defense: baseValues.defense + baseValuesBonus.defense,
    distance: baseValues.distance + baseValuesBonus.distance,
    dodge: baseValues.dodge + baseValuesBonus.dodge,
    magicalResistance: baseValues.magicalResistance + baseValuesBonus.magicalResistance,
    magicalPower: baseValues.magicalPower + baseValuesBonus.magicalPower,
    health: baseValues.health + baseValuesBonus.health,
    soul: baseValues.soul + baseValuesBonus.soul,
    classModificatorHealth: getClassModificatorHealth(),
    classModificatorSoul: getClassModificatorSoul()
  }
}
function getBaseValues() {
  return {
    attack: Math.round(attackAttributeValues() / 5),
    defense: Math.round(defenseAttributeValues() / 5),
    distance: Math.round(distanceAttributeValues() / 4),
    dodge: Math.round(dodgeAttributeValues() / 5),
    magicalResistance: Math.round(magicalResistanceAttributeValues() / 2),
    magicalPower: store.state.character.personality.clazz.startsWith('dilettanteMagician') ? Math.ceil(dilettanteMagicianMagicalPowerAttributeValues()) : Math.round(magicalPowerAttributeValues() / 5),
    health: Math.round(healthAttributeValues() + parseInt(store.state.character.personality.level) * store.getters.characterClazzModificatorHealth),
    soul: Math.round(soulAttributeValues() + parseInt(store.state.character.personality.level) * store.getters.characterClazzModificatorSoul)
  }
}
function getBaseValuesBonus() {
  const additionalBaseValues = getBonusObjectByObjectNameAndAttributeNames('baseValues', Object.keys(store.state.character.baseValues))
  additionalBaseValues.health = additionalBaseValues.health + parseInt(store.state.configuration.additionalHealthPoints)
  additionalBaseValues.soul = additionalBaseValues.soul + parseInt(store.state.configuration.additionalSoulPoints)
  return additionalBaseValues
}
function attackAttributeValues() {
  const currentAttributes = getCurrentAttributes()
  const audacity = currentAttributes.audacity
  const bodySkill = currentAttributes.bodySkill
  const muscleStrength = currentAttributes.muscleStrength
  return audacity + bodySkill + muscleStrength
}
function defenseAttributeValues() {
  const currentAttributes = getCurrentAttributes()
  const audacity = currentAttributes.audacity
  const stomachFeeling = currentAttributes.stomachFeeling
  const muscleStrength = currentAttributes.muscleStrength
  return audacity + stomachFeeling + muscleStrength
}
function distanceAttributeValues() {
  const currentAttributes = getCurrentAttributes()
  const handSkill = currentAttributes.handSkill
  const muscleStrength = currentAttributes.muscleStrength
  return handSkill + handSkill + muscleStrength
}
function dodgeAttributeValues() {
  const currentAttributes = getCurrentAttributes()
  const audacity = currentAttributes.audacity
  const stomachFeeling = currentAttributes.stomachFeeling
  const bodySkill = currentAttributes.bodySkill
  return audacity + stomachFeeling + bodySkill
}
function magicalResistanceAttributeValues() {
  const currentAttributes = getCurrentAttributes()
  const thinking = currentAttributes.thinking
  const stomachFeeling = currentAttributes.stomachFeeling
  const characterStrength = currentAttributes.characterStrength
  return thinking + stomachFeeling + characterStrength
}
function dilettanteMagicianMagicalPowerAttributeValues() {
  const currentAttributes = getCurrentAttributes()
  const thinking = currentAttributes.thinking
  const characterStrength = currentAttributes.characterStrength
  return characterStrength - Math.ceil(thinking / 2)
}
function magicalPowerAttributeValues() {
  const currentAttributes = getCurrentAttributes()
  const thinking = currentAttributes.thinking
  const characterStrength = currentAttributes.characterStrength
  return thinking + thinking + characterStrength
}
function healthAttributeValues() {
  const currentAttributes = getCurrentAttributes()
  const condition = currentAttributes.condition
  return condition + condition
}
function soulAttributeValues() {
  const currentAttributes = getCurrentAttributes()
  const thinking = currentAttributes.thinking
  const characterStrength = currentAttributes.characterStrength
  return thinking + characterStrength
}
function getClassModificatorHealth() {
  return getAllBonusPointsByName('clazzModificatorHealth')
}
function getClassModificatorSoul() {
  return getAllBonusPointsByName('clazzModificatorSoul')
}
export { getCurrentBaseValues, getBaseValues, getBaseValuesBonus, getClassModificatorHealth, getClassModificatorSoul }
