<template>
  <v-row>
    <v-col cols="6" md="6">
      <v-text-field style="visibility: hidden" type="number" disabled outlined />
    </v-col>
    <v-col cols="6" md="6">
      <v-text-field style="visibility: hidden" type="number" disabled outlined />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'EmptyValue'
}
</script>
