<template>
  <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            {{ $t('components.CharacterGenerator.inventory.money.doubloons') }}
          </th>
          <th class="text-left">
            {{ $t('components.CharacterGenerator.inventory.money.silverPieces') }}
          </th>
          <th class="text-left">
            {{ $t('components.CharacterGenerator.inventory.money.penny') }}
          </th>
          <th class="text-left">
            {{ $t('components.CharacterGenerator.inventory.money.nickel') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <v-text-field v-model="$store.getters.characterMoney[0]" type="number" outlined dense class="money" />
          </td>
          <td>
            <v-text-field v-model="$store.getters.characterMoney[1]" type="number" outlined dense class="money" />
          </td>
          <td>
            <v-text-field v-model="$store.getters.characterMoney[2]" type="number" outlined dense class="money" />
          </td>
          <td>
            <v-text-field v-model="$store.getters.characterMoney[3]" type="number" outlined dense class="money" />
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>
<script>
export default {
  name: 'Money'
}
</script>
<style>
.money {
  height: 60px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
</style>
