<template>
  <v-card outlined>
    <v-app-bar class="primary">
      <v-toolbar-title>
        {{ $t('components.CharacterGenerator.attributes.label') + ' (' + $store.getters.characterAttributePoints + ')' }}
      </v-toolbar-title>
    </v-app-bar>
    <v-container fluid>
      <v-row style="padding-top: 25px">
        <v-col>
          <Value
            :title="$t('components.CharacterGenerator.attributes.audacity')"
            :value="$store.getters.characterAttributes.audacity"
            :value-bonus="$store.getters.characterAttributesBonus.audacity"
            :min="$store.getters.characterAttributesMin.audacity"
            :max="$store.getters.characterAttributesMax.audacity"
            :value-name="'audacity'"
            @changed-value="valueChange"
          />
          <Value
            :title="$t('components.CharacterGenerator.attributes.thinking')"
            :value="$store.getters.characterAttributes.thinking"
            :value-bonus="$store.getters.characterAttributesBonus.thinking"
            :min="$store.getters.characterAttributesMin.thinking"
            :max="$store.getters.characterAttributesMax.thinking"
            :value-name="'thinking'"
            @changed-value="valueChange"
          />
          <Value
            :title="$t('components.CharacterGenerator.attributes.stomachFeeling')"
            :value="$store.getters.characterAttributes.stomachFeeling"
            :value-bonus="$store.getters.characterAttributesBonus.stomachFeeling"
            :min="$store.getters.characterAttributesMin.stomachFeeling"
            :max="$store.getters.characterAttributesMax.stomachFeeling"
            :value-name="'stomachFeeling'"
            @changed-value="valueChange"
          />
          <Value
            :title="$t('components.CharacterGenerator.attributes.characterStrength')"
            :value="$store.getters.characterAttributes.characterStrength"
            :value-bonus="$store.getters.characterAttributesBonus.characterStrength"
            :min="$store.getters.characterAttributesMin.characterStrength"
            :max="$store.getters.characterAttributesMax.characterStrength"
            :value-name="'characterStrength'"
            @changed-value="valueChange"
          />
          <Value
            :title="$t('components.CharacterGenerator.attributes.handSkill')"
            :value="$store.getters.characterAttributes.handSkill"
            :value-bonus="$store.getters.characterAttributesBonus.handSkill"
            :min="$store.getters.characterAttributesMin.handSkill"
            :max="$store.getters.characterAttributesMax.handSkill"
            :value-name="'handSkill'"
            @changed-value="valueChange"
          />
          <Value
            :title="$t('components.CharacterGenerator.attributes.bodySkill')"
            :value="$store.getters.characterAttributes.bodySkill"
            :value-bonus="$store.getters.characterAttributesBonus.bodySkill"
            :min="$store.getters.characterAttributesMin.bodySkill"
            :max="$store.getters.characterAttributesMax.bodySkill"
            :value-name="'bodySkill'"
            @changed-value="valueChange"
          />
          <Value
            :title="$t('components.CharacterGenerator.attributes.condition')"
            :value="$store.getters.characterAttributes.condition"
            :value-bonus="$store.getters.characterAttributesBonus.condition"
            :min="$store.getters.characterAttributesMin.condition"
            :max="$store.getters.characterAttributesMax.condition"
            :value-name="'condition'"
            @changed-value="valueChange"
          />
          <Value
            :title="$t('components.CharacterGenerator.attributes.muscleStrength')"
            :value="$store.getters.characterAttributes.muscleStrength"
            :value-bonus="$store.getters.characterAttributesBonus.muscleStrength"
            :min="$store.getters.characterAttributesMin.muscleStrength"
            :max="$store.getters.characterAttributesMax.muscleStrength"
            :value-name="'muscleStrength'"
            @changed-value="valueChange"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import Value from '@/components/content/Value'
import { setAttribute } from '@/services/attribute.service'

export default {
  name: 'Attributes',
  components: { Value },
  methods: {
    valueChange(attribute) {
      setAttribute(attribute.name, attribute.value)
    }
  }
}
</script>
