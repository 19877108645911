import i18n from '../i18n'

export function createSelect (elements, translationKey) {
  if (elements !== undefined && elements !== null) {
    const selectEntries = elements.map(element => createOption(element, translationKey))
    selectEntries.unshift(createEmptyOption())
    return selectEntries
  }
  return []
}

export function createOption (element, translationKey) {
  const translation = i18n.t(translationKey + element)
  return {
    text: translation,
    value: element
  }
}

export function createSelectWithPostFix (elements, translationKey, postfix) {
  if (elements !== undefined && elements !== null) {
    const selectEntries = elements.map(element => createOptionWithPostFix(element, translationKey, postfix))
    selectEntries.unshift(createEmptyOption())
    return selectEntries
  }
  return []
}

export function createOptionWithPostFix (element, translationKey, postfix) {
  const translation = i18n.t(translationKey + element + postfix)
  return {
    text: translation,
    value: element
  }
}

export function createSelectByName (elements, translationKey) {
  if (elements !== undefined && elements !== null) {
    const selectEntries = elements.map(element => createOptionByName(element, translationKey))
    selectEntries.unshift(createEmptyOption())
    return selectEntries
  }
  return []
}

export function createOptionByName (element, translationKey) {
  const translation = i18n.t(translationKey + element.name)
  return {
    text: translation,
    value: element.name
  }
}

export function createSelectByNameWithPostFix (elements, translationKey, postfix) {
  if (elements !== undefined && elements !== null) {
    const selectEntries = elements.map(element => createOptionByNameWithPostFix(element, translationKey, postfix))
    selectEntries.unshift(createEmptyOption())
    return selectEntries
  }
  return []
}

export function createOptionByNameWithPostFix (element, translationKey, postfix) {
  const translation = i18n.t(translationKey + element.name + postfix)
  return {
    text: translation,
    value: element.name
  }
}

export function createEmptyOption () {
  return {
    text: '',
    value: ''
  }
}
