import store from '../store'
import { allDependenciesReached, notAllDependenciesReached } from './dependency.service'

function getImpactForName(impactName) {
  return store.getters.impact.find(impact => impact.name === impactName)
}
function getCurrentImpact() {
  return store.getters.characterImpact.map(impactName => getImpactForName(impactName))
}
function addImpact(impactName) {
  store.dispatch('addCharacterImpact', impactName)
}
function removeImpact(impactName) {
  store.dispatch('removeCharacterImpact', impactName)
}
function updateImpact() {
  const currentImpact = store.getters.characterImpact
  const impacToAdd = store.getters.impact.filter(impact => allDependenciesReached(impact.dependencies) && !currentImpact.includes(impact.name))
  impacToAdd.forEach(impact => addImpact(impact.name))
  const impacToRemove = store.getters.impact.filter(impact => notAllDependenciesReached(impact.dependencies) && currentImpact.includes(impact.name))
  impacToRemove.forEach(impact => removeImpact(impact.name))
}
export { getImpactForName, getCurrentImpact, updateImpact, addImpact, removeImpact }
