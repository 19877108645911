import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify)

const opts = {
  icons: {
    iconfont: 'mdi' // default - only for display purposes
  },
  theme: {
    dark: true,
    themes: {
      light: {
        primary: '#000000',
        secondary: '#0E8388'
      },
      dark: {
        primary: '#393E46',
        secondary: '#00ADB5',
        accent: '#00FFF5'
        // error: '#FF5252',
        // info: '#2196F3',
        // success: '#4CAF50',
        // warning: '#FFC107'
      }
    }
  }
}

export default new Vuetify(opts)
