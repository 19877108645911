<template>
  <v-app>
    <AppBar />
    <Navigation />
    <v-main>
      <v-overlay :value="!showCharacterGenerator">
        <v-progress-circular indeterminate size="64" />
      </v-overlay>
      <Content v-if="showCharacterGenerator" />
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import Content from '@/components/content/Content'
import AppBar from '@/components/header/AppBar'
import Navigation from '@/components/header/Navigation'
import Footer from '@/components/footer/Footer'

export default {
  name: 'App',
  components: {
    AppBar,
    Navigation,
    Content,
    Footer
  },
  data() {
    return {
      drawer: false
    }
  },
  computed: {
    showCharacterGenerator() {
      return (
        this.$store.state.configuration !== null &&
        this.$store.state.genders !== null &&
        this.$store.state.cultures !== null &&
        this.$store.state.clazzes !== null &&
        this.$store.state.talents !== null &&
        this.$store.state.spells !== null &&
        this.$store.state.craftings !== null &&
        this.$store.state.languages !== null &&
        this.$store.state.items !== null
      )
    }
  },
  methods: {
    async loadData() {
      await this.$store.dispatch('loadData')
      this.$forceUpdate()
    },
    capitalize(s) {
      if (typeof s !== 'string') return ''
      return s.charAt(0).toUpperCase() + s.slice(1)
    }
  },
  created() {
    this.loadData()
  }
}
</script>
<style lang="scss">
.v-application .font-weight-light {
  font-weight: 400 !important;
}
.theme--light.primary {
  color: #ffffff;
}
.theme--light.v-system-bar {
  color: #ffffff;
}

.v-application .font-weight-dark {
  font-weight: 400 !important;
}
.theme--dark.v-application {
  background: #222831 !important;
}
.theme--dark.v-navigation-drawer {
  background-color: #222831;
}
.theme--dark.v-sheet {
  background-color: #222831;
}
.theme--dark.v-expansion-panels .v-expansion-panel {
  background-color: #222831;
}

.theme--dark.v-btn-toggle:not(.v-btn-toggle--group) {
  background: #222831;
}
.theme--dark.v-data-table {
  background-color: #222831;
}

.theme--dark.v-btn.v-btn--has-bg {
  background-color: #00adb5;
}
.theme--dark.v-chip:not(.v-chip--active) {
  background: #00adb5;
}
.theme--light.v-card > .v-card__text, .theme--light.v-card > .v-card__subtitle {
  color: #ffffff;
}
</style>
