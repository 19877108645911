<template>
  <v-col>
    <v-text-field :value="value" type="number" :min="min" :max="max" :label="title" @click.stop="clicked" outlined />
  </v-col>
</template>

<script>
export default {
  name: 'Points',
  props: {
    title: {
      type: String,
      default: () => 'Title'
    },
    value: {
      type: Number,
      default: () => 0
    },
    valueName: {
      type: String,
      default: () => 'Value_Name'
    },
    allowChanges: {
      type: Boolean,
      required: false,
      default: () => true
    },
    min: {
      type: Number,
      required: false,
      default: () => 0
    },
    max: {
      type: Number,
      required: false,
      default: () => 999999
    }
  },
  methods: {
    clicked(event) {
      this.changed(event.srcElement._value)
    },
    changed(value) {
      this.$emit('changed-value', {
        name: this.valueName,
        value: parseInt(value)
      })
    }
  }
}
</script>
