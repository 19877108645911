<template>
  <v-row>
    <v-col cols="8" md="8">
      <v-text-field :value="value" :ref="'attributeValue'" type="number" :label="title" :disabled="!allowChanges" :min="min" :max="max" @change="changed" @click.stop="clicked" outlined></v-text-field>
    </v-col>
    <v-col cols="4" md="4">
      <v-text-field :value="valueBonus" type="number" :label="'Bonus'" outlined disabled />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'Value',
  model: {
    event: 'changed-value'
  },
  props: {
    title: {
      type: String,
      default: () => 'Title'
    },
    allowChanges: {
      type: Boolean,
      required: false,
      default: () => true
    },
    valueName: {
      type: String,
      default: () => 'Value_Name'
    },
    value: {
      type: Number,
      default: () => 0
    },
    valueBonus: {
      type: Number,
      default: () => 0
    },
    min: {
      type: Number,
      require: false,
      default: () => 0
    },
    max: {
      type: Number,
      require: false,
      default: () => 999999
    }
  },
  methods: {
    clicked(event) {
      this.changed(event.srcElement._value)
    },
    changed(value) {
      this.$emit('changed-value', {
        name: this.valueName,
        value: parseInt(value)
      })
    }
  }
}
</script>
