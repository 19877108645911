<template>
  <v-card>
    <v-app-bar class="primary">
      <v-toolbar-title>
        {{ $t('components.CharacterGenerator.configuration.label') }}
      </v-toolbar-title>
    </v-app-bar>
    <v-container fluid>
      <v-text-field v-model="$store.getters.configuration.levelMax" type="number" :label="$t('components.CharacterGenerator.configuration.levelMax')" outlined />
      <v-text-field v-model="$store.getters.configuration.levelMin" type="number" :label="$t('components.CharacterGenerator.configuration.levelMin')" outlined />
      <v-text-field v-model="$store.getters.configuration.additionalHealthPoints" type="number" :label="$t('components.CharacterGenerator.configuration.additionalHealthPoints')" outlined />
      <v-text-field v-model="$store.getters.configuration.additionalSoulPoints" type="number" :label="$t('components.CharacterGenerator.configuration.additionalSoulPoints')" outlined />
      <v-text-field v-model="$store.getters.configuration.attributePoints" type="number" :label="$t('components.CharacterGenerator.configuration.attributePoints')" outlined />
      <v-text-field v-model="$store.getters.configuration.attributePointsStart" type="number" :label="$t('components.CharacterGenerator.configuration.attributePointsStart')" outlined />
      <v-text-field v-model="$store.getters.configuration.attributePointsMin" type="number" :label="$t('components.CharacterGenerator.configuration.attributePointsMin')" outlined />
      <v-text-field v-model="$store.getters.configuration.attributePointsMax" type="number" :label="$t('components.CharacterGenerator.configuration.attributePointsMax')" outlined />
      <v-text-field v-model="$store.getters.configuration.weaponSkillsPoints" type="number" :label="$t('components.CharacterGenerator.configuration.weaponSkillsPoints')" outlined />
      <v-text-field v-model="$store.getters.configuration.weaponSkillsPointsMin" type="number" :label="$t('components.CharacterGenerator.configuration.weaponSkillsPointsMin')" outlined />
      <v-text-field v-model="$store.getters.configuration.weaponSkillsPointsMax" type="number" :label="$t('components.CharacterGenerator.configuration.weaponSkillsPointsMax')" outlined />
      <v-text-field v-model="$store.getters.configuration.bodySkillsPoints" type="number" :label="$t('components.CharacterGenerator.configuration.bodySkillsPoints')" outlined />
      <v-text-field v-model="$store.getters.configuration.bodySkillsPointsMin" type="number" :label="$t('components.CharacterGenerator.configuration.bodySkillsPointsMin')" outlined />
      <v-text-field v-model="$store.getters.configuration.bodySkillsPointsMax" type="number" :label="$t('components.CharacterGenerator.configuration.bodySkillsPointsMax')" outlined />
      <v-text-field v-model="$store.getters.configuration.mentalSkillsPoints" type="number" :label="$t('components.CharacterGenerator.configuration.mentalSkillsPoints')" outlined />
      <v-text-field v-model="$store.getters.configuration.mentalSkillsPointsMin" type="number" :label="$t('components.CharacterGenerator.configuration.mentalSkillsPointsMin')" outlined />
      <v-text-field v-model="$store.getters.configuration.mentalSkillsPointsMax" type="number" :label="$t('components.CharacterGenerator.configuration.mentalSkillsPointsMax')" outlined />
      <v-text-field v-model="$store.getters.configuration.talentPoints" type="number" :label="$t('components.CharacterGenerator.configuration.talentPoints')" outlined />
      <v-text-field v-model="$store.getters.configuration.spellPoints" type="number" :label="$t('components.CharacterGenerator.configuration.spellPoints')" outlined />
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: 'Configuration'
}
</script>
