import 'leaflet/dist/leaflet.css'
import Vue from 'vue'
import App from './App.vue'
import store from './store'
import i18n from './i18n'
import vuetify from './plugins/vuetify'
import underscore from 'vue-underscore'

Vue.config.productionTip = false
Vue.use(underscore)

new Vue({
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')
