import store from '../store'
import { getStateForLevel } from './states.service'
import { getAllBonusPointsByName, getBonusObjectByObjectNameAndAttributeNames } from './bonus.service'
import { getAllMalusPointsByName, getMalusObjectByObjectNameAndAttributeNames } from './malus.service'

function getAttributePoints() {
  return getStartAttributePoints() + additionalAttributePoints() - getUsedAttributePoints()
}
function getStartAttributePoints() {
  return parseInt(store.getters.configuration.attributePoints) + Object.keys(store.getters.characterAttributes).length * parseInt(store.getters.configuration.attributePointsStart)
}
function additionalAttributePoints() {
  const levelAttributePoints = (parseInt(store.state.character.personality.level) - (parseInt(store.state.character.personality.level) % 2)) / 2
  const bonusAttributePoints = getAllBonusPointsByName('attributePoints')
  const malusAttributePoints = getAllMalusPointsByName('attributePoints')
  return levelAttributePoints + bonusAttributePoints - malusAttributePoints
}
function getUsedAttributePoints() {
  return (
    parseInt(store.getters.characterAttributes.audacity) +
    parseInt(store.getters.characterAttributes.thinking) +
    parseInt(store.getters.characterAttributes.stomachFeeling) +
    parseInt(store.getters.characterAttributes.characterStrength) +
    parseInt(store.getters.characterAttributes.bodySkill) +
    parseInt(store.getters.characterAttributes.handSkill) +
    parseInt(store.getters.characterAttributes.condition) +
    parseInt(store.getters.characterAttributes.muscleStrength)
  )
}
function getCurrentAttributes() {
  const attributes = getAttributes()
  const attributesBonus = getAttributesBonus()
  return {
    audacity: attributes.audacity + attributesBonus.audacity,
    thinking: attributes.thinking + attributesBonus.thinking,
    stomachFeeling: attributes.stomachFeeling + attributesBonus.stomachFeeling,
    characterStrength: attributes.characterStrength + attributesBonus.characterStrength,
    handSkill: attributes.handSkill + attributesBonus.handSkill,
    bodySkill: attributes.bodySkill + attributesBonus.bodySkill,
    condition: attributes.condition + attributesBonus.condition,
    muscleStrength: attributes.muscleStrength + attributesBonus.muscleStrength
  }
}
function getAttributes() {
  return {
    audacity: parseInt(store.getters.characterAttributes.audacity),
    thinking: parseInt(store.getters.characterAttributes.thinking),
    stomachFeeling: parseInt(store.getters.characterAttributes.stomachFeeling),
    characterStrength: parseInt(store.getters.characterAttributes.characterStrength),
    handSkill: parseInt(store.getters.characterAttributes.handSkill),
    bodySkill: parseInt(store.getters.characterAttributes.bodySkill),
    condition: parseInt(store.getters.characterAttributes.condition),
    muscleStrength: parseInt(store.getters.characterAttributes.muscleStrength)
  }
}
function getAttributesBonus() {
  const attributesBonus = getBonusObjectByObjectNameAndAttributeNames('attributes', Object.keys(store.getters.characterAttributes))
  const attributesMalus = getMalusObjectByObjectNameAndAttributeNames('attributes', Object.keys(store.getters.characterAttributes))
  const calculatedAttributesBonus = {}
  Object.keys(store.getters.characterAttributes).forEach(attributeName => {
    calculatedAttributesBonus[attributeName] = attributesBonus[attributeName] - attributesMalus[attributeName]
  })
  return calculatedAttributesBonus
}
function getAttributesPointsMax() {
  const attributePointsMaxByLevel = 12 + parseInt(store.getters.characterLevel) * 4
  const attributePointsMaxValue = attributePointsMaxByLevel > parseInt(store.getters.configuration.attributePointsMax) ? parseInt(store.getters.configuration.attributePointsMax) : attributePointsMaxByLevel
  const attributePointsMax = {}
  const attributes = store.getters.characterAttributes
  const attributePoints = getAttributePoints()
  Object.keys(attributes).forEach(attributeName => {
    const realAttributePointsMaxValue = attributes[attributeName] + attributePoints
    attributePointsMax[attributeName] = attributePointsMaxValue > realAttributePointsMaxValue ? realAttributePointsMaxValue : attributePointsMaxValue
  })
  return attributePointsMax
}
function getAttributesPointsMin() {
  const characterState = getStateForLevel(store.getters.characterLevel - 1)
  const attributePointsMinValue = store.getters.configuration.attributePointsMin
  const attributePointsMin = {}
  Object.keys(store.getters.characterAttributes).forEach(attributeName => {
    if (characterState !== undefined) {
      const realAttributePointsMinValue = parseInt(characterState.attributes[attributeName])
      attributePointsMin[attributeName] = realAttributePointsMinValue > attributePointsMinValue ? realAttributePointsMinValue : attributePointsMinValue
    } else {
      attributePointsMin[attributeName] = attributePointsMinValue
    }
  })
  return attributePointsMin
}
function calculateValue(name, value) {
  const valueMin = store.getters.characterAttributesMin[name]
  const valueMax = store.getters.characterAttributesMax[name]
  let newValue = value
  if (value > valueMax) {
    newValue = valueMax
  } else if (value < valueMin) {
    newValue = valueMin
  }
  return newValue
}
function setAttribute(name, value) {
  const attributes = store.getters.characterAttributes
  attributes[name] = calculateValue(name, value)
  store.dispatch('setCharacterAttributes', attributes)
}
export { getCurrentAttributes, getAttributes, getAttributesBonus, getAttributePoints, getAttributesPointsMax, getAttributesPointsMin, setAttribute }
