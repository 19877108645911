import { getAllBonusPointsByName } from './bonus.service'
import { getAllMalusPointsByName } from './malus.service'
import { getCurrentAttributes } from './attribute.service'
import { hasTalent } from './talent.service'
import { getCurrentArmor } from './items.service'

function getMovementSpeed() {
  const currentAttributes = getCurrentAttributes()
  const totalClumsiness = getTotalClumsiness()
  const bodySkill = parseInt(currentAttributes.bodySkill)
  const muscleStrength = parseInt(currentAttributes.muscleStrength)
  const movementBonus = getAllBonusPointsByName('movementBonus')
  const movementMalus = getAllMalusPointsByName('movementBonus')
  return Math.ceil(((bodySkill + bodySkill + muscleStrength) / 2 - totalClumsiness) / 3) + movementBonus - movementMalus
}
function getTotalClumsiness() {
  const armor = getCurrentArmor()
  if (armor.length > 0) {
    const armorClumsiness = armor.map(entry => entry.clumsinessHead + entry.clumsinessTorso + entry.clumsinessLimbs).reduce((accumulator, currentValue) => accumulator + currentValue)
    return armorClumsiness + getTotalArmorStackCount(armor) - (hasArmorFocus() ? armor.filter(entry => entry.metal).length : 0)
  }
  return 0
}
function getTotalArmorStackCount(armor) {
  let stackCountHead = 0
  let stackCountTorso = 0
  let stackCountLimbs = 0
  armor.forEach(entry => {
    if (entry.clumsinessHead > 0) {
      stackCountHead = stackCountHead + 1
    }
    if (entry.clumsinessTorso > 0) {
      stackCountTorso = stackCountTorso + 1
    }
    if (entry.clumsinessLimbs > 0) {
      stackCountLimbs = stackCountLimbs + 1
    }
  })
  return (stackCountHead > 0 ? stackCountHead - 1 : 0) + (stackCountTorso > 0 ? stackCountTorso - 1 : 0) + (stackCountLimbs > 0 ? stackCountLimbs - 1 : 0)
}
function getSingleClumsiness(armor) {
  return armor.clumsinessHead + armor.clumsinessTorso + armor.clumsinessLimbs - (hasArmorFocus() ? (armor.metal ? 1 : 0) : 0)
}
function hasArmorFocus() {
  return hasTalent('armorFocus')
}
export { getSingleClumsiness, getTotalClumsiness, getMovementSpeed }
