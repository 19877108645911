<template>
  <l-map class="besodd-map" :options="mapOptions" :center="center" :zoom="zoom" :min-zoom="minZoom" :max-zoom="maxZoom" :max-bounds="maxBounds" @update:center="updateCenter" @update:zoom="updateZoom">
    <l-control-layers :position="layersPosition" :collapsed="false" :sort-layers="true" />
    <l-control>
      <v-btn color="black" @click="$store.state.map = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </l-control>
    <l-control>
      <v-btn color="black" @click="showMarker = !showMarker">
        <v-icon v-show="showMarker">mdi-map-marker</v-icon>
        <v-icon v-show="!showMarker">mdi-map-marker-off</v-icon>
      </v-btn>
    </l-control>
    <l-tile-layer v-for="tileProvider in tileProviders" :key="tileProvider.name" :name="tileProvider.name" :visible="tileProvider.visible" :url="tileProvider.url" :attribution="tileProvider.attribution" :no-wrap="true" layer-type="base" />
    <l-layer-group v-if="showMarker" name="advanture1">
      <l-marker :lat-lng="[-10.75, -16.4]">
        <l-icon :icon-url="require('../../assets/icons/village.svg')" />
        <l-tooltip content="Von Datinum folgten wir der Einladung nach Slehannon und traten die Reise mit den Kutschen an." />
      </l-marker>
      <l-polyline
        :lat-lngs="[
          [-10.75, -16.4],
          [3.75, -8.4]
        ]"
        color="#476261"
      ></l-polyline>
      <l-marker :lat-lng="[3.75, -8.4]">
        <l-icon :icon-url="require('../../assets/icons/carriage.svg')" />
      </l-marker>
      <l-polyline
        :lat-lngs="[
          [3.75, -8.4],
          [15.75, -18.4]
        ]"
        color="#476261"
      ></l-polyline>
      <l-marker :lat-lng="[15.75, -18.4]">
        <l-icon :icon-url="require('../../assets/icons/carriage.svg')" />
      </l-marker>
      <l-polyline
        :lat-lngs="[
          [15.75, -18.4],
          [28.9, -15.56]
        ]"
        color="#476261"
      ></l-polyline>
      <l-marker :lat-lng="[28.9, -15.56]">
        <l-icon :icon-url="require('../../assets/icons/village.svg')" />
        <l-tooltip content="Von Slehannon aus sind wir in die See gestochen zu unserem Abenteuer in das Yetiland." />
      </l-marker>
      <l-polyline
        :lat-lngs="[
          [28.9, -15.56],
          [32.8, -10.8]
        ]"
        color="#476261"
      ></l-polyline>
      <l-marker :lat-lng="[32.8, -10.8]">
        <l-icon :icon-url="require('../../assets/icons/boat.svg')" />
      </l-marker>
      <l-polyline
        :lat-lngs="[
          [32.8, -10.8],
          [32.8, -2.8]
        ]"
        color="#476261"
      ></l-polyline>
      <l-polyline
        :lat-lngs="[
          [32.8, -2.8],
          [30.3, -2.19]
        ]"
        color="#476261"
      ></l-polyline>
      <l-marker :lat-lng="[30.3, -2.19]">
        <l-icon :icon-url="require('../../assets/icons/repair.svg')" />
        <l-tooltip content="Wir sind mit unserem Boot in einen Sturm geraten und mussten es reparieren lassen in Olport. <br> Dadurch verloren wir 6 Tage ..." />
      </l-marker>
      <l-polyline
        :lat-lngs="[
          [30.3, -2.19],
          [29.8, 16.5]
        ]"
        color="#476261"
      ></l-polyline>
      <l-marker :lat-lng="[29.8, 16.5]">
        <l-icon :icon-url="require('../../assets/icons/boat.svg')" />
      </l-marker>
      <l-polyline
        :lat-lngs="[
          [29.8, 16.5],
          [39.8, 27.5]
        ]"
        color="#476261"
      ></l-polyline>
      <l-marker :lat-lng="[39.8, 27.5]">
        <l-icon :icon-url="require('../../assets/icons/boat.svg')" />
      </l-marker>
      <l-polyline
        :lat-lngs="[
          [39.8, 27.5],
          [39.8, 40.5]
        ]"
        color="#476261"
      ></l-polyline>
      <l-polyline
        :lat-lngs="[
          [39.8, 40.5],
          [37.8, 50.5]
        ]"
        color="#476261"
      ></l-polyline>
      <l-marker :lat-lng="[37.8, 50.5]">
        <l-icon :icon-url="require('../../assets/icons/boat.svg')" />
      </l-marker>
      <l-polyline
        :lat-lngs="[
          [37.8, 50.5],
          [33.65, 58.7]
        ]"
        color="#476261"
      ></l-polyline>
      <l-polyline
        :lat-lngs="[
          [33.65, 58.7],
          [35.65, 62.7]
        ]"
        color="#476261"
      ></l-polyline>
      <l-marker :lat-lng="[35.65, 62.7]">
        <l-icon :icon-url="require('../../assets/icons/campfire.svg')" />
        <l-tooltip
          content="Ankunft auf der Yetiland Insel. Hier zogen wir mit Schlittenhunden und Eisseglern in Richtung Festland los. <br> Wir sollen hier ... <br> ... die Zähne eines riesenhaften zweizähnigen Kopfschwänzler holen. <br> ... die Geheimnisse des des Turms ergründen der am Himmelsgewölbe aufgehängt ist."
        />
      </l-marker>
      <l-polyline
        :lat-lngs="[
          [35.65, 62.7],
          [36.8, 63.5]
        ]"
        color="#476261"
      ></l-polyline>
      <l-marker :lat-lng="[36.8, 63.5]">
        <l-icon :icon-url="require('../../assets/icons/elephant.svg')" />
        <l-tooltip content="Hier haben wir den riesenhaften zweizähnigen Kopfschwänzler erlegt." />
      </l-marker>
    </l-layer-group>
  </l-map>
</template>

<script>
import { latLngBounds, latLng } from 'leaflet'
import { LMap, LControl, LTileLayer, LControlLayers, LMarker, LIcon, LPolyline, LLayerGroup, LTooltip } from 'vue2-leaflet'

export default {
  name: 'Map',
  components: {
    LMap,
    LControl,
    LTileLayer,
    LControlLayers,
    LLayerGroup,
    LMarker,
    LIcon,
    LPolyline,
    LTooltip
  },
  props: {
    dice: {
      type: Number,
      default: () => 20
    }
  },
  data() {
    return {
      tileProviders: [
        {
          name: 'Biomes',
          visible: true,
          url: 'https://map.weppelmann.ddnss.de/maps/biomes/{z}/{x}/{y}.png'
        },
        {
          name: 'Provinzen',
          visible: false,
          url: 'https://map.weppelmann.ddnss.de/maps/provinces/{z}/{x}/{y}.png'
        },
        {
          name: 'Kulturen',
          visible: false,
          url: 'https://map.weppelmann.ddnss.de/maps/cultures/{z}/{x}/{y}.png'
        },
        {
          name: 'Religionen',
          visible: false,
          url: 'https://map.weppelmann.ddnss.de/maps/religions/{z}/{x}/{y}.png'
        }
      ],
      mapOptions: {
        zoomControl: true,
        attributionControl: false
      },
      zoom: 3,
      minZoom: 3,
      maxZoom: 6,
      maxBounds: latLngBounds([
        [63.105942, -168.324366],
        [-63.783282, 169.976263]
      ]),
      layersPosition: 'bottomright',
      currentZoom: 5,
      center: latLng(0, 0),
      currentCenter: latLng(0, 0),
      showMarker: false
    }
  },
  methods: {
    updateZoom(zoom) {
      this.currentZoom = zoom
    },
    updateCenter(center) {
      this.currentCenter = center
    }
  },
  mounted() {}
}
</script>
<style>
.besodd-map {
  min-height: calc(100vh - 75px);
  min-width: calc(100vw - 50px);
}
.leaflet-container {
  background: #000;
}

.leaflet-tooltip {
  border: 0px;
  border-radius: 4px;
  background: #000;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.375rem;
  letter-spacing: 0.0071428571em;
}
.leaflet-tooltip-right:before {
  border-right-color: #000;
}
.leaflet-tooltip-left:before {
  border-right-color: #000;
}
.leaflet-touch .leaflet-bar a:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.leaflet-touch .leaflet-bar a:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.leaflet-control-layers-expanded {
  padding: 6px 10px 6px 6px;
  color: #fff;
  background: #000;
}
.leaflet-control-layers-selector {
  accent-color: #000;
  cursor: pointer;
}
.leaflet-bar a:hover,
.leaflet-bar a:focus {
  cursor: default;
  color: #fff !important;
  background-color: #000;
  cursor: pointer;
}
.leaflet-bar a.leaflet-disabled {
  cursor: default;
  color: #fff !important;
  background-color: #000;
  cursor: not-allowed;
}
.leaflet-bar a {
  color: #fff !important;
  background-color: #000;
  border-bottom: 1px solid #fff;
  width: 26px;
  height: 26px;
  line-height: 26px;
  display: block;
  text-align: center;
  text-decoration: none;
  color: black;
}
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: 0;
}
.leaflet-touch .leaflet-control-zoom-in,
.leaflet-touch .leaflet-control-zoom-out {
  font-size: 18px;
}
path {
  stroke-dasharray: 5 10; /* dashes and gaps are both 5 units long */
}
</style>
