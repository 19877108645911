import store from '../store'
import { getCurrentPersonality } from './personality.service'
import { getCurrentAttributes } from './attribute.service'
import { getCurrentBaseValues } from './baseValue.service'
import { getCurrentWeaponSkills } from './weaponSkills.service'
import { hasTalent } from './talent.service'
import { getTotalClumsiness } from './armor.service'
import { getActualShieldAttackReduction, getActualDodgeShieldDefenseBonus } from './shield.service'
import { capitalize } from './objecthelper.service'

function getActualMeleeWeaponDamage(weapon) {
  return weapon.damage + getMeleeWeaponBonusDamage(weapon)
}
function getActualMeleeWeaponAttackAbility(weapon) {
  const bonus = hasTail() ? 1 : 0
  const malus = hasTwoHandedToBeConsidered(weapon) ? 0 : getActualShieldAttackReduction()
  return weapon.attack + bonus - malus
}
function getActualMeleeWeaponDefenseAbility(weapons, weapon) {
  const bonus = 0
  const malus = (hasTwoHandedToBeConsidered(weapon) ? 0 : getActualShieldAttackReduction()) + (hasTail() ? 1 : 0)
  if (hasMultipleOneHandedWeaponFighter(weapon, weapons)) {
    const multipleOneHandedWeaponBonus = getOffHand(weapons).balanced ? 2 : 1
    return getMainHand(weapons).defense + (bonus + multipleOneHandedWeaponBonus) - malus
  }
  return weapon.defense + bonus - malus
}
function getActualMeleeWeaponAttack(weapons, weapon) {
  const currentBaseValues = getCurrentBaseValues()
  const bonus = (hasWeaponMaster(weapon) ? 1 : 0) + (hasTwoHandedToBeConsidered(weapon) ? 1 : 0)
  const clumsiness = Math.ceil((getTotalClumsiness() - 2) / 2)
  const malus = clumsiness <= 0 ? 0 : clumsiness
  if (hasMultipleOneHandedWeaponFighter(weapon, weapons)) {
    return getActualMeleeWeaponAttackForTwoWeaponFighter(weapons, weapon, bonus, malus)
  } else if (hasTwoHandedToBeConsidered(weapon)) {
    let weaponSkillValue = 0
    if (hasPracticedTwoHandedWeaponsFighter()) {
      weaponSkillValue = Math.ceil(parseInt(getWeaponSkillValueFromWeapon(weapon)) / 2)
    } else if (hasTwoHandedWeaponFighter()) {
      weaponSkillValue = Math.ceil(Math.ceil(parseInt(getWeaponSkillValueFromWeapon(weapon)) / 2) / 2)
    }
    const result = parseInt(currentBaseValues.attack) + weaponSkillValue + bonus - malus
    return result < 0 ? 0 : result
  }
  const result = parseInt(currentBaseValues.attack) + Math.ceil(parseInt(getWeaponSkillValueFromWeapon(weapon)) / 2) + bonus - malus
  return result < 0 ? 0 : result
}
function getActualMeleeWeaponAttackForTwoWeaponFighter(weapons, weapon, bonus, malus) {
  const currentBaseValues = getCurrentBaseValues()
  const multipleOneHandedWeaponBonus = (hasDoubleWeaponFighter() ? 2 : 0) + (hasPracticedDoubleWeaponsFighter() ? 2 : 0) + (hasMasterDoubleWeaponsFighter() ? 2 : 0)
  const multipleOneHandedWeaponMalus = hasMainHand(weapon) ? 6 : hasOffHand(weapon) ? (weapon.balanced ? 8 : 10) : 0
  const result = parseInt(currentBaseValues.attack) + Math.ceil(getWeaponSkillMiddleOfGivenWeapons(weapons) / 2) + (bonus + multipleOneHandedWeaponBonus) - (malus + multipleOneHandedWeaponMalus)
  return result < 0 ? 0 : result
}
function getActualMeleeWeaponDefense(weapons, weapon) {
  const currentBaseValues = getCurrentBaseValues()
  const bonus = hasWeaponMaster(weapon) ? 1 : 0
  const clumsiness = Math.floor((getTotalClumsiness() - 2) / 2)
  const malus = (hasTwoHandedToBeConsidered(weapon) ? 1 : 0) + (clumsiness <= 0 ? 0 : clumsiness)
  if (hasMultipleOneHandedWeaponFighter(weapon, weapons)) {
    return getActualMeleeWeaponDefenseForTwoWeaponFighter(weapons, bonus, malus)
  } else if (hasTwoHandedToBeConsidered(weapon)) {
    let weaponSkillValue = 0
    if (hasPracticedTwoHandedWeaponsFighter()) {
      weaponSkillValue = Math.floor(parseInt(getWeaponSkillValueFromWeapon(weapon)) / 2)
    } else if (hasTwoHandedWeaponFighter()) {
      weaponSkillValue = Math.ceil(Math.floor(parseInt(getWeaponSkillValueFromWeapon(weapon)) / 2) / 2)
    }
    const result = parseInt(currentBaseValues.defense) + weaponSkillValue + bonus - malus
    return result < 0 ? 0 : result
  }
  const result = parseInt(currentBaseValues.defense) + Math.floor(parseInt(getWeaponSkillValueFromWeapon(weapon)) / 2) + bonus - malus
  return result < 0 ? 0 : result
}
function getActualMeleeWeaponDefenseForTwoWeaponFighter(weapons, bonus, malus) {
  const currentBaseValues = getCurrentBaseValues()
  const result = parseInt(currentBaseValues.defense) + Math.floor(getWeaponSkillMiddleOfGivenWeapons(weapons) / 2) + bonus - malus
  return result < 0 ? 0 : result
}
function getActualMeleeWeaponDodge(weapon) {
  const currentBaseValues = getCurrentBaseValues()
  const currentWeaponSkills = getCurrentWeaponSkills()
  const totalClumsiness = getTotalClumsiness()
  const actualMeleeWeaponDodgeMalus = getActualMeleeWeaponDodgeMalus(weapon)
  const actualDodgeShieldDefenseBonus = getActualDodgeShieldDefenseBonus()
  const malus = totalClumsiness + actualMeleeWeaponDodgeMalus + actualDodgeShieldDefenseBonus
  const actualDodge = parseInt(currentBaseValues.dodge) + Math.ceil(parseInt(currentWeaponSkills.dodge) / 2) - malus
  return actualDodge > 0 ? actualDodge : 0
}
function getActualMeleeWeaponDodgeMalus(weapon) {
  const bonus = hasMasterTwoHandedWeaponsFighter() ? 2 : 0
  const actualDodgeMalus = weapon.dodgeMalus - bonus
  return actualDodgeMalus > 0 ? actualDodgeMalus : 0
}
function getMeleeWeaponBonusDamage(weapon) {
  const currentAttributes = getCurrentAttributes()
  var attributeMaxValue = currentAttributes.muscleStrength
  if (weapon.balanced && hasWeaponFinesse()) {
    attributeMaxValue = currentAttributes.muscleStrength > currentAttributes.bodySkill ? currentAttributes.muscleStrength : currentAttributes.bodySkill
    attributeMaxValue = attributeMaxValue > currentAttributes.handSkill ? attributeMaxValue : currentAttributes.handSkill
  }
  const bonusDamage = attributeMaxValue - weapon.muscleStrengthBonus
  return bonusDamage > 0 ? bonusDamage : 0
}
function hasTail() {
  return hasTalent('tail')
}
function hasTwoHandedToBeConsidered(weapon) {
  return weapon.twoHanded && !hasStabOrSpear(weapon)
}
function hasMultipleOneHandedWeaponFighter(weapon, weapons) {
  return !hasTwoHandedToBeConsidered(weapon) && hasMainOrOffHand(weapon) && hasMultipleOneHandWeaponsEquipped(weapons)
}
function hasWeaponFinesse() {
  return hasTalent('weaponFinesse')
}
function hasWeaponMaster(weapon) {
  return hasTalent('weaponMaster' + capitalize(weapon.type))
}
function hasDoubleWeaponFighter() {
  return hasTalent('doubleWeaponFighter')
}
function hasPracticedDoubleWeaponsFighter() {
  return hasTalent('practicedDoubleWeaponsFighter')
}
function hasMasterDoubleWeaponsFighter() {
  return hasTalent('masterDoubleWeaponsFighter')
}
function hasTwoHandedWeaponFighter() {
  return hasTalent('twoHandedWeaponFighter')
}
function hasPracticedTwoHandedWeaponsFighter() {
  return hasTalent('practicedTwoHandedWeaponFighter')
}
function hasMasterTwoHandedWeaponsFighter() {
  return hasTalent('masterTwoHandedWeaponFighter')
}
function hasImprovedCriticalStrike(weapon) {
  return hasTalent('improvedCriticalStrike' + capitalize(weapon.type))
}
function hasAssassin() {
  return hasTalent('assassin')
}
function hasSacred() {
  const currentPersonality = getCurrentPersonality()
  return currentPersonality.clazz !== undefined && currentPersonality.clazz !== null && currentPersonality.clazz.startsWith('sacred')
}
function getWeaponSkillValueFromWeapon(weapon) {
  const currentWeaponSkills = getCurrentWeaponSkills()
  return currentWeaponSkills[weapon.type]
}
function getWeaponSkillMiddleOfGivenWeapons(weapons) {
  return (
    weapons !== undefined &&
    weapons !== null &&
    Math.floor(
      weapons
        .filter(weapon => hasMainOrOffHand(weapon))
        .filter(weapon => !hasStabOrSpear(weapon))
        .filter(weapon => !hasTwoHandedToBeConsidered(weapon))
        .map(weapon => parseInt(getWeaponSkillValueFromWeapon(weapon)))
        .reduce((accumulator, currentValue) => accumulator + currentValue) / 2
    )
  )
}
function hasMainOrOffHand(weapon) {
  return hasMainHand(weapon) || hasOffHand(weapon)
}
function getMainHand(weapons) {
  return weapons.find(weapon => hasMainHand(weapon))
}
function hasMainHand(weapon) {
  return store.state.character.inventory.weapons.melee[0] === weapon.name
}
function getOffHand(weapons) {
  return weapons.find(weapon => hasOffHand(weapon))
}
function hasOffHand(weapon) {
  return store.state.character.inventory.weapons.melee[1] === weapon.name
}
function hasMultipleOneHandWeaponsEquipped(weapons) {
  return (
    weapons !== undefined &&
    weapons !== null &&
    weapons
      .filter(weapon => hasMainOrOffHand(weapon))
      .filter(weapon => !hasStabOrSpear(weapon))
      .filter(weapon => !hasTwoHandedToBeConsidered(weapon)).length > 1
  )
}
function hasStabOrSpear(weapon) {
  return hasSpear(weapon) || hasStab(weapon)
}
function hasStab(weapon) {
  return weapon.type === 'stabWeapons'
}
function hasSpear(weapon) {
  return weapon.type === 'spears'
}
export {
  getActualMeleeWeaponDodgeMalus,
  getActualMeleeWeaponAttackAbility,
  getActualMeleeWeaponDefenseAbility,
  getActualMeleeWeaponAttack,
  getActualMeleeWeaponDefense,
  getActualMeleeWeaponDodge,
  getActualMeleeWeaponDamage,
  hasImprovedCriticalStrike,
  hasAssassin,
  hasSacred
}
