import store from '../store'

function notAllDependenciesReached(dependencies) {
  return !allDependenciesReached(dependencies)
}
function allDependenciesReached(dependencies) {
  if (dependencies === undefined) {
    return true
  }
  return allEveryDependencyReached(dependencies) && allSomeDependencyReached(dependencies) && allNotDependencyReached(dependencies)
}
function allEveryDependencyReached(dependencies) {
  const dependenciesEvery = dependencies.every
  if (dependenciesEvery === undefined) {
    return true
  }
  const isLevelReached = dependenciesEvery.level !== undefined ? parseInt(store.getters.characterLevel) >= dependenciesEvery.level : true
  const isDependingGender = dependenciesEvery.genders !== undefined && store.getters.characterGender !== undefined ? dependenciesEvery.genders.includes(store.getters.characterGender) : true
  const isDependingCulture = dependenciesEvery.cultures !== undefined && store.getters.characterCulture !== undefined ? dependenciesEvery.cultures.includes(store.getters.characterCulture) : true
  const isDependingClazz = dependenciesEvery.clazzes !== undefined && store.getters.characterClazz !== undefined ? dependenciesEvery.clazzes.includes(store.getters.characterClazz) : true
  const isCaster = dependenciesEvery.caster !== undefined && dependenciesEvery.caster ? store.state.character.personality.caster : true
  const isCraft = dependenciesEvery.crafts !== undefined ? dependenciesEvery.crafts.every(craft => store.getters.characterCraft === craft) : true
  const hasHairColor = dependenciesEvery.hairColors !== undefined ? dependenciesEvery.hairColors.every(hairColor => store.getters.characterHairColor === hairColor) : true
  const hasEyeColor = dependenciesEvery.eyeColors !== undefined ? dependenciesEvery.eyeColors.every(eyeColor => store.getters.characterEyeColor === eyeColor) : true
  const hasSkinColor = dependenciesEvery.skinColors !== undefined ? dependenciesEvery.skinColors.every(skillColor => store.getters.characterSkinColor === skillColor) : true
  const hasHigherAge = dependenciesEvery.higherAge !== undefined ? store.getters.characterAge >= dependenciesEvery.higherAge : true
  const hasHigherHeight = dependenciesEvery.higherSize !== undefined ? store.getters.characterSize >= dependenciesEvery.higherSize : true
  const hasHigherWeight = dependenciesEvery.higherWeight !== undefined ? store.getters.characterWeight >= dependenciesEvery.higherWeight : true
  const hasLowerAge = dependenciesEvery.lowerAge !== undefined ? store.getters.characterAge < dependenciesEvery.lowerAge : true
  const hasLowerHeight = dependenciesEvery.lowerSize !== undefined ? store.getters.characterSize < dependenciesEvery.lowerSize : true
  const hasLowerWeight = dependenciesEvery.lowerWeight !== undefined ? store.getters.characterWeight < dependenciesEvery.lowerWeight : true
  const hasAllDependingLanguages = dependenciesEvery.languages !== undefined ? dependenciesEvery.languages.every(language => store.getters.characterLanguages.includes(language)) : true
  const hasAllDependingTalents = dependenciesEvery.talents !== undefined ? dependenciesEvery.talents.every(talent => store.getters.characterTalents.includes(talent)) : true
  const hasAllDependingSpells = dependenciesEvery.spells !== undefined ? dependenciesEvery.spells.every(spell => store.getters.characterSpells.includes(spell)) : true
  const hasDependingAttributeValueReached = dependenciesEvery.attributes !== undefined ? dependenciesEvery.attributes.every(attribute => store.getters.characterAttributes[attribute.name] >= attribute.value) : true
  const hasDependingWeaponSkillValueReached = dependenciesEvery.weaponSkills !== undefined ? dependenciesEvery.weaponSkills.every(weaponSkill => store.getters.characterWeaponSkills[weaponSkill.name] >= weaponSkill.value) : true
  const hasDependingBodySkillValueReached = dependenciesEvery.bodySkills !== undefined ? dependenciesEvery.bodySkills.every(bodySkill => store.getters.characterBodySkills[bodySkill.name] >= bodySkill.value) : true
  const hasDependingMentalSkillValueReached = dependenciesEvery.mentalSkills !== undefined ? dependenciesEvery.mentalSkills.every(mentalSkill => store.getters.characterMentalSkills[mentalSkill.name] >= mentalSkill.value) : true
  const hasDependingArmor = dependenciesEvery.armor !== undefined ? dependenciesEvery.items.every(item => store.getters.characterArmor.includes(item)) : true
  const hasDependingShield = dependenciesEvery.shield !== undefined ? dependenciesEvery.items.every(item => store.getters.characterShield.includes(item)) : true
  const hasDependingWeaponsMelee = dependenciesEvery.weapons !== undefined && dependenciesEvery.weapons.melee !== undefined ? dependenciesEvery.items.every(item => store.getters.characterWeapons.melee.includes(item)) : true
  const hasDependingWeaponsRanged = dependenciesEvery.weapons !== undefined && dependenciesEvery.weapons.ranged !== undefined ? dependenciesEvery.items.every(item => store.getters.characterWeapons.ranged.includes(item)) : true
  const hasDependingRings = dependenciesEvery.rings !== undefined ? dependenciesEvery.items.every(item => store.getters.characterRings.includes(item)) : true
  const hasDependingAmulets = dependenciesEvery.amulets !== undefined ? dependenciesEvery.items.every(item => store.getters.characterAmulet.includes(item)) : true
  const hasDependingItems = dependenciesEvery.items !== undefined ? dependenciesEvery.items.every(item => store.getters.characterItems.some(characterItem => characterItem.startsWith(item))) : true
  return (
    isLevelReached &&
    isDependingGender &&
    isDependingCulture &&
    isDependingClazz &&
    isCaster &&
    isCraft &&
    hasHairColor &&
    hasEyeColor &&
    hasSkinColor &&
    hasHigherAge &&
    hasHigherHeight &&
    hasHigherWeight &&
    hasLowerAge &&
    hasLowerHeight &&
    hasLowerWeight &&
    hasAllDependingLanguages &&
    hasAllDependingTalents &&
    hasAllDependingSpells &&
    hasDependingAttributeValueReached &&
    hasDependingWeaponSkillValueReached &&
    hasDependingBodySkillValueReached &&
    hasDependingMentalSkillValueReached &&
    hasDependingArmor &&
    hasDependingShield &&
    hasDependingWeaponsMelee &&
    hasDependingWeaponsRanged &&
    hasDependingRings &&
    hasDependingAmulets &&
    hasDependingItems
  )
}
function allSomeDependencyReached(dependencies) {
  const dependenciesSome = dependencies.some
  if (dependenciesSome === undefined) {
    return true
  }
  const isLevelReached = dependenciesSome.level !== undefined ? parseInt(store.getters.characterLevel) >= dependenciesSome.level : true
  const isDependingGender = dependenciesSome.genders !== undefined && store.getters.characterGender !== undefined ? dependenciesSome.genders.includes(store.getters.characterGender) : true
  const isDependingCulture = dependenciesSome.cultures !== undefined && store.getters.characterCulture !== undefined ? dependenciesSome.cultures.includes(store.getters.characterCulture) : true
  const isDependingClazz = dependenciesSome.clazzes !== undefined && store.getters.characterClazz !== undefined ? dependenciesSome.clazzes.includes(store.getters.characterClazz) : true
  const isCaster = dependenciesSome.caster !== undefined && dependenciesSome.caster ? store.getters.caster : true
  const isCraft = dependenciesSome.crafts !== undefined ? dependenciesSome.crafts.some(craft => store.getters.characterCraft === craft) : true
  const hasHairColor = dependenciesSome.hairColors !== undefined ? dependenciesSome.hairColors.some(hairColor => store.getters.characterHairColor === hairColor) : true
  const hasEyeColor = dependenciesSome.eyeColors !== undefined ? dependenciesSome.eyeColors.some(eyeColor => store.getters.characterEyeColor === eyeColor) : true
  const hasSkinColor = dependenciesSome.skinColors !== undefined ? dependenciesSome.skinColors.some(skillColor => store.getters.characterSkinColor === skillColor) : true
  const hasHigherAge = dependenciesSome.higherAge !== undefined ? store.getters.characterAge >= dependenciesSome.higherAge : true
  const hasHigherHeight = dependenciesSome.higherSize !== undefined ? store.getters.characterSize >= dependenciesSome.higherSize : true
  const hasHigherWeight = dependenciesSome.higherWeight !== undefined ? store.getters.characterWeight >= dependenciesSome.higherWeight : true
  const hasLowerAge = dependenciesSome.lowerAge !== undefined ? store.getters.characterAge < dependenciesSome.lowerAge : true
  const hasLowerHeight = dependenciesSome.lowerSize !== undefined ? store.getters.characterSize < dependenciesSome.lowerSize : true
  const hasLowerWeight = dependenciesSome.lowerWeight !== undefined ? store.getters.characterWeight < dependenciesSome.lowerWeight : true
  const hasAllDependingLanguages = dependenciesSome.languages !== undefined ? dependenciesSome.languages.some(language => store.getters.characterLanguages.includes(language)) : true
  const hasAllDependingTalents = dependenciesSome.talents !== undefined ? dependenciesSome.talents.some(talent => store.getters.characterTalents.includes(talent)) : true
  const hasAllDependingSpells = dependenciesSome.spells !== undefined ? dependenciesSome.spells.some(spell => store.getters.characterSpells.includes(spell)) : true
  const hasDependingAttributeValueReached = dependenciesSome.attributes !== undefined ? dependenciesSome.attributes.some(attribute => store.getters.characterAttributes[attribute.name] >= attribute.value) : true
  const hasDependingWeaponSkillValueReached = dependenciesSome.weaponSkills !== undefined ? dependenciesSome.weaponSkills.some(weaponSkill => store.getters.characterWeaponSkills[weaponSkill.name] >= weaponSkill.value) : true
  const hasDependingBodySkillValueReached = dependenciesSome.bodySkills !== undefined ? dependenciesSome.bodySkills.some(bodySkill => store.getters.characterBodySkills[bodySkill.name] >= bodySkill.value) : true
  const hasDependingMentalSkillValueReached = dependenciesSome.mentalSkills !== undefined ? dependenciesSome.mentalSkills.some(mentalSkill => store.getters.characterMentalSkills[mentalSkill.name] >= mentalSkill.value) : true
  const hasDependingArmor = dependenciesSome.armor !== undefined ? dependenciesSome.items.some(item => store.getters.characterArmor.includes(item)) : true
  const hasDependingShield = dependenciesSome.shield !== undefined ? dependenciesSome.items.some(item => store.getters.characterShield.includes(item)) : true
  const hasDependingWeaponsMelee = dependenciesSome.weapons !== undefined && dependenciesSome.weapons.melee !== undefined ? dependenciesSome.items.some(item => store.getters.characterWeapons.melee.includes(item)) : true
  const hasDependingWeaponsRanged = dependenciesSome.weapons !== undefined && dependenciesSome.weapons.ranged !== undefined ? dependenciesSome.items.some(item => store.getters.characterWeapons.ranged.includes(item)) : true
  const hasDependingRings = dependenciesSome.rings !== undefined ? dependenciesSome.items.some(item => store.getters.characterRings.includes(item)) : true
  const hasDependingAmulets = dependenciesSome.amulets !== undefined ? dependenciesSome.items.some(item => store.getters.characterAmulet.includes(item)) : true
  const hasDependingItems = dependenciesSome.items !== undefined ? dependenciesSome.items.some(item => store.getters.characterItems.some(characterItem => characterItem.startsWith(item))) : true
  return (
    isLevelReached &&
    isDependingGender &&
    isDependingCulture &&
    isDependingClazz &&
    isCaster &&
    isCraft &&
    hasHairColor &&
    hasEyeColor &&
    hasSkinColor &&
    hasHigherAge &&
    hasHigherHeight &&
    hasHigherWeight &&
    hasLowerAge &&
    hasLowerHeight &&
    hasLowerWeight &&
    hasAllDependingLanguages &&
    hasAllDependingTalents &&
    hasAllDependingSpells &&
    hasDependingAttributeValueReached &&
    hasDependingWeaponSkillValueReached &&
    hasDependingBodySkillValueReached &&
    hasDependingMentalSkillValueReached &&
    hasDependingArmor &&
    hasDependingShield &&
    hasDependingWeaponsMelee &&
    hasDependingWeaponsRanged &&
    hasDependingRings &&
    hasDependingAmulets &&
    hasDependingItems
  )
}

function allNotDependencyReached(dependencies) {
  const dependenciesNot = dependencies.not
  if (dependenciesNot === undefined) {
    return true
  }

  const isLevelNotReached = dependenciesNot.level !== undefined ? parseInt(store.getters.characterLevel) < dependenciesNot.level : true
  const isNotDependingGender = dependenciesNot.genders !== undefined && store.getters.characterGender !== undefined ? !dependenciesNot.genders.includes(store.getters.characterGender) : true
  const isNotDependingCulture = dependenciesNot.cultures !== undefined && store.getters.characterCulture !== undefined ? !dependenciesNot.cultures.includes(store.getters.characterCulture) : true
  const isNotDependingClazz = dependenciesNot.clazzes !== undefined && store.getters.characterClazz !== undefined ? !dependenciesNot.clazzes.includes(store.getters.characterClazz) : true
  const isNotCaster = dependenciesNot.caster !== undefined && dependenciesNot.caster ? !store.state.character.personality.caster : true
  const isNotCraft = dependenciesNot.crafts !== undefined ? !dependenciesNot.crafts.some(craft => store.getters.characterCraft === craft) : true
  const hasNotHairColor = dependenciesNot.hairColors !== undefined ? !dependenciesNot.hairColors.some(hairColor => store.getters.characterHairColor === hairColor) : true
  const hasNotEyeColor = dependenciesNot.eyeColors !== undefined ? !dependenciesNot.eyeColors.some(eyeColor => store.getters.characterEyeColor === eyeColor) : true
  const hasNotSkinColor = dependenciesNot.skinColors !== undefined ? !dependenciesNot.skinColors.some(skinColor => store.getters.characterSkinColor === skinColor) : true
  const hasLowerAgeNot = dependenciesNot.lowerAge !== undefined ? store.getters.characterAge < dependenciesNot.lowerAge : true
  const hasLowerHeightNot = dependenciesNot.lowerSize !== undefined ? store.getters.characterSize < dependenciesNot.lowerSize : true
  const hasLowerWeightNot = dependenciesNot.lowerWeight !== undefined ? store.getters.characterWeight < dependenciesNot.lowerWeight : true
  const hasHigherAgeNot = dependenciesNot.higherAge !== undefined ? store.getters.characterAge >= dependenciesNot.higherAge : true
  const hasHigherHeightNot = dependenciesNot.higherSize !== undefined ? store.getters.characterSize >= dependenciesNot.higherSize : true
  const hasHigherWeightNot = dependenciesNot.higherWeight !== undefined ? store.getters.characterWeight >= dependenciesNot.higherWeight : true
  const hasNotAllDependingLanguages = dependenciesNot.languages !== undefined ? !dependenciesNot.languages.some(language => store.getters.characterLanguages.includes(language)) : true
  const hasNotAllDependingTalents = dependenciesNot.talents !== undefined ? !dependenciesNot.talents.some(talent => store.getters.characterTalents.includes(talent)) : true
  const hasNotAllDependingSpells = dependenciesNot.spells !== undefined ? !dependenciesNot.spells.some(spell => store.getters.characterSpells.includes(spell)) : true
  const hasNotDependingAttributeValue = dependenciesNot.attributes !== undefined ? !dependenciesNot.attributes.some(attribute => store.getters.characterAttributes[attribute.name] >= attribute.value) : true
  const hasNotDependingWeaponSkillValue = dependenciesNot.weaponSkills !== undefined ? !dependenciesNot.weaponSkills.some(weaponSkill => store.getters.characterWeaponSkills[weaponSkill.name] >= weaponSkill.value) : true
  const hasNotDependingBodySkillValue = dependenciesNot.bodySkills !== undefined ? !dependenciesNot.bodySkills.some(bodySkill => store.getters.characterBodySkills[bodySkill.name] >= bodySkill.value) : true
  const hasNotDependingMentalSkillValue = dependenciesNot.mentalSkills !== undefined ? !dependenciesNot.mentalSkills.some(mentalSkill => store.getters.characterMentalSkills[mentalSkill.name] >= mentalSkill.value) : true
  const hasNotDependingArmor = dependenciesNot.armor !== undefined ? !dependenciesNot.items.some(item => store.getters.characterArmor.includes(item)) : true
  const hasNotDependingShield = dependenciesNot.shield !== undefined ? !dependenciesNot.items.some(item => store.getters.characterShield.includes(item)) : true
  const hasNotDependingWeaponsMelee = dependenciesNot.weapons !== undefined && dependenciesNot.weapons.melee !== undefined ? !dependenciesNot.items.some(item => store.getters.characterWeapons.melee.includes(item)) : true
  const hasNotDependingWeaponsRanged = dependenciesNot.weapons !== undefined && dependenciesNot.weapons.ranged !== undefined ? !dependenciesNot.items.some(item => store.getters.characterWeapons.ranged.includes(item)) : true
  const hasNotDependingRings = dependenciesNot.rings !== undefined ? !dependenciesNot.items.some(item => store.getters.characterRings.includes(item)) : true
  const hasNotDependingAmulets = dependenciesNot.amulets !== undefined ? !dependenciesNot.items.some(item => store.getters.characterAmulet.includes(item)) : true
  const hasNotDependingItems = dependenciesNot.items !== undefined ? !dependenciesNot.items.some(item => store.getters.characterItems.some(characterItem => characterItem.startsWith(item))) : true

  return (
    isLevelNotReached &&
    isNotDependingGender &&
    isNotDependingCulture &&
    isNotDependingClazz &&
    isNotCaster &&
    isNotCraft &&
    hasNotHairColor &&
    hasNotEyeColor &&
    hasNotSkinColor &&
    hasLowerAgeNot &&
    hasLowerHeightNot &&
    hasLowerWeightNot &&
    hasHigherAgeNot &&
    hasHigherHeightNot &&
    hasHigherWeightNot &&
    hasNotAllDependingLanguages &&
    hasNotAllDependingTalents &&
    hasNotAllDependingSpells &&
    hasNotDependingAttributeValue &&
    hasNotDependingWeaponSkillValue &&
    hasNotDependingBodySkillValue &&
    hasNotDependingMentalSkillValue &&
    hasNotDependingArmor &&
    hasNotDependingShield &&
    hasNotDependingWeaponsMelee &&
    hasNotDependingWeaponsRanged &&
    hasNotDependingRings &&
    hasNotDependingAmulets &&
    hasNotDependingItems
  )
}
export { notAllDependenciesReached, allDependenciesReached }
