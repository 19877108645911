<template>
  <v-card outlined>
    <v-app-bar class="primary">
      <v-toolbar-title>
        {{ $t('components.CharacterGenerator.mentalSkills.label') + ' ( GF / ' + $store.getters.characterMentalSkillPoints + ' )' }}
      </v-toolbar-title>
    </v-app-bar>
    <v-container fluid>
      <v-row style="padding-top: 25px">
        <v-col>
          <Value
            :title="$t('components.CharacterGenerator.mentalSkills.alleyKnowledge')"
            :value="$store.getters.characterMentalSkills.alleyKnowledge"
            :value-bonus="$store.getters.characterMentalSkillsBonus.alleyKnowledge"
            :min="$store.getters.characterMentalSkillsMin.alleyKnowledge"
            :max="$store.getters.characterMentalSkillsMax.alleyKnowledge"
            :value-name="'alleyKnowledge'"
            @changed-value="valueChange"
          />
          <Value
            :title="$t('components.CharacterGenerator.mentalSkills.historicalKnowledge')"
            :value="$store.getters.characterMentalSkills.historicalKnowledge"
            :value-bonus="$store.getters.characterMentalSkillsBonus.historicalKnowledge"
            :min="$store.getters.characterMentalSkillsMin.historicalKnowledge"
            :max="$store.getters.characterMentalSkillsMax.historicalKnowledge"
            :value-name="'historicalKnowledge'"
            @changed-value="valueChange"
          />
          <Value
            :title="$t('components.CharacterGenerator.mentalSkills.trading')"
            :value="$store.getters.characterMentalSkills.trading"
            :value-bonus="$store.getters.characterMentalSkillsBonus.trading"
            :min="$store.getters.characterMentalSkillsMin.trading"
            :max="$store.getters.characterMentalSkillsMax.trading"
            :value-name="'trading'"
            @changed-value="valueChange"
          />
          <Value
            :title="$t('components.CharacterGenerator.mentalSkills.poisons')"
            :value="$store.getters.characterMentalSkills.poisons"
            :value-bonus="$store.getters.characterMentalSkillsBonus.poisons"
            :min="$store.getters.characterMentalSkillsMin.poisons"
            :max="$store.getters.characterMentalSkillsMax.poisons"
            :value-name="'poisons'"
            @changed-value="valueChange"
          />
          <Value
            :title="$t('components.CharacterGenerator.mentalSkills.magicScience')"
            :value="$store.getters.characterMentalSkills.magicScience"
            :value-bonus="$store.getters.characterMentalSkillsBonus.magicScience"
            :min="$store.getters.characterMentalSkillsMin.magicScience"
            :max="$store.getters.characterMentalSkillsMax.magicScience"
            :value-name="'magicScience'"
            @changed-value="valueChange"
          />
          <Value
            :title="$t('components.CharacterGenerator.mentalSkills.religion')"
            :value="$store.getters.characterMentalSkills.religion"
            :value-bonus="$store.getters.characterMentalSkillsBonus.religion"
            :min="$store.getters.characterMentalSkillsMin.religion"
            :max="$store.getters.characterMentalSkillsMax.religion"
            :value-name="'religion'"
            @changed-value="valueChange"
          />
          <Value
            :title="$t('components.CharacterGenerator.mentalSkills.persuation')"
            :value="$store.getters.characterMentalSkills.persuation"
            :value-bonus="$store.getters.characterMentalSkillsBonus.persuation"
            :min="$store.getters.characterMentalSkillsMin.persuation"
            :max="$store.getters.characterMentalSkillsMax.persuation"
            :value-name="'persuation'"
            @changed-value="valueChange"
          />
          <Value
            :title="$t('components.CharacterGenerator.mentalSkills.perception')"
            :value="$store.getters.characterMentalSkills.perception"
            :value-bonus="$store.getters.characterMentalSkillsBonus.perception"
            :min="$store.getters.characterMentalSkillsMin.perception"
            :max="$store.getters.characterMentalSkillsMax.perception"
            :value-name="'perception'"
            @changed-value="valueChange"
          />
          <EmptyValue />
          <EmptyValue />
          <EmptyValue />
          <EmptyValue />
          <EmptyValue />
          <EmptyValue />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import Value from '@/components/content/Value'
import EmptyValue from '@/components/content/EmptyValue'
import { setMentalSkill } from '@/services/mentalSkills.service'

export default {
  name: 'MentalSkills',
  components: { Value, EmptyValue },
  methods: {
    valueChange(skill) {
      setMentalSkill(skill.name, skill.value)
    }
  }
}
</script>
