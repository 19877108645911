<template>
  <v-expansion-panels v-model="panel" class="primary v-sheet v-toolbar v-app-bar v-toolbar__content" focusable multiple>
    <v-expansion-panel v-if="$store.state.character.personality.caster && ($store.state.character.personality.clazz !== 'dilettanteMagicianWarrior' || $store.state.character.personality.clazz !== 'dilettanteMagicianScout')">
      <v-expansion-panel-header class="v-application v-besodd-expansion-panels">
        <div class="v-toolbar__title">{{ $t('components.CharacterGenerator.triggermoments.label') + ' (' + numberOfTriggerMomentTalents + ')' }}</div>
        <template v-slot:actions>
          <v-icon color="primary"> $expand </v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <Triggermoment :header="$t('components.CharacterGenerator.talents.selectTriggerMoments')" :talents="triggerMomentTalents" :selected-talents="selectedTriggerMomentTalents" />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { getTalentForName } from '@/services/talent.service'
import { notAllDependenciesReached } from '@/services/dependency.service'
import Triggermoment from '@/components/content/Triggermoment'

export default {
  name: 'Triggermoments',
  components: { Triggermoment },
  data: () => ({
    panel: [0]
  }),
  computed: {
    selectedTriggerMomentTalents() {
      return this.$store.getters.characterTalents.map(talent => getTalentForName(talent)).filter(talent => talent.name.startsWith('triggerMoment'))
    },
    triggerMomentTalents() {
      return this.$store.getters.talents.filter(talent => talent.name.startsWith('triggerMoment')).filter(talent => this.$store.getters.configuration.showDisabledTalents || !this.selectAbleCharacterTalentIsDisabled(talent))
    },
    numberOfTriggerMomentTalents() {
      return this.hasNotSelectedTriggerMoment() ? 1 : 0
    }
  },
  methods: {
    isTriggerMoment(talent) {
      return talent.name.startsWith('triggerMoment')
    },
    isDilettanteMagician() {
      return this.$store.state.character.personality.clazz.startsWith('dilettanteMagician')
    },
    hasNotSelectedTriggerMoment() {
      return this.$store.getters.characterTalents.filter(characterTalent => characterTalent.startsWith('triggerMoment')).length === 0
    },
    isOtherTalentThenTriggermomentSelectable(talent) {
      return this.isDilettanteMagician() && this.hasNotSelectedTriggerMoment() && !this.isTriggerMoment(talent)
    },
    selectAbleCharacterTalentIsDisabled(talent) {
      const talentIsActive = this.$store.getters.characterTalents.filter(characterTalent => characterTalent === talent.name).length > 0
      const isNotMultiple = talent.multiple === undefined || !talent.multiple
      const notEnougthTalentPoints = this.$store.getters.characterTalentPoints <= 0
      return notEnougthTalentPoints || (talentIsActive && isNotMultiple) || notAllDependenciesReached(talent.dependencies) || this.isOtherTalentThenTriggermomentSelectable(talent)
    }
  }
}
</script>
<style lang="scss" scoped>
  .v-expansion-panels .v-besodd-expansion-panels {
    padding: 16px 16px !important;
  }

  .theme--light.v-expansion-panels .v-expansion-panel-header {
    background-color: #000 !important;
    color: #fff !important;
  }

  .theme--light.v-expansion-panels .v-expansion-panel-header--active {
    background-color: #000 !important;
    color: #fff !important;
  }

  .theme--light.v-expansion-panels.v-expansion-panels--focusable .v-expansion-panel-header--active:hover::before, .theme--light.v-expansion-panels.v-expansion-panels--focusable .v-expansion-panel-header--active::before {
    opacity: 0;
  }

  .theme--light.v-app-bar.v-toolbar.v-sheet {
    border-radius: 4px !important;
  }

  .v-expansion-panel--active>.v-expansion-panel-header--active .v-expansion-panel-header__icon:not(.v-expansion-panel-header__icon--disable-rotate) .v-icon {
    color: #fff !important;
  }

  .theme--light.v-expansion-panels .v-expansion-panel-header .v-expansion-panel-header__icon .v-icon {
    color: #fff !important;
  }
</style>
