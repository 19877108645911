import store from '../store'

function getClazzForName(clazzName) {
  return store.getters.clazzes.find(clazz => clazz.name === clazzName)
}
function getCurrentClazz() {
  return store.getters.clazzes.find(clazz => clazz.name === store.getters.characterClazz)
}
function setClazz(clazzName) {
  store.dispatch('setCharacterClazz', clazzName)
}
export { getCurrentClazz, getClazzForName, setClazz }
