import store from '../store'

function getCurrentMeleeWeapons() {
  return store.getters.characterWeapons.melee.filter(weapon => weapon !== '').map(weapon => getMeleeWeaponForName(weapon))
}
function getMeleeWeaponForName(weaponName) {
  return store.getters.weapons.melee.find(weapon => weapon.name === weaponName)
}
function getMeleeWeaponForIndex(index) {
  return getMeleeWeaponForName(store.getters.characterWeapons.melee[index])
}
function getCurrentRangedWeapons() {
  return store.getters.characterWeapons.ranged.filter(weapon => weapon !== '').map(weapon => getRangedWeaponForName(weapon))
}
function getRangedWeaponForName(weaponName) {
  return store.getters.weapons.ranged.find(weapon => weapon.name === weaponName)
}
function getRangedWeaponForIndex(index) {
  return getRangedWeaponForName(store.getters.characterWeapons.ranged[index])
}
function getCurrentArmor() {
  return store.getters.characterArmor.filter(armor => armor !== '').map(armor => getArmorForName(armor))
}
function getArmorForName(armorName) {
  const foundArmor = store.getters.armor.find(armor => armor.name === armorName)
  if (foundArmor !== undefined && foundArmor !== null) {
    foundArmor.armorHead = foundArmor.armorHead ?? 0
    foundArmor.armorTorso = foundArmor.armorTorso ?? 0
    foundArmor.armorLimbs = foundArmor.armorLimbs ?? 0
    foundArmor.clumsinessHead = foundArmor.clumsinessHead ?? 0
    foundArmor.clumsinessTorso = foundArmor.clumsinessTorso ?? 0
    foundArmor.clumsinessLimbs = foundArmor.clumsinessLimbs ?? 0
  }
  return foundArmor
}
function getArmorForIndex(index) {
  return getArmorForName(store.getters.characterArmor[index])
}
function getCurrentShield() {
  return store.getters.characterShield.filter(shield => shield !== '').map(shield => getShieldForName(shield))
}
function getShieldForName(shieldName) {
  return store.getters.shield.find(shield => shield.name === shieldName)
}
function getShieldForIndex(index) {
  return getShieldForName(store.getters.characterShield[index])
}
function getCurrentRings() {
  return store.getters.characterRings.filter(ring => ring !== '').map(ring => getRingForName(ring))
}
function getRingForName(ringName) {
  return store.getters.rings.find(ring => ring.name === ringName)
}
function getRingForIndex(index) {
  return getRingForName(store.getters.characterRings[index])
}
function getCurrentAmulets() {
  return store.getters.characterAmulets.filter(amulet => amulet !== '').map(amulet => getAmuletForName(amulet))
}
function getAmuletForName(amuletName) {
  return store.getters.amulets.find(amulet => amulet.name === amuletName)
}
function getAmuletForIndex(index) {
  return getAmuletForName(store.getters.characterAmulets[index])
}
function setArmor(armorName, index) {
  store.dispatch('setCharacterArmor', {
    name: armorName,
    index
  })
}
function setShield(shieldName, index) {
  store.dispatch('setCharacterShield', {
    name: shieldName,
    index
  })
}
function setWeaponsMelee(weaponName, index) {
  store.dispatch('setCharacterWeaponMelee', {
    name: weaponName,
    index
  })
}
function setWeaponsRanged(weaponName, index) {
  store.dispatch('setCharacterWeaponRanged', {
    name: weaponName,
    index
  })
}
function setRing(ringName, index) {
  store.dispatch('setCharacterRing', {
    name: ringName,
    index
  })
}
function setAmulet(amuletName, index) {
  store.dispatch('setCharacterAmulet', {
    name: amuletName,
    index
  })
}
function addItem(item) {
  store.dispatch('addCharacterItem', item)
}
function removeItem(itemId) {
  store.dispatch('removeCharacterItem', itemId)
}
function getItemForName(itemName) {
  let items = []
  Object.keys(store.getters.items.others).forEach(other => {
    items = [...items, ...store.getters.items.others[other]]
  })
  return items.find(item => item.name === itemName)
}
function getCurrentItems() {
  return store.getters.characterItems.map(item => getItemForName(item.split('|')[0])).filter(item => item !== undefined)
}
export {
  getCurrentMeleeWeapons,
  getMeleeWeaponForName,
  getMeleeWeaponForIndex,
  setWeaponsMelee,
  getCurrentRangedWeapons,
  getRangedWeaponForName,
  getRangedWeaponForIndex,
  setWeaponsRanged,
  getCurrentArmor,
  getArmorForName,
  getArmorForIndex,
  setArmor,
  getCurrentShield,
  getShieldForName,
  getShieldForIndex,
  setShield,
  getCurrentRings,
  getRingForName,
  getRingForIndex,
  setRing,
  getCurrentAmulets,
  getAmuletForName,
  getAmuletForIndex,
  setAmulet,
  getItemForName,
  getCurrentItems,
  addItem,
  removeItem
}
