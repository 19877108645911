<template>
  <v-row>
    <v-col cols="12" md="4">
      <v-card outlined>
        <v-app-bar class="primary">
          <v-toolbar-title> Waffenfertigkeitspunkte Umwandeln {{ '( WF / ' + $store.getters.characterWeaponSkillPoints + ' )' }} </v-toolbar-title>
        </v-app-bar>
        <v-container fluid>
          <Points
            :title="$t('components.CharacterGenerator.bodySkills.label')"
            :value="$store.getters.characterSharedSkillPoints.weaponSkillsToBodySkills"
            :valueName="'weaponSkillsToBodySkills'"
            :min="$store.getters.characterSharedSkillPointsMin.weaponSkillsToBodySkills"
            :max="$store.getters.characterSharedSkillPointsMax.weaponSkillsToBodySkills"
            @changed-value="valueChange"
          />
          <Points
            :title="$t('components.CharacterGenerator.mentalSkills.label')"
            :value="$store.getters.characterSharedSkillPoints.weaponSkillsToMentalSkills"
            :valueName="'weaponSkillsToMentalSkills'"
            :min="$store.getters.characterSharedSkillPointsMin.weaponSkillsToMentalSkills"
            :max="$store.getters.characterSharedSkillPointsMax.weaponSkillsToMentalSkills"
            @changed-value="valueChange"
          />
          <Points
            v-if="$store.state.character.personality.clazz === 'runeFighter'"
            :title="$t('components.CharacterGenerator.runes.label')"
            :value="$store.getters.characterSharedSkillPoints.weaponSkillsToRunes"
            :valueName="'weaponSkillsToRunes'"
            :min="$store.getters.characterSharedSkillPointsMin.weaponSkillsToRunes"
            :max="$store.getters.characterSharedSkillPointsMax.weaponSkillsToRunes"
            @changed-value="valueChange"
          />
        </v-container>
      </v-card>
    </v-col>
    <v-col cols="12" md="4">
      <v-card outlined>
        <v-app-bar class="primary">
          <v-toolbar-title> Körperliche Fertigkeitspunkte Umwandeln {{ '( KF / ' + $store.getters.characterBodySkillPoints + ' )' }} </v-toolbar-title>
        </v-app-bar>
        <v-container fluid>
          <Points
            :title="$t('components.CharacterGenerator.weaponSkills.label')"
            :value="$store.getters.characterSharedSkillPoints.bodySkillsToWeaponSkills"
            :valueName="'bodySkillsToWeaponSkills'"
            :min="$store.getters.characterSharedSkillPointsMin.bodySkillsToWeaponSkills"
            :max="$store.getters.characterSharedSkillPointsMax.bodySkillsToWeaponSkills"
            @changed-value="valueChange"
          />
          <Points
            :title="$t('components.CharacterGenerator.mentalSkills.label')"
            :value="$store.getters.characterSharedSkillPoints.bodySkillsToMentalSkills"
            :valueName="'bodySkillsToMentalSkills'"
            :min="$store.getters.characterSharedSkillPointsMin.bodySkillsToMentalSkills"
            :max="$store.getters.characterSharedSkillPointsMax.bodySkillsToMentalSkills"
            @changed-value="valueChange"
          />
          <Points
            v-if="$store.state.character.personality.clazz === 'runeFighter'"
            :title="$t('components.CharacterGenerator.runes.label')"
            :value="$store.getters.characterSharedSkillPoints.bodySkillsToRunes"
            :valueName="'bodySkillsToRunes'"
            :min="$store.getters.characterSharedSkillPointsMin.bodySkillsToRunes"
            :max="$store.getters.characterSharedSkillPointsMax.bodySkillsToRunes"
            @changed-value="valueChange"
          />
        </v-container>
      </v-card>
    </v-col>
    <v-col cols="12" md="4">
      <v-card outlined>
        <v-app-bar class="primary">
          <v-toolbar-title> Geistige Fertigkeitespunkte Umwandeln {{ '( GF / ' + $store.getters.characterMentalSkillPoints + ' )' }} </v-toolbar-title>
        </v-app-bar>
        <v-container fluid>
          <Points
            :title="$t('components.CharacterGenerator.weaponSkills.label')"
            :value="$store.getters.characterSharedSkillPoints.mentalSkillsToWeaponSkills"
            :valueName="'mentalSkillsToWeaponSkills'"
            :min="$store.getters.characterSharedSkillPointsMin.mentalSkillsToWeaponSkills"
            :max="$store.getters.characterSharedSkillPointsMax.mentalSkillsToWeaponSkills"
            @changed-value="valueChange"
          />
          <Points
            :title="$t('components.CharacterGenerator.bodySkills.label')"
            :value="$store.getters.characterSharedSkillPoints.mentalSkillsToBodySkills"
            :valueName="'mentalSkillsToBodySkills'"
            :min="$store.getters.characterSharedSkillPointsMin.mentalSkillsToBodySkills"
            :max="$store.getters.characterSharedSkillPointsMax.mentalSkillsToBodySkills"
            @changed-value="valueChange"
          />
          <Points
            v-if="$store.state.character.personality.clazz === 'runeFighter'"
            :title="$t('components.CharacterGenerator.runes.label')"
            :value="$store.getters.characterSharedSkillPoints.mentalSkillsToRunes"
            :valueName="'mentalSkillsToRunes'"
            :min="$store.getters.characterSharedSkillPointsMin.mentalSkillsToRunes"
            :max="$store.getters.characterSharedSkillPointsMax.mentalSkillsToRunes"
            @changed-value="valueChange"
          />
        </v-container>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import Points from '@/components/content/Points'
import { setSharedSkillPoint } from '@/services/skillpoint.service'

export default {
  name: 'SharedSkillPoints',
  components: { Points },
  methods: {
    valueChange(skillPoint) {
      setSharedSkillPoint(skillPoint.name, skillPoint.value)
    }
  }
}
</script>
