import store from '../store'

function getStateForLevel(level) {
  return store.state.states.find(characterState => characterState.personality.level === level)
}

function getStates() {
  return store.state.states
}

function loadState(level) {
  store.state.character.personality.level = level
  const characterState = JSON.parse(JSON.stringify(getStateForLevel(level)))
  characterState.personality = JSON.parse(JSON.stringify(store.state.character.personality))
  characterState.languages = JSON.parse(JSON.stringify(store.state.character.languages))
  characterState.inventory = JSON.parse(JSON.stringify(store.state.character.inventory))
  store.state.character = characterState
}

function saveState() {
  const characterState = JSON.parse(JSON.stringify(store.getters.character))
  characterState.personality.level = characterState.personality.level - 1
  addState(characterState)
}

function addState(character) {
  const characterStates = getStates()
  for (let i = 0; i < characterStates.length; i++) {
    const characterState = characterStates[i]
    if (characterState.personality.level === character.personality.level) {
      characterStates.splice(i, 1)
      break
    }
  }
  characterStates.push(JSON.parse(JSON.stringify(character)))
  store.dispatch('setCharacterStates', characterStates)
}

function removeState(character) {
  const characterStates = getStates()
  for (let i = 0; i < characterStates.length; i++) {
    const characterState = characterStates[i]
    if (characterState.personality.level === character.personality.level) {
      characterStates.splice(i, 1)
      break
    }
  }
  store.dispatch('setCharacterStates', characterStates)
}

export { getStateForLevel, getStates, loadState, saveState, addState, removeState }
