<template>
  <v-card outlined>
    <v-app-bar class="primary">
      <v-toolbar-title>
        {{ 'Charakterbogen' }}
      </v-toolbar-title>
      <template v-slot:extension>
        <v-fab-transition>
          <v-btn class="secondary" fab absolute bottom right @click="downloadCharacterSheet">
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </v-fab-transition>
      </template>
    </v-app-bar>
    <v-container fluid>
      <br />
      <br />
      <v-overlay :value="loading">
        <v-progress-circular indeterminate size="64" />
      </v-overlay>
      <div v-if="!loading">
        <v-row v-for="i in 2" :key="i">
          <v-col>
            <pdf :src="characterSheetData" :page="i" />
          </v-col>
        </v-row>
      </div>
    </v-container>
  </v-card>
</template>

<script>
import { getSingleClumsiness, getMovementSpeed } from '@/services/armor.service'
import { getActualShieldAttackReduction } from '@/services/shield.service'
import {
  getActualMeleeWeaponDodgeMalus,
  getActualMeleeWeaponAttackAbility,
  getActualMeleeWeaponDefenseAbility,
  getActualMeleeWeaponAttack,
  getActualMeleeWeaponDefense,
  getActualMeleeWeaponDodge,
  getActualMeleeWeaponDamage,
  hasImprovedCriticalStrike,
  hasAssassin,
  hasSacred
} from '@/services/meleeWeapon.service'
import { getActualRangedWeaponDodgeMalus, getActualRangedWeaponRange, getActualRangedWeaponAttack, getActualRangedWeaponDodge, getActualRangedWeaponDamage } from '@/services/rangedWeapon.service'
import { hasRuneFocus, hasRuneSpecialization } from '@/services/rune.service'
import { PDFDocument, rgb } from 'pdf-lib'
import fontKit from '@pdf-lib/fontkit'
import pdf from 'vue-pdf'
import download from 'downloadjs'

export default {
  name: 'Preview',
  components: { pdf },
  props: {
    character: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      characterSheetData: this.buildUrl('documentation/BeSoDD-Charakterbogen.pdf'),
      loading: true
    }
  },
  created() {
    this.initializeCharacterSheet()
  },
  methods: {
    buildUrl(url) {
      return this.$i18n.locale + '/' + url
    },
    async downloadCharacterSheet() {
      const doc = await this.createCharacterSheet()
      download(await doc.save(), this.character.personality.name !== '' ? this.character.personality.name : 'character', 'application/pdf')
    },
    async initializeCharacterSheet() {
      const doc = await this.createCharacterSheet()
      this.characterSheetData = 'data:application/pdf;base64,' + (await doc.saveAsBase64())
      this.loading = false
    },
    async loadPdf(url) {
      const existingPdfBytes = await fetch(url).then(res => {
        return res.arrayBuffer()
      })
      const pdfDoc = await PDFDocument.load(existingPdfBytes)
      pdfDoc.registerFontkit(fontKit)
      return pdfDoc
    },
    async loadFont(url) {
      return await fetch(url).then(res => res.arrayBuffer())
    },
    drawText(doc, pageIndex, font, size, x, y, text) {
      const pages = doc.getPages()
      const page = pages[pageIndex]
      const { height } = page.getSize()
      page.drawText(text, {
        x: x,
        y: height - y,
        size: size,
        font: font
      })
    },
    drawTextUnderlined(doc, pageIndex, font, size, x, y, text) {
      const pages = doc.getPages()
      const page = pages[pageIndex]
      const { height } = page.getSize()
      page.drawText(text, {
        x: x,
        y: height - y,
        size: size,
        font: font
      })
      const textWidth = font.widthOfTextAtSize(text, size)
      page.drawRectangle({
        x: x,
        y: height - y - 0.8,
        width: textWidth,
        height: 0,
        borderColor: rgb(0, 0, 0),
        borderWidth: 0.2
      })
    },
    drawRectangle(doc, pageIndex, rectAngleWidth, rectAngleHeight, color, x, y) {
      const pages = doc.getPages()
      const page = pages[pageIndex]
      const { height } = page.getSize()
      page.drawRectangle({
        x: x,
        y: height - y,
        width: rectAngleWidth,
        height: rectAngleHeight,
        color: color
      })
    },
    async createCharacterSheet() {
      const doc = await this.loadPdf(this.buildUrl('documentation/BeSoDD-Charakterbogen.pdf'))
      const font = await doc.embedFont(await this.loadFont(this.buildUrl('fonts/Pali-Bold.otf')))

      this.drawPersonality(doc, font)
      this.drawAttributes(doc, font)
      this.drawBaseValues(doc, font)
      this.drawWeaponSkills(doc, font)
      this.drawBodySkills(doc, font)
      this.drawMentalSkills(doc, font)
      if (this.character.crafting !== '' && (this.character.bodySkills.craft > 0 || this.character.additionalBodySkills.craft)) {
        this.drawCrafting(doc, font)
      }
      this.drawTalents(doc, font)
      this.drawMeleeWeapons(doc, font)
      this.drawArmor(doc, font)
      this.drawRangedWeapons(doc, font)
      this.drawShield(doc, font)
      this.drawMoney(doc, font)
      this.drawMovement(doc, font)
      this.drawItems(doc, font)
      this.drawLanguages(doc, font)
      this.drawMagicalBase(doc, font)
      this.drawSpells(doc, font)
      this.drawRunes(doc, font)
      return doc
    },
    drawPersonality(doc, font) {
      const pageIndex = 0
      const size = 8

      this.drawText(doc, pageIndex, font, size, 42, 68, this.character.personality.name !== '' ? this.character.personality.name : '')
      this.drawText(doc, pageIndex, font, size, 202, 68, this.character.personality.gender !== '' ? this.$t('components.CharacterGenerator.personality.gender.' + this.character.personality.gender + '.name') : '')
      this.drawText(doc, pageIndex, font, size, 350, 68, this.character.personality.culture !== '' ? this.$t('components.CharacterGenerator.personality.culture.' + this.character.personality.culture + '.name') : '')
      this.drawText(doc, pageIndex, font, size, 468, 68, this.character.personality.clazz !== '' ? this.$t('components.CharacterGenerator.personality.clazz.' + this.character.personality.clazz + '.name') : '')

      this.drawText(doc, pageIndex, font, size, 57, 84.5, this.character.personality.hairColor !== '' ? this.$t('components.CharacterGenerator.colors.' + this.character.personality.hairColor) : '')
      this.drawText(doc, pageIndex, font, size, 198, 84.5, this.character.personality.skinColor !== '' ? this.$t('components.CharacterGenerator.colors.' + this.character.personality.skinColor) : '')
      this.drawText(doc, pageIndex, font, size, 345, 84.5, this.character.personality.eyeColor !== '' ? this.$t('components.CharacterGenerator.colors.' + this.character.personality.eyeColor) : '')
      this.drawText(doc, pageIndex, font, size, 466.5, 84.5, this.character.personality.size.toString() + ' Halbfinger')

      this.drawText(doc, pageIndex, font, size, 51, 100.8, this.character.personality.weight.toString() + ' Stein')
      this.drawText(doc, pageIndex, font, size, 180, 100.8, this.character.personality.age.toString() + ' Jahre')
      this.drawText(doc, pageIndex, font, size, 369, 100.8, this.character.personality.experience.toString())
      this.drawText(doc, pageIndex, font, size, 463, 100.8, this.character.personality.level.toString())
    },
    drawAttributes(doc, font) {
      const pageIndex = 0
      const size = 9

      const attributesX = 211
      this.drawText(doc, pageIndex, font, size, attributesX, 146.2, this.character.attributes.audacity.toString())
      this.drawText(doc, pageIndex, font, size, attributesX, 165.2, this.character.attributes.thinking.toString())
      this.drawText(doc, pageIndex, font, size, attributesX, 184.2, this.character.attributes.stomachFeeling.toString())
      this.drawText(doc, pageIndex, font, size, attributesX, 203.2, this.character.attributes.characterStrength.toString())
      this.drawText(doc, pageIndex, font, size, attributesX, 222.2, this.character.attributes.handSkill.toString())
      this.drawText(doc, pageIndex, font, size, attributesX, 241.2, this.character.attributes.bodySkill.toString())
      this.drawText(doc, pageIndex, font, size, attributesX, 260.2, this.character.attributes.condition.toString())
      this.drawText(doc, pageIndex, font, size, attributesX, 279.2, this.character.attributes.muscleStrength.toString())
    },
    drawBaseValues(doc, font) {
      const pageIndex = 0
      const size = 9
      const modificatorSize = 8

      const baseValuesX = 493.5
      this.drawText(doc, pageIndex, font, size, baseValuesX, 146.2, this.character.baseValues.attack.toString())
      this.drawText(doc, pageIndex, font, size, baseValuesX, 165.2, this.character.baseValues.defense.toString())
      this.drawText(doc, pageIndex, font, size, baseValuesX, 184.2, this.character.baseValues.distance.toString())
      this.drawText(doc, pageIndex, font, size, baseValuesX, 203.2, this.character.baseValues.dodge.toString())
      this.drawText(doc, pageIndex, font, size, baseValuesX, 222.2, this.character.baseValues.magicalResistance.toString())
      this.drawText(doc, pageIndex, font, size, baseValuesX, 241.2, this.character.personality.caster ? this.character.baseValues.magicalPower.toString() : '-')
      this.drawText(doc, pageIndex, font, size, baseValuesX, 260.2, this.character.baseValues.health.toString())
      this.drawText(doc, pageIndex, font, size, baseValuesX, 279.2, this.character.personality.caster || hasSacred() ? this.character.baseValues.soul.toString() : '-')
      this.drawText(doc, pageIndex, font, modificatorSize, baseValuesX - 68, 260.2, this.character.baseValues.classModificatorHealth.toString())
      this.drawText(doc, pageIndex, font, modificatorSize, baseValuesX - 68, 279.2, this.character.personality.caster || hasSacred() ? this.character.baseValues.classModificatorSoul.toString() : '-')
    },
    drawWeaponSkills(doc, font) {
      const pageIndex = 0
      const size = 9

      // Weapon skills
      const weaponSkillsX = 98.5
      this.drawText(doc, pageIndex, font, size, weaponSkillsX, 319, this.character.weaponSkills.dodge.toString())
      this.drawText(doc, pageIndex, font, size, weaponSkillsX, 335.5, this.character.weaponSkills.axesAndHatchets.toString())
      this.drawText(doc, pageIndex, font, size, weaponSkillsX, 352, this.character.weaponSkills.flail.toString())
      this.drawText(doc, pageIndex, font, size, weaponSkillsX, 368, this.character.weaponSkills.hammersAndClubs.toString())
      this.drawText(doc, pageIndex, font, size, weaponSkillsX, 385, this.character.weaponSkills.curvedSword.toString())
      this.drawText(doc, pageIndex, font, size, weaponSkillsX, 401, this.character.weaponSkills.dagger.toString())
      this.drawText(doc, pageIndex, font, size, weaponSkillsX, 418, this.character.weaponSkills.swords.toString())
      this.drawText(doc, pageIndex, font, size, weaponSkillsX, 433.5, this.character.weaponSkills.spears.toString())
      this.drawText(doc, pageIndex, font, size, weaponSkillsX, 451, this.character.weaponSkills.stabWeapons.toString())
      this.drawText(doc, pageIndex, font, size, weaponSkillsX, 467.5, this.character.weaponSkills.unarmed.toString())
      this.drawText(doc, pageIndex, font, size, weaponSkillsX, 484.5, this.character.weaponSkills.harantzu.toString())
      this.drawText(doc, pageIndex, font, size, weaponSkillsX, 502, this.character.weaponSkills.crossbows.toString())
      this.drawText(doc, pageIndex, font, size, weaponSkillsX, 519, this.character.weaponSkills.bows.toString())
      this.drawText(doc, pageIndex, font, size, weaponSkillsX, 537, this.character.weaponSkills.throwingWeapons.toString())

      // Weapon skills focus
      const weaponSkillsFocusX = 143
      this.drawText(doc, pageIndex, font, size, weaponSkillsFocusX, 319, this.character.weaponSkillsFocus.dodge ? 'X' : '')
      this.drawText(doc, pageIndex, font, size, weaponSkillsFocusX, 335.5, this.character.weaponSkillsFocus.axesAndHatchets ? 'X' : '')
      this.drawText(doc, pageIndex, font, size, weaponSkillsFocusX, 352, this.character.weaponSkillsFocus.flail ? 'X' : '')
      this.drawText(doc, pageIndex, font, size, weaponSkillsFocusX, 368, this.character.weaponSkillsFocus.hammersAndClubs ? 'X' : '')
      this.drawText(doc, pageIndex, font, size, weaponSkillsFocusX, 385, this.character.weaponSkillsFocus.curvedSword ? 'X' : '')
      this.drawText(doc, pageIndex, font, size, weaponSkillsFocusX, 401, this.character.weaponSkillsFocus.dagger ? 'X' : '')
      this.drawText(doc, pageIndex, font, size, weaponSkillsFocusX, 418, this.character.weaponSkillsFocus.swords ? 'X' : '')
      this.drawText(doc, pageIndex, font, size, weaponSkillsFocusX, 433.5, this.character.weaponSkillsFocus.spears ? 'X' : '')
      this.drawText(doc, pageIndex, font, size, weaponSkillsFocusX, 451, this.character.weaponSkillsFocus.stabWeapons ? 'X' : '')
      this.drawText(doc, pageIndex, font, size, weaponSkillsFocusX, 467.5, this.character.weaponSkillsFocus.unarmed ? 'X' : '')
      this.drawText(doc, pageIndex, font, size, weaponSkillsFocusX, 484.5, this.character.weaponSkillsFocus.harantzu ? 'X' : '')
      this.drawText(doc, pageIndex, font, size, weaponSkillsFocusX, 502, this.character.weaponSkillsFocus.crossbows ? 'X' : '')
      this.drawText(doc, pageIndex, font, size, weaponSkillsFocusX, 519, this.character.weaponSkillsFocus.bows ? 'X' : '')
      this.drawText(doc, pageIndex, font, size, weaponSkillsFocusX, 537, this.character.weaponSkillsFocus.throwingWeapons ? 'X' : '')

      const weaponSkillsSpecialisationX = 157
      this.drawText(doc, pageIndex, font, size, weaponSkillsSpecialisationX, 319, this.character.weaponSkillsSpecialist.dodge ? 'X' : '')
      this.drawText(doc, pageIndex, font, size, weaponSkillsSpecialisationX, 335.5, this.character.weaponSkillsSpecialist.axesAndHatchets ? 'X' : '')
      this.drawText(doc, pageIndex, font, size, weaponSkillsSpecialisationX, 352, this.character.weaponSkillsSpecialist.flail ? 'X' : '')
      this.drawText(doc, pageIndex, font, size, weaponSkillsSpecialisationX, 368, this.character.weaponSkillsSpecialist.hammersAndClubs ? 'X' : '')
      this.drawText(doc, pageIndex, font, size, weaponSkillsSpecialisationX, 385, this.character.weaponSkillsSpecialist.curvedSword ? 'X' : '')
      this.drawText(doc, pageIndex, font, size, weaponSkillsSpecialisationX, 401, this.character.weaponSkillsSpecialist.dagger ? 'X' : '')
      this.drawText(doc, pageIndex, font, size, weaponSkillsSpecialisationX, 418, this.character.weaponSkillsSpecialist.swords ? 'X' : '')
      this.drawText(doc, pageIndex, font, size, weaponSkillsSpecialisationX, 433.5, this.character.weaponSkillsSpecialist.spears ? 'X' : '')
      this.drawText(doc, pageIndex, font, size, weaponSkillsSpecialisationX, 451, this.character.weaponSkillsSpecialist.stabWeapons ? 'X' : '')
      this.drawText(doc, pageIndex, font, size, weaponSkillsSpecialisationX, 467.5, this.character.weaponSkillsSpecialist.unarmed ? 'X' : '')
      this.drawText(doc, pageIndex, font, size, weaponSkillsSpecialisationX, 484.5, this.character.weaponSkillsSpecialist.harantzu ? 'X' : '')
      this.drawText(doc, pageIndex, font, size, weaponSkillsSpecialisationX, 502, this.character.weaponSkillsSpecialist.crossbows ? 'X' : '')
      this.drawText(doc, pageIndex, font, size, weaponSkillsSpecialisationX, 519, this.character.weaponSkillsSpecialist.bows ? 'X' : '')
      this.drawText(doc, pageIndex, font, size, weaponSkillsSpecialisationX, 537, this.character.weaponSkillsSpecialist.throwingWeapons ? 'X' : '')
    },
    drawBodySkills(doc, font) {
      const pageIndex = 0
      const size = 9

      // Body skills
      const bodySkillsX = 300.5
      this.drawText(doc, pageIndex, font, size, bodySkillsX, 319, this.character.bodySkills.athletics.toString())
      this.drawText(doc, pageIndex, font, size, bodySkillsX, 335.5, this.character.bodySkills.acrobatics.toString())
      this.drawText(doc, pageIndex, font, size, bodySkillsX, 352, this.character.bodySkills.craft.toString())
      this.drawText(doc, pageIndex, font, size, bodySkillsX, 368, this.character.bodySkills.medicine.toString())
      this.drawText(doc, pageIndex, font, size, bodySkillsX, 385, this.character.bodySkills.stealth.toString())
      this.drawText(doc, pageIndex, font, size, bodySkillsX, 401, this.character.bodySkills.cooking.toString())
      this.drawText(doc, pageIndex, font, size, bodySkillsX, 418, this.character.bodySkills.mechanics.toString())
      this.drawText(doc, pageIndex, font, size, bodySkillsX, 433.5, this.character.bodySkills.wildLife.toString())

      // Body skills focus
      const bodySkillsFocusX = 344
      this.drawText(doc, pageIndex, font, size, bodySkillsFocusX, 319, this.character.bodySkillsFocus.athletics ? 'X' : '')
      this.drawText(doc, pageIndex, font, size, bodySkillsFocusX, 335.5, this.character.bodySkillsFocus.acrobatics ? 'X' : '')
      this.drawText(doc, pageIndex, font, size, bodySkillsFocusX, 352, this.character.bodySkillsFocus.craft ? 'X' : '')
      this.drawText(doc, pageIndex, font, size, bodySkillsFocusX, 368, this.character.bodySkillsFocus.medicine ? 'X' : '')
      this.drawText(doc, pageIndex, font, size, bodySkillsFocusX, 385, this.character.bodySkillsFocus.stealth ? 'X' : '')
      this.drawText(doc, pageIndex, font, size, bodySkillsFocusX, 401, this.character.bodySkillsFocus.cooking ? 'X' : '')
      this.drawText(doc, pageIndex, font, size, bodySkillsFocusX, 418, this.character.bodySkillsFocus.mechanics ? 'X' : '')
      this.drawText(doc, pageIndex, font, size, bodySkillsFocusX, 433.5, this.character.bodySkillsFocus.wildLife ? 'X' : '')

      // Body skills focus
      const bodySkillsBonusX = 363
      this.drawText(doc, pageIndex, font, size, bodySkillsBonusX, 319, this.character.additionalBodySkills.athletics > 0 ? this.character.additionalBodySkills.athletics.toString() : '')
      this.drawText(doc, pageIndex, font, size, bodySkillsBonusX, 335.5, this.character.additionalBodySkills.acrobatics > 0 ? this.character.additionalBodySkills.acrobatics.toString() : '')
      this.drawText(doc, pageIndex, font, size, bodySkillsBonusX, 352, this.character.additionalBodySkills.craft > 0 ? this.character.additionalBodySkills.craft.toString() : '')
      this.drawText(doc, pageIndex, font, size, bodySkillsBonusX, 368, this.character.additionalBodySkills.medicine > 0 ? this.character.additionalBodySkills.medicine.toString() : '')
      this.drawText(doc, pageIndex, font, size, bodySkillsBonusX, 385, this.character.additionalBodySkills.stealth > 0 ? this.character.additionalBodySkills.stealth.toString() : '')
      this.drawText(doc, pageIndex, font, size, bodySkillsBonusX, 401, this.character.additionalBodySkills.cooking > 0 ? this.character.additionalBodySkills.cooking.toString() : '')
      this.drawText(doc, pageIndex, font, size, bodySkillsBonusX, 418, this.character.additionalBodySkills.mechanics > 0 ? this.character.additionalBodySkills.mechanics.toString() : '')
      this.drawText(doc, pageIndex, font, size, bodySkillsBonusX, 433.5, this.character.additionalBodySkills.wildLife > 0 ? this.character.additionalBodySkills.wildLife.toString() : '')
    },
    drawMentalSkills(doc, font) {
      const pageIndex = 0
      const size = 9

      // Mental skills
      const mentalSkillsX = 503
      this.drawText(doc, pageIndex, font, size, mentalSkillsX, 319, this.character.mentalSkills.alleyKnowledge.toString())
      this.drawText(doc, pageIndex, font, size, mentalSkillsX, 335.5, this.character.mentalSkills.historicalKnowledge.toString())
      this.drawText(doc, pageIndex, font, size, mentalSkillsX, 352, this.character.mentalSkills.trading.toString())
      this.drawText(doc, pageIndex, font, size, mentalSkillsX, 368, this.character.mentalSkills.poisons.toString())
      this.drawText(doc, pageIndex, font, size, mentalSkillsX, 385, this.character.mentalSkills.magicScience.toString())
      this.drawText(doc, pageIndex, font, size, mentalSkillsX, 401, this.character.mentalSkills.religion.toString())
      this.drawText(doc, pageIndex, font, size, mentalSkillsX, 418, this.character.mentalSkills.persuation.toString())
      this.drawText(doc, pageIndex, font, size, mentalSkillsX, 433.5, this.character.mentalSkills.perception.toString())

      // Mental skills focus
      const mentalSkillsFocusX = 549
      this.drawText(doc, pageIndex, font, size, mentalSkillsFocusX, 319, this.character.mentalSkillsFocus.alleyKnowledge ? 'X' : '')
      this.drawText(doc, pageIndex, font, size, mentalSkillsFocusX, 335.5, this.character.mentalSkillsFocus.historicalKnowledge ? 'X' : '')
      this.drawText(doc, pageIndex, font, size, mentalSkillsFocusX, 352, this.character.mentalSkillsFocus.trading ? 'X' : '')
      this.drawText(doc, pageIndex, font, size, mentalSkillsFocusX, 368, this.character.mentalSkillsFocus.poisons ? 'X' : '')
      this.drawText(doc, pageIndex, font, size, mentalSkillsFocusX, 385, this.character.mentalSkillsFocus.magicScience ? 'X' : '')
      this.drawText(doc, pageIndex, font, size, mentalSkillsFocusX, 401, this.character.mentalSkillsFocus.religion ? 'X' : '')
      this.drawText(doc, pageIndex, font, size, mentalSkillsFocusX, 418, this.character.mentalSkillsFocus.persuation ? 'X' : '')
      this.drawText(doc, pageIndex, font, size, mentalSkillsFocusX, 433.5, this.character.mentalSkillsFocus.perception ? 'X' : '')

      // Mental skills focus
      const mentalSkillsBonusX = 568
      this.drawText(doc, pageIndex, font, size, mentalSkillsBonusX, 319, this.character.additionalMentalSkills.alleyKnowledge > 0 ? this.character.additionalMentalSkills.alleyKnowledge.toString() : '')
      this.drawText(doc, pageIndex, font, size, mentalSkillsBonusX, 335.5, this.character.additionalMentalSkills.historicalKnowledge > 0 ? this.character.additionalMentalSkills.historicalKnowledge.toString() : '')
      this.drawText(doc, pageIndex, font, size, mentalSkillsBonusX, 352, this.character.additionalMentalSkills.trading > 0 ? this.character.additionalMentalSkills.trading.toString() : '')
      this.drawText(doc, pageIndex, font, size, mentalSkillsBonusX, 368, this.character.additionalMentalSkills.poisons > 0 ? this.character.additionalMentalSkills.poisons.toString() : '')
      this.drawText(doc, pageIndex, font, size, mentalSkillsBonusX, 385, this.character.additionalMentalSkills.magicScience > 0 ? this.character.additionalMentalSkills.magicScience.toString() : '')
      this.drawText(doc, pageIndex, font, size, mentalSkillsBonusX, 401, this.character.additionalMentalSkills.religion > 0 ? this.character.additionalMentalSkills.religion.toString() : '')
      this.drawText(doc, pageIndex, font, size, mentalSkillsBonusX, 418, this.character.additionalMentalSkills.persuation > 0 ? this.character.additionalMentalSkills.persuation.toString() : '')
      this.drawText(doc, pageIndex, font, size, mentalSkillsBonusX, 433.5, this.character.additionalMentalSkills.perception > 0 ? this.character.additionalMentalSkills.perception.toString() : '')
    },
    drawCrafting(doc, font) {
      const pageIndex = 0
      const size = 6
      const attributeSize = 5

      const craftingX = 215
      const crafting = this.$t('components.CharacterGenerator.craftings.' + this.character.crafting + '.name')
      const craftingParts = crafting.split(' ')
      const craftingName = craftingParts[0]
      const craftingAttributes = craftingParts[1].replaceAll('(', '').replaceAll(')', '').split('/')

      this.drawText(doc, pageIndex, font, size, craftingX, 352, craftingName)
      this.drawText(doc, pageIndex, font, attributeSize, craftingX + 46, 352, craftingAttributes[0])
      this.drawText(doc, pageIndex, font, attributeSize, craftingX + 57, 352, craftingAttributes[1])
      this.drawText(doc, pageIndex, font, attributeSize, craftingX + 66.5, 352, craftingAttributes[2])
    },
    drawTalents(doc, font) {
      const pageIndex = 0
      const size = 6
      const maxCharacter = 25
      const smallSize = 5
      const maxCharacterSmall = 30
      const verySmallSize = 4
      const maxCharacterXtraSmall = 35
      const xtraSmallSize = 3

      const rows = this.formatTalentsToRows()
      for (let l = 0; l < rows.length; l++) {
        const cols = rows[l]
        const rowPixelToAdd = l * 13.5
        for (let k = 0; k < cols.length; k++) {
          const talent = cols[k]
          const colPixelToAdd = k * 102.5
          const talentValue = talent !== '' ? this.$t('components.CharacterGenerator.talents.' + talent + '.name') : talent
          const textSize = talentValue.length < maxCharacter ? size : talentValue.length < maxCharacterSmall ? smallSize : talentValue.length < maxCharacterXtraSmall ? verySmallSize : xtraSmallSize
          this.drawText(doc, pageIndex, font, textSize, 171.5 + colPixelToAdd, 464 + rowPixelToAdd, talentValue)
        }
      }
    },
    drawMeleeWeapons(doc, font) {
      const pageIndex = 0
      const size = 7
      const maxCharacter = 15
      const smallSize = 5
      const maxCharacterSmall = 20
      const verySmallSize = 4
      const maxCharacterXtraSmall = 25
      const xtraSmallSize = 3
      const meleeWeaponsX = 591.2

      this.drawRectangle(doc, pageIndex, 30, 14.5, rgb(1, 1, 1), 199, 596)
      this.drawRectangle(doc, pageIndex, 30, 14.5, rgb(1, 1, 1), 199, 612.3)
      this.drawRectangle(doc, pageIndex, 30, 14.5, rgb(1, 1, 1), 199, 628.6)

      const rows = this.formatMeleeWeaponsToRows(this.character.inventory.weapons.melee)
      for (let l = 0; l < rows.length; l++) {
        const weapon = rows[l]
        const rowPixelToAdd = l * 16.5
        const textSizeWeapon = (weapon.name.length < maxCharacter ? size : weapon.name.length < maxCharacterSmall ? smallSize : weapon.name.length < maxCharacterXtraSmall ? verySmallSize : xtraSmallSize) + 1
        const textSizeType = weapon.type.length < maxCharacter ? size : weapon.type.length < maxCharacterSmall ? smallSize : weapon.type.length < maxCharacterXtraSmall ? verySmallSize : xtraSmallSize
        this.drawText(doc, pageIndex, font, textSizeWeapon, 17, meleeWeaponsX + rowPixelToAdd, weapon.name)
        this.drawText(doc, pageIndex, font, textSizeType, 99, meleeWeaponsX + rowPixelToAdd, weapon.type)
        this.drawText(doc, pageIndex, font, size, 159, meleeWeaponsX + rowPixelToAdd, weapon.dodgeMalus.toString())
        this.drawText(doc, pageIndex, font, size, 181, meleeWeaponsX + rowPixelToAdd, weapon.muscleStrengthBonus.toString())
        this.drawText(doc, pageIndex, font, size, 203.4, meleeWeaponsX + rowPixelToAdd, weapon.defenseAndAttack.toString())
        this.drawText(doc, pageIndex, font, size, 241, meleeWeaponsX + rowPixelToAdd, weapon.actualAttack.toString())
        this.drawText(doc, pageIndex, font, size, 274, meleeWeaponsX + rowPixelToAdd, weapon.actualDefense.toString())
        this.drawText(doc, pageIndex, font, size, 307, meleeWeaponsX + rowPixelToAdd, weapon.actualDodge.toString())
        this.drawText(doc, pageIndex, font, size, 340, meleeWeaponsX + rowPixelToAdd, weapon.crit.toString())
        this.drawText(doc, pageIndex, font, size, 402, meleeWeaponsX + rowPixelToAdd, weapon.diceAndDamage.toString())
        this.drawText(doc, pageIndex, font, size, 451, meleeWeaponsX + rowPixelToAdd, weapon.durability.toString())
      }
    },
    drawArmor(doc, font) {
      const pageIndex = 0
      const size = 7
      const miniSize = 4
      const maxCharacter = 13
      const armorX = 591.2

      const rows = this.formatArmorToRows(this.character.inventory.armor)
      for (let l = 0; l < rows.length; l++) {
        const armor = rows[l]
        const rowPixelToAdd = l * 16.5

        this.drawText(doc, pageIndex, font, armor.name.length > maxCharacter ? miniSize : size, 473, armorX + rowPixelToAdd, armor.name)
        this.drawText(doc, pageIndex, font, size, 547, armorX + rowPixelToAdd, armor.armor.toString())
        this.drawText(doc, pageIndex, font, size, 566, armorX + rowPixelToAdd, armor.clumsiness.toString())
      }
    },
    drawRangedWeapons(doc, font) {
      const pageIndex = 0
      const size = 7
      const maxCharacter = 15
      const smallSize = 5
      const maxCharacterSmall = 20
      const verySmallSize = 4
      const maxCharacterXtraSmall = 25
      const xtraSmallSize = 3
      const rangedWeaponsX = 678.2

      const rows = this.formatRangedWeaponsToRows(this.character.inventory.weapons.ranged)
      for (let l = 0; l < rows.length; l++) {
        const weapon = rows[l]
        const rowPixelToAdd = l * 16.5
        const textSizeWeapon = (weapon.name.length < maxCharacter ? size : weapon.name.length < maxCharacterSmall ? smallSize : weapon.name.length < maxCharacterXtraSmall ? verySmallSize : xtraSmallSize) + 1
        const textSizeType = weapon.type.length < maxCharacter ? size : weapon.type.length < maxCharacterSmall ? smallSize : weapon.type.length < maxCharacterXtraSmall ? verySmallSize : xtraSmallSize
        this.drawText(doc, pageIndex, font, textSizeWeapon, 17, rangedWeaponsX + rowPixelToAdd, weapon.name)
        this.drawText(doc, pageIndex, font, textSizeType, 99, rangedWeaponsX + rowPixelToAdd, weapon.type)
        this.drawText(doc, pageIndex, font, size, 159, rangedWeaponsX + rowPixelToAdd, weapon.dodgeMalus.toString())
        this.drawText(doc, pageIndex, font, size, 181, rangedWeaponsX + rowPixelToAdd, weapon.muscleStrengthBonus.toString())
        this.drawText(doc, pageIndex, font, size, 207, rangedWeaponsX + rowPixelToAdd, weapon.range.toString())
        this.drawText(doc, pageIndex, font, size, 274, rangedWeaponsX + rowPixelToAdd, weapon.actualAttack.toString())
        this.drawText(doc, pageIndex, font, size, 307, rangedWeaponsX + rowPixelToAdd, weapon.actualDodge.toString())
        this.drawText(doc, pageIndex, font, size, 340, rangedWeaponsX + rowPixelToAdd, weapon.crit.toString())
        this.drawText(doc, pageIndex, font, size, 402, rangedWeaponsX + rowPixelToAdd, weapon.diceAndDamage.toString())
        this.drawText(doc, pageIndex, font, size, 451, rangedWeaponsX + rowPixelToAdd, weapon.durability.toString())
      }
    },
    drawShield(doc, font) {
      const pageIndex = 0
      const size = 7
      const miniSize = 4
      const maxCharacter = 13
      const shieldX = 678

      const rows = this.formatShieldToRows(this.character.inventory.shield)
      for (let l = 0; l < rows.length; l++) {
        const shield = rows[l]
        const rowPixelToAdd = l * 16.5

        this.drawText(doc, pageIndex, font, shield.name.length > maxCharacter ? miniSize : size, 471, shieldX + rowPixelToAdd, shield.name)
        this.drawText(doc, pageIndex, font, size, 524, shieldX + rowPixelToAdd, shield.attackReduction.toString())
        this.drawText(doc, pageIndex, font, size, 546, shieldX + rowPixelToAdd, shield.defenseBonus.toString())
        this.drawText(doc, pageIndex, font, size, 565, shieldX + rowPixelToAdd, shield.durability.toString())
      }
    },
    drawMoney(doc, font) {
      const pageIndex = 0
      const size = 8
      const moneyY = 716
      this.drawText(doc, pageIndex, font, size, 154, moneyY, this.character.inventory.money[0] > 0 ? this.character.inventory.money[0].toString() : '0')
      this.drawText(doc, pageIndex, font, size, 246, moneyY, this.character.inventory.money[1] > 0 ? this.character.inventory.money[1].toString() : '0')
      this.drawText(doc, pageIndex, font, size, 329, moneyY, this.character.inventory.money[2] > 0 ? this.character.inventory.money[2].toString() : '0')
      this.drawText(doc, pageIndex, font, size, 408, moneyY, this.character.inventory.money[3] > 0 ? this.character.inventory.money[3].toString() : '0')
    },
    drawMovement(doc, font) {
      const pageIndex = 0
      const size = 8
      this.drawText(doc, pageIndex, font, size, 530, 716, getMovementSpeed().toString() + ' Arm/Sek')
    },
    drawItems(doc, font) {
      let drawDetails = {
        maxLetters: 125,
        defaultX: 20,
        defaultY: 753.5,
        yRowPixels: 15.4,
        last: false,
        x: 20,
        y: 753.5,
        count: 0
      }
      drawDetails = this.drawEquipmentAsText(doc, font, drawDetails, this.character.inventory.weapons.melee, 'components.CharacterGenerator.items.weapons.melee.')
      drawDetails = this.drawEquipmentAsText(doc, font, drawDetails, this.character.inventory.weapons.ranged, 'components.CharacterGenerator.items.weapons.ranged.')
      drawDetails = this.drawEquipmentAsText(doc, font, drawDetails, this.character.inventory.armor, 'components.CharacterGenerator.items.armor.')
      drawDetails = this.drawEquipmentAsText(doc, font, drawDetails, this.character.inventory.shield, 'components.CharacterGenerator.items.shield.')
      drawDetails = this.drawEquipmentAsText(doc, font, drawDetails, this.character.inventory.jewellery.rings, 'components.CharacterGenerator.items.jewellery.rings.')
      drawDetails = this.drawEquipmentAsText(doc, font, drawDetails, this.character.inventory.jewellery.amulets, 'components.CharacterGenerator.items.jewellery.amulets.')
      this.drawItemsAsText(doc, font, drawDetails, this.character.inventory.items)
      this.drawRectangle(doc, 0, 2, 6, rgb(1, 1, 1), drawDetails.x - 2.9, drawDetails.y + 1.5)
    },
    drawItemsAsText(doc, font, details, items) {
      const pageIndex = 0
      const size = 6
      for (let i = 0; i < items.length; i++) {
        const itemText = this.getItemTranslation(items[i])
        if (details.count > details.maxLetters) {
          details.y += details.yRowPixels
          details.x = details.defaultX
          details.count = 0
        }
        const itemTextWidth = font.widthOfTextAtSize(itemText, size)
        this.drawText(doc, pageIndex, font, size, details.x, details.y, itemText)
        this.drawText(doc, pageIndex, font, size, details.x + itemTextWidth, details.y, ',')
        details.x += itemTextWidth + 3
        details.count += itemText.length + 2
      }
      return details
    },
    drawEquipmentAsText(doc, font, details, equipment, translationKey) {
      const pageIndex = 0
      const size = 6
      for (let i = 0; i < equipment.length; i++) {
        const itemText = this.$t(translationKey + equipment[i].name)
        if (details.count > details.maxLetters) {
          details.y += details.yRowPixels
          details.x = details.defaultX
          details.count = 0
        }
        const itemTextWidth = font.widthOfTextAtSize(itemText, size)
        this.drawTextUnderlined(doc, pageIndex, font, size, details.x, details.y, itemText)
        this.drawText(doc, pageIndex, font, size, details.x + itemTextWidth, details.y, ',')
        details.x += itemTextWidth + 3
        details.count += itemText.length + 2
      }
      return details
    },
    drawLanguages(doc, font) {
      const pageIndex = 0
      const size = 6
      const languageToAdd = 12
      const languageLength = this.character.languages.length > 6 ? 6 : this.character.languages.length
      for (let i = 0; i < languageLength; i++) {
        const language = this.character.languages[i]
        this.drawText(doc, pageIndex, font, size, 465, 756 + i * languageToAdd, this.$t('components.CharacterGenerator.languages.' + language + '.name'))
      }
    },
    drawMagicalBase(doc, font) {
      const pageIndex = 1
      const size = 10
      this.drawText(doc, pageIndex, font, size, 316, 67.5, this.character.personality.caster ? this.character.baseValues.magicalPower.toString() : '-')
    },
    drawSpells(doc, font) {
      this.drawSpell(doc, font, 17, 103, 268, 0, this.formatSpeelsToRows(this.character.kataSpells))
      this.drawSpellFocusAndSpecialization(doc, font, 204, 88, this.formatSpeelsToRows(this.character.kataSpells))
      this.drawSpell(doc, font, 17, 208, 268, 0, this.formatSpeelsToRows(this.character.controlSpells))
      this.drawSpellFocusAndSpecialization(doc, font, 204, 193, this.formatSpeelsToRows(this.character.controlSpells))
      this.drawSpell(doc, font, 17, 312, 268, 0, this.formatSpeelsToRows(this.character.summoningSpells))
      this.drawSpellFocusAndSpecialization(doc, font, 204, 297, this.formatSpeelsToRows(this.character.summoningSpells))
      this.drawSpell(doc, font, 17, 416.5, 268, 0, this.formatSpeelsToRows(this.character.movementSpells))
      this.drawSpellFocusAndSpecialization(doc, font, 204, 401.5, this.formatSpeelsToRows(this.character.movementSpells))
      this.drawSpell(doc, font, 17, 520.5, 268, 0, this.formatSpeelsToRows(this.character.healSpells))
      this.drawSpellFocusAndSpecialization(doc, font, 204, 505.5, this.formatSpeelsToRows(this.character.healSpells))

      this.drawSpell(doc, font, 299, 103, 268, 0, this.formatSpeelsToRows(this.character.clearanceSpells))
      this.drawSpellFocusAndSpecialization(doc, font, 486, 88, this.formatSpeelsToRows(this.character.clearanceSpells))
      this.drawSpell(doc, font, 299, 208, 268, 0, this.formatSpeelsToRows(this.character.illusionSpells))
      this.drawSpellFocusAndSpecialization(doc, font, 486, 193, this.formatSpeelsToRows(this.character.illusionSpells))
      this.drawSpell(doc, font, 299, 312, 268, 0, this.formatSpeelsToRows(this.character.combatSpells))
      this.drawSpellFocusAndSpecialization(doc, font, 486, 297, this.formatSpeelsToRows(this.character.combatSpells))
      this.drawSpell(doc, font, 299, 416.5, 268, 0, this.formatSpeelsToRows(this.character.aliveTransformationSpells))
      this.drawSpellFocusAndSpecialization(doc, font, 486, 401.5, this.formatSpeelsToRows(this.character.aliveTransformationSpells))
      this.drawSpell(doc, font, 299, 520.5, 268, 0, this.formatSpeelsToRows(this.character.deathTransformationSpells))
      this.drawSpellFocusAndSpecialization(doc, font, 486, 505.5, this.formatSpeelsToRows(this.character.deathTransformationSpells))
    },
    drawSpellFocusAndSpecialization(doc, font, spellsX, spellsY, spells) {
      const pageIndex = 1
      const size = 7
      const hasMagicFocus = spells.some(spell => spell.magicFocus)
      const hasMagicSpecialization = spells.some(spell => spell.magicSpecialization)
      if (hasMagicFocus) {
        this.drawText(doc, pageIndex, font, size, spellsX, spellsY, 'X')
      }
      if (hasMagicSpecialization) {
        this.drawText(doc, pageIndex, font, size, spellsX + 32, spellsY, 'X')
      }
    },
    drawSpell(doc, font, spellsX, spellsY, spellPowerX, spellPowerY, spells) {
      const pageIndex = 1
      const size = 7
      const spellsYadd = 12.35
      for (let i = 0; i < spells.length; i++) {
        const spell = spells[i]
        this.drawText(doc, pageIndex, font, size, spellsX, spellsY + i * spellsYadd, spell !== '' ? this.$t('components.CharacterGenerator.spells.' + spell.name + '.longName') : '')
        if (spell.power !== undefined) {
          this.drawText(doc, pageIndex, font, size, spellsX + spellPowerX, spellsY + i * spellsYadd - spellPowerY, spell.power.toString())
        }
        if (spell.metaMagic !== undefined && spell.metaMagic) {
          this.drawText(doc, pageIndex, font, size, spellsX + spellPowerX - 12, spellsY + i * spellsYadd - spellPowerY, 'X')
        }
      }
    },
    drawRunePotences(doc, font) {
      const pageIndex = 1
      const size = 10
      const runePotencesY = 646
      this.drawText(doc, pageIndex, font, size, 175, runePotencesY, this.character.personality.caster && this.character.personality.clazz === 'runeFighter' ? this.calculatePotenceMightyRuneValue(this.character.talents).toString() : '-')
      this.drawText(doc, pageIndex, font, size, 451, runePotencesY, this.character.personality.caster && this.character.personality.clazz === 'runeFighter' ? this.calculatePotenceTargetRuneValue(this.character.talents).toString() : '-')
    },
    drawRunes(doc, font) {
      this.drawRunePotences(doc, font)
      if (this.character.personality.caster && this.character.personality.clazz === 'runeFighter') {
        this.drawRuneLevels(doc, font)
      }
    },
    drawRuneLevels(doc, font) {
      const pageIndex = 1
      const size = 10
      const runesX = 140
      const runesY = 664
      const runtAbleX = 277.5
      const runtRowX = 16.4
      const runtColX = 20.5

      const table = this.formatRunesToRows(this.character.runes)
      for (let i = 0; i < table.length; i++) {
        const rows = table[i]
        const runeTableToAddX = i * runtAbleX
        for (let k = 0; k < rows.length; k++) {
          const cols = rows[k]
          const runeRowToAddY = k * runtRowX
          for (let l = 1; l < cols.length; l++) {
            const rune = cols[l]
            const runeColToAddX = l * runtColX
            this.drawText(doc, pageIndex, font, size, runesX + runeColToAddX + runeTableToAddX, runesY + runeRowToAddY, rune)
          }
          this.drawText(doc, pageIndex, font, size, runesX + runtColX * 6 + runeTableToAddX, runesY + runeRowToAddY, hasRuneFocus(this.character.runes[i * 10 + k]) ? 'X' : '')
          this.drawText(doc, pageIndex, font, size, runesX + runtColX * 7 + runeTableToAddX, runesY + runeRowToAddY, hasRuneSpecialization(this.character.runes[i * 10 + k]) ? 'X' : '')
        }
      }
    },
    getItemTranslation(itemEntry) {
      const itemEntrySplit = itemEntry.split('|')
      const name = itemEntrySplit[1]
      const counter = parseInt(itemEntrySplit[2])
      return counter > 1 ? counter + 'x ' + name : name
    },
    formatTalentsToRows() {
      let talentRow = []
      const talentRows = []
      for (let i = 0; i < 24; i++) {
        if (this.character.talents[i] !== undefined) {
          talentRow.push(this.character.talents[i])
        } else {
          talentRow.push('')
        }
        if (talentRow.length % 4 === 0) {
          talentRows.push(talentRow)
          talentRow = []
        }
      }
      return talentRows
    },
    calculatePotenceMightyRuneValue(talents) {
      return talents.includes('potenceMightyRune5') ? '5' : talents.includes('potenceMightyRune4') ? '4' : talents.includes('potenceMightyRune3') ? '3' : talents.includes('potenceMightyRune2') ? '2' : talents.includes('potenceMightyRune') ? '1' : ''
    },
    calculatePotenceTargetRuneValue(talents) {
      return talents.includes('potenceTargetRune5') ? '5' : talents.includes('potenceTargetRune4') ? '4' : talents.includes('potenceTargetRune3') ? '3' : talents.includes('potenceTargetRune2') ? '2' : talents.includes('potenceTargetRune') ? '1' : ''
    },
    formatRunesToRows(runes) {
      const runesTable = []
      const runeRows = []
      for (let i = 0; i < runes.length; i++) {
        runeRows.push(this.formatRuneRow(runes[i]))
      }
      runesTable.push(runeRows.slice(0, runeRows.length / 2))
      runesTable.push(runeRows.slice(runeRows.length / 2, runeRows.length))
      return runesTable
    },
    formatRuneRow(rune) {
      const runeRow = []
      runeRow.push(this.$t('components.CharacterGenerator.runes.' + rune.name + '.name'))
      for (let i = 1; i <= 5; i++) {
        if (i <= rune.level) {
          runeRow.push('' + i)
        } else {
          runeRow.push('-')
        }
      }
      return runeRow
    },
    formatSpeelsToRows(spells) {
      const spellRows = []
      for (let i = 0; i < 6; i++) {
        if (spells[i] !== undefined) {
          spellRows.push(spells[i])
        } else {
          spellRows.push('')
        }
      }
      return spellRows
    },
    formatMeleeWeaponsToRows(weapons) {
      const weaponRows = []
      for (let i = 0; i < 3; i++) {
        if (weapons[i] !== undefined) {
          const critMultiplicator = hasAssassin() ? weapons[i].critMultiplicator * 2 : weapons[i].critMultiplicator
          const critRange = 21 - weapons[i].critRange * (hasImprovedCriticalStrike(weapons[i]) ? 2 : 1)
          weaponRows.push({
            name: this.$t('components.CharacterGenerator.items.weapons.melee.' + weapons[i].name),
            type: this.$t('components.CharacterGenerator.weaponSkills.' + weapons[i].type),
            dodgeMalus: getActualMeleeWeaponDodgeMalus(weapons[i]),
            muscleStrengthBonus: weapons[i].muscleStrengthBonus,
            defenseAndAttack: getActualMeleeWeaponAttackAbility(weapons[i]) + ' / ' + getActualMeleeWeaponDefenseAbility(weapons, weapons[i]),
            actualAttack: getActualMeleeWeaponAttack(weapons, weapons[i]),
            actualDefense: getActualMeleeWeaponDefense(weapons, weapons[i]),
            actualDodge: getActualMeleeWeaponDodge(weapons[i]),
            diceAndDamage: weapons[i].dice + '+' + getActualMeleeWeaponDamage(weapons[i]),
            crit: weapons[i].critRange !== '-' ? critRange + '-20 x ' + critMultiplicator : '-',
            durability: weapons[i].durability
          })
        } else {
          weaponRows.push({
            name: '',
            type: '',
            dodgeMalus: '',
            muscleStrengthBonus: '',
            defenseAndAttack: '',
            actualAttack: '',
            actualDefense: '',
            actualDodge: '',
            diceAndDamage: '',
            crit: '',
            durability: ''
          })
        }
      }
      return weaponRows
    },
    formatRangedWeaponsToRows(weapons) {
      const weaponRows = []
      for (let i = 0; i < 3; i++) {
        if (weapons[i] !== undefined) {
          weaponRows.push({
            name: this.$t('components.CharacterGenerator.items.weapons.ranged.' + weapons[i].name),
            type: this.$t('components.CharacterGenerator.weaponSkills.' + weapons[i].type),
            dodgeMalus: getActualRangedWeaponDodgeMalus(weapons[i]),
            muscleStrengthBonus: weapons[i].type === 'crossbows' ? '' : weapons[i].muscleStrengthBonus,
            range: getActualRangedWeaponRange(weapons[i]),
            actualAttack: getActualRangedWeaponAttack(weapons[i]),
            actualDodge: getActualRangedWeaponDodge(weapons[i]),
            diceAndDamage: weapons[i].dice + '+' + getActualRangedWeaponDamage(weapons[i]),
            crit: weapons[i].critRange !== '-' ? 21 - weapons[i].critRange * (hasImprovedCriticalStrike(weapons[i]) ? 2 : 1) + '-20 x ' + weapons[i].critMultiplicator : '-',
            durability: weapons[i].durability
          })
        } else {
          weaponRows.push({
            name: '',
            type: '',
            dodgeMalus: '',
            muscleStrengthBonus: '',
            range: '',
            actualAttack: '',
            actualDodge: '',
            diceAndDamage: '',
            crit: '',
            durability: ''
          })
        }
      }
      return weaponRows
    },
    formatArmorToRows(armor) {
      const armorRows = []
      for (let i = 0; i < 3; i++) {
        if (armor[i] !== undefined) {
          armorRows.push({
            name: this.$t('components.CharacterGenerator.items.armor.' + armor[i].name),
            armor: armor[i].armorHead + armor[i].armorTorso + armor[i].armorLimbs,
            clumsiness: getSingleClumsiness(armor[i])
          })
        } else {
          armorRows.push({
            name: '',
            armor: '',
            clumsiness: ''
          })
        }
      }
      return armorRows
    },
    formatShieldToRows(shield) {
      const shieldRows = []
      for (let i = 0; i < 3; i++) {
        if (shield[i] !== undefined) {
          shieldRows.push({
            name: this.$t('components.CharacterGenerator.items.shield.' + shield[i].name),
            attackReduction: getActualShieldAttackReduction(shield[i]),
            defenseBonus: shield[i].defenseBonus,
            durability: shield[i].durability
          })
        } else {
          shieldRows.push({
            name: '',
            attackReduction: '',
            defenseBonus: '',
            durability: ''
          })
        }
      }
      return shieldRows
    }
  }
}
</script>
