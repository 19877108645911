<template>
  <v-row>
    <v-col cols="12" md="6">
      <v-toolbar elevation="0">
        <v-toolbar-title>
          {{ 'Verfügbare Zauber' }}
        </v-toolbar-title>
      </v-toolbar>
      <v-list>
        <v-list-item v-for="(spell, spellIndex) in spells" :key="spell.name + spellIndex">
          <v-list-item-content>
            <v-btn-toggle borderless>
              <v-btn class="spell_info" @click="showDialog(spell)">
                <v-icon>mdi-information</v-icon>
              </v-btn>
              <v-btn class="spell" @click="addSpell(spell.name)" :disabled="selectAbleCharacterSpellIsDisabled(spell)">
                {{ $t('components.CharacterGenerator.spells.' + spell.name + '.name') }}
              </v-btn>
            </v-btn-toggle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-col>
    <v-col cols="12" md="6">
      <v-toolbar elevation="0">
        <v-toolbar-title>{{ 'Gewählte Zauber' }}</v-toolbar-title>
      </v-toolbar>
      <v-list>
        <v-list-item v-for="(spell, spellIndex) in selectedSpells" :key="spell.name + spellIndex">
          <v-list-item-content>
            <v-btn-toggle class="primary" borderless>
              <v-btn class="spell_info" @click="showDialog(spell)">
                <v-icon>mdi-information</v-icon>
              </v-btn>
              <v-btn class="spell" @click="removeSpell(spell.name)" :disabled="activeCharacterSpellIsDisabled(spell.name)">
                {{ $t('components.CharacterGenerator.spells.' + spell.name + '.name') }}
              </v-btn>
            </v-btn-toggle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-col>
    <v-dialog v-model="dialog">
      <v-card class="primary">
        <v-card-title>
          {{ title }}
        </v-card-title>
        <v-card-text>
          <b>{{ $t('components.CharacterGenerator.spells.dependencies') }}</b
          >&nbsp;{{ dependency }}
          <br />
          <br />
          <div v-html="content" />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="dialog = false"> Schließen </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { removeSpell, addSpell, isBonusSpell } from '@/services/spell.service'
import { notAllDependenciesReached } from '@/services/dependency.service'
import { getStateForLevel } from '@/services/states.service'

export default {
  name: 'Spell',
  props: {
    header: {
      type: String,
      default: () => 'Title'
    },
    spells: {
      type: Array,
      default: () => []
    },
    selectedSpells: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    dialog: false,
    title: '',
    content: '',
    dependency: ''
  }),
  methods: {
    showDialog(spell) {
      this.title = this.$t('components.CharacterGenerator.spells.' + spell.name + '.longName')
      this.content = this.$t('components.CharacterGenerator.spells.' + spell.name + '.description')
      this.dependency = this.$t('components.CharacterGenerator.spells.' + spell.name + '.dependencies')
      this.dialog = true
    },
    isSpellDisabledByEarlierSelection(givenSpell) {
      const characterState = getStateForLevel(this.$store.getters.characterLevel - 1)
      if (characterState !== undefined) {
        return characterState.spells.some(selectedSpell => givenSpell === selectedSpell)
      }
      return false
    },
    activeCharacterSpellIsDisabled(spellName) {
      return isBonusSpell(spellName) || this.isSpellDisabledByEarlierSelection(spellName)
    },
    selectAbleCharacterSpellIsDisabled(spell) {
      const spellIsActive = this.$store.getters.characterSpells.filter(characterSpell => characterSpell === spell.name).length > 0
      const notEnougthSpellPoints = this.$store.getters.characterSpellPoints <= 0
      return notEnougthSpellPoints || spellIsActive || notAllDependenciesReached(spell.dependencies)
    },
    removeSpell(spell) {
      removeSpell(spell)
    },
    addSpell(spellName) {
      addSpell(spellName)
    }
  }
}
</script>
<style>
.spell {
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.spell_info {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.theme--light.v-btn--active:hover::before,
.theme--light.v-btn--active::before {
  opacity: 0;
}
</style>
