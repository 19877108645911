<template>
  <v-card outlined>
    <v-app-bar class="primary">
      <v-toolbar-title>
        {{ $t('components.CharacterGenerator.baseValues.label') }}
      </v-toolbar-title>
    </v-app-bar>
    <v-container fluid>
      <v-row style="padding-top: 25px">
        <v-col>
          <Value :title="$t('components.CharacterGenerator.baseValues.attack')" :allow-changes="false" :value="$store.getters.characterBaseValues.attack" :value-bonus="$store.getters.characterBaseValuesBonus.attack" :value-name="'attack'" />
          <Value :title="$t('components.CharacterGenerator.baseValues.defense')" :allow-changes="false" :value="$store.getters.characterBaseValues.defense" :value-bonus="$store.getters.characterBaseValuesBonus.defense" :value-name="'defense'" />
          <Value :title="$t('components.CharacterGenerator.baseValues.distance')" :allow-changes="false" :value="$store.getters.characterBaseValues.distance" :value-bonus="$store.getters.characterBaseValuesBonus.distance" :value-name="'distance'" />
          <Value :title="$t('components.CharacterGenerator.baseValues.dodge')" :allow-changes="false" :value="$store.getters.characterBaseValues.dodge" :value-bonus="$store.getters.characterBaseValuesBonus.dodge" :value-name="'dodge'" />
          <Value
            :title="$t('components.CharacterGenerator.baseValues.magicalResistance')"
            :allow-changes="false"
            :value="$store.getters.characterBaseValues.magicalResistance"
            :value-bonus="$store.getters.characterBaseValuesBonus.magicalResistance"
            :value-name="'magicalResistance'"
          />
          <Value
            :title="$t('components.CharacterGenerator.baseValues.magicalPower')"
            :allow-changes="false"
            :value="$store.getters.characterBaseValues.magicalPower"
            :value-bonus="$store.getters.characterBaseValuesBonus.magicalPower"
            :value-name="'magicalPower'"
          />
          <Value
            :title="$t('components.CharacterGenerator.baseValues.health', [$store.getters.characterClazzModificatorHealth])"
            :allow-changes="false"
            :value="$store.getters.characterBaseValues.health"
            :value-bonus="$store.getters.characterBaseValuesBonus.health"
            :value-name="'health'"
          />
          <Value
            :title="$t('components.CharacterGenerator.baseValues.soul', [$store.getters.characterClazzModificatorSoul])"
            :allow-changes="false"
            :value="$store.getters.characterBaseValues.soul"
            :value-bonus="$store.getters.characterBaseValuesBonus.soul"
            :value-name="'soul'"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import Value from '@/components/content/Value'

export default {
  name: 'BaseValues',
  components: { Value }
}
</script>
