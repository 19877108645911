<template>
  <v-footer color="primary" padless app bottom absolute>
    <v-row justify="center" no-gutters>
      <v-col class="py-4 text-center white--text" cols="12"> ©&nbsp;{{ new Date().getFullYear() }} — <strong>Patrick Weppelmann & Florian Ruf</strong> </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>
