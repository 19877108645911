<template>
  <v-card outlined>
    <v-app-bar class="primary">
      <v-toolbar-title>
        {{ $t('components.CharacterGenerator.spells.label') + ' (' + $store.getters.characterSpellPoints + ')' }}
      </v-toolbar-title>
    </v-app-bar>
    <v-container fluid>
      <v-expansion-panels focusable>
        <v-expansion-panel v-show="this.$store.getters.configuration.showEasterEggs">
          <v-expansion-panel-header>
            <h3>{{ $t('components.CharacterGenerator.spells.easterEgg') }}</h3>
            <template v-slot:actions>
              <Availability :selectable="easterEggSpells.length" :selected="selectedEasterEggSpells.length" />
              <v-icon color="primary"> $expand </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <Spell :header="$t('components.CharacterGenerator.spells.easterEgg')" :spells="easterEggSpells" :selected-spells="selectedEasterEggSpells" />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>{{ $t('components.CharacterGenerator.spells.kata') }}</h3>
            <template v-slot:actions>
              <Availability :selectable="kataSpells.length" :selected="selectedKataSpells.length" />
              <v-icon color="primary"> $expand </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <Spell :header="$t('components.CharacterGenerator.spells.kata')" :spells="kataSpells" :selected-spells="selectedKataSpells" />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>{{ $t('components.CharacterGenerator.spells.clearance') }}</h3>
            <template v-slot:actions>
              <Availability :selectable="clearanceSpells.length" :selected="selectedClearanceSpells.length" />
              <v-icon color="primary"> $expand </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <Spell :header="$t('components.CharacterGenerator.spells.clearance')" :spells="clearanceSpells" :selected-spells="selectedClearanceSpells" />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>{{ $t('components.CharacterGenerator.spells.control') }}</h3>
            <template v-slot:actions>
              <Availability :selectable="controlSpells.length" :selected="selectedControlSpells.length" />
              <v-icon color="primary"> $expand </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <Spell :header="$t('components.CharacterGenerator.spells.control')" :spells="controlSpells" :selected-spells="selectedControlSpells" />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>{{ $t('components.CharacterGenerator.spells.illusion') }}</h3>
            <template v-slot:actions>
              <Availability :selectable="illusionSpells.length" :selected="selectedIllusionSpells.length" />
              <v-icon color="primary"> $expand </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <Spell :header="$t('components.CharacterGenerator.spells.illusion')" :spells="illusionSpells" :selected-spells="selectedIllusionSpells" />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>{{ $t('components.CharacterGenerator.spells.summoning') }}</h3>
            <template v-slot:actions>
              <Availability :selectable="summoningSpells.length" :selected="selectedSummoningSpells.length" />
              <v-icon color="primary"> $expand </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <Spell :header="$t('components.CharacterGenerator.spells.summoning')" :spells="summoningSpells" :selected-spells="selectedSummoningSpells" />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>{{ $t('components.CharacterGenerator.spells.combat') }}</h3>
            <template v-slot:actions>
              <Availability :selectable="combatSpells.length" :selected="selectedCombatSpells.length" />
              <v-icon color="primary"> $expand </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <Spell :header="$t('components.CharacterGenerator.spells.combat')" :spells="combatSpells" :selected-spells="selectedCombatSpells" />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>{{ $t('components.CharacterGenerator.spells.movement') }}</h3>
            <template v-slot:actions>
              <Availability :selectable="movementSpells.length" :selected="selectedMovementSpells.length" />
              <v-icon color="primary"> $expand </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <Spell :header="$t('components.CharacterGenerator.spells.movement')" :spells="movementSpells" :selected-spells="selectedMovementSpells" />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>{{ $t('components.CharacterGenerator.spells.aliveTransformation') }}</h3>
            <template v-slot:actions>
              <Availability :selectable="aliveTransformationSpells.length" :selected="selectedAliveTransformationSpells.length" />
              <v-icon color="primary"> $expand </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <Spell :header="$t('components.CharacterGenerator.spells.aliveTransformation')" :spells="aliveTransformationSpells" :selected-spells="selectedAliveTransformationSpells" />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>{{ $t('components.CharacterGenerator.spells.heal') }}</h3>
            <template v-slot:actions>
              <Availability :selectable="healSpells.length" :selected="selectedHealSpells.length" />
              <v-icon color="primary"> $expand </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <Spell :header="$t('components.CharacterGenerator.spells.heal')" :spells="healSpells" :selected-spells="selectedHealSpells" />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>{{ $t('components.CharacterGenerator.spells.deathTransformation') }}</h3>
            <template v-slot:actions>
              <Availability :selectable="deathTransformationSpells.length" :selected="selectedDeathTransformationSpells.length" />
              <v-icon color="primary"> $expand </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <Spell :header="$t('components.CharacterGenerator.spells.deathTransformation')" :spells="deathTransformationSpells" :selected-spells="selectedDeathTransformationSpells" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
  </v-card>
</template>

<script>
import { getSpellForName } from '@/services/spell.service'
import { notAllDependenciesReached } from '@/services/dependency.service'
import Spell from '@/components/content/Spell'
import Availability from '@/components/content/Availability'

export default {
  name: 'Spells',
  components: { Availability, Spell },
  computed: {
    selectedEasterEggSpells() {
      return this.$store.getters.characterSpells.map(spell => getSpellForName(spell)).filter(spell => spell.type.startsWith('easterEgg'))
    },
    easterEggSpells() {
      return this.$store.getters.spells.filter(spell => spell.type.startsWith('easterEgg')).filter(spell => this.$store.getters.configuration.showDisabledSpells || !this.selectAbleCharacterSpellIsDisabled(spell))
    },
    selectedKataSpells() {
      return this.$store.getters.characterSpells.map(spell => getSpellForName(spell)).filter(spell => spell.type.startsWith('kata'))
    },
    kataSpells() {
      return this.$store.getters.spells.filter(spell => spell.type.startsWith('kata')).filter(spell => this.$store.getters.configuration.showDisabledSpells || !this.selectAbleCharacterSpellIsDisabled(spell))
    },
    selectedControlSpells() {
      return this.$store.getters.characterSpells.map(spell => getSpellForName(spell)).filter(spell => spell.type.startsWith('control'))
    },
    controlSpells() {
      return this.$store.getters.spells.filter(spell => spell.type.startsWith('control')).filter(spell => this.$store.getters.configuration.showDisabledSpells || !this.selectAbleCharacterSpellIsDisabled(spell))
    },
    selectedMovementSpells() {
      return this.$store.getters.characterSpells.map(spell => getSpellForName(spell)).filter(spell => spell.type.startsWith('movement'))
    },
    movementSpells() {
      return this.$store.getters.spells.filter(spell => spell.type.startsWith('movement')).filter(spell => this.$store.getters.configuration.showDisabledSpells || !this.selectAbleCharacterSpellIsDisabled(spell))
    },
    selectedHealSpells() {
      return this.$store.getters.characterSpells.map(spell => getSpellForName(spell)).filter(spell => spell.type.startsWith('heal'))
    },
    healSpells() {
      return this.$store.getters.spells.filter(spell => spell.type.startsWith('heal')).filter(spell => this.$store.getters.configuration.showDisabledSpells || !this.selectAbleCharacterSpellIsDisabled(spell))
    },
    selectedClearanceSpells() {
      return this.$store.getters.characterSpells.map(spell => getSpellForName(spell)).filter(spell => spell.type.startsWith('clearance'))
    },
    clearanceSpells() {
      return this.$store.getters.spells.filter(spell => spell.type.startsWith('clearance')).filter(spell => this.$store.getters.configuration.showDisabledSpells || !this.selectAbleCharacterSpellIsDisabled(spell))
    },
    selectedIllusionSpells() {
      return this.$store.getters.characterSpells.map(spell => getSpellForName(spell)).filter(spell => spell.type.startsWith('illusion'))
    },
    illusionSpells() {
      return this.$store.getters.spells.filter(spell => spell.type.startsWith('illusion')).filter(spell => this.$store.getters.configuration.showDisabledSpells || !this.selectAbleCharacterSpellIsDisabled(spell))
    },
    selectedSummoningSpells() {
      return this.$store.getters.characterSpells.map(spell => getSpellForName(spell)).filter(spell => spell.type.startsWith('summoning'))
    },
    summoningSpells() {
      return this.$store.getters.spells.filter(spell => spell.type.startsWith('summoning')).filter(spell => this.$store.getters.configuration.showDisabledSpells || !this.selectAbleCharacterSpellIsDisabled(spell))
    },
    selectedCombatSpells() {
      return this.$store.getters.characterSpells.map(spell => getSpellForName(spell)).filter(spell => spell.type.startsWith('combat'))
    },
    combatSpells() {
      return this.$store.getters.spells.filter(spell => spell.type.startsWith('combat')).filter(spell => this.$store.getters.configuration.showDisabledSpells || !this.selectAbleCharacterSpellIsDisabled(spell))
    },
    selectedAliveTransformationSpells() {
      return this.$store.getters.characterSpells.map(spell => getSpellForName(spell)).filter(spell => spell.type.startsWith('aliveTransformation'))
    },
    aliveTransformationSpells() {
      return this.$store.getters.spells.filter(spell => spell.type.startsWith('aliveTransformation')).filter(spell => this.$store.getters.configuration.showDisabledSpells || !this.selectAbleCharacterSpellIsDisabled(spell))
    },
    selectedDeathTransformationSpells() {
      return this.$store.getters.characterSpells.map(spell => getSpellForName(spell)).filter(spell => spell.type.startsWith('deathTransformation'))
    },
    deathTransformationSpells() {
      return this.$store.getters.spells.filter(spell => spell.type.startsWith('deathTransformation')).filter(spell => this.$store.getters.configuration.showDisabledSpells || !this.selectAbleCharacterSpellIsDisabled(spell))
    }
  },
  methods: {
    selectAbleCharacterSpellIsDisabled(spell) {
      const spellIsActive = this.$store.getters.characterSpells.filter(characterSpell => characterSpell === spell.name).length > 0
      const notEnougthSpellPoints = this.$store.getters.characterSpellPoints <= 0
      return notEnougthSpellPoints || spellIsActive || notAllDependenciesReached(spell.dependencies)
    }
  }
}
</script>
