<template>
  <v-row>
    <v-col>
      <v-text-field v-model="search" label="Suche" clearable outlined />
      <v-treeview :items="items" :search="search" open-on-click transition>
        <template v-slot:prepend="{ item }">
          <v-icon v-if="item.id === 'weapons'"> mdi-sword-cross </v-icon>
          <v-icon v-if="item.id === 'melee'"> mdi-sword </v-icon>
          <v-icon v-if="item.id === 'ranged'"> mdi-bullseye-arrow </v-icon>
          <v-icon v-if="item.id === 'armor'"> mdi-tshirt-v </v-icon>
          <v-icon v-if="item.id === 'shield'"> mdi-shield </v-icon>
          <v-icon v-if="item.id === 'jewellery'"> mdi-diamond </v-icon>
          <v-icon v-if="item.id === 'rings'"> mdi-ring </v-icon>
          <v-icon v-if="item.id === 'amulets'"> mdi-necklace </v-icon>
          <v-icon v-if="item.id === 'others'"> mdi-sack </v-icon>
          <v-icon v-if="item.id === 'alchemicalSubstances'"> mdi-test-tube </v-icon>
          <v-icon v-if="item.id === 'containers'"> mdi-cube </v-icon>
          <v-icon v-if="item.id === 'lighting'"> mdi-torch </v-icon>
          <v-icon v-if="item.id === 'gems'"> mdi-language-ruby </v-icon>
          <v-icon v-if="item.id === 'vehicles'"> mdi-sledding </v-icon>
          <v-icon v-if="item.id === 'inTheTavern'"> mdi-glass-mug-variant </v-icon>
          <v-icon v-if="item.id === 'instruments'"> mdi-trumpet </v-icon>
          <v-icon v-if="item.id === 'bodyCare'"> mdi-paper-roll </v-icon>
          <v-icon v-if="item.id === 'metals'"> mdi-gold </v-icon>
          <v-icon v-if="item.id === 'food'"> mdi-barley </v-icon>
          <v-icon v-if="item.id === 'horses'"> mdi-horse </v-icon>
          <v-icon v-if="item.id === 'travelAndTransport'"> mdi-purse </v-icon>
          <v-icon v-if="item.id === 'stationeryAndPrecisionMechanics'"> mdi-pencil-ruler </v-icon>
          <v-icon v-if="item.id === 'ropeGear'"> mdi-lasso </v-icon>
          <v-icon v-if="item.id === 'petSupplies'"> mdi-paw </v-icon>
          <v-icon v-if="item.id === 'potions'"> mdi-flask </v-icon>
          <v-icon v-if="item.id === 'fabric'"> mdi-layers-triple </v-icon>
          <v-icon v-if="item.id === 'tools'"> mdi-hammer-screwdriver </v-icon>
          <v-icon v-if="item.id === 'weaponAccessories'"> mdi-oil </v-icon>
          <v-icon v-if="item.id === 'storyTelling'"> mdi-book-open-page-variant </v-icon>
        </template>
        <template v-slot:append="{ item }">
          <v-btn v-if="!item.children" @click="add(item)" elevation="0" icon>
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
      </v-treeview>
    </v-col>
    <v-divider vertical />
    <v-col class="pa-6" cols="6">
      <template v-if="!$store.getters.characterItems.length">
        {{ $t('components.CharacterGenerator.inventory.items.missing') }}
      </template>
      <template v-else>
        <v-scroll-x-transition class="text-center" group>
          <v-chip v-for="(itemEntry, i) in $store.getters.characterItems" :key="i" class="ma-2" close-icon="mdi-delete-circle" small label close @click:close="remove(itemEntry)">
            {{ getItemTranslation(itemEntry) }}
          </v-chip>
        </v-scroll-x-transition>
      </template>
    </v-col>
  </v-row>
</template>
<script>
import { addItem, removeItem } from '@/services/items.service'
export default {
  name: 'Items',
  data: () => ({
    search: null
  }),
  computed: {
    items() {
      return this.currentAvailAbleItems
    },
    currentAvailAbleItems() {
      const items = this.$store.getters.items
      const mainCategoryNames = Object.keys(items)
      return mainCategoryNames.map(categoryName => this.createCategory(categoryName, items[categoryName], 'components.CharacterGenerator.items.'))
    }
  },
  methods: {
    createCategory(name, category, translationPrefix) {
      if (!Array.isArray(category)) {
        const subcategoryNames = Object.keys(category)
        return {
          id: name,
          name: this.$t('components.CharacterGenerator.inventory.' + name),
          children: subcategoryNames.map(subcategoryName => this.createCategory(subcategoryName, category[subcategoryName], translationPrefix + name + '.'))
        }
      } else {
        return {
          id: name,
          name: this.$t('components.CharacterGenerator.inventory.' + name),
          children: category.map(categoryEntry => this.createEntry(categoryEntry, translationPrefix + name + '.'))
        }
      }
    },
    createEntry(item, translationPrefix) {
      return {
        id: item.name,
        name: this.$t(translationPrefix + item.name)
      }
    },
    add(item) {
      addItem(item)
    },
    remove(itemId) {
      removeItem(itemId)
    },
    getItemTranslation(itemEntry) {
      const itemEntrySplit = itemEntry.split('|')
      const name = itemEntrySplit[1]
      const counter = parseInt(itemEntrySplit[2])
      return counter > 1 ? counter + 'x ' + name : name
    }
  }
}
</script>
