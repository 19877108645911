<template>
  <v-app-bar app color="primary" elevation="0">
    <v-app-bar-nav-icon color="white" @click.stop="$store.state.drawer = !$store.state.drawer" />
    <div class="d-flex align-center">
      <v-img alt="BeSoDD Logo" contain :src="require('../../assets/logo_white.svg')" transition="scale-transition" width="100" />
    </div>
    <v-spacer></v-spacer>
    <v-tooltip v-if="$store.state.account.loggedIn" bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" icon :disabled="!$store.state.character.personality.name" @click="save">
          <v-icon>mdi-content-save</v-icon>
        </v-btn>
      </template>
      <span>Speichern</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" icon @click="changeLanguage">{{ currentLanguage }}</v-btn>
      </template>
      <span>Localization</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" icon @click="$vuetify.theme.dark = !$vuetify.theme.dark">
          <v-icon v-show="$vuetify.theme.dark">mdi-weather-night</v-icon>
          <v-icon v-show="!$vuetify.theme.dark">mdi-weather-sunny</v-icon>
        </v-btn>
      </template>
      <span>Darkmode</span>
    </v-tooltip>
    <template v-slot:extension>
      <ToolBar />
    </template>
  </v-app-bar>
</template>

<script>
import { saveCharacter } from '../../services/account.service'
import ToolBar from '@/components/header/ToolBar'

export default {
  name: 'AppBar',
  components: {
    ToolBar
  },
  data() {
    return {
      currentLanguage: this.$i18n.locale
    }
  },
  methods: {
    changeLanguage() {
      const currentLanguage = this.$i18n.locale
      console.log('currentLanguage', this.$i18n.locale)
      if (currentLanguage === 'de') {
        this.currentLanguage = 'en'
        this.$i18n.locale = 'en'
      } else {
        this.currentLanguage = 'de'
        this.$i18n.locale = 'de'
      }
      console.log('changedLanguage', this.$i18n.locale)
    },
    async save() {
      const saveSuccess = await saveCharacter()
      if (saveSuccess) {
        const character = this.$store.getters.character
        const states = this.$store.getters.characterStates
        const configuration = this.$store.getters.configuration
        const existingCharacter = this.$store.state.account.characters.find(accountCharacter => accountCharacter.name === character.personality.name)
        if (existingCharacter) {
          existingCharacter.gender = character.personality.gender
          existingCharacter.culture = character.personality.culture
          existingCharacter.clazz = character.personality.clazz
          existingCharacter.level = character.personality.level
          existingCharacter.experience = character.personality.experience
          existingCharacter.content = {
            character,
            states,
            configuration
          }
        } else {
          this.$store.state.account.characters.push({
            name: character.personality.name,
            gender: character.personality.gender,
            culture: character.personality.culture,
            clazz: character.personality.clazz,
            level: character.personality.level,
            experience: character.personality.experience,
            content: {
              character,
              states,
              configuration
            }
          })
        }
        this.$store.state.account.messages.saveSuccess = true
      } else {
        this.$store.state.account.messages.saveError = true
      }
    }
  }
}
</script>

<style scoped>
.theme--light.v-btn.v-btn--icon {
  color: rgba(255, 255, 255) !important;
}

.theme--light.v-btn.v-btn--disabled .v-icon,
.theme--light.v-btn.v-btn--disabled .v-btn__loading {
  color: rgba(255, 255, 255, 0.26) !important;
}
</style>
