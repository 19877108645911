import store from '../store'
import { loadState } from './states.service'

function getCurrentPersonality() {
  return {
    name: store.getters.characterName,
    gender: store.getters.characterGender,
    culture: store.getters.characterCulture,
    clazz: store.getters.characterClazz,
    hairColor: store.state.character.personality.hairColor,
    skinColor: store.state.character.personality.skinColor,
    eyeColor: store.state.character.personality.eyeColor,
    size: store.state.character.personality.size,
    weight: store.state.character.personality.weight,
    age: store.state.character.personality.age,
    experience: parseInt(store.getters.characterExperience),
    level: parseInt(store.getters.characterLevel),
    caster: store.getters.caster
  }
}
function levelToExperience(level) {
  let calculatedExperience = 0
  for (let i = 0; i < level; i++) {
    calculatedExperience = calculatedExperience + 1000 * i
  }
  return calculatedExperience
}
function experienceToLevel(experience) {
  let calculatedExperience = 0
  let levelCount = 0
  while (experience >= calculatedExperience) {
    calculatedExperience = calculatedExperience + 1000 * levelCount
    levelCount++
  }
  return levelCount - 1
}
function setLevel(level) {
  store.dispatch('setCharacterLevel', level)
}
function setExperience(experience) {
  store.dispatch('setCharacterExperience', experience)
}
function setName(name) {
  store.dispatch('setCharacterName', name)
}
function setBackground(background) {
  store.dispatch('setCharacterBackground', background)
}
function levelUp() {
  let allPoints = store.getters.characterAttributePoints + store.getters.characterWeaponSkillPoints + store.getters.characterBodySkillPoints + store.getters.characterMentalSkillPoints + store.getters.characterTalentPoints
  if (store.getters.caster && store.getters.characterClazz !== 'runeFighter') {
    allPoints = allPoints + store.getters.characterSpellPoints
  }
  if (store.getters.caster && store.getters.characterClazz === 'runeFighter') {
    allPoints = allPoints + store.getters.characterRunePoints
  }
  return allPoints <= 0
}
function initializeCharacter() {
  loadState(1)
}
function getHairColorForName(colorName) {
  return store.getters.hairColors.find(color => color.name === colorName)
}
function getCurrentHairColor() {
  return getHairColorForName(store.getters.characterHairColor)
}
function setHairColor(hairColor) {
  store.dispatch('setCharacterHairColor', hairColor)
}
function getEyeColorForName(colorName) {
  return store.getters.eyeColors.find(color => color.name === colorName)
}
function getCurrentEyeColor() {
  return getEyeColorForName(store.getters.characterEyeColor)
}
function setEyeColor(eyeColor) {
  store.dispatch('setCharacterEyeColor', eyeColor)
}
function getSkinColorForName(colorName) {
  return store.getters.skinColors.find(color => color.name === colorName)
}
function getCurrentSkinColor() {
  return getSkinColorForName(store.getters.characterSkinColor)
}
function setSkinColor(skinColor) {
  store.dispatch('setCharacterSkinColor', skinColor)
}
function setAge(age) {
  store.dispatch('setCharacterAge', age)
}
function setSize(size) {
  store.dispatch('setCharacterSize', size)
}
function setWeight(weight) {
  store.dispatch('setCharacterWeight', weight)
}
export {
  getCurrentPersonality,
  levelToExperience,
  experienceToLevel,
  setName,
  setBackground,
  setLevel,
  setExperience,
  initializeCharacter,
  levelUp,
  getHairColorForName,
  getCurrentHairColor,
  setHairColor,
  getEyeColorForName,
  getCurrentEyeColor,
  getSkinColorForName,
  getCurrentSkinColor,
  setEyeColor,
  setSkinColor,
  setAge,
  setSize,
  setWeight
}
