import { getCurrentBaseValues } from './baseValue.service'
import { getCurrentAttributes } from './attribute.service'
import { getCurrentWeaponSkills } from './weaponSkills.service'
import { getTotalClumsiness } from './armor.service'
import { getActualShieldAttackReduction } from './shield.service'

function getActualRangedWeaponDodgeMalus(weapon) {
  const actualDodgeMalus = weapon.dodgeMalus
  return actualDodgeMalus > 0 ? actualDodgeMalus : 0
}
function getActualRangedWeaponRange(weapon) {
  const currentAttributes = getCurrentAttributes()
  return weapon.type !== 'throwingWeapons' ? weapon.range : weapon.range + currentAttributes.muscleStrength
}
function getActualRangedWeaponAttack(weapon) {
  const currentBaseValues = getCurrentBaseValues()
  const weaponSkill = getWeaponSkillValueFromWeapon(weapon)
  const totalClumsiness = getTotalClumsiness()
  return parseInt(currentBaseValues.distance) + weaponSkill - totalClumsiness
}

function getActualRangedWeaponDodge(weapon) {
  const currentBaseValues = getCurrentBaseValues()
  const currentWeaponSkills = getCurrentWeaponSkills()
  const totalClumsiness = getTotalClumsiness()
  const actualShieldAttackReduction = getActualShieldAttackReduction()
  const actualRangedWeaponDodgeMalus = getActualRangedWeaponDodgeMalus(weapon)
  const malus = actualRangedWeaponDodgeMalus + totalClumsiness + actualShieldAttackReduction
  const actualDodge = parseInt(currentBaseValues.dodge) + Math.ceil(parseInt(currentWeaponSkills.dodge) / 2) - malus
  return actualDodge > 0 ? actualDodge : 0
}

function getActualRangedWeaponDamage(weapon) {
  return weapon.damage + getRangedWeaponBonusDamage(weapon)
}
function getRangedWeaponBonusDamage(weapon) {
  const currentAttributes = getCurrentAttributes()
  const bonusDamage = currentAttributes.muscleStrength - weapon.muscleStrengthBonus
  return weapon.type !== 'crossbows' && bonusDamage > 0 ? bonusDamage : 0
}
function getWeaponSkillValueFromWeapon(weapon) {
  const currentWeaponSkills = getCurrentWeaponSkills()
  return parseInt(currentWeaponSkills[weapon.type])
}
export { getActualRangedWeaponDodgeMalus, getActualRangedWeaponRange, getActualRangedWeaponAttack, getActualRangedWeaponDodge, getActualRangedWeaponDamage }
