import store from '../store'
import { getAllBonusPointsByName } from './bonus.service'
import { getAllMalusPointsByName } from './malus.service'
import { getCurrentPersonality } from './personality.service'
import { getStateForLevel } from './states.service'

function getSkillPoints() {
  return getAdditionalSkillPoints() - getUsedSkillPoints()
}
function getAdditionalSkillPoints() {
  const bonusSkillPoints = getAllBonusPointsByName('skillPoints')
  const malusSkillPoints = getAllMalusPointsByName('skillPoints')
  return parseInt(bonusSkillPoints) - parseInt(malusSkillPoints)
}
function getUsedSkillPoints() {
  const sharedSkillPoints = store.getters.characterSharedSkillPoints
  return parseInt(sharedSkillPoints.skillPointsToWeaponSkills) + parseInt(sharedSkillPoints.skillPointsToBodySkills) + parseInt(sharedSkillPoints.skillPointsToMentalSkills) + parseInt(sharedSkillPoints.skillPointsToRunes)
}
function getAllSharedSkillPoints() {
  const sharedSkillPoints = store.getters.characterSharedSkillPoints
  return (
    parseInt(sharedSkillPoints.weaponSkillsToBodySkills) +
    parseInt(sharedSkillPoints.weaponSkillsToMentalSkills) +
    // parseInt(sharedSkillPoints.weaponSkillsToRunes) +
    parseInt(sharedSkillPoints.bodySkillsToWeaponSkills) +
    parseInt(sharedSkillPoints.bodySkillsToMentalSkills) +
    // parseInt(sharedSkillPoints.bodySkillsToRunes) +
    parseInt(sharedSkillPoints.mentalSkillsToWeaponSkills) +
    parseInt(sharedSkillPoints.mentalSkillsToBodySkills)
    // parseInt(sharedSkillPoints.mentalSkillsToRunes)
  )
}
function getPreviousSharedSkillPoints() {
  const currentPersonality = getCurrentPersonality()
  let previousCharacterState = getStateForLevel(currentPersonality.level - 1)
  if (previousCharacterState === undefined) {
    previousCharacterState = getStateForLevel(1)
  }
  return previousCharacterState.sharedSkillPoints
}
function getPreviousAllSharedSkillPoints() {
  const previousSharedSkillPoints = getPreviousSharedSkillPoints()
  return (
    parseInt(previousSharedSkillPoints.weaponSkillsToBodySkills) +
    parseInt(previousSharedSkillPoints.weaponSkillsToMentalSkills) +
    // parseInt(sharedSkillPoints.weaponSkillsToRunes) +
    parseInt(previousSharedSkillPoints.bodySkillsToWeaponSkills) +
    parseInt(previousSharedSkillPoints.bodySkillsToMentalSkills) +
    // parseInt(sharedSkillPoints.bodySkillsToRunes) +
    parseInt(previousSharedSkillPoints.mentalSkillsToWeaponSkills) +
    parseInt(previousSharedSkillPoints.mentalSkillsToBodySkills)
    // parseInt(sharedSkillPoints.mentalSkillsToRunes)
  )
}
function getSharedSkillPointsMax() {
  const allSharedSkillPoints = getAllSharedSkillPoints()
  const previousAllSharedSkillPoints = getPreviousAllSharedSkillPoints()
  const sharedSkillPointsMax = {}
  const sharedSkillPoints = store.getters.characterSharedSkillPoints
  const skillPoints = store.getters.characterSkillPoints
  const weaponSkillPoints = store.getters.characterWeaponSkillPoints
  const bodySkillPoints = store.getters.characterBodySkillPoints
  const mentalSkillPoints = store.getters.characterMentalSkillPoints
  Object.keys(sharedSkillPoints)
    .filter(key => key.startsWith('skillPoints'))
    .forEach(sharedSkillName => {
      let realSkillPointsMaxValue = sharedSkillPoints[sharedSkillName] + skillPoints
      realSkillPointsMaxValue = realSkillPointsMaxValue > 0 ? realSkillPointsMaxValue : 0
      sharedSkillPointsMax[sharedSkillName] = realSkillPointsMaxValue
    })
  Object.keys(sharedSkillPoints)
    .filter(sharedSkillName => sharedSkillName.startsWith('weaponSkills'))
    .forEach(sharedSkillName => {
      const availableSharedSkillPoints = previousAllSharedSkillPoints + 1
      const usedSharedSkillPoints = allSharedSkillPoints
      const currentMaxValue = availableSharedSkillPoints - usedSharedSkillPoints
      if (currentMaxValue <= 0) {
        sharedSkillPointsMax[sharedSkillName] = sharedSkillPoints[sharedSkillName] + currentMaxValue
      } else if (currentMaxValue > weaponSkillPoints) {
        sharedSkillPointsMax[sharedSkillName] = sharedSkillPoints[sharedSkillName] + weaponSkillPoints
      } else {
        sharedSkillPointsMax[sharedSkillName] = sharedSkillPoints[sharedSkillName] + currentMaxValue
      }
    })
  Object.keys(sharedSkillPoints)
    .filter(sharedSkillName => sharedSkillName.startsWith('bodySkills'))
    .forEach(sharedSkillName => {
      const availableSharedSkillPoints = previousAllSharedSkillPoints + 1
      const usedSharedSkillPoints = allSharedSkillPoints
      const currentMaxValue = availableSharedSkillPoints - usedSharedSkillPoints
      if (currentMaxValue <= 0) {
        sharedSkillPointsMax[sharedSkillName] = sharedSkillPoints[sharedSkillName] + currentMaxValue
      } else if (currentMaxValue > bodySkillPoints) {
        sharedSkillPointsMax[sharedSkillName] = sharedSkillPoints[sharedSkillName] + bodySkillPoints
      } else {
        sharedSkillPointsMax[sharedSkillName] = sharedSkillPoints[sharedSkillName] + currentMaxValue
      }
    })
  Object.keys(sharedSkillPoints)
    .filter(sharedSkillName => sharedSkillName.startsWith('mentalSkills'))
    .forEach(sharedSkillName => {
      const availableSharedSkillPoints = previousAllSharedSkillPoints + 1
      const usedSharedSkillPoints = allSharedSkillPoints
      const currentMaxValue = availableSharedSkillPoints - usedSharedSkillPoints
      if (currentMaxValue <= 0) {
        sharedSkillPointsMax[sharedSkillName] = sharedSkillPoints[sharedSkillName] + currentMaxValue
      } else if (currentMaxValue > mentalSkillPoints) {
        sharedSkillPointsMax[sharedSkillName] = sharedSkillPoints[sharedSkillName] + mentalSkillPoints
      } else {
        sharedSkillPointsMax[sharedSkillName] = sharedSkillPoints[sharedSkillName] + currentMaxValue
      }
    })
  return sharedSkillPointsMax
}
function getSharedSkillPointsMin() {
  const previousSharedSkillPoints = getPreviousSharedSkillPoints()
  const sharedSkillPointsMin = {}
  const sharedSkillPoints = store.getters.characterSharedSkillPoints
  const weaponSkillPoints = store.getters.characterWeaponSkillPoints
  const bodySkillPoints = store.getters.characterBodySkillPoints
  const mentalSkillPoints = store.getters.characterMentalSkillPoints
  Object.keys(sharedSkillPoints)
    .filter(key => key.startsWith('skillPoints'))
    .forEach(sharedSkillName => {
      if (sharedSkillName.endsWith('WeaponSkills')) {
        if (weaponSkillPoints > 0) {
          sharedSkillPointsMin[sharedSkillName] = previousSharedSkillPoints[sharedSkillName]
        } else {
          sharedSkillPointsMin[sharedSkillName] = sharedSkillPoints[sharedSkillName]
        }
      } else if (sharedSkillName.endsWith('BodySkills')) {
        if (bodySkillPoints > 0) {
          sharedSkillPointsMin[sharedSkillName] = previousSharedSkillPoints[sharedSkillName]
        } else {
          sharedSkillPointsMin[sharedSkillName] = sharedSkillPoints[sharedSkillName]
        }
      } else if (sharedSkillName.endsWith('MentalSkills')) {
        if (mentalSkillPoints > 0) {
          sharedSkillPointsMin[sharedSkillName] = previousSharedSkillPoints[sharedSkillName]
        } else {
          sharedSkillPointsMin[sharedSkillName] = sharedSkillPoints[sharedSkillName]
        }
      }
    })
  Object.keys(sharedSkillPoints)
    .filter(sharedSkillName => sharedSkillName.startsWith('weaponSkills'))
    .forEach(sharedSkillName => {
      sharedSkillPointsMin[sharedSkillName] = previousSharedSkillPoints[sharedSkillName]
    })
  Object.keys(sharedSkillPoints)
    .filter(sharedSkillName => sharedSkillName.startsWith('bodySkills'))
    .forEach(sharedSkillName => {
      sharedSkillPointsMin[sharedSkillName] = previousSharedSkillPoints[sharedSkillName]
    })
  Object.keys(sharedSkillPoints)
    .filter(sharedSkillName => sharedSkillName.startsWith('mentalSkills'))
    .forEach(sharedSkillName => {
      sharedSkillPointsMin[sharedSkillName] = previousSharedSkillPoints[sharedSkillName]
    })
  return sharedSkillPointsMin
}
function calculateValue(name, value) {
  const valueMin = store.getters.characterSharedSkillPointsMin[name]
  const valueMax = store.getters.characterSharedSkillPointsMax[name]
  let newValue = value
  if (value > valueMax) {
    newValue = valueMax
  } else if (value < valueMin) {
    newValue = valueMin
  }
  return newValue
}
function setSharedSkillPoint(name, value) {
  const sharedSkillPoints = store.getters.characterSharedSkillPoints
  sharedSkillPoints[name] = calculateValue(name, value)
  store.dispatch('setCharacterSharedSkillPoints', sharedSkillPoints)
}
export { getSkillPoints, setSharedSkillPoint, getSharedSkillPointsMin, getSharedSkillPointsMax }
