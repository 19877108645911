<template>
  <v-row>
    <v-col cols="12" md="6">
      <v-list>
        <v-list-item-title class="text-h6">{{ 'Verfügbare Talente' }}</v-list-item-title>
        <v-list-item v-for="(talent, talentIndex) in talents" :key="talent.name + talentIndex">
          <v-list-item-content>
            <v-btn-toggle borderless>
              <v-btn class="talent_info" @click="showDialog(talent)">
                <v-icon>mdi-information</v-icon>
              </v-btn>
              <v-btn class="talent" @click="addTalent(talent.name)" :disabled="selectAbleCharacterTalentIsDisabled(talent)">
                {{ $t('components.CharacterGenerator.talents.' + talent.name + '.name') }}
              </v-btn>
            </v-btn-toggle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-col>
    <v-col cols="12" md="6">
      <v-list>
        <v-list-item-title class="text-h6">{{ 'Gewählte Talente' }}</v-list-item-title>
        <v-list-item v-for="(talent, talentIndex) in selectedTalents" :key="talent.name + talentIndex">
          <v-list-item-content>
            <v-btn-toggle class="primary" borderless dark>
              <v-btn class="talent_info" @click="showDialog(talent)">
                <v-icon>mdi-information</v-icon>
              </v-btn>
              <v-btn class="talent" @click="removeTalent(talent.name)" :disabled="activeCharacterTalentIsDisabled(talent.name)">
                {{ $t('components.CharacterGenerator.talents.' + talent.name + '.name') }}
              </v-btn>
            </v-btn-toggle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-col>
    <v-dialog v-model="dialog">
      <v-card class="primary" dark>
        <v-card-title>
          {{ title }}
        </v-card-title>
        <v-card-text>
          <b>{{ $t('components.CharacterGenerator.talents.dependencies') }}</b
          >&nbsp;{{ dependency }}
          <br />
          <br />
          {{ content }}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="dialog = false"> Schließen </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { notAllDependenciesReached } from '@/services/dependency.service'
import { removeTalent, addTalent, isBonusTalent } from '@/services/talent.service'
import { getStateForLevel } from '@/services/states.service'

export default {
  name: 'Talent',
  props: {
    header: {
      type: String,
      default: () => 'Title'
    },
    talents: {
      type: Array,
      default: () => []
    },
    selectedTalents: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    dialog: false,
    title: '',
    content: '',
    dependency: ''
  }),
  methods: {
    showDialog(talent) {
      this.title = this.$t('components.CharacterGenerator.talents.' + talent.name + '.name')
      this.content = this.$t('components.CharacterGenerator.talents.' + talent.name + '.description')
      this.dependency = this.$t('components.CharacterGenerator.talents.' + talent.name + '.dependencies')
      this.dialog = true
    },
    isTalentDisabledByEarlierSelection(givenTalent) {
      const characterState = getStateForLevel(this.$store.getters.characterLevel - 1)
      if (characterState !== undefined) {
        return characterState.talents.some(selectedTalent => givenTalent === selectedTalent)
      }
      return false
    },
    activeCharacterTalentIsDisabled(talentName) {
      return isBonusTalent(talentName) || this.isTalentDisabledByEarlierSelection(talentName)
    },
    selectAbleCharacterTalentIsDisabled(talent) {
      const talentIsActive = this.$store.getters.characterTalents.filter(characterTalent => characterTalent === talent.name).length > 0
      const isNotMultiple = talent.multiple === undefined || !talent.multiple
      const notEnougthTalentPoints = this.$store.getters.characterTalentPoints <= 0
      return notEnougthTalentPoints || (talentIsActive && isNotMultiple) || notAllDependenciesReached(talent.dependencies)
    },
    removeTalent(talentName) {
      removeTalent(talentName)
    },
    addTalent(talentName) {
      addTalent(talentName)
    }
  }
}
</script>
<style>
.talent {
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.talent_info {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.theme--light.v-btn--active:hover::before,
.theme--light.v-btn--active::before {
  opacity: 0 !important;
}
</style>
