<template>
  <v-card outlined>
    <v-app-bar class="primary">
      <v-toolbar-title>
        {{ $t('components.CharacterGenerator.runes.label') + ' ( RP / ' + $store.getters.characterRunePoints + ' )' }}
      </v-toolbar-title>
    </v-app-bar>
    <v-container fluid>
      <v-row>
        <v-col v-for="(runeCol, runColIndex) in runesTable" :key="runColIndex" cols="12" md="6">
          <Rune v-for="(rune, runeIndex) in runeCol" :key="runeIndex" :header="$t('components.CharacterGenerator.runes.' + rune.name + '.name')" :description="$t('components.CharacterGenerator.runes.' + rune.name + '.description')" :rune="rune" />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import Rune from './Rune'

export default {
  name: 'Runes',
  components: { Rune },
  computed: {
    runesTable() {
      const runesTable = []
      runesTable.push(this.$store.getters.characterRunes.slice(0, this.$store.getters.characterRunes.length / 2))
      runesTable.push(this.$store.getters.characterRunes.slice(this.$store.getters.characterRunes.length / 2, this.$store.getters.characterRunes.length))
      return runesTable
    }
  }
}
</script>
