<template>
  <v-navigation-drawer v-model="$store.state.drawer" fixed temporary style="min-width: 350px">
    <v-list>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            <v-img v-if="this.$vuetify.theme.dark" alt="BeSoDD Logo" contain :src="require('../../assets/logo_white.svg')" width="100" />
            <v-img v-else alt="BeSoDD Logo" contain :src="require('../../assets/logo_black.svg')" width="100" />
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider />
      <v-list-item-group>
        <v-list-item v-if="!$store.state.account.loggedIn" @click="registrationDialog = true">
          <v-list-item-icon>
            <v-icon>mdi-account-plus</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Register</v-list-item-title>
        </v-list-item>
        <v-dialog v-if="!$store.state.account.loggedIn" v-model="registrationDialog" persistent width="auto">
          <v-card>
            <v-card-title>Registration</v-card-title>
            <v-card-text>
              <p v-show="$store.state.account.messages.registrationError" class="red--text font-weight-medium">{{ $t('components.CharacterGenerator.account.messages.registrationError') }}</p>
              <v-form>
                <v-text-field v-model="first_name" append-icon="mdi-form-textbox" label="Vorname" clearable required outlined></v-text-field>
                <v-text-field v-model="last_name" append-icon="mdi-form-textbox" label="Nachname" clearable required outlined></v-text-field>
                <v-text-field v-model="email" append-icon="mdi-account" :rules="emailRules" label="E-Mail" clearable required outlined></v-text-field>
                <v-text-field
                  v-model="password"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  :rules="passwordRules"
                  label="Passwort"
                  clearable
                  required
                  outlined
                  @click:append="showPassword = !showPassword"
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="registrationDialog = false"> Abbrechen </v-btn>
              <v-btn text @click="register()"> Register </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-list-item-group>
      <v-divider />
      <v-list-item-group>
        <v-list-item v-if="!$store.state.account.loggedIn" @click="loginDialog = true">
          <v-list-item-icon>
            <v-icon>mdi-login</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Login</v-list-item-title>
        </v-list-item>
        <v-dialog v-if="!$store.state.account.loggedIn" v-model="loginDialog" persistent width="auto">
          <v-card>
            <v-card-title>Login</v-card-title>
            <v-card-text>
              <p v-show="$store.state.account.messages.loginError" class="red--text font-weight-medium">{{ $t('components.CharacterGenerator.account.messages.loginError') }}</p>
              <v-form>
                <v-text-field v-model="email" append-icon="mdi-account" :rules="emailRules" label="E-Mail" clearable required outlined></v-text-field>
                <v-text-field
                  v-model="password"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  :rules="passwordRules"
                  label="Passwort"
                  clearable
                  required
                  outlined
                  @click:append="showPassword = !showPassword"
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="loginDialog = false"> Abbrechen </v-btn>
              <v-btn text @click="login()"> Login </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-list-item v-if="$store.state.account.loggedIn" @click="logoutDialog = true">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item>
        <v-dialog v-if="$store.state.account.loggedIn" v-model="logoutDialog" persistent width="auto">
          <v-card>
            <v-card-title>Logout</v-card-title>
            <v-card-text> Möchtest du sicher ausloggen? </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="logoutDialog = false"> Abbrechen </v-btn>
              <v-btn text @click="logout()"> Ok </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-divider />
        <v-list-item>
          <template>
            <v-list-item-icon>
              <v-icon>mdi-file-eye</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Preview</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-switch v-model="$store.state.configuration.showPrintView" />
            </v-list-item-action>
          </template>
        </v-list-item>
        <v-divider />
        <v-list-group v-if="!$store.state.configuration.showPrintView" prepend-icon="mdi-human">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Charakter</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item v-if="$store.state.account.loggedIn && $store.state.account.characters" @click="accountCharactersDialog = true">
            <v-list-item-icon>
              <v-icon>mdi-open-in-app</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Öffnen</v-list-item-title>
          </v-list-item>
          <v-dialog v-if="$store.state.account.loggedIn && $store.state.account.characters" v-model="accountCharactersDialog" persistent width="auto">
            <v-card>
              <v-card-title>Charaktere</v-card-title>
              <v-card-text>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Name</th>
                        <th class="text-left">{{ $t('components.CharacterGenerator.personality.gender.label') }}</th>
                        <th class="text-left">{{ $t('components.CharacterGenerator.personality.culture.label') }}</th>
                        <th class="text-left">{{ $t('components.CharacterGenerator.personality.clazz.label') }}</th>
                        <th class="text-left">{{ $t('components.CharacterGenerator.personality.level') }}</th>
                        <th class="text-left"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(character, index) in $store.state.account.characters" :key="character.name">
                        <td>{{ character.name }}</td>
                        <td>{{ $t('components.CharacterGenerator.personality.gender.' + character.gender + '.name') }}</td>
                        <td>{{ $t('components.CharacterGenerator.personality.culture.' + character.culture + '.name') }}</td>
                        <td>{{ $t('components.CharacterGenerator.personality.clazz.' + character.clazz + '.name') }}</td>
                        <td>{{ character.level }}</td>
                        <td>
                          <v-btn icon @click="load(character.content)">
                            <v-icon>mdi-open-in-app</v-icon>
                          </v-btn>
                          <v-btn v-if="character.shareAble" icon @click="showCharacterRemoveDialog(character.name, index)">
                            <v-icon>mdi-trash-can</v-icon>
                          </v-btn>
                          <v-btn v-if="character.shareAble" icon @click="shareOrUnshare(character.name, character.shared, index)">
                            <v-icon v-if="character.shared">mdi-share-off</v-icon>
                            <v-icon v-else>mdi-share</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <v-dialog v-model="removeCharacterDialog" persistent width="auto">
                  <v-card>
                    <v-card-title>Charakter löschen</v-card-title>
                    <v-card-text> Möchtest du sicher den Charakter löschen? </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" text @click="closeCharacterRemoveDialog()"> Abbrechen </v-btn>
                      <v-btn text @click="remove()"> Ok </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="accountCharactersDialog = false"> Abbrechen </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-list-item v-if="$store.state.account.loggedIn" :disabled="!$store.state.character.personality.name" @click="save()">
            <v-list-item-icon>
              <v-icon>mdi-content-save</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Speichern</v-list-item-title>
          </v-list-item>
          <v-list-item @click="downloadCharacter()">
            <v-list-item-icon>
              <v-icon>mdi-file-download</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Herunterladen</v-list-item-title>
          </v-list-item>
          <v-list-item @click="$refs.uploadCharacter.click()">
            <v-list-item-icon>
              <v-icon>mdi-file-upload</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Hochladen</v-list-item-title>
            <input ref="uploadCharacter" type="file" accept="application/json" style="display: none" @change="uploadCharacter()" />
          </v-list-item>
          <v-list-item @click="restCharacter()">
            <v-list-item-icon>
              <v-icon>mdi-file-restore</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Zurücksetzen</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-divider />
        <v-list-group v-if="!$store.state.configuration.showPrintView" prepend-icon="mdi-book-open-variant">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Dokumente</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item link @click="openUrl('/documentation/BeSoDD-Regelwerk.pdf')">
            <v-list-item-title>Regelwerk</v-list-item-title>
            <v-list-item-icon>
              <v-icon>mdi-open-in-new</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-list-item link @click="openUrl('/documentation/BeSoDD-Kodikos-Magikis.pdf')">
            <v-list-item-title>Kodikos-Magikis</v-list-item-title>
            <v-list-item-icon>
              <v-icon>mdi-open-in-new</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-list-item link @click="openUrl('/documentation/BeSoDD-Tabellen.pdf')">
            <v-list-item-title>Tabellen</v-list-item-title>
            <v-list-item-icon>
              <v-icon>mdi-open-in-new</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-list-item link @click="openUrl('/documentation/BeSoDD-Steigerungstabellen.pdf')">
            <v-list-item-title>Steigerungstabellen</v-list-item-title>
            <v-list-item-icon>
              <v-icon>mdi-open-in-new</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-list-item link @click="openUrl('/documentation/BeSoDD-Charakterbogen.pdf')">
            <v-list-item-title>Charakterbogen</v-list-item-title>
            <v-list-item-icon>
              <v-icon>mdi-open-in-new</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-list-item link @click="openUrl('/documentation/BeSoDD-Kompakt.pdf')">
            <v-list-item-title>Regelwerk-Kompakt</v-list-item-title>
            <v-list-item-icon>
              <v-icon>mdi-open-in-new</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>
        <v-divider v-if="!$store.state.configuration.showPrintView" />
        <v-list-group v-if="!$store.state.configuration.showPrintView" prepend-icon="mdi-script-text">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Abenteuer</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-group :value="true" no-action sub-group>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>Einführung</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item link @click="openUrl('/adventures/01_introduction/Die-Verfallene-Ruine.pdf')">
              <v-list-item-title>1. Die verfallene Ruine</v-list-item-title>
              <v-list-item-icon>
                <v-icon>mdi-open-in-new</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>
          <v-divider />
          <v-list-group :value="true" no-action sub-group>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>Die Wildland Trilogie</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item link @click="openUrl('/adventures/02_wildlands/Die-Leseratte.pdf')">
              <v-list-item-title>1. Die Leseratte</v-list-item-title>
              <v-list-item-icon>
                <v-icon>mdi-open-in-new</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item link @click="openUrl('/adventures/02_wildlands/Der-Purpurturm.pdf')">
              <v-list-item-title>2. Der Purpurturm</v-list-item-title>
              <v-list-item-icon>
                <v-icon>mdi-open-in-new</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item link @click="openUrl('/adventures/02_wildlands/Der-Orkenhort.pdf')">
              <v-list-item-title>3. Der Orkenhort</v-list-item-title>
              <v-list-item-icon>
                <v-icon>mdi-open-in-new</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>
          <v-divider />
          <v-list-group :value="true" no-action sub-group>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>Das Wildland Addon</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item link @click="openUrl('/adventures/03_legacy_of_the_gnomes/Das-Erbe-der-Gnome_Ein-Abenteuer-für-Rätselfreunde.pdf')">
              <v-list-item-title>1. Das Erbe der Gnome</v-list-item-title>
              <v-list-item-icon>
                <v-icon>mdi-open-in-new</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>
        </v-list-group>
        <v-divider />
        <v-list-group v-if="!$store.state.configuration.showPrintView" prepend-icon="mdi-map">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Karten</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item link @click="openMap">
            <v-list-item-title>Fereldin</v-list-item-title>
            <v-list-item-icon>
              <v-icon>mdi-arrow-expand</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>
        <v-divider />
        <v-list-group v-if="!$vuetify.breakpoint.xs && !$store.state.configuration.showPrintView" prepend-icon="mdi-tools">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Tools</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item link @click="openUrlNewWindow('https://chatgpt.com/g/g-WV1L9R53H-black-eyed-slayers-of-dungeon-dragons-assistant')">
            <v-list-item-title>BeSoDD-GPT</v-list-item-title>
            <v-list-item-icon>
              <v-icon>mdi-open-in-new</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-list-item link @click="countryMapEditorDialog = true">
            <v-list-item-title>Landkartengenerator</v-list-item-title>
            <v-list-item-icon>
              <v-icon>mdi-arrow-expand</v-icon>
            </v-list-item-icon>
            <v-dialog v-model="countryMapEditorDialog" transition="dialog-bottom-transition" fullscreen hide-overlay>
              <v-card>
                <v-toolbar color="primary">
                  <v-toolbar-title>Landkartengenerator</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-toolbar-items>
                    <v-btn icon @click="countryMapEditorDialog = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar-items>
                </v-toolbar>
                <iframe src="https://azgaar.github.io/Fantasy-Map-Generator/" height="100%" width="100%" />
              </v-card>
            </v-dialog>
          </v-list-item>
          <v-list-item link @click="townMapEditorDialog = true">
            <v-list-item-title>Stadtkartengenerator</v-list-item-title>
            <v-list-item-icon>
              <v-icon>mdi-arrow-expand</v-icon>
            </v-list-item-icon>
            <v-dialog v-model="townMapEditorDialog" transition="dialog-bottom-transition" fullscreen hide-overlay>
              <v-card>
                <v-toolbar color="primary">
                  <v-toolbar-title>Stadtkartengenerator</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-toolbar-items>
                    <v-btn icon @click="townMapEditorDialog = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar-items>
                </v-toolbar>
                <iframe src="https://watabou.github.io/city-generator/" height="100%" width="100%" />
              </v-card>
            </v-dialog>
          </v-list-item>
        </v-list-group>
        <v-divider v-if="!$vuetify.breakpoint.xs && !$store.state.configuration.showPrintView" />
        <v-list-group v-if="!$store.state.configuration.showPrintView" prepend-icon="mdi-cog">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Einstellungen</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item>
            <template>
              <v-list-item-content>
                <v-list-item-title>Darkmode</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-switch v-model="$vuetify.theme.dark" />
              </v-list-item-action>
            </template>
          </v-list-item>
          <v-list-item>
            <template>
              <v-list-item-content>
                <v-list-item-title>{{ $t('components.CharacterGenerator.configuration.showDisabledTalents') }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-switch v-model="$store.state.configuration.showDisabledTalents" />
              </v-list-item-action>
            </template>
          </v-list-item>
          <v-list-item>
            <template>
              <v-list-item-content>
                <v-list-item-title>{{ $t('components.CharacterGenerator.configuration.showDisabledSpells') }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-switch v-model="$store.state.configuration.showDisabledSpells" />
              </v-list-item-action>
            </template>
          </v-list-item>
          <v-list-item>
            <template>
              <v-list-item-content>
                <v-list-item-title>{{ $t('components.CharacterGenerator.configuration.showConfiguration') }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-switch v-model="$store.state.configuration.showConfiguration" />
              </v-list-item-action>
            </template>
          </v-list-item>
          <v-list-item>
            <template>
              <v-list-item-content>
                <v-list-item-title>{{ $t('components.CharacterGenerator.configuration.showExperienceInputField') }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-switch v-model="$store.state.configuration.showExperienceInputField" />
              </v-list-item-action>
            </template>
          </v-list-item>
          <v-list-item>
            <template>
              <v-list-item-content>
                <v-list-item-title>{{ $t('components.CharacterGenerator.configuration.showEasterEggs') }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-switch v-model="$store.state.configuration.showEasterEggs" />
              </v-list-item-action>
            </template>
          </v-list-item>
        </v-list-group>
        <v-divider v-if="!$store.state.configuration.showPrintView && false" />
        <v-list-group v-if="!$store.state.configuration.showPrintView && false" prepend-icon="auto_fix_high">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Character Generator</v-list-item-title>
            </v-list-item-content>
          </template>
          <Generator />
          <v-list-item>
            <template>
              <v-list-item-content>
                <v-list-item-title>{{ $t('components.CharacterGenerator.configuration.keepLevel') }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-switch v-model="$store.state.configuration.keepLevel" />
              </v-list-item-action>
            </template>
          </v-list-item>
          <v-list-item>
            <template>
              <v-list-item-content>
                <v-list-item-title>{{ $t('components.CharacterGenerator.configuration.keepName') }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-switch v-model="$store.state.configuration.keepName" />
              </v-list-item-action>
            </template>
          </v-list-item>
          <v-list-item>
            <template>
              <v-list-item-content>
                <v-list-item-title>{{ $t('components.CharacterGenerator.configuration.keepGender') }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-switch v-model="$store.state.configuration.keepGender" />
              </v-list-item-action>
            </template>
          </v-list-item>
          <v-list-item>
            <template>
              <v-list-item-content>
                <v-list-item-title>{{ $t('components.CharacterGenerator.configuration.keepCulture') }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-switch v-model="$store.state.configuration.keepCulture" />
              </v-list-item-action>
            </template>
          </v-list-item>
          <v-list-item>
            <template>
              <v-list-item-content>
                <v-list-item-title>{{ $t('components.CharacterGenerator.configuration.keepClazz') }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-switch v-model="$store.state.configuration.keepClazz" />
              </v-list-item-action>
            </template>
          </v-list-item>
        </v-list-group>
      </v-list-item-group>
      <v-divider />
      <v-list-group v-if="!$vuetify.breakpoint.xs && !$store.state.configuration.showPrintView" prepend-icon="mdi-memory">
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>In Memory Data</v-list-item-title>
          </v-list-item-content>
        </template>
        <v-list-item-group>
          <v-list-item v-if="!$store.state.configuration.showPrintView" @click="downloadData()">
            <v-list-item-title>Save Data</v-list-item-title>
            <v-list-item-icon>
              <v-icon>mdi-file-download</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-list-item @click="$refs.uploadData.click()">
            <v-list-item-title>Load Data</v-list-item-title>
            <input ref="uploadData" type="file" accept="application/json" style="display: none" @change="uploadData()" />
            <v-list-item-icon>
              <v-icon>mdi-file-upload</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-item-group>
      </v-list-group>
      <v-divider v-if="!$vuetify.breakpoint.xs" />
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import download from 'downloadjs'
import Generator from '@/components/header/Generator'
import { login, logout, registration, saveCharacter, removeCharacter, shareCharacter, unShareCharacter } from '../../services/account.service'

export default {
  name: 'Navigation',
  components: {
    Generator
  },
  data() {
    return {
      registrationDialog: false,
      loginDialog: false,
      showPassword: false,
      logoutDialog: false,
      first_name: '',
      last_name: '',
      email: '',
      emailRules: [v => !!v || 'E-Mail ist erforderlich', v => /.+@.+\..+/.test(v) || 'E-Mail muss korrekt sein'],
      password: '',
      passwordRules: [v => !!v || 'Passwort ist erforderlich'],
      saveSnackbar: false,
      saveSnackbarTimeout: 2000,
      accountCharactersDialog: false,
      removeCharacterDialog: false,
      characterForRemoval: '',
      characterForRemovalIndex: 0,
      feraldinBiomesDialog: false,
      feraldinCulturesDialog: false,
      countryMapEditorDialog: false,
      townMapEditorDialog: false
    }
  },
  methods: {
    openMap() {
      this.$store.state.map = true
      this.$store.state.drawer = false
    },
    openUrl(url) {
      const localizedUrl = '/' + this.$i18n.locale + url
      window.open(localizedUrl, '_blank')
    },
    openUrlNewWindow(url) {
      window.open(url, '_blank')
    },
    restCharacter() {
      window.location.reload()
    },
    downloadCharacter() {
      const blob = new Blob(['\ufeff', this.createCharacterJson()])
      download(blob, this.$store.getters.characterName !== '' ? this.$store.getters.characterName + '.json' : 'character.json', 'application/json')
    },
    uploadCharacter() {
      const file = this.$refs.uploadCharacter.files[0]
      const reader = new FileReader()
      reader.onload = () => this.load(JSON.parse(reader.result))
      reader.readAsText(file)
    },
    createCharacterJson() {
      const characterJsonObject = {
        character: this.$store.getters.character,
        states: this.$store.getters.characterStates,
        configuration: this.$store.getters.configuration
      }
      return JSON.stringify(characterJsonObject, null, 2)
    },
    isOldCharacter(characterJsonObject) {
      return characterJsonObject.character.attributePoints === undefined
    },
    convertObjectAttributesToNumber(oldObject) {
      const newObject = {}
      Object.keys(oldObject).forEach(key => {
        newObject[key] = parseInt(oldObject[key])
      })
      return newObject
    },
    downloadData() {
      const blob = new Blob(['\ufeff', JSON.stringify(this.$store.getters.dataSet, null, 2)])
      download(blob, 'Data.json', 'application/json')
    },
    uploadData() {
      const file = this.$refs.uploadData.files[0]
      const reader = new FileReader()
      reader.onload = () => this.$store.dispatch('setDataSet', JSON.parse(reader.result))
      reader.readAsText(file)
    },
    async login() {
      this.$store.state.account.messages.loginError = false
      const loginSuccess = await login(this.email, this.password)
      if (loginSuccess) {
        this.email = ''
        this.password = ''
        await this.$store.dispatch('loadAccountCharacters')
        this.loginDialog = false
        this.$store.state.account.loggedIn = true
      } else {
        this.$store.state.account.messages.loginError = true
      }
    },
    async register() {
      this.$store.state.account.messages.registrationError = false
      const registrationSuccess = await registration(this.first_name, this.last_name, this.email, this.password)
      if (registrationSuccess) {
        this.first_name = ''
        this.email = ''
        this.last_name = ''
        this.password = ''
        this.registrationDialog = false
        this.$store.state.account.messages.registrationSuccess = true
      } else {
        this.$store.state.account.messages.registrationError = true
      }
    },
    async logout() {
      const logoutSuccess = await logout()
      if (logoutSuccess) {
        await this.$store.dispatch('clearAccountCharacters')
        this.logoutDialog = false
        this.$store.state.account.loggedIn = false
      }
    },
    async load(content) {
      this.$store.dispatch('setCharacterStates', content.states)
      this.$store.dispatch('setCharacter', content.character)
      this.$store.dispatch('setConfiguration', content.configuration)
      this.accountCharactersDialog = false
    },
    async save() {
      const saveSuccess = await saveCharacter()
      if (saveSuccess) {
        const character = this.$store.getters.character
        const states = this.$store.getters.characterStates
        const configuration = this.$store.getters.configuration
        const existingCharacter = this.$store.state.account.characters.find(accountCharacter => accountCharacter.name === character.personality.name)
        if (existingCharacter) {
          existingCharacter.gender = character.personality.gender
          existingCharacter.culture = character.personality.culture
          existingCharacter.clazz = character.personality.clazz
          existingCharacter.level = character.personality.level
          existingCharacter.experience = character.personality.experience
          existingCharacter.content = {
            character,
            states,
            configuration
          }
        } else {
          this.$store.state.account.characters.push({
            name: character.personality.name,
            gender: character.personality.gender,
            culture: character.personality.culture,
            clazz: character.personality.clazz,
            level: character.personality.level,
            experience: character.personality.experience,
            content: {
              character,
              states,
              configuration
            }
          })
        }
        this.$store.state.account.messages.saveSuccess = true
      } else {
        this.$store.state.account.messages.saveError = true
      }
    },
    showCharacterRemoveDialog(name, index) {
      this.characterForRemoval = name
      this.characterForRemovalIndex = index
      this.removeCharacterDialog = true
    },
    shareOrUnshare(name, shared, index) {
      if (shared) {
        const unshareSuccess = unShareCharacter(name)
        if (unshareSuccess) {
          this.$store.state.account.characters[index].shared = false
        }
      } else {
        const shareSuccess = shareCharacter(name)
        if (shareSuccess) {
          this.$store.state.account.characters[index].shared = true
        }
      }
    },
    closeCharacterRemoveDialog() {
      this.characterForRemoval = ''
      this.removeCharacterDialog = false
    },
    async remove() {
      const removeSuccess = await removeCharacter(this.characterForRemoval)
      if (removeSuccess) {
        this.$store.state.account.characters.splice(this.characterForRemovalIndex, 1)
      }
      this.closeCharacterRemoveDialog()
    }
  }
}
</script>

<style scoped>
iframe {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  inset: 64px 0;
}
</style>
