<template>
  <v-btn-toggle>
    <v-btn>
      <v-icon large> mdi-dice-d{{ dice }}-outline </v-icon>
    </v-btn>
    <v-btn @click="newRoll">
      {{ currentNumber }}
    </v-btn>
  </v-btn-toggle>
</template>

<script>
export default {
  name: 'Dice',
  props: {
    dice: {
      type: Number,
      default: () => 20
    }
  },
  data() {
    return {
      numbers: Array.from(Array(this.dice), (_, i) => i + 1),
      delay: 20,
      delayIncrement: 20,
      heighten: true
    }
  },
  computed: {
    currentNumber() {
      if (this.numbers.length > 1) {
        return Math.floor(Math.floor(Math.random() * this.dice + 1))
      }
      return this.numbers[0]
    }
  },
  methods: {
    newRoll() {
      const timeout = this.numbers.length > 1 ? 0 : 1000
      this.delay = this.delayIncrement
      this.numbers = Array.from(Array(this.dice), (_, i) => i + 1)
      setTimeout(() => {
        this.removeDigits()
      }, timeout)
    },
    setDelay() {
      if (this.numbers.length <= 5 && this.heighten) {
        return this.numbers.length === 2 ? this.delay * 3 : this.delay * 2
      } else {
        return this.delay
      }
    },
    removeDigits() {
      const randomRemoved = Math.floor(Math.random() * this.numbers.length)
      this.numbers.splice(randomRemoved, 1)

      if (this.numbers.length > 1) {
        setTimeout(() => {
          this.delay += this.delayIncrement
          this.removeDigits()
        }, this.setDelay())
      }
    }
  }
}
</script>
<style></style>
