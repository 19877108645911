<template>
  <v-tabs hide-slider fixed-tabs flat optional show-arrows>
    <v-tab disabled>{{ 'ST ' + $store.getters.characterLevel }}</v-tab>
    <v-tab disabled>{{ 'AP ' + $store.getters.characterAttributePoints }}</v-tab>
    <v-tab disabled>{{ 'FP ' + $store.getters.characterSkillPoints }}</v-tab>
    <v-tab disabled>{{ 'WF ' + $store.getters.characterWeaponSkillPoints }}</v-tab>
    <v-tab disabled>{{ 'KF ' + $store.getters.characterBodySkillPoints }}</v-tab>
    <v-tab disabled>{{ 'GF ' + $store.getters.characterMentalSkillPoints }}</v-tab>
    <v-tab disabled>{{ 'TP ' + $store.getters.characterTalentPoints }}</v-tab>
    <v-tab v-if="$store.getters.caster && $store.getters.characterClazz === 'runeFighter'" disabled>{{ 'RP ' + $store.getters.characterRunePoints }}</v-tab>
    <v-tab v-if="$store.getters.caster && $store.getters.characterClazz !== 'runeFighter'" disabled>{{ 'ZP ' + $store.getters.characterSpellPoints }}</v-tab>
  </v-tabs>
</template>

<script>
export default {
  name: 'ToolBar'
}
</script>

<style scoped>
.v-tab {
  padding: 0px;
  min-width: 50px;
}

.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active),
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-icon,
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-btn,
.theme--light.v-tabs > .v-tabs-bar .v-tab--disabled {
  color: rgba(255, 255, 255, 0.6);
}
</style>
