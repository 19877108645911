var render = function render(){var _vm=this,_c=_vm._self._c;return _c('l-map',{staticClass:"besodd-map",attrs:{"options":_vm.mapOptions,"center":_vm.center,"zoom":_vm.zoom,"min-zoom":_vm.minZoom,"max-zoom":_vm.maxZoom,"max-bounds":_vm.maxBounds},on:{"update:center":_vm.updateCenter,"update:zoom":_vm.updateZoom}},[_c('l-control-layers',{attrs:{"position":_vm.layersPosition,"collapsed":false,"sort-layers":true}}),_c('l-control',[_c('v-btn',{attrs:{"color":"black"},on:{"click":function($event){_vm.$store.state.map = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('l-control',[_c('v-btn',{attrs:{"color":"black"},on:{"click":function($event){_vm.showMarker = !_vm.showMarker}}},[_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.showMarker),expression:"showMarker"}]},[_vm._v("mdi-map-marker")]),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showMarker),expression:"!showMarker"}]},[_vm._v("mdi-map-marker-off")])],1)],1),_vm._l((_vm.tileProviders),function(tileProvider){return _c('l-tile-layer',{key:tileProvider.name,attrs:{"name":tileProvider.name,"visible":tileProvider.visible,"url":tileProvider.url,"attribution":tileProvider.attribution,"no-wrap":true,"layer-type":"base"}})}),(_vm.showMarker)?_c('l-layer-group',{attrs:{"name":"advanture1"}},[_c('l-marker',{attrs:{"lat-lng":[-10.75, -16.4]}},[_c('l-icon',{attrs:{"icon-url":require('../../assets/icons/village.svg')}}),_c('l-tooltip',{attrs:{"content":"Von Datinum folgten wir der Einladung nach Slehannon und traten die Reise mit den Kutschen an."}})],1),_c('l-polyline',{attrs:{"lat-lngs":[
        [-10.75, -16.4],
        [3.75, -8.4]
      ],"color":"#476261"}}),_c('l-marker',{attrs:{"lat-lng":[3.75, -8.4]}},[_c('l-icon',{attrs:{"icon-url":require('../../assets/icons/carriage.svg')}})],1),_c('l-polyline',{attrs:{"lat-lngs":[
        [3.75, -8.4],
        [15.75, -18.4]
      ],"color":"#476261"}}),_c('l-marker',{attrs:{"lat-lng":[15.75, -18.4]}},[_c('l-icon',{attrs:{"icon-url":require('../../assets/icons/carriage.svg')}})],1),_c('l-polyline',{attrs:{"lat-lngs":[
        [15.75, -18.4],
        [28.9, -15.56]
      ],"color":"#476261"}}),_c('l-marker',{attrs:{"lat-lng":[28.9, -15.56]}},[_c('l-icon',{attrs:{"icon-url":require('../../assets/icons/village.svg')}}),_c('l-tooltip',{attrs:{"content":"Von Slehannon aus sind wir in die See gestochen zu unserem Abenteuer in das Yetiland."}})],1),_c('l-polyline',{attrs:{"lat-lngs":[
        [28.9, -15.56],
        [32.8, -10.8]
      ],"color":"#476261"}}),_c('l-marker',{attrs:{"lat-lng":[32.8, -10.8]}},[_c('l-icon',{attrs:{"icon-url":require('../../assets/icons/boat.svg')}})],1),_c('l-polyline',{attrs:{"lat-lngs":[
        [32.8, -10.8],
        [32.8, -2.8]
      ],"color":"#476261"}}),_c('l-polyline',{attrs:{"lat-lngs":[
        [32.8, -2.8],
        [30.3, -2.19]
      ],"color":"#476261"}}),_c('l-marker',{attrs:{"lat-lng":[30.3, -2.19]}},[_c('l-icon',{attrs:{"icon-url":require('../../assets/icons/repair.svg')}}),_c('l-tooltip',{attrs:{"content":"Wir sind mit unserem Boot in einen Sturm geraten und mussten es reparieren lassen in Olport. <br> Dadurch verloren wir 6 Tage ..."}})],1),_c('l-polyline',{attrs:{"lat-lngs":[
        [30.3, -2.19],
        [29.8, 16.5]
      ],"color":"#476261"}}),_c('l-marker',{attrs:{"lat-lng":[29.8, 16.5]}},[_c('l-icon',{attrs:{"icon-url":require('../../assets/icons/boat.svg')}})],1),_c('l-polyline',{attrs:{"lat-lngs":[
        [29.8, 16.5],
        [39.8, 27.5]
      ],"color":"#476261"}}),_c('l-marker',{attrs:{"lat-lng":[39.8, 27.5]}},[_c('l-icon',{attrs:{"icon-url":require('../../assets/icons/boat.svg')}})],1),_c('l-polyline',{attrs:{"lat-lngs":[
        [39.8, 27.5],
        [39.8, 40.5]
      ],"color":"#476261"}}),_c('l-polyline',{attrs:{"lat-lngs":[
        [39.8, 40.5],
        [37.8, 50.5]
      ],"color":"#476261"}}),_c('l-marker',{attrs:{"lat-lng":[37.8, 50.5]}},[_c('l-icon',{attrs:{"icon-url":require('../../assets/icons/boat.svg')}})],1),_c('l-polyline',{attrs:{"lat-lngs":[
        [37.8, 50.5],
        [33.65, 58.7]
      ],"color":"#476261"}}),_c('l-polyline',{attrs:{"lat-lngs":[
        [33.65, 58.7],
        [35.65, 62.7]
      ],"color":"#476261"}}),_c('l-marker',{attrs:{"lat-lng":[35.65, 62.7]}},[_c('l-icon',{attrs:{"icon-url":require('../../assets/icons/campfire.svg')}}),_c('l-tooltip',{attrs:{"content":"Ankunft auf der Yetiland Insel. Hier zogen wir mit Schlittenhunden und Eisseglern in Richtung Festland los. <br> Wir sollen hier ... <br> ... die Zähne eines riesenhaften zweizähnigen Kopfschwänzler holen. <br> ... die Geheimnisse des des Turms ergründen der am Himmelsgewölbe aufgehängt ist."}})],1),_c('l-polyline',{attrs:{"lat-lngs":[
        [35.65, 62.7],
        [36.8, 63.5]
      ],"color":"#476261"}}),_c('l-marker',{attrs:{"lat-lng":[36.8, 63.5]}},[_c('l-icon',{attrs:{"icon-url":require('../../assets/icons/elephant.svg')}}),_c('l-tooltip',{attrs:{"content":"Hier haben wir den riesenhaften zweizähnigen Kopfschwänzler erlegt."}})],1)],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }