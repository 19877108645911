<template>
  <div>
    <h2>{{ $t('components.CharacterGenerator.inventory.armor') }}</h2>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              {{ $t('components.CharacterGenerator.items.armor.name') }}
            </th>
            <th class="text-left">
              {{ $t('components.CharacterGenerator.items.armor.armor') }}
            </th>
            <th class="text-left">
              {{ $t('components.CharacterGenerator.items.armor.clumsiness') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in armorTableData" :key="row.name">
            <td>
              <v-autocomplete :items="currentAvailableArmor" :value="$store.getters.characterArmor[row.id]" outlined dense prepend-inner-icon="mdi-tshirt-v" class="armor" @change="valueChanged($event, row.id)" :disabled="!isActive">
                <template v-if="!$vuetify.breakpoint.xs" v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title>{{ data.item.text }}</v-list-item-title>
                      <v-list-item-subtitle>{{ data.item.specs }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </td>
            <td>{{ row.armor }}</td>
            <td>{{ row.clumsiness }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { allDependenciesReached } from '@/services/dependency.service'
import { getArmorForIndex, setArmor, getArmorForName } from '@/services/items.service'
import { createSelectByName } from '@/utils/ComponentUtils'

export default {
  name: 'Armor',
  data() {
    return {
      historyArmor: {
        firstArmor: null,
        secondArmor: null,
        thirdArmor: null
      }
    }
  },
  computed: {
    currentAvailableArmor() {
      let options = createSelectByName(
        this.$store.getters.armor.filter(item => allDependenciesReached(item.dependencies)),
        'components.CharacterGenerator.items.armor.'
      )
      options = options.map(option => {
        const armor = getArmorForName(option.value)
        if (armor !== undefined) {
          if (!this.$vuetify.breakpoint.xs) {
            option.specs = 'Metalisch ' + (armor.metal ? 'Ja' : 'Nein') + ' | SM ' + (armor.armorHead + armor.armorTorso + armor.armorLimbs) + ' | BE ' + (armor.clumsinessHead + armor.clumsinessTorso + armor.clumsinessLimbs)
          }
        }
        return option
      })
      return options
    },
    isActive() {
      return this.$store.getters.characterGender !== '' && this.$store.getters.characterCulture !== '' && this.$store.getters.characterClazz !== ''
    },
    armorTableData() {
      const tableData = []
      if (this.$store.getters.characterArmor[0] !== '') {
        tableData.push(this.tableRow(0))
      } else {
        tableData.push(this.emptyTableRow(0))
      }
      if (this.$store.getters.characterArmor[1] !== '') {
        tableData.push(this.tableRow(1))
      } else {
        tableData.push(this.emptyTableRow(1))
      }
      if (this.$store.getters.characterArmor[2] !== '') {
        tableData.push(this.tableRow(2))
      } else {
        tableData.push(this.emptyTableRow(2))
      }
      return tableData
    }
  },
  methods: {
    emptyTableRow(index) {
      return {
        id: index,
        armor: '',
        clumsiness: ''
      }
    },
    tableRow(index) {
      const armor = getArmorForIndex(index)
      return {
        id: index,
        armor: armor.armorHead + armor.armorTorso + armor.armorLimbs,
        clumsiness: armor.clumsinessHead + armor.clumsinessTorso + armor.clumsinessLimbs
      }
    },
    getArmorForIndex(index) {
      return getArmorForIndex(index)
    },
    valueChanged(name, index) {
      setArmor(name, index)
    }
  }
}
</script>
<style>
.armor {
  height: 60px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  width: 200px;
}
</style>
