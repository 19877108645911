import { hasTalent } from './talent.service'
import { getCurrentShield } from './items.service'

function getActualShieldAttackReduction() {
  const shield = getCurrentShield()
  if (shield.filter(entry => entry !== '').length > 0) {
    return shield.map(entry => entry.attackReduction).reduce((accumulator, currentValue) => accumulator + currentValue) - ((hasShieldFocus() ? 1 : 0) + (hasShieldSpecialization() ? 1 : 0))
  }
  return 0
}
function getActualShieldDefenseBonus(shield) {
  if (shield.filter(entry => entry !== '').length > 0) {
    return shield.map(entry => entry.defenseBonus).reduce((accumulator, currentValue) => accumulator + currentValue)
  }
  return 0
}
function getActualDodgeShieldDefenseBonus() {
  const shield = getCurrentShield()
  if (shield.filter(entry => entry !== '').length > 0) {
    return getActualShieldDefenseBonus(shield) - ((hasShieldFocus() ? 1 : 0) + (hasShieldSpecialization() ? 1 : 0))
  }
  return 0
}
function hasShieldFocus() {
  return hasTalent('shieldFocus')
}
function hasShieldSpecialization() {
  return hasTalent('shieldSpecialization')
}
export { getActualShieldAttackReduction, getActualDodgeShieldDefenseBonus }
