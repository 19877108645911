import store from '../store'
import { getCurrentTalents } from './talent.service'
import { getStateForLevel } from './states.service'
import { getAllBonusPointsByName, getBonusObjectByObjectNameAndAttributeNames } from './bonus.service'
import { getAllMalusPointsByName, getMalusObjectByObjectNameAndAttributeNames } from './malus.service'
function getWeaponSkillPoints() {
  return getStartWeaponSkillPoints() + getSharedWeaponSkillPoints() + getAdditionalWeaponSkillPoints() - getUsedWeaponSkillPoints()
}
function getStartWeaponSkillPoints() {
  return parseInt(store.getters.configuration.weaponSkillsPoints)
}
function getSharedWeaponSkillPoints() {
  const sharedSkillPoints = store.getters.characterSharedSkillPoints
  return (
    parseInt(sharedSkillPoints.bodySkillsToWeaponSkills) +
    parseInt(sharedSkillPoints.mentalSkillsToWeaponSkills) +
    parseInt(sharedSkillPoints.skillPointsToWeaponSkills) -
    (parseInt(sharedSkillPoints.weaponSkillsToBodySkills) + parseInt(sharedSkillPoints.weaponSkillsToMentalSkills) + parseInt(sharedSkillPoints.weaponSkillsToRunes))
  )
}
function getAdditionalWeaponSkillPoints() {
  const bonusWeaponSkillPoints = getAllBonusPointsByName('weaponSkillsPoints')
  const malusWeaponSkillPoints = getAllMalusPointsByName('weaponSkillsPoints')
  return parseInt(bonusWeaponSkillPoints) - parseInt(malusWeaponSkillPoints)
}
function getUsedWeaponSkillPoints() {
  const weaponSkills = store.getters.characterWeaponSkills
  return (
    parseInt(weaponSkills.dodge) +
    parseInt(weaponSkills.axesAndHatchets) +
    parseInt(weaponSkills.flail) +
    parseInt(weaponSkills.hammersAndClubs) +
    parseInt(weaponSkills.curvedSword) +
    parseInt(weaponSkills.dagger) +
    parseInt(weaponSkills.swords) +
    parseInt(weaponSkills.spears) +
    parseInt(weaponSkills.stabWeapons) +
    parseInt(weaponSkills.unarmed) +
    parseInt(weaponSkills.harantzu) +
    parseInt(weaponSkills.crossbows) +
    parseInt(weaponSkills.bows) +
    parseInt(weaponSkills.throwingWeapons)
  )
}
function getCurrentWeaponSkills() {
  const weaponsSkillPoints = getWeaponSkills()
  const weaponsSkillPointsBonus = getWeaponSkillsBonus()
  return {
    dodge: weaponsSkillPoints.dodge + weaponsSkillPointsBonus.dodge,
    axesAndHatchets: weaponsSkillPoints.axesAndHatchets + weaponsSkillPointsBonus.axesAndHatchets,
    flail: weaponsSkillPoints.flail + weaponsSkillPointsBonus.flail,
    hammersAndClubs: weaponsSkillPoints.hammersAndClubs + weaponsSkillPointsBonus.hammersAndClubs,
    curvedSword: weaponsSkillPoints.curvedSword + weaponsSkillPointsBonus.curvedSword,
    dagger: weaponsSkillPoints.dagger + weaponsSkillPointsBonus.dagger,
    swords: weaponsSkillPoints.swords + weaponsSkillPointsBonus.swords,
    spears: weaponsSkillPoints.spears + weaponsSkillPointsBonus.spears,
    stabWeapons: weaponsSkillPoints.stabWeapons + weaponsSkillPointsBonus.stabWeapons,
    unarmed: weaponsSkillPoints.unarmed + weaponsSkillPointsBonus.unarmed,
    harantzu: weaponsSkillPoints.harantzu + weaponsSkillPointsBonus.harantzu,
    crossbows: weaponsSkillPoints.crossbows + weaponsSkillPointsBonus.crossbows,
    bows: weaponsSkillPoints.bows + weaponsSkillPointsBonus.bows,
    throwingWeapons: weaponsSkillPoints.throwingWeapons + weaponsSkillPointsBonus.throwingWeapons
  }
}
function getWeaponSkills() {
  return {
    dodge: parseInt(store.getters.characterWeaponSkills.dodge),
    axesAndHatchets: parseInt(store.getters.characterWeaponSkills.axesAndHatchets),
    flail: parseInt(store.getters.characterWeaponSkills.flail),
    hammersAndClubs: parseInt(store.getters.characterWeaponSkills.hammersAndClubs),
    curvedSword: parseInt(store.getters.characterWeaponSkills.curvedSword),
    dagger: parseInt(store.getters.characterWeaponSkills.dagger),
    swords: parseInt(store.getters.characterWeaponSkills.swords),
    spears: parseInt(store.getters.characterWeaponSkills.spears),
    stabWeapons: parseInt(store.getters.characterWeaponSkills.stabWeapons),
    unarmed: parseInt(store.getters.characterWeaponSkills.unarmed),
    harantzu: parseInt(store.getters.characterWeaponSkills.harantzu),
    crossbows: parseInt(store.getters.characterWeaponSkills.crossbows),
    bows: parseInt(store.getters.characterWeaponSkills.bows),
    throwingWeapons: parseInt(store.getters.characterWeaponSkills.throwingWeapons)
  }
}
function getWeaponSkillsBonus() {
  const weaponSkills = store.getters.characterWeaponSkills
  const weaponSkillPointsBonus = getBonusObjectByObjectNameAndAttributeNames('weaponSkills', Object.keys(weaponSkills))
  const weaponSkillPointsMalus = getMalusObjectByObjectNameAndAttributeNames('weaponSkills', Object.keys(weaponSkills))
  const calculatedWeaponSkillPointsBonus = {}
  Object.keys(weaponSkills).forEach(weaponSkillName => {
    calculatedWeaponSkillPointsBonus[weaponSkillName] = weaponSkillPointsBonus[weaponSkillName] - weaponSkillPointsMalus[weaponSkillName]
  })
  return calculatedWeaponSkillPointsBonus
}
function getWeaponSkillPointsMax() {
  const configuration = store.getters.configuration
  const currentTalents = getCurrentTalents()
  const weaponSkillPointsMaxDodge = currentTalents.filter(talent => talent.weaponSkillPointsMaxDodge !== undefined).map(talent => talent.weaponSkillPointsMaxDodge)
  const weaponSkillPointsMaxAxesAndHatchets = currentTalents.filter(talent => talent.weaponSkillPointsMaxAxesAndHatchets !== undefined).map(talent => talent.weaponSkillPointsMaxAxesAndHatchets)
  const weaponSkillPointsMaxFlail = currentTalents.filter(talent => talent.weaponSkillPointsMaxFlail !== undefined).map(talent => talent.weaponSkillPointsMaxFlail)
  const weaponSkillPointsMaxHammersAndClubs = currentTalents.filter(talent => talent.weaponSkillPointsMaxHammersAndClubs !== undefined).map(talent => talent.weaponSkillPointsMaxHammersAndClubs)
  const weaponSkillPointsMaxCurvedSword = currentTalents.filter(talent => talent.weaponSkillPointsMaxCurvedSword !== undefined).map(talent => talent.weaponSkillPointsMaxCurvedSword)
  const weaponSkillPointsMaxDagger = currentTalents.filter(talent => talent.weaponSkillPointsMaxDagger !== undefined).map(talent => talent.weaponSkillPointsMaxDagger)
  const weaponSkillPointsMaxSwords = currentTalents.filter(talent => talent.weaponSkillPointsMaxSwords !== undefined).map(talent => talent.weaponSkillPointsMaxSwords)
  const weaponSkillPointsMaxSpears = currentTalents.filter(talent => talent.weaponSkillPointsMaxSpears !== undefined).map(talent => talent.weaponSkillPointsMaxSpears)
  const weaponSkillPointsMaxStabWeapons = currentTalents.filter(talent => talent.weaponSkillPointsMaxStabWeapons !== undefined).map(talent => talent.weaponSkillPointsMaxStabWeapons)
  const weaponSkillPointsMaxUnarmed = currentTalents.filter(talent => talent.weaponSkillPointsMaxUnarmed !== undefined).map(talent => talent.weaponSkillPointsMaxUnarmed)
  const weaponSkillPointsMaxHarantzu = currentTalents.filter(talent => talent.weaponSkillPointsMaxHarantzu !== undefined).map(talent => talent.weaponSkillPointsMaxHarantzu)
  const weaponSkillPointsMaxCrossbows = currentTalents.filter(talent => talent.weaponSkillPointsMaxCrossbows !== undefined).map(talent => talent.weaponSkillPointsMaxCrossbows)
  const weaponSkillPointsMaxBows = currentTalents.filter(talent => talent.weaponSkillPointsMaxBows !== undefined).map(talent => talent.weaponSkillPointsMaxBows)
  const weaponSkillPointsMaxThrowingWeapons = currentTalents.filter(talent => talent.weaponSkillPointsMaxThrowingWeapons !== undefined).map(talent => talent.weaponSkillPointsMaxThrowingWeapons)
  const weaponSkillPointsMax = {
    dodge: parseInt(store.state.character.personality.level) + (weaponSkillPointsMaxDodge.length > 0 ? weaponSkillPointsMaxDodge.reduce((accumulator, currentValue) => accumulator + currentValue) : parseInt(configuration.weaponSkillsPointsMax)),
    axesAndHatchets:
      parseInt(store.state.character.personality.level) +
      (weaponSkillPointsMaxAxesAndHatchets.length > 0 ? weaponSkillPointsMaxAxesAndHatchets.reduce((accumulator, currentValue) => accumulator + currentValue) : parseInt(configuration.weaponSkillsPointsMax)),
    flail: parseInt(store.state.character.personality.level) + (weaponSkillPointsMaxFlail.length > 0 ? weaponSkillPointsMaxFlail.reduce((accumulator, currentValue) => accumulator + currentValue) : parseInt(configuration.weaponSkillsPointsMax)),
    hammersAndClubs:
      parseInt(store.state.character.personality.level) +
      (weaponSkillPointsMaxHammersAndClubs.length > 0 ? weaponSkillPointsMaxHammersAndClubs.reduce((accumulator, currentValue) => accumulator + currentValue) : parseInt(configuration.weaponSkillsPointsMax)),
    curvedSword:
      parseInt(store.state.character.personality.level) +
      (weaponSkillPointsMaxCurvedSword.length > 0 ? weaponSkillPointsMaxCurvedSword.reduce((accumulator, currentValue) => accumulator + currentValue) : parseInt(configuration.weaponSkillsPointsMax)),
    dagger: parseInt(store.state.character.personality.level) + (weaponSkillPointsMaxDagger.length > 0 ? weaponSkillPointsMaxDagger.reduce((accumulator, currentValue) => accumulator + currentValue) : parseInt(configuration.weaponSkillsPointsMax)),
    swords: parseInt(store.state.character.personality.level) + (weaponSkillPointsMaxSwords.length > 0 ? weaponSkillPointsMaxSwords.reduce((accumulator, currentValue) => accumulator + currentValue) : parseInt(configuration.weaponSkillsPointsMax)),
    spears: parseInt(store.state.character.personality.level) + (weaponSkillPointsMaxSpears.length > 0 ? weaponSkillPointsMaxSpears.reduce((accumulator, currentValue) => accumulator + currentValue) : parseInt(configuration.weaponSkillsPointsMax)),
    stabWeapons:
      parseInt(store.state.character.personality.level) +
      (weaponSkillPointsMaxStabWeapons.length > 0 ? weaponSkillPointsMaxStabWeapons.reduce((accumulator, currentValue) => accumulator + currentValue) : parseInt(configuration.weaponSkillsPointsMax)),
    unarmed: parseInt(store.state.character.personality.level) + (weaponSkillPointsMaxUnarmed.length > 0 ? weaponSkillPointsMaxUnarmed.reduce((accumulator, currentValue) => accumulator + currentValue) : parseInt(configuration.weaponSkillsPointsMax)),
    harantzu:
      parseInt(store.state.character.personality.level) + (weaponSkillPointsMaxHarantzu.length > 0 ? weaponSkillPointsMaxHarantzu.reduce((accumulator, currentValue) => accumulator + currentValue) : parseInt(configuration.weaponSkillsPointsMax)),
    crossbows:
      parseInt(store.state.character.personality.level) + (weaponSkillPointsMaxCrossbows.length > 0 ? weaponSkillPointsMaxCrossbows.reduce((accumulator, currentValue) => accumulator + currentValue) : parseInt(configuration.weaponSkillsPointsMax)),
    bows: parseInt(store.state.character.personality.level) + (weaponSkillPointsMaxBows.length > 0 ? weaponSkillPointsMaxBows.reduce((accumulator, currentValue) => accumulator + currentValue) : parseInt(configuration.weaponSkillsPointsMax)),
    throwingWeapons:
      parseInt(store.state.character.personality.level) +
      (weaponSkillPointsMaxThrowingWeapons.length > 0 ? weaponSkillPointsMaxThrowingWeapons.reduce((accumulator, currentValue) => accumulator + currentValue) : parseInt(configuration.weaponSkillsPointsMax))
  }
  const weaponSkills = store.getters.characterWeaponSkills
  const weaponSkillPoints = store.getters.characterWeaponSkillPoints
  Object.keys(weaponSkillPointsMax).forEach(skillName => {
    const realSkillPointsMaxValue = weaponSkills[skillName] + weaponSkillPoints
    if (weaponSkillPointsMax[skillName] > realSkillPointsMaxValue) {
      weaponSkillPointsMax[skillName] = realSkillPointsMaxValue
    }
  })
  return weaponSkillPointsMax
}
function getWeaponSkillPointsMin() {
  const characterState = getStateForLevel(store.getters.characterLevel - 1)
  const weaponSkillsPointsMinValue = store.getters.configuration.weaponSkillsPointsMin
  const weaponSkillsPointsMin = {}
  Object.keys(store.getters.characterWeaponSkills).forEach(weaponSkillName => {
    if (characterState !== undefined) {
      const realSkillPointsMinValue = parseInt(characterState.weaponSkills[weaponSkillName])
      weaponSkillsPointsMin[weaponSkillName] = realSkillPointsMinValue > weaponSkillsPointsMinValue ? realSkillPointsMinValue : weaponSkillsPointsMinValue
    } else {
      weaponSkillsPointsMin[weaponSkillName] = weaponSkillsPointsMinValue
    }
  })
  return weaponSkillsPointsMin
}
function calculateValue(name, value) {
  const valueMin = store.getters.characterWeaponSkillsMin[name]
  const valueMax = store.getters.characterWeaponSkillsMax[name]
  let newValue = value
  if (value > valueMax) {
    newValue = valueMax
  } else if (value < valueMin) {
    newValue = valueMin
  }
  return newValue
}
function setWeaponSkill(name, value) {
  const skills = store.getters.characterWeaponSkills
  skills[name] = calculateValue(name, value)
  store.dispatch('setCharacterWeaponSkills', skills)
}
export { getWeaponSkillPoints, getCurrentWeaponSkills, getWeaponSkills, getWeaponSkillsBonus, getWeaponSkillPointsMax, getWeaponSkillPointsMin, setWeaponSkill }
