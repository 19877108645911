<template>
  <v-list-item link @click="generateCharacter">
    <v-list-item-title>Shuffle</v-list-item-title>
    <v-list-item-icon>
      <v-icon>cached</v-icon>
    </v-list-item-icon>
  </v-list-item>
</template>

<script>
import { notAllDependenciesReached } from '../../services/dependency.service'
import { calculateRunePoints } from '../../services/rune.service'
import { initializeCharacter, setExperience, setLevel, setName } from '../../services/personality.service'
import { loadState } from '../../services/states.service'
import { setAttribute } from '../../services/attribute.service'
import { setSharedSkillPoint } from '../../services/skillpoint.service'
import { setWeaponSkill } from '../../services/weaponSkills.service'
import { setBodySkill } from '../../services/bodySkills.service'
import { setMentalSkill } from '../../services/mentalSkills.service'
import { addTalent } from '../../services/talent.service'
import { addSpell } from '../../services/spell.service'
import { setGender } from '../../services/gender.service'
import { setCulture } from '../../services/culture.service'
import { getClazzForName, setClazz } from '../../services/clazz.service'

export default {
  name: 'Generator',
  methods: {
    generateCharacter() {
      initializeCharacter()
      setTimeout(this.generatePersonality, 100)
      setTimeout(this.setAttributePoints, 400)
      setTimeout(this.setTalents, 200)
      setTimeout(this.setSpells, 200)
      setTimeout(this.setSkillPoints, 500)
      setTimeout(this.setWeaponSkillPoints, 600)
      setTimeout(this.setBodySkillPoints, 600)
      setTimeout(this.setMentalSkillPoints, 600)
      setTimeout(this.setRunes, 600)
    },
    generatePersonality() {
      if (!this.$store.getters.configuration.keepLevel) {
        setLevel(this.randomIntFromInterval(1, 20))
        setExperience(this.$store.getters.characterLevel)
      }
      loadState(this.$store.character.personality.level)
      if (!this.$store.getters.configuration.keepGender) {
        const genders = this.$store.getters.genders
        const gender = genders[this.randomIntFromInterval(1, genders.length) - 1]
        setGender(gender.name)
        if (!this.$store.getters.configuration.keepCulture) {
          const cultures = this.$store.state.cultures
          const culture = cultures[this.randomIntFromInterval(1, cultures.length) - 1]
          setCulture(culture.name)
          if (!this.$store.getters.configuration.keepClazz) {
            const clazzes = culture.clazzes.map(clazz => getClazzForName(clazz))
            setClazz(clazzes[this.randomIntFromInterval(1, clazzes.length) - 1].name)
          }
          if (!this.$store.getters.configuration.keepName) {
            const filteredNames = this.$store.getters.names[gender.name][culture.name]
            setName(filteredNames[this.randomIntFromInterval(1, filteredNames.length) - 1])
          }
          this.$store.state.character.personality.skinColor = culture.skinColors[this.randomIntFromInterval(1, culture.skinColors.length) - 1]
          this.$store.state.character.personality.hairColor = culture.hairColors[this.randomIntFromInterval(1, culture.hairColors.length) - 1]
          this.$store.state.character.personality.eyeColor = culture.eyeColors[this.randomIntFromInterval(1, culture.eyeColors.length) - 1]
        }
      }
    },
    setAttributePoints() {
      const attributeNames = Object.keys(this.$store.state.character.attributes)
      let attributePoints = this.$store.getters.characterAttributePoints
      while (attributePoints <= 0) {
        const attributeName = attributeNames[this.randomIntFromInterval(1, attributeNames.length) - 1]
        const attributeValue = this.$store.getters.characterAttributes[attributeName] + 1
        setAttribute(attributeName, attributeValue)
        attributePoints = this.$store.getters.characterAttributePoints
      }
    },
    setSkillPoints() {
      const giftedSkillNames = ['skillPointsToWeaponSkills', 'skillPointsToBodySkills', 'skillPointsToMentalSkills']
      if (this.$store.getters.characterClazz === 'runeFighter') {
        giftedSkillNames.push('skillPointsToRunes')
      }
      for (let i = 0; i < this.$store.getters.characterSkillPoints; i++) {
        const giftedSkillName = giftedSkillNames[this.randomIntFromInterval(1, giftedSkillNames.length) - 1]
        const giftedSkillValue = this.$store.getters.characterSharedSkillPoints[giftedSkillName] + 1
        setSharedSkillPoint(giftedSkillName, giftedSkillValue)
      }
    },
    setWeaponSkillPoints() {
      const skillNames = Object.keys(this.$store.getters.characterWeaponSkills)
      for (let i = 0; i < this.$store.getters.characterWeaponSkillPoints; i++) {
        const skillName = skillNames[this.randomIntFromInterval(1, skillNames.length) - 1]
        const skillValue = this.$store.getters.characterWeaponSkills[skillName] + 1
        setWeaponSkill(skillName, skillValue)
      }
    },
    setBodySkillPoints() {
      const skillNames = Object.keys(this.$store.getters.characterBodySkills)
      for (let i = 0; i < this.$store.getters.characterBodySkillPoints; i++) {
        const skillName = skillNames[this.randomIntFromInterval(1, skillNames.length) - 1]
        const skillValue = this.$store.getters.characterBodySkillPoints[skillName] + 1
        setBodySkill(skillName, skillValue)
      }
    },
    setMentalSkillPoints() {
      const skillNames = Object.keys(this.$store.getters.characterMentalSkills)
      for (let i = 0; i < this.$store.getters.characterMentalSkillPoints; i++) {
        const skillName = skillNames[this.randomIntFromInterval(1, skillNames.length) - 1]
        const skillValue = this.$store.getters.characterMentalSkills[skillName] + 1
        setMentalSkill(skillName, skillValue)
      }
    },
    randomIntFromInterval(min, max) {
      // min and max included
      return Math.floor(Math.random() * (max - min + 1) + min)
    },
    setTalents() {
      for (let i = 0; i < this.$store.getters.characterTalentPoints; i++) {
        const selectAbleTalents = this.$store.getters.talents.filter(talent => !this.selectAbleCharacterTalentIsDisabled(talent))
        const talentName = selectAbleTalents[this.randomIntFromInterval(1, selectAbleTalents.length) - 1].name
        addTalent(talentName)
      }
    },
    setSpells() {
      for (let i = 0; i < this.$store.getters.characterSpellPoints; i++) {
        const selectAbleSpells = this.$store.getters.spells.filter(spell => !this.selectAbleCharacterSpellIsDisabled(spell) && spell.name !== 'fixThis')
        const spell = selectAbleSpells[this.randomIntFromInterval(1, selectAbleSpells.length) - 1]
        if (spell !== undefined) {
          addSpell(spell.name)
        }
      }
    },
    setRunes() {
      let availableRunePoints = this.$store.getters.characterRunePoints
      while (availableRunePoints > 0) {
        const runeSteps = {
          2: '1',
          3: '3',
          4: '6',
          5: '10'
        }
        const characterRunes = this.$store.getters.characterRunes
        const runeStepNames = Object.keys(runeSteps)
        const allPossibleRuneStepNames = runeStepNames.filter(runeStepName => parseInt(runeSteps[runeStepName]) <= availableRunePoints)
        const allPossibleLevelRunes = characterRunes.filter(rune => parseInt(rune.level) < Math.max.apply(null, allPossibleRuneStepNames))
        const selectedRune = allPossibleLevelRunes[this.randomIntFromInterval(1, allPossibleLevelRunes.length) - 1]
        const selectedRuneIndex = characterRunes.indexOf(selectedRune)
        const currentLevel = parseInt(characterRunes[selectedRuneIndex].level)
        characterRunes[selectedRuneIndex].level = (currentLevel + 1).toString()
        availableRunePoints = availableRunePoints - this.calculateNeededRunePoints(currentLevel, currentLevel + 1)
      }
    },
    selectAbleCharacterTalentIsDisabled(talent) {
      const talentIsActive = this.$store.getters.characterTalents.filter(characterTalent => characterTalent === talent.name).length > 0
      const isNotMultiple = talent.multiple === undefined || !talent.multiple
      const notEnougthTalentPoints = this.talentPoints <= 0
      return notEnougthTalentPoints || (talentIsActive && isNotMultiple) || notAllDependenciesReached(talent.dependencies)
    },
    selectAbleCharacterSpellIsDisabled(spell) {
      const spellIsActive = this.$store.getters.characterSpells.filter(characterSpell => characterSpell === spell.name).length > 0
      const notEnougthSpellPoints = this.spellPoints <= 0
      return notEnougthSpellPoints || spellIsActive || notAllDependenciesReached(spell.dependencies)
    },
    calculateNeededRunePoints(currentRuneLevel, runeLevel) {
      const currentRuneLevelNeededRunePoints = calculateRunePoints(currentRuneLevel)
      const runeLevelNeededRunePoints = calculateRunePoints(runeLevel)
      return runeLevelNeededRunePoints - currentRuneLevelNeededRunePoints
    }
  }
}
</script>
