import axios from 'axios'
import store from '../store'

const api = axios.create({
  baseURL: process.env.VUE_APP_AXIOS_BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
})

api.interceptors.request.use(
  config => {
    if (store.state.account.accessToken) {
      config.headers.Authorization = `Bearer ${store.state.account.accessToken}`
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  response => {
    return response
  },
  async error => {
    const originalRequest = error.config
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true

      if (!store.state.account.accessTokenRefreshing) {
        store.state.account.accessTokenRefreshing = true
        try {
          const refreshResponse = await api.post('/rest/besodd/refresh', {}, { withCredentials: true })
          store.state.account.accessToken = refreshResponse.data.token
          store.state.account.accessTokenRefreshing = false
        } catch (error) {
          console.error('Error refreshing access token', error)
          store.state.account.accessTokenRefreshing = false
          return Promise.reject(error)
        }
      }
      originalRequest.headers.Authorization = `Bearer ${store.state.account.accessToken}`
      return api(originalRequest)
    }

    return Promise.reject(error)
  }
)

async function login(username, password) {
  try {
    const loginResponse = await api.post(
      '/rest/besodd/login',
      {
        username,
        password
      },
      { withCredentials: true }
    )
    if (loginResponse.status === 200) {
      console.debug('Login success: ', loginResponse)
      store.state.account.accessToken = loginResponse.data.token
      return true
    }
  } catch (e) {
    console.debug(e)
    return false
  }
  return false
}

async function registration(firstName, lastName, email, password) {
  try {
    const registrationResponse = await api.post(
      '/rest/besodd/register',
      {
        first_name: firstName,
        last_name: lastName,
        username: email,
        password
      },
      { withCredentials: true }
    )
    if (registrationResponse.status === 200) {
      console.debug('Registration success: ', registrationResponse)
      return true
    }
  } catch (e) {
    console.debug(e)
    return false
  }
  return false
}

async function logout() {
  const logoutResponse = await api.post('/rest/besodd/logout', {}, { withCredentials: true })
  if (logoutResponse.status === 204) {
    console.debug('Logout success: ', logoutResponse)
    return true
  } else {
    console.debug('Login error: ', logoutResponse)
  }
  return false
}

async function saveCharacter() {
  const character = store.getters.character
  const states = store.getters.characterStates
  const configuration = store.getters.configuration
  const saveCharactersResponse = await api.post('/rest/besodd/Avatars', {
    name: character.personality.name,
    gender: character.personality.gender,
    culture: character.personality.culture,
    clazz: character.personality.clazz,
    level: character.personality.level,
    experience: character.personality.experience,
    content: JSON.stringify({
      character,
      states,
      configuration
    })
  })
  if (saveCharactersResponse.status === 201) {
    console.debug('Save character success: ', saveCharactersResponse)
    return true
  } else {
    console.debug('Save character error: ', saveCharactersResponse)
  }
  return false
}

async function removeCharacter(name) {
  const removeCharactersResponse = await api.delete(`/rest/besodd/Avatars('${name}')`)
  if (removeCharactersResponse.status === 204) {
    console.debug('Remove character success: ', removeCharactersResponse)
    return true
  } else {
    console.debug('Remove character error: ', removeCharactersResponse)
  }
  return false
}

async function loadCharacters() {
  const loadCharactersResponse = await api.get('/rest/besodd/Avatars')
  if (loadCharactersResponse.status === 200) {
    console.debug('Load characters success: ', loadCharactersResponse)
    for (const charcter of loadCharactersResponse.data) {
      charcter.content = JSON.parse(charcter.content)
    }
    return loadCharactersResponse.data
  } else {
    console.debug('Load characters error: ', loadCharactersResponse)
  }
  return null
}

async function shareCharacter(name) {
  try {
    const registrationResponse = await api.post(
      '/rest/besodd/share',
      {
        name
      },
      { withCredentials: true }
    )
    if (registrationResponse.status === 200) {
      console.debug('Sharing Character success: ', registrationResponse)
      return true
    }
  } catch (e) {
    console.debug(e)
    return false
  }
  return false
}

async function unShareCharacter(name) {
  try {
    const registrationResponse = await api.post(
      '/rest/besodd/unshare',
      {
        name
      },
      { withCredentials: true }
    )
    if (registrationResponse.status === 200) {
      console.debug('Sharing Character success: ', registrationResponse)
      return true
    }
  } catch (e) {
    console.debug(e)
    return false
  }
  return false
}

export { login, logout, saveCharacter, registration, removeCharacter, loadCharacters, shareCharacter, unShareCharacter }
