<template>
  <v-card outlined>
    <v-app-bar class="primary">
      <v-toolbar-title>
        {{ $t('components.CharacterGenerator.weaponSkills.label') + ' ( WF / ' + $store.getters.characterWeaponSkillPoints + ' )' }}
      </v-toolbar-title>
    </v-app-bar>
    <v-container fluid>
      <v-row style="padding-top: 25px">
        <v-col>
          <Value
            :title="$t('components.CharacterGenerator.weaponSkills.dodge')"
            :value="$store.getters.characterWeaponSkills.dodge"
            :value-bonus="$store.getters.characterWeaponSkillsBonus.dodge"
            :min="$store.getters.characterWeaponSkillsMin.dodge"
            :max="$store.getters.characterWeaponSkillsMax.dodge"
            :value-name="'dodge'"
            @changed-value="valueChange"
          />
          <Value
            :title="$t('components.CharacterGenerator.weaponSkills.axesAndHatchets')"
            :value="$store.getters.characterWeaponSkills.axesAndHatchets"
            :value-bonus="$store.getters.characterWeaponSkillsBonus.axesAndHatchets"
            :min="$store.getters.characterWeaponSkillsMin.axesAndHatchets"
            :max="$store.getters.characterWeaponSkillsMax.axesAndHatchets"
            :value-name="'axesAndHatchets'"
            @changed-value="valueChange"
          />
          <Value
            :title="$t('components.CharacterGenerator.weaponSkills.flail')"
            :value="$store.getters.characterWeaponSkills.flail"
            :value-bonus="$store.getters.characterWeaponSkillsBonus.flail"
            :min="$store.getters.characterWeaponSkillsMin.flail"
            :max="$store.getters.characterWeaponSkillsMax.flail"
            :value-name="'flail'"
            @changed-value="valueChange"
          />
          <Value
            :title="$t('components.CharacterGenerator.weaponSkills.hammersAndClubs')"
            :value="$store.getters.characterWeaponSkills.hammersAndClubs"
            :value-bonus="$store.getters.characterWeaponSkillsBonus.hammersAndClubs"
            :min="$store.getters.characterWeaponSkillsMin.hammersAndClubs"
            :max="$store.getters.characterWeaponSkillsMax.hammersAndClubs"
            :value-name="'hammersAndClubs'"
            @changed-value="valueChange"
          />
          <Value
            :title="$t('components.CharacterGenerator.weaponSkills.curvedSword')"
            :value="$store.getters.characterWeaponSkills.curvedSword"
            :value-bonus="$store.getters.characterWeaponSkillsBonus.curvedSword"
            :min="$store.getters.characterWeaponSkillsMin.curvedSword"
            :max="$store.getters.characterWeaponSkillsMax.curvedSword"
            :value-name="'curvedSword'"
            @changed-value="valueChange"
          />
          <Value
            :title="$t('components.CharacterGenerator.weaponSkills.dagger')"
            :value="$store.getters.characterWeaponSkills.dagger"
            :value-bonus="$store.getters.characterWeaponSkillsBonus.dagger"
            :min="$store.getters.characterWeaponSkillsMin.dagger"
            :max="$store.getters.characterWeaponSkillsMax.dagger"
            :value-name="'dagger'"
            @changed-value="valueChange"
          />
          <Value
            :title="$t('components.CharacterGenerator.weaponSkills.swords')"
            :value="$store.getters.characterWeaponSkills.swords"
            :value-bonus="$store.getters.characterWeaponSkillsBonus.swords"
            :min="$store.getters.characterWeaponSkillsMin.swords"
            :max="$store.getters.characterWeaponSkillsMax.swords"
            :value-name="'swords'"
            @changed-value="valueChange"
          />
          <Value
            :title="$t('components.CharacterGenerator.weaponSkills.spears')"
            :value="$store.getters.characterWeaponSkills.spears"
            :value-bonus="$store.getters.characterWeaponSkillsBonus.spears"
            :min="$store.getters.characterWeaponSkillsMin.spears"
            :max="$store.getters.characterWeaponSkillsMax.spears"
            :value-name="'spears'"
            @changed-value="valueChange"
          />
          <Value
            :title="$t('components.CharacterGenerator.weaponSkills.stabWeapons')"
            :value="$store.getters.characterWeaponSkills.stabWeapons"
            :value-bonus="$store.getters.characterWeaponSkillsBonus.stabWeapons"
            :min="$store.getters.characterWeaponSkillsMin.stabWeapons"
            :max="$store.getters.characterWeaponSkillsMax.stabWeapons"
            :value-name="'stabWeapons'"
            @changed-value="valueChange"
          />
          <Value
            :title="$t('components.CharacterGenerator.weaponSkills.unarmed')"
            :value="$store.getters.characterWeaponSkills.unarmed"
            :value-bonus="$store.getters.characterWeaponSkillsBonus.unarmed"
            :min="$store.getters.characterWeaponSkillsMin.unarmed"
            :max="$store.getters.characterWeaponSkillsMax.unarmed"
            :value-name="'unarmed'"
            @changed-value="valueChange"
          />
          <Value
            :title="$t('components.CharacterGenerator.weaponSkills.harantzu')"
            :value="$store.getters.characterWeaponSkills.harantzu"
            :value-bonus="$store.getters.characterWeaponSkillsBonus.harantzu"
            :min="$store.getters.characterWeaponSkillsMin.harantzu"
            :max="$store.getters.characterWeaponSkillsMax.harantzu"
            :value-name="'harantzu'"
            @changed-value="valueChange"
          />
          <Value
            :title="$t('components.CharacterGenerator.weaponSkills.crossbows')"
            :value="$store.getters.characterWeaponSkills.crossbows"
            :value-bonus="$store.getters.characterWeaponSkillsBonus.crossbows"
            :min="$store.getters.characterWeaponSkillsMin.crossbows"
            :max="$store.getters.characterWeaponSkillsMax.crossbows"
            :value-name="'crossbows'"
            @changed-value="valueChange"
          />
          <Value
            :title="$t('components.CharacterGenerator.weaponSkills.bows')"
            :value="$store.getters.characterWeaponSkills.bows"
            :value-bonus="$store.getters.characterWeaponSkillsBonus.bows"
            :min="$store.getters.characterWeaponSkillsMin.bows"
            :max="$store.getters.characterWeaponSkillsMax.bows"
            :value-name="'bows'"
            @changed-value="valueChange"
          />
          <Value
            :title="$t('components.CharacterGenerator.weaponSkills.throwingWeapons')"
            :value="$store.getters.characterWeaponSkills.throwingWeapons"
            :value-bonus="$store.getters.characterWeaponSkillsBonus.throwingWeapons"
            :min="$store.getters.characterWeaponSkillsMin.throwingWeapons"
            :max="$store.getters.characterWeaponSkillsMax.throwingWeapons"
            :value-name="'throwingWeapons'"
            @changed-value="valueChange"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import Value from '@/components/content/Value'
import { setWeaponSkill } from '@/services/weaponSkills.service'

export default {
  name: 'WeaponSkills',
  components: { Value },
  methods: {
    valueChange(skill) {
      setWeaponSkill(skill.name, skill.value)
    }
  }
}
</script>
