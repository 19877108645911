<template>
  <v-card outlined>
    <v-app-bar class="primary">
      <v-toolbar-title>
        {{ $t('components.CharacterGenerator.inventory.label') }}
      </v-toolbar-title>
    </v-app-bar>
    <v-container fluid elevation="0">
      <v-expansion-panels focusable accordion>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>
              <v-icon> mdi-cash-multiple </v-icon>
              {{ $t('components.CharacterGenerator.inventory.money.label') }}
            </h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <Money />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>
              <v-icon> mdi-shield-sword </v-icon>
              {{ $t('components.CharacterGenerator.inventory.header') }}
            </h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-container fluid>
              <v-row>
                <v-col cols="12" md="12">
                  <Jewellery />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="8">
                  <WeaponsMelee />
                </v-col>
                <v-col cols="12" md="4">
                  <Armor />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="8">
                  <WeaponsRanged />
                </v-col>
                <v-col cols="12" md="4">
                  <Shield />
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>
              <v-icon> mdi-sack </v-icon>
              {{ $t('components.CharacterGenerator.inventory.items.label') }}
            </h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-container fluid>
              <Items />
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
  </v-card>
</template>

<script>
import WeaponsMelee from '@/components/content/WeaponsMelee'
import WeaponsRanged from '@/components/content/WeaponsRanged'
import Armor from '@/components/content/Armor'
import Shield from '@/components/content/Shield'
import Items from '@/components/content/Items'
import Money from '@/components/content/Money'
import Jewellery from '@/components/content/Jewellery'

export default {
  name: 'Inventory',
  components: { Jewellery, Items, Money, Shield, Armor, WeaponsRanged, WeaponsMelee }
}
</script>
