<template>
  <v-card outlined>
    <v-app-bar class="primary">
      <v-toolbar-title>
        {{ $t('components.CharacterGenerator.personality.label') }}
      </v-toolbar-title>
    </v-app-bar>
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="6">
          <v-card elevation="0" outlined>
            <v-card-text>
              <v-row class="mb-4" justify="space-between">
                <v-col class="text-left">
                  <span class="text-h2 font-weight-light" v-text="$store.getters.characterLevel" />
                  <span class="subheading font-weight-light mr-1">
                    {{ $t('components.CharacterGenerator.personality.level') }}
                  </span>
                </v-col>
              </v-row>
              <v-slider :value="$store.getters.characterLevel" track-color="grey" step="1" :min="$store.getters.configuration.levelMin" :max="$store.getters.configuration.levelMax" @change="changeLevel" :readonly="true">
                <template v-slot:prepend>
                  <v-icon @click="dialogDecreaseLevel = true" :disabled="$store.getters.characterLevel < 2 || getCharacterState($store.getters.characterLevel - 1) === undefined"> mdi-minus </v-icon>
                </template>
                <template v-slot:append>
                  <v-icon @click="dialogIncreaseLevel = true" :disabled="!levelUp"> mdi-plus </v-icon>
                </template>
              </v-slider>
            </v-card-text>
          </v-card>
          <v-dialog v-model="dialogIncreaseLevel" persistent max-width="290">
            <v-card>
              <v-card-title class="text-h5">
                {{ $t('components.CharacterGenerator.personality.levelUp') }}
              </v-card-title>
              <v-card-text>{{ $t('components.CharacterGenerator.personality.levelUpDescription') }}</v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn @click="dialogIncreaseLevel = false">
                  {{ $t('components.CharacterGenerator.personality.no') }}
                </v-btn>
                <v-btn color="primary" @click="increaseLevel">
                  {{ $t('components.CharacterGenerator.personality.yes') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDecreaseLevel" persistent max-width="290">
            <v-card>
              <v-card-title class="text-h5">
                {{ $t('components.CharacterGenerator.personality.levelDown') }}
              </v-card-title>
              <v-card-text>{{ $t('components.CharacterGenerator.personality.levelDownDescription') }}</v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn @click="dialogDecreaseLevel = false">
                  {{ $t('components.CharacterGenerator.personality.no') }}
                </v-btn>
                <v-btn color="primary" @click="decreaseLevel">
                  {{ $t('components.CharacterGenerator.personality.yes') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col cols="12" md="6">
          <v-card elevation="0" outlined>
            <v-card-text>
              <v-row class="mb-4" justify="space-between">
                <v-col class="text-left">
                  <span class="text-h2 font-weight-light" v-text="$store.getters.characterExperience" />
                  <span class="subheading font-weight-light mr-1">
                    {{ $t('components.CharacterGenerator.personality.experience') }}
                  </span>
                  <v-text-field
                    v-show="$store.getters.configuration.showExperienceInputField"
                    type="number"
                    :value="$store.getters.characterExperience"
                    :min="minExperience"
                    :max="maxExperience"
                    @change="changeExperience"
                    :readonly="true"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-slider :value="$store.getters.characterExperience" track-color="grey" step="50" :min="minExperience" :max="maxExperience" @change="changeExperience" :readonly="true">
                <template v-slot:prepend>
                  <v-icon @click="changeExperience($store.getters.characterExperience - 50)"> mdi-minus </v-icon>
                </template>
                <template v-slot:append>
                  <v-icon @click="changeExperience($store.getters.characterExperience + 50)"> mdi-plus </v-icon>
                </template>
              </v-slider>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="false">
        <v-col cols="12" md="12">
          <Levels />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3">
          <v-combobox :value="$store.getters.characterName" :items="currentAvailableNames" :label="$t('components.CharacterGenerator.personality.name')" @change="nameChange" outlined />
        </v-col>
        <v-col cols="12" md="3">
          <v-select :value="$store.getters.characterGender" :disabled="permanent" :items="currentAvailableGenders" :label="$t('components.CharacterGenerator.personality.gender.label')" @change="genderChange" outlined />
        </v-col>
        <v-col cols="12" md="3">
          <v-select
            :value="$store.getters.characterCulture"
            :disabled="$store.getters.characterGender === undefined || $store.getters.characterGender === '' || permanent"
            :items="currentAvailableCultures"
            :label="$t('components.CharacterGenerator.personality.culture.label')"
            @change="cultureChange"
            outlined
          />
        </v-col>
        <v-col cols="12" md="3">
          <v-select
            :value="$store.getters.characterClazz"
            :disabled="$store.getters.characterCulture === undefined || $store.getters.characterCulture === '' || permanent"
            :items="currentAvailableClazzes"
            :label="$t('components.CharacterGenerator.personality.clazz.label')"
            @change="clazzChange"
            outlined
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3">
          <v-select
            :value="$store.getters.characterHairColor"
            :disabled="$store.getters.characterCulture === undefined || $store.getters.characterCulture === ''"
            :items="currentAvailableHairColors"
            :label="$t('components.CharacterGenerator.personality.hairColor')"
            @change="hairColorChange"
            outlined
          />
        </v-col>
        <v-col cols="12" md="3">
          <v-select
            :value="$store.getters.characterEyeColor"
            :disabled="$store.getters.characterCulture === undefined || $store.getters.characterCulture === ''"
            :items="currentAvailableEyeColors"
            :label="$t('components.CharacterGenerator.personality.eyeColor')"
            @change="eyeColorChange"
            outlined
          />
        </v-col>
        <v-col cols="12" md="3">
          <v-select
            :value="$store.getters.characterSkinColor"
            :disabled="$store.getters.characterCulture === undefined || $store.getters.characterCulture === ''"
            :items="currentAvailableSkinColors"
            :label="$t('components.CharacterGenerator.personality.skinColor')"
            @change="skinColorChange"
            outlined
          />
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field :value="$store.getters.characterAge" type="number" :label="$t('components.CharacterGenerator.personality.age')" @change="ageChanged" @click.stop="ageClicked" suffix="Jahre" outlined />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3">
          <v-text-field :value="$store.getters.characterSize" type="number" :label="$t('components.CharacterGenerator.personality.size')" @change="sizeChanged" @click.stop="sizeClicked" suffix="Halbfinger" outlined />
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field :value="$store.getters.characterWeight" type="number" :label="$t('components.CharacterGenerator.personality.weight')" @change="weightChanged" @click.stop="weightClicked" suffix="Stein" outlined />
        </v-col>
        <v-col cols="12" md="3">
          <v-select :value="$store.getters.characterCraft" :disabled="craftDisable" :items="currentAvailableCrafts" :label="$t('components.CharacterGenerator.bodySkills.craft')" @change="craftChange" outlined />
        </v-col>
        <v-col cols="12" md="3">
          <v-select
            :value="$store.getters.characterLanguages"
            :disabled="$store.getters.characterCulture === undefined || $store.getters.characterCulture === ''"
            :items="currentAvailableLanguages"
            :label="$t('components.CharacterGenerator.languages.label')"
            @change="languagesChange"
            outlined
            multiple
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-textarea :value="$store.getters.characterBackground" :label="$t('components.CharacterGenerator.personality.background.label')" @change="backgroundChange" outlined no-resize />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { levelToExperience, experienceToLevel, setLevel, setExperience, setName, setBackground, levelUp, setAge, setEyeColor, setHairColor, setSize, setSkinColor, setWeight } from '@/services/personality.service'
import { addLanguage, removeLanguage } from '@/services/language.service'
import { loadState, saveState, removeState, getStateForLevel } from '@/services/states.service'
import { getCurrentGender, setGender } from '@/services/gender.service'
import { getCurrentCulture, setCulture } from '@/services/culture.service'
import { getClazzForName, setClazz } from '@/services/clazz.service'
import { setCraft } from '@/services/craft.service'
import { createSelect, createSelectByNameWithPostFix } from '@/utils/ComponentUtils'
import Levels from '@/components/content/Levels'

export default {
  name: 'Personalities',
  components: { Levels },
  data() {
    return {
      dialogIncreaseLevel: false,
      dialogDecreaseLevel: false
    }
  },
  computed: {
    permanent() {
      return this.$store.getters.characterLevel > 1
    },
    levelUp() {
      return levelUp()
    },
    maxExperience() {
      return levelToExperience(this.$store.getters.configuration.levelMax)
    },
    minExperience() {
      return levelToExperience(this.$store.getters.configuration.levelMin)
    },
    currentAvailableGenders() {
      return createSelectByNameWithPostFix(this.$store.getters.genders, 'components.CharacterGenerator.personality.gender.', '.name')
    },
    currentAvailableCultures() {
      return createSelectByNameWithPostFix(this.$store.getters.cultures, 'components.CharacterGenerator.personality.culture.', '.name')
    },
    currentAvailableClazzes() {
      const currentCulture = getCurrentCulture()
      if (!this.$_.isEmpty(currentCulture)) {
        const filteredCultures = currentCulture.clazzes.map(clazz => getClazzForName(clazz))
        return createSelectByNameWithPostFix(filteredCultures, 'components.CharacterGenerator.personality.clazz.', '.name')
      }
      return []
    },
    currentAvailableNames() {
      const currentGender = getCurrentGender()
      const currentCulture = getCurrentCulture()
      if (!this.$_.isEmpty(currentGender) && !this.$_.isEmpty(currentCulture)) {
        return this.$store.state.names[currentGender.name][currentCulture.name]
      }
      return []
    },
    currentAvailableHairColors() {
      const currentCulture = getCurrentCulture()
      if (!this.$_.isEmpty(currentCulture)) {
        return createSelect(currentCulture.hairColors, 'components.CharacterGenerator.colors.', '')
      }
      return []
    },
    currentAvailableEyeColors() {
      const currentCulture = getCurrentCulture()
      if (!this.$_.isEmpty(currentCulture)) {
        return createSelect(currentCulture.eyeColors, 'components.CharacterGenerator.colors.', '')
      }
      return []
    },
    currentAvailableSkinColors() {
      const currentCulture = getCurrentCulture()
      if (!this.$_.isEmpty(currentCulture)) {
        return createSelect(currentCulture.skinColors, 'components.CharacterGenerator.colors.', '')
      }
      return []
    },
    currentAvailableCrafts() {
      return createSelectByNameWithPostFix(this.$store.getters.crafts, 'components.CharacterGenerator.craftings.', '.name')
    },
    currentAvailableLanguages() {
      return createSelectByNameWithPostFix(this.$store.getters.languages, 'components.CharacterGenerator.languages.', '.name')
    },
    craftDisable() {
      return this.$store.getters.characterBodySkills.craft <= 0
    }
  },
  methods: {
    decreaseLevel() {
      this.changeLevel(this.$store.getters.characterLevel - 1)
      this.loadCharacterState(this.$store.getters.characterLevel)
      this.dialogDecreaseLevel = false
      this.removeOldCharacterStates()
    },
    increaseLevel() {
      this.changeLevel(this.$store.getters.characterLevel + 1)
      this.saveCharacterState()
      this.dialogIncreaseLevel = false
      this.removeOldCharacterStates()
    },
    removeOldCharacterStates() {
      for (let i = this.$store.getters.characterLevel + 1; i <= 20; ++i) {
        const characterState = getStateForLevel(i)
        if (characterState !== undefined) {
          removeState(characterState)
        }
      }
    },
    changeLevel(level) {
      let newLevel = level
      if (level > this.$store.getters.configuration.levelMax) {
        newLevel = this.$store.getters.configuration.levelMax
      } else if (level < this.$store.getters.configuration.levelMin) {
        newLevel = this.$store.getters.configuration.levelMin
      }
      setLevel(newLevel)
      this.updateExperience()
    },
    changeExperience(experience) {
      const calculatedLevel = experienceToLevel(experience)
      const characterState = getStateForLevel(this.$store.getters.characterLevel - 1)
      if (this.$store.getters.characterLevel - calculatedLevel === 0) {
        setExperience(experience)
      } else if (calculatedLevel - this.$store.getters.characterLevel === 1 && this.levelUp) {
        this.dialogIncreaseLevel = true
      } else if (characterState !== undefined) {
        this.updateLevel()
        this.loadCharacterState(this.$store.getters.characterLevel)
      } else {
        this.updateExperience()
      }
    },
    updateExperience() {
      setExperience(levelToExperience(this.$store.getters.characterLevel))
    },
    updateLevel() {
      let calculatetExperience = 0
      let levelCount = 0
      while (this.$store.getters.characterExperience >= calculatetExperience) {
        calculatetExperience = calculatetExperience + 1000 * levelCount
        levelCount++
      }
      setLevel(levelCount - 1)
    },
    nameChange(name) {
      setName(name)
    },
    backgroundChange(background) {
      setBackground(background)
    },
    genderChange(genderName) {
      setGender(genderName)
    },
    cultureChange(cultureName) {
      setCulture(cultureName)
    },
    clazzChange(clazzName) {
      setClazz(clazzName)
    },
    hairColorChange(name) {
      setHairColor(name)
    },
    eyeColorChange(name) {
      setEyeColor(name)
    },
    skinColorChange(name) {
      setSkinColor(name)
    },
    ageClicked(event) {
      setAge(parseInt(event.srcElement._value))
    },
    ageChanged(value) {
      setAge(parseInt(value))
    },
    sizeClicked(event) {
      setSize(parseInt(event.srcElement._value))
    },
    sizeChanged(value) {
      setSize(parseInt(value))
    },
    weightClicked(event) {
      setWeight(parseInt(event.srcElement._value))
    },
    weightChanged(value) {
      setWeight(parseInt(value))
    },
    craftChange(craftName) {
      setCraft(craftName)
    },
    languagesChange(languages) {
      const currentLanguages = this.$store.getters.characterLanguages
      const addedLanguages = languages.filter(language => !currentLanguages.includes(language))
      const removedLanguages = currentLanguages.filter(language => !languages.includes(language))
      addedLanguages.forEach(language => addLanguage(language))
      removedLanguages.forEach(language => removeLanguage(language))
    },
    getCharacterState(level) {
      return getStateForLevel(level)
    },
    saveCharacterState() {
      saveState()
    },
    loadCharacterState(level) {
      loadState(level)
    }
  }
}
</script>
<style lang="scss" scoped>
.theme--light.v-card > .v-card__text,
.theme--light.v-card > .v-card__subtitle {
  color: #000 !important;
}
</style>
