import Vue from 'vue'
import Vuex from 'vuex'
import { updateInputByOldAndNewReference } from '../services/update.service'
import { getGenderForName } from '../services/gender.service'
import { getCultureForName } from '../services/culture.service'
import { getClazzForName } from '../services/clazz.service'
import { getCraftForName } from '../services/craft.service'
import { updateImpact, getImpactForName } from '../services/impact.service'
import { getAttributePoints, getAttributesBonus, getAttributesPointsMax, getAttributesPointsMin } from '../services/attribute.service'
import { getBaseValuesBonus, getBaseValues, getClassModificatorHealth, getClassModificatorSoul } from '../services/baseValue.service'
import { getSharedSkillPointsMax, getSharedSkillPointsMin, getSkillPoints } from '../services/skillpoint.service'
import { getWeaponSkillPoints, getWeaponSkillsBonus, getWeaponSkillPointsMax, getWeaponSkillPointsMin } from '../services/weaponSkills.service'
import { getBodySkillPoints, getBodySkillPointsBonus, getBodySkillPointsMax, getBodySkillPointsMin } from '../services/bodySkills.service'
import { getMentalSkillPoints, getMentalSkillPointsBonus, getMentalSkillPointsMax, getMentalSkillsPointsMin } from '../services/mentalSkills.service'
import { getTalentPoints } from '../services/talent.service'
import { getSpellPoints } from '../services/spell.service'
import { getRunePoints } from '../services/rune.service'
import { getHairColorForName, getEyeColorForName, getSkinColorForName } from '../services/personality.service'
import {
  getAmuletForIndex,
  getAmuletForName,
  getArmorForIndex,
  getArmorForName,
  getMeleeWeaponForIndex,
  getMeleeWeaponForName,
  getRangedWeaponForIndex,
  getRangedWeaponForName,
  getRingForIndex,
  getRingForName,
  getShieldForIndex,
  getShieldForName,
  getItemForName
} from '../services/items.service'
import { loadCharacters } from '../services/account.service'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loggedIn: false,
    accountCharacters: [],
    easterEggs: false,
    drawer: false,
    map: false,
    character: {
      personality: {
        name: '',
        background: '',
        gender: '',
        culture: '',
        clazz: '',
        caster: false,
        hairColor: '',
        skinColor: '',
        eyeColor: '',
        size: 0,
        weight: 0,
        age: 0,
        experience: 0,
        level: 1
      },
      attributePoints: 35,
      attributes: {
        audacity: 8,
        thinking: 8,
        stomachFeeling: 8,
        characterStrength: 8,
        handSkill: 8,
        bodySkill: 8,
        condition: 8,
        muscleStrength: 8
      },
      attributesBonus: {
        audacity: 0,
        thinking: 0,
        stomachFeeling: 0,
        characterStrength: 0,
        handSkill: 0,
        bodySkill: 0,
        condition: 0,
        muscleStrength: 0
      },
      attributesMin: {
        audacity: 8,
        thinking: 8,
        stomachFeeling: 8,
        characterStrength: 8,
        handSkill: 8,
        bodySkill: 8,
        condition: 8,
        muscleStrength: 8
      },
      attributesMax: {
        audacity: 16,
        thinking: 16,
        stomachFeeling: 16,
        characterStrength: 16,
        handSkill: 16,
        bodySkill: 16,
        condition: 16,
        muscleStrength: 16
      },
      clazzModificatorHealth: 0,
      clazzModificatorSoul: 0,
      baseValues: {
        attack: 0,
        defense: 0,
        distance: 0,
        dodge: 0,
        magicalResistance: 0,
        magicalPower: 0,
        health: 0,
        soul: 0
      },
      baseValuesBonus: {
        attack: 0,
        defense: 0,
        distance: 0,
        dodge: 0,
        magicalResistance: 0,
        magicalPower: 0,
        health: 0,
        soul: 0
      },
      skillPoints: 0,
      weaponSkillPoints: 0,
      bodySkillPoints: 0,
      mentalSkillPoints: 0,
      sharedSkillPoints: {
        skillPointsToWeaponSkills: 0,
        skillPointsToBodySkills: 0,
        skillPointsToMentalSkills: 0,
        skillPointsToRunes: 0,
        weaponSkillsToBodySkills: 0,
        weaponSkillsToMentalSkills: 0,
        weaponSkillsToRunes: 0,
        bodySkillsToWeaponSkills: 0,
        bodySkillsToMentalSkills: 0,
        bodySkillsToRunes: 0,
        mentalSkillsToWeaponSkills: 0,
        mentalSkillsToBodySkills: 0,
        mentalSkillsToRunes: 0
      },
      sharedSkillPointsMin: {
        skillPointsToWeaponSkills: 0,
        skillPointsToBodySkills: 0,
        skillPointsToMentalSkills: 0,
        skillPointsToRunes: 0,
        weaponSkillsToBodySkills: 0,
        weaponSkillsToMentalSkills: 0,
        weaponSkillsToRunes: 0,
        bodySkillsToWeaponSkills: 0,
        bodySkillsToMentalSkills: 0,
        bodySkillsToRunes: 0,
        mentalSkillsToWeaponSkills: 0,
        mentalSkillsToBodySkills: 0,
        mentalSkillsToRunes: 0
      },
      sharedSkillPointsMax: {
        skillPointsToWeaponSkills: 0,
        skillPointsToBodySkills: 0,
        skillPointsToMentalSkills: 0,
        skillPointsToRunes: 0,
        weaponSkillsToBodySkills: 0,
        weaponSkillsToMentalSkills: 0,
        weaponSkillsToRunes: 0,
        bodySkillsToWeaponSkills: 0,
        bodySkillsToMentalSkills: 0,
        bodySkillsToRunes: 0,
        mentalSkillsToWeaponSkills: 0,
        mentalSkillsToBodySkills: 0,
        mentalSkillsToRunes: 0
      },
      weaponSkills: {
        dodge: 0,
        axesAndHatchets: 0,
        flail: 0,
        hammersAndClubs: 0,
        curvedSword: 0,
        dagger: 0,
        swords: 0,
        spears: 0,
        stabWeapons: 0,
        unarmed: 0,
        harantzu: 0,
        crossbows: 0,
        bows: 0,
        throwingWeapons: 0
      },
      weaponSkillsBonus: {
        dodge: 0,
        axesAndHatchets: 0,
        flail: 0,
        hammersAndClubs: 0,
        curvedSword: 0,
        dagger: 0,
        swords: 0,
        spears: 0,
        stabWeapons: 0,
        unarmed: 0,
        harantzu: 0,
        crossbows: 0,
        bows: 0,
        throwingWeapons: 0
      },
      weaponSkillsMin: {
        dodge: 0,
        axesAndHatchets: 0,
        flail: 0,
        hammersAndClubs: 0,
        curvedSword: 0,
        dagger: 0,
        swords: 0,
        spears: 0,
        stabWeapons: 0,
        unarmed: 0,
        harantzu: 0,
        crossbows: 0,
        bows: 0,
        throwingWeapons: 0
      },
      weaponSkillsMax: {
        dodge: 0,
        axesAndHatchets: 0,
        flail: 0,
        hammersAndClubs: 0,
        curvedSword: 0,
        dagger: 0,
        swords: 0,
        spears: 0,
        stabWeapons: 0,
        unarmed: 0,
        harantzu: 0,
        crossbows: 0,
        bows: 0,
        throwingWeapons: 0
      },
      bodySkills: {
        athletics: 0,
        acrobatics: 0,
        craft: 0,
        medicine: 0,
        stealth: 0,
        cooking: 0,
        mechanics: 0,
        wildLife: 0
      },
      bodySkillsBonus: {
        athletics: 0,
        acrobatics: 0,
        craft: 0,
        medicine: 0,
        stealth: 0,
        cooking: 0,
        mechanics: 0,
        wildLife: 0
      },
      bodySkillsMin: {
        athletics: 0,
        acrobatics: 0,
        craft: 0,
        medicine: 0,
        stealth: 0,
        cooking: 0,
        mechanics: 0,
        wildLife: 0
      },
      bodySkillsMax: {
        athletics: 0,
        acrobatics: 0,
        craft: 0,
        medicine: 0,
        stealth: 0,
        cooking: 0,
        mechanics: 0,
        wildLife: 0
      },
      mentalSkills: {
        alleyKnowledge: 0,
        historicalKnowledge: 0,
        trading: 0,
        poisons: 0,
        magicScience: 0,
        religion: 0,
        persuation: 0,
        perception: 0
      },
      mentalSkillsBonus: {
        alleyKnowledge: 0,
        historicalKnowledge: 0,
        trading: 0,
        poisons: 0,
        magicScience: 0,
        religion: 0,
        persuation: 0,
        perception: 0
      },
      mentalSkillsMin: {
        alleyKnowledge: 0,
        historicalKnowledge: 0,
        trading: 0,
        poisons: 0,
        magicScience: 0,
        religion: 0,
        persuation: 0,
        perception: 0
      },
      mentalSkillsMax: {
        alleyKnowledge: 0,
        historicalKnowledge: 0,
        trading: 0,
        poisons: 0,
        magicScience: 0,
        religion: 0,
        persuation: 0,
        perception: 0
      },
      talentPoints: 0,
      talents: [],
      spellPoints: 0,
      spells: [],
      runePoints: 0,
      runes: [
        {
          name: 'fear',
          level: '1'
        },
        {
          name: 'attribute',
          level: '1'
        },
        {
          name: 'repeal',
          level: '1'
        },
        {
          name: 'blindness',
          level: '1'
        },
        {
          name: 'darkness',
          level: '1'
        },
        {
          name: 'ice',
          level: '1'
        },
        {
          name: 'fire',
          level: '1'
        },
        {
          name: 'heal',
          level: '1'
        },
        {
          name: 'control',
          level: '1'
        },
        {
          name: 'light',
          level: '1'
        },
        {
          name: 'projection',
          level: '1'
        },
        {
          name: 'sleep',
          level: '1'
        },
        {
          name: 'pain',
          level: '1'
        },
        {
          name: 'speed',
          level: '1'
        },
        {
          name: 'protection',
          level: '1'
        },
        {
          name: 'soulBurn',
          level: '1'
        },
        {
          name: 'silence',
          level: '1'
        },
        {
          name: 'resistance',
          level: '1'
        },
        {
          name: 'petrification',
          level: '1'
        },
        {
          name: 'invisibility',
          level: '1'
        }
      ],
      crafting: '',
      languages: [],
      inventory: {
        weapons: {
          melee: ['', '', ''],
          ranged: ['', '']
        },
        armor: ['', '', ''],
        shield: ['', ''],
        jewellery: {
          rings: ['', ''],
          amulets: ['']
        },
        items: [],
        money: [0, 0, 0, 0]
      },
      impact: []
    },
    states: [
      {
        personality: {
          name: '',
          gender: '',
          culture: '',
          clazz: '',
          caster: false,
          hairColor: '',
          skinColor: '',
          eyeColor: '',
          size: 0,
          weight: 0,
          age: 0,
          experience: 0,
          level: 1
        },
        attributePoints: 35,
        attributes: {
          audacity: 8,
          thinking: 8,
          stomachFeeling: 8,
          characterStrength: 8,
          handSkill: 8,
          bodySkill: 8,
          condition: 8,
          muscleStrength: 8
        },
        attributesBonus: {
          audacity: 0,
          thinking: 0,
          stomachFeeling: 0,
          characterStrength: 0,
          handSkill: 0,
          bodySkill: 0,
          condition: 0,
          muscleStrength: 0
        },
        attributesMin: {
          audacity: 8,
          thinking: 8,
          stomachFeeling: 8,
          characterStrength: 8,
          handSkill: 8,
          bodySkill: 8,
          condition: 8,
          muscleStrength: 8
        },
        attributesMax: {
          audacity: 16,
          thinking: 16,
          stomachFeeling: 16,
          characterStrength: 16,
          handSkill: 16,
          bodySkill: 16,
          condition: 16,
          muscleStrength: 16
        },
        clazzModificatorHealth: 0,
        clazzModificatorSoul: 0,
        baseValues: {
          attack: 0,
          defense: 0,
          distance: 0,
          dodge: 0,
          magicalResistance: 0,
          magicalPower: 0,
          health: 0,
          soul: 0
        },
        baseValuesBonus: {
          attack: 0,
          defense: 0,
          distance: 0,
          dodge: 0,
          magicalResistance: 0,
          magicalPower: 0,
          health: 0,
          soul: 0
        },
        skillPoints: 0,
        weaponSkillPoints: 0,
        bodySkillPoints: 0,
        mentalSkillPoints: 0,
        sharedSkillPoints: {
          skillPointsToWeaponSkills: 0,
          skillPointsToBodySkills: 0,
          skillPointsToMentalSkills: 0,
          skillPointsToRunes: 0,
          weaponSkillsToBodySkills: 0,
          weaponSkillsToMentalSkills: 0,
          weaponSkillsToRunes: 0,
          bodySkillsToWeaponSkills: 0,
          bodySkillsToMentalSkills: 0,
          bodySkillsToRunes: 0,
          mentalSkillsToWeaponSkills: 0,
          mentalSkillsToBodySkills: 0,
          mentalSkillsToRunes: 0
        },
        sharedSkillPointsMin: {
          skillPointsToWeaponSkills: 0,
          skillPointsToBodySkills: 0,
          skillPointsToMentalSkills: 0,
          skillPointsToRunes: 0,
          weaponSkillsToBodySkills: 0,
          weaponSkillsToMentalSkills: 0,
          weaponSkillsToRunes: 0,
          bodySkillsToWeaponSkills: 0,
          bodySkillsToMentalSkills: 0,
          bodySkillsToRunes: 0,
          mentalSkillsToWeaponSkills: 0,
          mentalSkillsToBodySkills: 0,
          mentalSkillsToRunes: 0
        },
        sharedSkillPointsMax: {
          skillPointsToWeaponSkills: 0,
          skillPointsToBodySkills: 0,
          skillPointsToMentalSkills: 0,
          skillPointsToRunes: 0,
          weaponSkillsToBodySkills: 0,
          weaponSkillsToMentalSkills: 0,
          weaponSkillsToRunes: 0,
          bodySkillsToWeaponSkills: 0,
          bodySkillsToMentalSkills: 0,
          bodySkillsToRunes: 0,
          mentalSkillsToWeaponSkills: 0,
          mentalSkillsToBodySkills: 0,
          mentalSkillsToRunes: 0
        },
        weaponSkills: {
          dodge: 0,
          axesAndHatchets: 0,
          flail: 0,
          hammersAndClubs: 0,
          curvedSword: 0,
          dagger: 0,
          swords: 0,
          spears: 0,
          stabWeapons: 0,
          unarmed: 0,
          harantzu: 0,
          crossbows: 0,
          bows: 0,
          throwingWeapons: 0
        },
        weaponSkillsBonus: {
          dodge: 0,
          axesAndHatchets: 0,
          flail: 0,
          hammersAndClubs: 0,
          curvedSword: 0,
          dagger: 0,
          swords: 0,
          spears: 0,
          stabWeapons: 0,
          unarmed: 0,
          harantzu: 0,
          crossbows: 0,
          bows: 0,
          throwingWeapons: 0
        },
        weaponSkillsMin: {
          dodge: 0,
          axesAndHatchets: 0,
          flail: 0,
          hammersAndClubs: 0,
          curvedSword: 0,
          dagger: 0,
          swords: 0,
          spears: 0,
          stabWeapons: 0,
          unarmed: 0,
          harantzu: 0,
          crossbows: 0,
          bows: 0,
          throwingWeapons: 0
        },
        weaponSkillsMax: {
          dodge: 0,
          axesAndHatchets: 0,
          flail: 0,
          hammersAndClubs: 0,
          curvedSword: 0,
          dagger: 0,
          swords: 0,
          spears: 0,
          stabWeapons: 0,
          unarmed: 0,
          harantzu: 0,
          crossbows: 0,
          bows: 0,
          throwingWeapons: 0
        },
        bodySkills: {
          athletics: 0,
          acrobatics: 0,
          craft: 0,
          medicine: 0,
          stealth: 0,
          cooking: 0,
          mechanics: 0,
          wildLife: 0
        },
        bodySkillsBonus: {
          athletics: 0,
          acrobatics: 0,
          craft: 0,
          medicine: 0,
          stealth: 0,
          cooking: 0,
          mechanics: 0,
          wildLife: 0
        },
        bodySkillsMin: {
          athletics: 0,
          acrobatics: 0,
          craft: 0,
          medicine: 0,
          stealth: 0,
          cooking: 0,
          mechanics: 0,
          wildLife: 0
        },
        bodySkillsMax: {
          athletics: 0,
          acrobatics: 0,
          craft: 0,
          medicine: 0,
          stealth: 0,
          cooking: 0,
          mechanics: 0,
          wildLife: 0
        },
        mentalSkills: {
          alleyKnowledge: 0,
          historicalKnowledge: 0,
          trading: 0,
          poisons: 0,
          magicScience: 0,
          religion: 0,
          persuation: 0,
          perception: 0
        },
        mentalSkillsBonus: {
          alleyKnowledge: 0,
          historicalKnowledge: 0,
          trading: 0,
          poisons: 0,
          magicScience: 0,
          religion: 0,
          persuation: 0,
          perception: 0
        },
        mentalSkillsMin: {
          alleyKnowledge: 0,
          historicalKnowledge: 0,
          trading: 0,
          poisons: 0,
          magicScience: 0,
          religion: 0,
          persuation: 0,
          perception: 0
        },
        mentalSkillsMax: {
          alleyKnowledge: 0,
          historicalKnowledge: 0,
          trading: 0,
          poisons: 0,
          magicScience: 0,
          religion: 0,
          persuation: 0,
          perception: 0
        },
        talentPoints: 0,
        talents: [],
        spellPoints: 0,
        spells: [],
        runePoints: 0,
        runes: [
          {
            name: 'fear',
            level: '1'
          },
          {
            name: 'attribute',
            level: '1'
          },
          {
            name: 'repeal',
            level: '1'
          },
          {
            name: 'blindness',
            level: '1'
          },
          {
            name: 'darkness',
            level: '1'
          },
          {
            name: 'ice',
            level: '1'
          },
          {
            name: 'fire',
            level: '1'
          },
          {
            name: 'heal',
            level: '1'
          },
          {
            name: 'control',
            level: '1'
          },
          {
            name: 'light',
            level: '1'
          },
          {
            name: 'projection',
            level: '1'
          },
          {
            name: 'sleep',
            level: '1'
          },
          {
            name: 'pain',
            level: '1'
          },
          {
            name: 'speed',
            level: '1'
          },
          {
            name: 'protection',
            level: '1'
          },
          {
            name: 'soulBurn',
            level: '1'
          },
          {
            name: 'silence',
            level: '1'
          },
          {
            name: 'resistance',
            level: '1'
          },
          {
            name: 'petrification',
            level: '1'
          },
          {
            name: 'invisibility',
            level: '1'
          }
        ],
        crafting: '',
        languages: [],
        inventory: {
          weapons: {
            melee: ['', '', ''],
            ranged: ['', '']
          },
          armor: ['', '', ''],
          shield: ['', ''],
          jewellery: {
            rings: ['', ''],
            amulets: ['']
          },
          items: [],
          money: [0, 0, 0, 0]
        },
        impact: []
      }
    ],
    configuration: null,
    genders: null,
    cultures: null,
    clazzes: null,
    hairColors: null,
    eyeColors: null,
    skinColors: null,
    talents: null,
    spells: null,
    runes: null,
    impact: null,
    craftings: null,
    languages: null,
    items: null,
    names: null,
    translations: null,
    account: {
      loggedIn: false,
      accessToken: null,
      accessTokenRefreshing: false,
      characters: [],
      messages: {
        registrationSuccess: false,
        saveSuccess: false,
        saveError: false,
        registrationError: false,
        loginError: false,
        timeout: 5000
      }
    }
  },
  mutations: {
    SET_CONFIGURATION(state, configuration) {
      state.configuration = configuration
    },
    SET_GENDERS(state, genders) {
      state.genders = genders
    },
    SET_INVENTORY(state, inventory) {
      state.character.inventory = inventory
    },
    SET_CULTURES(state, cultures) {
      state.cultures = cultures
    },
    SET_CLAZZES(state, clazzes) {
      state.clazzes = clazzes
    },
    SET_HAIR_COLORS(state, hairColors) {
      state.hairColors = hairColors
    },
    SET_EYE_COLORS(state, eyeColors) {
      state.eyeColors = eyeColors
    },
    SET_SKIN_COLORS(state, skinColors) {
      state.skinColors = skinColors
    },
    SET_TALENTS(state, talents) {
      state.talents = talents
    },
    SET_SPELLS(state, spells) {
      state.spells = spells
    },
    SET_RUNES(state, runes) {
      state.runes = runes
    },
    SET_IMPACT(state, impact) {
      state.impact = impact
    },
    SET_CRAFTS(state, crafts) {
      state.craftings = crafts
    },
    SET_LANGUAGES(state, languages) {
      state.languages = languages
    },
    SET_ITEMS(state, items) {
      state.items = items
    },
    SET_NAMES(state, names) {
      state.names = names
    },
    SET_TRANSLATIONS(state, translations) {
      state.translations = translations
    },
    SET_CHARACTER(state, character) {
      state.character = character
    },
    SET_CHARACTER_STATES(state, states) {
      state.states = states
    },
    SET_CHARACTER_LEVEL(state, level) {
      state.character.personality.level = level
    },
    SET_CHARACTER_EXPERIENCE(state, experience) {
      state.character.personality.experience = experience
    },
    SET_CHARACTER_NAME(state, name) {
      state.character.personality.name = name
    },
    SET_CHARACTER_BACKGROUND(state, background) {
      state.character.personality.background = background
    },
    SET_CHARACTER_GENDER(state, gender) {
      state.character.personality.gender = gender
    },
    SET_CHARACTER_CULTURE(state, culture) {
      state.character.personality.culture = culture
    },
    SET_CHARACTER_CLAZZ(state, clazz) {
      state.character.personality.clazz = clazz
    },
    SET_CHARACTER_CASTER(state, caster) {
      state.character.personality.caster = caster
    },
    SET_CHARACTER_CRAFT(state, craft) {
      state.character.crafting = craft
    },
    SET_CHARACTER_HAIR_COLOR(state, hairColor) {
      state.character.personality.hairColor = hairColor
    },
    SET_CHARACTER_EYE_COLOR(state, eyeColor) {
      state.character.personality.eyeColor = eyeColor
    },
    SET_CHARACTER_SKIN_COLOR(state, skinColor) {
      state.character.personality.skinColor = skinColor
    },
    SET_CHARACTER_AGE(state, age) {
      state.character.personality.age = age
    },
    SET_CHARACTER_SIZE(state, size) {
      state.character.personality.size = size
    },
    SET_CHARACTER_WEIGHT(state, weight) {
      state.character.personality.weight = weight
    },
    SET_CHARACTER_LANGUAGES(state, languages) {
      state.character.languages = languages
    },
    SET_CHARACTER_ATTRIBUTE_POINTS(state, attributePoints) {
      state.character.attributePoints = attributePoints
    },
    SET_CHARACTER_ATTRIBUTES(state, attributes) {
      state.character.attributes = attributes
    },
    SET_CHARACTER_ATTRIBUTES_BONUS(state, attributesBonus) {
      state.character.attributesBonus = attributesBonus
    },
    SET_CHARACTER_ATTRIBUTES_MIN(state, attributesMin) {
      state.character.attributesMin = attributesMin
    },
    SET_CHARACTER_ATTRIBUTES_MAX(state, attributesMax) {
      state.character.attributesMax = attributesMax
    },
    SET_CHARACTER_CLAZZ_MODIFICATOR_HEALTH(state, clazzModificatorHealth) {
      state.character.clazzModificatorHealth = clazzModificatorHealth
    },
    SET_CHARACTER_CLAZZ_MODIFICATOR_SOUL(state, clazzModificatorSoul) {
      state.character.clazzModificatorSoul = clazzModificatorSoul
    },
    SET_CHARACTER_BASE_VALUES(state, baseValues) {
      state.character.baseValues = baseValues
    },
    SET_CHARACTER_BASE_VALUES_BONUS(state, baseValuesBonus) {
      state.character.baseValuesBonus = baseValuesBonus
    },
    SET_CHARACTER_SKILL_POINTS(state, skillPoints) {
      state.character.skillPoints = skillPoints
    },
    SET_CHARACTER_SHARED_SKILLS_POINTS(state, sharedSkillPoints) {
      state.character.sharedSkillPoints = sharedSkillPoints
    },
    SET_CHARACTER_SHARED_SKILLS_POINTS_MIN(state, sharedSkillPointsMin) {
      state.character.sharedSkillPointsMin = sharedSkillPointsMin
    },
    SET_CHARACTER_SHARED_SKILLS_POINTS_MAX(state, sharedSkillPointsMax) {
      state.character.sharedSkillPointsMax = sharedSkillPointsMax
    },
    SET_CHARACTER_WEAPON_SKILLS_POINTS(state, weaponSkillPoints) {
      state.character.weaponSkillPoints = weaponSkillPoints
    },
    SET_CHARACTER_WEAPON_SKILLS_POINTS_MIN(state, weaponSkillsMin) {
      state.character.weaponSkillsMin = weaponSkillsMin
    },
    SET_CHARACTER_WEAPON_SKILLS_POINTS_MAX(state, weaponSkillsMax) {
      state.character.weaponSkillsMax = weaponSkillsMax
    },
    SET_CHARACTER_WEAPON_SKILLS(state, weaponSkills) {
      state.character.weaponSkills = weaponSkills
    },
    SET_CHARACTER_WEAPON_SKILLS_BONUS(state, weaponSkillsBonus) {
      state.character.weaponSkillsBonus = weaponSkillsBonus
    },
    SET_CHARACTER_BODY_SKILLS_POINTS(state, bodySkillPoints) {
      state.character.bodySkillPoints = bodySkillPoints
    },
    SET_CHARACTER_BODY_SKILLS_POINTS_MIN(state, bodySkillsMin) {
      state.character.bodySkillsMin = bodySkillsMin
    },
    SET_CHARACTER_BODY_SKILLS_POINTS_MAX(state, bodySkillsMax) {
      state.character.bodySkillsMax = bodySkillsMax
    },
    SET_CHARACTER_BODY_SKILLS(state, bodySkills) {
      state.character.bodySkills = bodySkills
    },
    SET_CHARACTER_BODY_SKILLS_BONUS(state, bodySkillsBonus) {
      state.character.bodySkillsBonus = bodySkillsBonus
    },
    SET_CHARACTER_MENTAL_SKILLS_POINTS(state, mentalSkillPoints) {
      state.character.mentalSkillPoints = mentalSkillPoints
    },
    SET_CHARACTER_MENTAL_SKILLS_POINTS_MIN(state, mentalSkillsMin) {
      state.character.mentalSkillsMin = mentalSkillsMin
    },
    SET_CHARACTER_MENTAL_SKILLS_POINTS_MAX(state, mentalSkillsMax) {
      state.character.mentalSkillsMax = mentalSkillsMax
    },
    SET_CHARACTER_MENTAL_SKILLS(state, mentalSkills) {
      state.character.mentalSkills = mentalSkills
    },
    SET_CHARACTER_MENTAL_SKILLS_BONUS(state, mentalSkillsBonus) {
      state.character.mentalSkillsBonus = mentalSkillsBonus
    },
    SET_CHARACTER_TALENT_POINTS(state, talentPoints) {
      state.character.talentPoints = talentPoints
    },
    SET_CHARACTER_TALENTS(state, talents) {
      state.character.talents = talents
    },
    SET_CHARACTER_SPELL_POINTS(state, spellPoints) {
      state.character.spellPoints = spellPoints
    },
    SET_CHARACTER_SPELLS(state, spells) {
      state.character.spells = spells
    },
    SET_CHARACTER_RUNE_POINTS(state, runePoints) {
      state.character.runePoints = runePoints
    },
    SET_CHARACTER_RUNES(state, runes) {
      state.character.runes = runes
    },
    SET_CHARACTER_IMPACT(state, impact) {
      state.character.impact = impact
    },
    SET_CHARACTER_ARMOR(state, armor) {
      state.character.inventory.armor = armor
    },
    SET_CHARACTER_SHIELD(state, shield) {
      state.character.inventory.shield = shield
    },
    SET_CHARACTER_WEAPONS_MELEE(state, melee) {
      state.character.inventory.weapons.melee = melee
    },
    SET_CHARACTER_WEAPONS_RANGED(state, ranged) {
      state.character.inventory.weapons.ranged = ranged
    },
    SET_CHARACTER_RINGS(state, rings) {
      state.character.inventory.jewellery.rings = rings
    },
    SET_CHARACTER_AMULETS(state, amulets) {
      state.character.inventory.jewellery.amulets = amulets
    },
    SET_CHARACTER_ITEMS(state, items) {
      state.character.inventory.items = items
    },
    SET_ACCOUNT_CHARACTERS(state, accountCharacters) {
      state.account.characters = accountCharacters
    },
    SET_LOGGED_IN(state, loggedIn) {
      state.account.loggedIn = loggedIn
    }
  },
  actions: {
    setTranslations(context, translations) {
      context.commit('SET_TRANSLATIONS', translations)
    },
    setConfiguration(context, configuration) {
      context.commit('SET_CONFIGURATION', configuration)
      context.dispatch('update')
    },
    setCharacter(context, character) {
      context.commit('SET_CHARACTER', character)
      context.dispatch('update')
    },
    setCharacterStates(context, states) {
      context.commit('SET_CHARACTER_STATES', states)
      context.dispatch('update')
    },
    setCharacterLevel(context, level) {
      context.commit('SET_CHARACTER_LEVEL', level)
    },
    setCharacterExperience(context, experience) {
      context.commit('SET_CHARACTER_EXPERIENCE', experience)
    },
    setCharacterName(context, name) {
      context.commit('SET_CHARACTER_NAME', name)
      context.dispatch('update')
    },
    setCharacterBackground(context, background) {
      context.commit('SET_CHARACTER_BACKGROUND', background)
      context.dispatch('update')
    },
    setCharacterGender(context, genderName) {
      const oldReference = getGenderForName(context.getters.characterGender)
      const newReference = getGenderForName(genderName)
      updateInputByOldAndNewReference(oldReference, newReference)
      context.commit('SET_CHARACTER_GENDER', genderName)
      context.dispatch('update')
    },
    setCharacterCulture(context, cultureName) {
      const oldReference = getCultureForName(context.getters.characterCulture)
      const newReference = getCultureForName(cultureName)
      updateInputByOldAndNewReference(oldReference, newReference)
      context.commit('SET_CHARACTER_CULTURE', cultureName)
      context.dispatch('update')
    },
    setCharacterClazz(context, clazzName) {
      const oldReference = getClazzForName(context.getters.characterClazz)
      const newReference = getClazzForName(clazzName)
      updateInputByOldAndNewReference(oldReference, newReference)
      context.commit('SET_CHARACTER_CLAZZ', clazzName)
      context.dispatch('update')
    },
    setCharacterCraft(context, craftName) {
      const oldReference = getCraftForName(context.getters.characterCraft)
      const newReference = getCraftForName(craftName)
      updateInputByOldAndNewReference(oldReference, newReference)
      context.commit('SET_CHARACTER_CRAFT', craftName)
      context.dispatch('update')
    },
    setCharacterHairColor(context, hairColor) {
      const oldReference = getHairColorForName(context.getters.characterHairColor)
      const newReference = getHairColorForName(hairColor)
      updateInputByOldAndNewReference(oldReference, newReference)
      context.commit('SET_CHARACTER_HAIR_COLOR', hairColor)
      context.dispatch('update')
    },
    setCharacterEyeColor(context, eyeColor) {
      const oldReference = getEyeColorForName(context.getters.characterEyeColor)
      const newReference = getEyeColorForName(eyeColor)
      updateInputByOldAndNewReference(oldReference, newReference)
      context.commit('SET_CHARACTER_EYE_COLOR', eyeColor)
      context.dispatch('update')
    },
    setCharacterSkinColor(context, skinColor) {
      const oldReference = getSkinColorForName(context.getters.characterSkinColor)
      const newReference = getSkinColorForName(skinColor)
      updateInputByOldAndNewReference(oldReference, newReference)
      context.commit('SET_CHARACTER_SKIN_COLOR', skinColor)
      context.dispatch('update')
    },
    setCharacterAge(context, age) {
      context.commit('SET_CHARACTER_AGE', age)
      context.dispatch('update')
    },
    setCharacterSize(context, weight) {
      context.commit('SET_CHARACTER_SIZE', weight)
      context.dispatch('update')
    },
    setCharacterWeight(context, size) {
      context.commit('SET_CHARACTER_WEIGHT', size)
      context.dispatch('update')
    },
    addCharacterLanguage(context, languageName) {
      context.commit('SET_CHARACTER_LANGUAGES', [...context.getters.characterLanguages, languageName])
      context.dispatch('update')
    },
    removeCharacterLanguage(context, languageName) {
      context.commit(
        'SET_CHARACTER_LANGUAGES',
        context.getters.characterLanguages.filter(language => language !== languageName)
      )
      context.dispatch('update')
    },
    setCharacterAttributes(context, attributes) {
      context.commit('SET_CHARACTER_ATTRIBUTES', attributes)
      context.dispatch('update')
    },
    setCharacterSharedSkillPoints(context, sharedSkillPoints) {
      context.commit('SET_CHARACTER_SHARED_SKILLS_POINTS', sharedSkillPoints)
      context.dispatch('update')
    },
    setCharacterWeaponSkills(context, weaponSkills) {
      context.commit('SET_CHARACTER_WEAPON_SKILLS', weaponSkills)
      context.dispatch('update')
    },
    setCharacterBodySkills(context, bodySkills) {
      context.commit('SET_CHARACTER_BODY_SKILLS', bodySkills)
      context.dispatch('update')
    },
    setCharacterMentalSkills(context, mentalSkills) {
      context.commit('SET_CHARACTER_MENTAL_SKILLS', mentalSkills)
      context.dispatch('update')
    },
    addCharacterTalent(context, talentName) {
      context.commit('SET_CHARACTER_TALENTS', [...context.getters.characterTalents, talentName])
      context.dispatch('update')
    },
    removeCharacterTalent(context, talentName) {
      context.commit(
        'SET_CHARACTER_TALENTS',
        context.getters.characterTalents.filter(talent => talent !== talentName)
      )
      context.dispatch('update')
    },
    addCharacterSpell(context, spellName) {
      context.commit('SET_CHARACTER_SPELLS', [...context.getters.characterSpells, spellName])
      context.dispatch('update')
    },
    removeCharacterSpell(context, spellName) {
      context.commit(
        'SET_CHARACTER_SPELLS',
        context.getters.characterSpells.filter(spell => spell !== spellName)
      )
      context.dispatch('update')
    },
    addCharacterRune(context, runeToAdd) {
      context.commit('SET_CHARACTER_RUNES', [...context.getters.characterSpells, runeToAdd])
      context.dispatch('update')
    },
    removeCharacterRune(context, runeToRemove) {
      context.commit(
        'SET_CHARACTER_RUNES',
        context.getters.characterRunes.filter(rune => rune.name === runeToRemove.name)
      )
      context.dispatch('update')
    },
    addCharacterImpact(context, impactName) {
      context.commit('SET_CHARACTER_IMPACT', [...context.getters.characterImpact, impactName])
      updateInputByOldAndNewReference(null, getImpactForName(impactName))
      context.dispatch('update')
    },
    removeCharacterImpact(context, impactName) {
      context.commit(
        'SET_CHARACTER_IMPACT',
        context.getters.characterImpact.filter(impact => impact !== impactName)
      )
      updateInputByOldAndNewReference(getImpactForName(impactName), null)
      context.dispatch('update')
    },
    setCharacterArmor(context, armor) {
      const oldReference = getArmorForIndex(armor.index)
      const newReference = getArmorForName(armor.name)
      updateInputByOldAndNewReference(oldReference, newReference)
      const newArmor = context.getters.characterArmor
      newArmor.splice(armor.index, 1, armor.name)
      context.commit('SET_CHARACTER_ARMOR', newArmor)
      context.dispatch('update')
    },
    setCharacterShield(context, shield) {
      const oldReference = getShieldForIndex(shield.index)
      const newReference = getShieldForName(shield.name)
      updateInputByOldAndNewReference(oldReference, newReference)
      const newShield = context.getters.characterShield
      newShield.splice(shield.index, 1, shield.name)
      context.commit('SET_CHARACTER_SHIELD', newShield)
      context.dispatch('update')
    },
    setCharacterWeaponMelee(context, melee) {
      const oldReference = getMeleeWeaponForIndex(melee.index)
      const newReference = getMeleeWeaponForName(melee.name)
      updateInputByOldAndNewReference(oldReference, newReference)
      const newMelee = context.getters.characterWeapons.melee
      newMelee.splice(melee.index, 1, melee.name)
      context.commit('SET_CHARACTER_WEAPONS_MELEE', newMelee)
      context.dispatch('update')
    },
    setCharacterWeaponRanged(context, ranged) {
      const oldReference = getRangedWeaponForIndex(ranged.index)
      const newReference = getRangedWeaponForName(ranged.name)
      updateInputByOldAndNewReference(oldReference, newReference)
      const newRanged = context.getters.characterWeapons.ranged
      newRanged.splice(ranged.index, 1, ranged.name)
      context.commit('SET_CHARACTER_WEAPONS_RANGED', newRanged)
      context.dispatch('update')
    },
    setCharacterRing(context, ring) {
      const oldReference = getRingForIndex(ring.index)
      const newReference = getRingForName(ring.name)
      updateInputByOldAndNewReference(oldReference, newReference)
      const newRing = context.getters.characterRings
      newRing.splice(ring.index, 1, ring.name)
      context.commit('SET_CHARACTER_RINGS', newRing)
      context.dispatch('update')
    },
    setCharacterAmulet(context, amulet) {
      const oldReference = getAmuletForIndex(amulet.index)
      const newReference = getAmuletForName(amulet.name)
      updateInputByOldAndNewReference(oldReference, newReference)
      const newAmulet = context.getters.characterAmulets
      newAmulet.splice(amulet.index, 1, amulet.name)
      context.commit('SET_CHARACTER_AMULETS', newAmulet)
      context.dispatch('update')
    },
    addCharacterItem(context, item) {
      const oldCharacterItems = context.getters.characterItems
      const newCharacterItems = context.getters.characterItems
      const foundEntry = oldCharacterItems.find(entry => entry.startsWith(item.id + '|' + item.name + '|'))
      if (!foundEntry) {
        const itemEntry = item.id + '|' + item.name + '|' + 1
        newCharacterItems.push(itemEntry)
      } else {
        const index = oldCharacterItems.indexOf(foundEntry)
        const itemEntrySplit = oldCharacterItems[index].split('|')
        let counter = parseInt(itemEntrySplit[2])
        counter = counter + 1
        newCharacterItems.splice(index, 1, item.id + '|' + item.name + '|' + counter)
      }
      updateInputByOldAndNewReference(null, getItemForName(item.id))
      context.commit('SET_CHARACTER_ITEMS', newCharacterItems)
      context.dispatch('update')
    },
    removeCharacterItem(context, itemId) {
      const oldCharacterItems = context.getters.characterItems
      const newCharacterItems = context.getters.characterItems
      const itemIndex = oldCharacterItems.indexOf(itemId)
      newCharacterItems.splice(itemIndex, 1)
      updateInputByOldAndNewReference(getItemForName(itemId.split('|')[0]), null)
      context.commit('SET_CHARACTER_ITEMS', newCharacterItems)
      context.dispatch('update')
    },
    update(context) {
      const isElf = context.getters.characterCulture !== undefined ? context.getters.characterCulture === 'elfs' : false
      const isRuneFighter = context.getters.characterClazz !== undefined ? context.getters.characterClazz === 'runeFighter' : false
      const isMage = context.getters.characterClazz !== undefined ? context.getters.characterClazz === 'magician' : false
      const isDilettanteMagician = context.getters.characterClazz !== undefined ? context.getters.characterClazz.startsWith('dilettanteMagician') : false
      context.commit('SET_CHARACTER_CASTER', isElf || isRuneFighter || isMage || isDilettanteMagician)

      context.commit('SET_CHARACTER_ATTRIBUTES_BONUS', getAttributesBonus())
      context.commit('SET_CHARACTER_ATTRIBUTE_POINTS', getAttributePoints())
      context.commit('SET_CHARACTER_ATTRIBUTES_MIN', getAttributesPointsMin())
      context.commit('SET_CHARACTER_ATTRIBUTES_MAX', getAttributesPointsMax())

      context.commit('SET_CHARACTER_CLAZZ_MODIFICATOR_HEALTH', getClassModificatorHealth())
      context.commit('SET_CHARACTER_CLAZZ_MODIFICATOR_SOUL', getClassModificatorSoul())

      context.commit('SET_CHARACTER_BASE_VALUES', getBaseValues())
      context.commit('SET_CHARACTER_BASE_VALUES_BONUS', getBaseValuesBonus())

      context.commit('SET_CHARACTER_SKILL_POINTS', getSkillPoints())

      context.commit('SET_CHARACTER_WEAPON_SKILLS_BONUS', getWeaponSkillsBonus())
      context.commit('SET_CHARACTER_WEAPON_SKILLS_POINTS', getWeaponSkillPoints())
      context.commit('SET_CHARACTER_WEAPON_SKILLS_POINTS_MIN', getWeaponSkillPointsMin())
      context.commit('SET_CHARACTER_WEAPON_SKILLS_POINTS_MAX', getWeaponSkillPointsMax())

      context.commit('SET_CHARACTER_BODY_SKILLS_BONUS', getBodySkillPointsBonus())
      context.commit('SET_CHARACTER_BODY_SKILLS_POINTS', getBodySkillPoints())
      context.commit('SET_CHARACTER_BODY_SKILLS_POINTS_MIN', getBodySkillPointsMin())
      context.commit('SET_CHARACTER_BODY_SKILLS_POINTS_MAX', getBodySkillPointsMax())

      context.commit('SET_CHARACTER_MENTAL_SKILLS_BONUS', getMentalSkillPointsBonus())
      context.commit('SET_CHARACTER_MENTAL_SKILLS_POINTS', getMentalSkillPoints())
      context.commit('SET_CHARACTER_MENTAL_SKILLS_POINTS_MIN', getMentalSkillsPointsMin())
      context.commit('SET_CHARACTER_MENTAL_SKILLS_POINTS_MAX', getMentalSkillPointsMax())

      context.commit('SET_CHARACTER_SHARED_SKILLS_POINTS_MIN', getSharedSkillPointsMin())
      context.commit('SET_CHARACTER_SHARED_SKILLS_POINTS_MAX', getSharedSkillPointsMax())

      context.commit('SET_CHARACTER_TALENT_POINTS', getTalentPoints())
      context.commit('SET_CHARACTER_SPELL_POINTS', getSpellPoints())
      context.commit('SET_CHARACTER_RUNE_POINTS', getRunePoints())

      updateImpact()
    },
    async loadData(context) {
      context.commit('SET_CONFIGURATION', require('@/assets/data/configuration.json'))
      context.commit('SET_GENDERS', require('@/assets/data/genders.json'))
      context.commit('SET_CULTURES', require('@/assets/data/cultures.json'))
      context.commit('SET_CLAZZES', require('@/assets/data/clazzes.json'))
      context.commit('SET_HAIR_COLORS', require('@/assets/data/colors/hair.json'))
      context.commit('SET_EYE_COLORS', require('@/assets/data/colors/eye.json'))
      context.commit('SET_SKIN_COLORS', require('@/assets/data/colors/skin.json'))
      context.commit('SET_TALENTS', require('@/assets/data/talents.json'))
      context.commit('SET_SPELLS', require('@/assets/data/spells.json'))
      context.commit('SET_RUNES', require('@/assets/data/runes.json'))
      context.commit('SET_IMPACT', require('@/assets/data/impact.json'))
      context.commit('SET_CRAFTS', require('@/assets/data/crafts.json'))
      context.commit('SET_LANGUAGES', require('@/assets/data/languages.json'))
      context.commit('SET_ITEMS', {
        weapons: {
          melee: require('@/assets/data/items/weapons/melee.json'),
          ranged: require('@/assets/data/items/weapons/ranged.json')
        },
        armor: require('@/assets/data/items/defense/armor.json'),
        shield: require('@/assets/data/items/defense/shield.json'),
        jewellery: {
          rings: require('@/assets/data/items/jewellery/rings.json'),
          amulets: require('@/assets/data/items/jewellery/amulets.json')
        },
        others: {
          alchemicalSubstances: require('@/assets/data/items/others/alchemicalSubstances.json'),
          containers: require('@/assets/data/items/others/containers.json'),
          lighting: require('@/assets/data/items/others/lighting.json'),
          gems: require('@/assets/data/items/others/gems.json'),
          vehicles: require('@/assets/data/items/others/vehicles.json'),
          inTheTavern: require('@/assets/data/items/others/inTheTavern.json'),
          instruments: require('@/assets/data/items/others/instruments.json'),
          bodyCare: require('@/assets/data/items/others/bodyCare.json'),
          metals: require('@/assets/data/items/others/metals.json'),
          food: require('@/assets/data/items/others/food.json'),
          horses: require('@/assets/data/items/others/horses.json'),
          travelAndTransport: require('@/assets/data/items/others/travelAndTransport.json'),
          stationeryAndPrecisionMechanics: require('@/assets/data/items/others/stationeryAndPrecisionMechanics.json'),
          ropeGear: require('@/assets/data/items/others/ropeGear.json'),
          petSupplies: require('@/assets/data/items/others/petSupplies.json'),
          potions: require('@/assets/data/items/others/potions.json'),
          fabric: require('@/assets/data/items/others/fabric.json'),
          tools: require('@/assets/data/items/others/tools.json'),
          weaponAccessories: require('@/assets/data/items/others/weaponAccessories.json'),
          storyTelling: require('@/assets/data/items/others/storyTelling.json')
        }
      })
      context.commit('SET_NAMES', {
        male: {
          humanDalkin: require('@/assets/data/names/male/humanDalkin.json'),
          humanGjaskalar: require('@/assets/data/names/male/humanGjaskalar.json'),
          humanIshadi: require('@/assets/data/names/male/humanIshadi.json'),
          humanBashtir: require('@/assets/data/names/male/humanBashtir.json'),
          humanInrit: require('@/assets/data/names/male/humanInrit.json'),
          lizards: require('@/assets/data/names/male/lizards.json'),
          felkartigris: require('@/assets/data/names/male/felkartigris.json'),
          felkarpanthar: require('@/assets/data/names/male/felkarpanthar.json'),
          gnome: require('@/assets/data/names/male/gnome.json'),
          elfs: require('@/assets/data/names/male/humanDalkin.json'),
          dwarf: require('@/assets/data/names/male/humanDalkin.json')
        },
        female: {
          humanDalkin: require('@/assets/data/names/female/humanDalkin.json'),
          humanGjaskalar: require('@/assets/data/names/female/humanGjaskalar.json'),
          humanIshadi: require('@/assets/data/names/female/humanIshadi.json'),
          humanBashtir: require('@/assets/data/names/female/humanBashtir.json'),
          humanInrit: require('@/assets/data/names/female/humanInrit.json'),
          lizards: require('@/assets/data/names/female/lizards.json'),
          felkartigris: require('@/assets/data/names/female/felkartigris.json'),
          felkarpanthar: require('@/assets/data/names/female/felkarpanthar.json'),
          gnome: require('@/assets/data/names/female/gnome.json'),
          elfs: require('@/assets/data/names/female/humanDalkin.json'),
          dwarf: require('@/assets/data/names/female/humanDalkin.json')
        }
      })
      context.dispatch('update')
      context.dispatch('loadAccountCharacters')
    },
    setDataSet(context, dataSet) {
      context.commit('SET_TRANSLATIONS', dataSet.translations)
      context.commit('SET_CONFIGURATION', dataSet.configuration)
      context.commit('SET_GENDERS', dataSet.genders)
      context.commit('SET_CULTURES', dataSet.cultures)
      context.commit('SET_CLAZZES', dataSet.clazzes)
      context.commit('SET_HAIR_COLORS', dataSet.hairColors)
      context.commit('SET_EYE_COLORS', dataSet.eyeColors)
      context.commit('SET_SKIN_COLORS', dataSet.skinColors)
      context.commit('SET_TALENTS', dataSet.talents)
      context.commit('SET_SPELLS', dataSet.spells)
      context.commit('SET_RUNES', dataSet.runes)
      context.commit('SET_IMPACT', dataSet.impact)
      context.commit('SET_CRAFTS', dataSet.craftings)
      context.commit('SET_LANGUAGES', dataSet.languages)
      context.commit('SET_ITEMS', dataSet.items)
      context.commit('SET_NAMES', dataSet.names)
      context.dispatch('update')
    },
    async loadAccountCharacters(context) {
      const accountrCharacters = await loadCharacters()
      if (accountrCharacters) {
        context.commit('SET_ACCOUNT_CHARACTERS', accountrCharacters)
        context.commit('SET_LOGGED_IN', true)
      } else {
        context.commit('SET_LOGGED_IN', false)
      }
    },
    clearAccountCharacters(context) {
      context.commit('SET_ACCOUNT_CHARACTERS', [])
    }
  },
  getters: {
    dataSet(state) {
      return {
        configuration: state.configuration,
        genders: state.genders,
        cultures: state.cultures,
        clazzes: state.clazzes,
        hairColors: state.hairColors,
        eyeColors: state.eyeColors,
        skinColors: state.skinColors,
        talents: state.talents,
        spells: state.spells,
        runes: state.runes,
        impact: state.impact,
        craftings: state.craftings,
        languages: state.languages,
        items: state.items,
        names: state.names,
        translations: state.translations
      }
    },
    translations(state) {
      return state.translations
    },
    configuration(state) {
      return state.configuration
    },
    character(state) {
      return state.character
    },
    characterStates(state) {
      return state.states
    },
    characterLevel(state) {
      return state.character.personality.level
    },
    characterExperience(state) {
      return state.character.personality.experience
    },
    characterName(state) {
      return state.character.personality.name
    },
    characterBackground(state) {
      return state.character.personality.background
    },
    characterGender(state) {
      return state.character.personality.gender
    },
    genders(state) {
      return state.genders
    },
    characterCulture(state) {
      return state.character.personality.culture
    },
    cultures(state) {
      return state.cultures
    },
    characterClazz(state) {
      return state.character.personality.clazz
    },
    clazzes(state) {
      return state.clazzes
    },
    caster(state) {
      return state.character.personality.caster
    },
    characterCraft(state) {
      return state.character.crafting
    },
    characterHairColor(state) {
      return state.character.personality.hairColor
    },
    characterEyeColor(state) {
      return state.character.personality.eyeColor
    },
    characterSkinColor(state) {
      return state.character.personality.skinColor
    },
    characterAge(state) {
      return state.character.personality.age
    },
    characterSize(state) {
      return state.character.personality.size
    },
    characterWeight(state) {
      return state.character.personality.weight
    },
    crafts(state) {
      return state.craftings
    },
    characterLanguages(state) {
      return state.character.languages
    },
    languages(state) {
      return state.languages
    },
    characterAttributePoints(state) {
      return state.character.attributePoints
    },
    characterAttributes(state) {
      return state.character.attributes
    },
    characterAttributesBonus(state) {
      return state.character.attributesBonus
    },
    characterAttributesMin(state) {
      return state.character.attributesMin
    },
    characterAttributesMax(state) {
      return state.character.attributesMax
    },
    characterClazzModificatorHealth(state) {
      return state.character.clazzModificatorHealth
    },
    characterClazzModificatorSoul(state) {
      return state.character.clazzModificatorSoul
    },
    characterBaseValues(state) {
      return state.character.baseValues
    },
    characterBaseValuesBonus(state) {
      return state.character.baseValuesBonus
    },
    characterSkillPoints(state) {
      return state.character.skillPoints
    },
    characterWeaponSkillPoints(state) {
      return state.character.weaponSkillPoints
    },
    characterBodySkillPoints(state) {
      return state.character.bodySkillPoints
    },
    characterMentalSkillPoints(state) {
      return state.character.mentalSkillPoints
    },
    characterSharedSkillPoints(state) {
      return state.character.sharedSkillPoints
    },
    characterSharedSkillPointsMin(state) {
      return state.character.sharedSkillPointsMin
    },
    characterSharedSkillPointsMax(state) {
      return state.character.sharedSkillPointsMax
    },
    characterWeaponSkills(state) {
      return state.character.weaponSkills
    },
    characterWeaponSkillsBonus(state) {
      return state.character.weaponSkillsBonus
    },
    characterWeaponSkillsMin(state) {
      return state.character.weaponSkillsMin
    },
    characterWeaponSkillsMax(state) {
      return state.character.weaponSkillsMax
    },
    characterBodySkills(state) {
      return state.character.bodySkills
    },
    characterBodySkillsBonus(state) {
      return state.character.bodySkillsBonus
    },
    characterBodySkillsMin(state) {
      return state.character.bodySkillsMin
    },
    characterBodySkillsMax(state) {
      return state.character.bodySkillsMax
    },
    characterMentalSkills(state) {
      return state.character.mentalSkills
    },
    characterMentalSkillsBonus(state) {
      return state.character.mentalSkillsBonus
    },
    characterMentalSkillsMin(state) {
      return state.character.mentalSkillsMin
    },
    characterMentalSkillsMax(state) {
      return state.character.mentalSkillsMax
    },
    characterTalentPoints(state) {
      return state.character.talentPoints
    },
    characterTalents(state) {
      return state.character.talents
    },
    characterSpellPoints(state) {
      return state.character.spellPoints
    },
    characterSpells(state) {
      return state.character.spells
    },
    characterRunePoints(state) {
      return state.character.runePoints
    },
    characterRunes(state) {
      return state.character.runes
    },
    characterInventory(state) {
      return state.character.inventory
    },
    characterArmor(state) {
      return state.character.inventory.armor
    },
    characterShield(state) {
      return state.character.inventory.shield
    },
    characterWeapons(state) {
      return state.character.inventory.weapons
    },
    characterRings(state) {
      return state.character.inventory.jewellery.rings
    },
    characterAmulets(state) {
      return state.character.inventory.jewellery.amulets
    },
    characterItems(state) {
      return state.character.inventory.items
    },
    characterMoney(state) {
      return state.character.inventory.money
    },
    characterImpact(state) {
      return state.character.impact
    },
    names(state) {
      return state.names
    },
    hairColors(state) {
      return state.hairColors
    },
    eyeColors(state) {
      return state.eyeColors
    },
    skinColors(state) {
      return state.skinColors
    },
    talents(state) {
      return state.talents
    },
    spells(state) {
      return state.spells
    },
    runes(state) {
      return state.runes
    },
    items(state) {
      return state.items
    },
    weapons(state) {
      return state.items.weapons
    },
    armor(state) {
      return state.items.armor
    },
    shield(state) {
      return state.items.shield
    },
    rings(state) {
      return state.items.jewellery.rings
    },
    amulets(state) {
      return state.items.jewellery.amulets
    },
    impact(state) {
      return state.impact
    }
  },
  modules: {}
})
