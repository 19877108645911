import store from '../store'
import { getAllBonusObjectsByName, getAllBonusPointsByName, provideBonusObjectByName } from './bonus.service'
import { getAllMalusObjectsByName, getAllMalusPointsByName } from './malus.service'
import { capitalize } from './objecthelper.service'

function hasTalent(talentName) {
  return store.state.character.talents.includes(talentName)
}
function getTalentPoints() {
  return getStartTalentPoints() + getAdditionalTalentPoints() - getUsedTalentPoints()
}
function getStartTalentPoints() {
  return parseInt(store.state.configuration.talentPoints) + parseInt(store.state.character.personality.level)
}
function getUsedTalentPoints() {
  return parseInt(store.getters.characterTalents.length)
}
function getAdditionalTalentPoints() {
  const numberOfBonusTalents = getAllBonusObjectsByName('talents').length
  const bonusTalentPoints = getAllBonusPointsByName('talentPoints')
  const malusAttributePoints = getAllMalusPointsByName('talentPoints')
  return parseInt(numberOfBonusTalents) + parseInt(bonusTalentPoints) - parseInt(malusAttributePoints)
}
function isBonusTalent(talentName) {
  const bonusTalents = getAllBonusObjectsByName('talents')
  const malusTalents = getAllMalusObjectsByName('talents')
  return bonusTalents.filter(bonusTalentName => !malusTalents.includes(bonusTalentName)).includes(talentName)
}
function getTalentForName(talentName) {
  return store.getters.talents.find(talent => talent.name === talentName)
}
function getCurrentTalents() {
  return store.getters.characterTalents.map(talentName => getTalentForName(talentName))
}
function removeOldAndAddNewTalentsByReference(oldReference, newReference) {
  if (oldReference !== null && oldReference !== undefined) {
    provideBonusObjectByName(oldReference, 'talents').forEach(talentName => removeTalent(talentName))
  }
  if (newReference !== null && newReference !== undefined) {
    provideBonusObjectByName(newReference, 'talents').forEach(talentName => addTalent(talentName))
  }
}
function removeTalent(talentName) {
  store.dispatch('removeCharacterTalent', talentName)
}
function addTalent(talentName) {
  store.dispatch('addCharacterTalent', talentName)
}

function getWeaponSkillsFocus() {
  const currentTalents = store.getters.characterTalents
  return {
    dodge: currentTalents.includes('weaponFocus' + capitalize('dodge')),
    axesAndHatchets: currentTalents.includes('weaponFocus' + capitalize('axesAndHatchets')),
    flail: currentTalents.includes('weaponFocus' + capitalize('flail')),
    hammersAndClubs: currentTalents.includes('weaponFocus' + capitalize('hammersAndClubs')),
    curvedSword: currentTalents.includes('weaponFocus' + capitalize('curvedSword')),
    dagger: currentTalents.includes('weaponFocus' + capitalize('dagger')),
    swords: currentTalents.includes('weaponFocus' + capitalize('swords')),
    spears: currentTalents.includes('weaponFocus' + capitalize('spears')),
    stabWeapons: currentTalents.includes('weaponFocus' + capitalize('stabWeapons')),
    unarmed: currentTalents.includes('weaponFocus' + capitalize('unarmed')),
    harantzu: currentTalents.includes('weaponFocus' + capitalize('harantzu')),
    crossbows: currentTalents.includes('weaponFocus' + capitalize('crossbows')),
    bows: currentTalents.includes('weaponFocus' + capitalize('bows')),
    throwingWeapons: currentTalents.includes('weaponFocus' + capitalize('throwingWeapons'))
  }
}
function getWeaponSkillsSpecialist() {
  const currentTalents = store.getters.characterTalents
  return {
    dodge: currentTalents.includes('weaponSpecialist' + capitalize('dodge')),
    axesAndHatchets: currentTalents.includes('weaponSpecialist' + capitalize('axesAndHatchets')),
    flail: currentTalents.includes('weaponSpecialist' + capitalize('flail')),
    hammersAndClubs: currentTalents.includes('weaponSpecialist' + capitalize('hammersAndClubs')),
    curvedSword: currentTalents.includes('weaponSpecialist' + capitalize('curvedSword')),
    dagger: currentTalents.includes('weaponSpecialist' + capitalize('dagger')),
    swords: currentTalents.includes('weaponSpecialist' + capitalize('swords')),
    spears: currentTalents.includes('weaponSpecialist' + capitalize('spears')),
    stabWeapons: currentTalents.includes('weaponSpecialist' + capitalize('stabWeapons')),
    unarmed: currentTalents.includes('weaponSpecialist' + capitalize('unarmed')),
    harantzu: currentTalents.includes('weaponSpecialist' + capitalize('harantzu')),
    crossbows: currentTalents.includes('weaponSpecialist' + capitalize('crossbows')),
    bows: currentTalents.includes('weaponSpecialist' + capitalize('bows')),
    throwingWeapons: currentTalents.includes('weaponSpecialist' + capitalize('throwingWeapons'))
  }
}

function getBodySkillsFocus() {
  const currentTalents = store.getters.characterTalents
  return {
    athletics: currentTalents.includes('skillFocus' + capitalize('athletics')),
    acrobatics: currentTalents.includes('skillFocus' + capitalize('acrobatics')),
    craft: currentTalents.includes('skillFocus' + capitalize('craft')),
    medicine: currentTalents.includes('skillFocus' + capitalize('medicine')),
    stealth: currentTalents.includes('skillFocus' + capitalize('stealth')),
    cooking: currentTalents.includes('skillFocus' + capitalize('cooking')),
    mechanics: currentTalents.includes('skillFocus' + capitalize('mechanics')),
    wildLife: currentTalents.includes('skillFocus' + capitalize('wildLife'))
  }
}

function getMentalSkillsFocus() {
  const currentTalents = store.getters.characterTalents
  return {
    alleyKnowledge: currentTalents.includes('skillFocus' + capitalize('alleyKnowledge')),
    historicalKnowledge: currentTalents.includes('skillFocus' + capitalize('historicalKnowledge')),
    trading: currentTalents.includes('skillFocus' + capitalize('trading')),
    poisons: currentTalents.includes('skillFocus' + capitalize('poisons')),
    magicScience: currentTalents.includes('skillFocus' + capitalize('magicScience')),
    religion: currentTalents.includes('skillFocus' + capitalize('religion')),
    persuation: currentTalents.includes('skillFocus' + capitalize('persuation')),
    perception: currentTalents.includes('skillFocus' + capitalize('perception'))
  }
}
export { getTalentPoints, getCurrentTalents, getTalentForName, removeTalent, addTalent, isBonusTalent, removeOldAndAddNewTalentsByReference, getWeaponSkillsFocus, getWeaponSkillsSpecialist, getBodySkillsFocus, getMentalSkillsFocus, hasTalent }
