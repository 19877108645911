<template>
  <v-row>
    <v-col cols="12" md="4">
      <v-btn class="rune" @click="showDialog" elevation="0">
        {{ header }}
      </v-btn>
    </v-col>
    <v-col cols="12" md="8">
      <v-radio-group v-model="rune.level" row>
        <v-radio label="1" value="1" :disabled="isRuneDisabled(1) || isRuneDisabledByEarlierSelection(rune.name, 1)" />
        <v-radio label="2" value="2" :disabled="isRuneDisabled(2) || isRuneDisabledByEarlierSelection(rune.name, 2)" />
        <v-radio label="3" value="3" :disabled="isRuneDisabled(3) || isRuneDisabledByEarlierSelection(rune.name, 3)" />
        <v-radio label="4" value="4" :disabled="isRuneDisabled(4) || isRuneDisabledByEarlierSelection(rune.name, 4)" />
        <v-radio label="5" value="5" :disabled="isRuneDisabled(5) || isRuneDisabledByEarlierSelection(rune.name, 5)" />
      </v-radio-group>
    </v-col>
    <v-dialog v-model="dialog">
      <v-card class="primary">
        <v-card-title>
          <div v-html="title" />
        </v-card-title>
        <v-card-text>
          <div v-html="content" />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="dialog = false"> Schließen </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { getStateForLevel } from '@/services/states.service'

export default {
  name: 'Rune',
  props: {
    header: {
      type: String,
      default: () => 'Title'
    },
    description: {
      type: String,
      default: () => 'Description'
    },
    rune: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    dialog: false,
    title: '',
    content: '',
    dependency: ''
  }),
  methods: {
    showDialog() {
      this.title = this.rune.title
      this.content = this.description
      this.dialog = true
    },
    isRuneDisabledByEarlierSelection(givenRune, givenRuneLevel) {
      const characterState = getStateForLevel(this.$store.getters.characterLevel - 1)
      if (characterState !== undefined) {
        return characterState.runes.some(selectedRune => selectedRune.name === givenRune && selectedRune.level > givenRuneLevel)
      }
      return false
    },
    isRuneDisabled(runeLevel) {
      return this.rune.level === runeLevel || (this.$store.getters.characterRunePoints < this.calculateNeededRunePoints(this.rune.level, runeLevel) && runeLevel > this.rune.level)
    },
    calculateNeededRunePoints(currentRuneLevel, runeLevel) {
      const currentRuneLevelNeededRunePoints = this.calculateRunePoints(currentRuneLevel)
      const runeLevelNeededRunePoints = this.calculateRunePoints(runeLevel)
      return runeLevelNeededRunePoints - currentRuneLevelNeededRunePoints
    },
    calculateRunePoints(runeLevel) {
      let runeUsedPoints = 0
      for (let i = 0; i < runeLevel; ++i) {
        runeUsedPoints = runeUsedPoints + i
      }
      return runeUsedPoints
    }
  }
}
</script>
<style>
.rune {
  width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.v-input--selection-controls {
  margin-top: 3px !important;
}
</style>
