import store from '../store'

function getCraftForName(craftName) {
  return store.getters.crafts.find(craft => craft.name === craftName)
}
function getCurrentCraft() {
  return getCraftForName(store.getters.characterCraft)
}
function setCraft(craftName) {
  store.dispatch('setCharacterCraft', craftName)
}
export { getCraftForName, getCurrentCraft, setCraft }
