import store from '../store'

function getCultureForName(cultureName) {
  return store.getters.cultures.find(culture => culture.name === cultureName)
}
function getCurrentCulture() {
  return getCultureForName(store.getters.characterCulture)
}
function setCulture(cultureName) {
  store.dispatch('setCharacterCulture', cultureName)
}
export { getCurrentCulture, getCultureForName, setCulture }
