<template>
  <v-stepper :value="$store.state.character.personality.level" non-linear outlined>
    <v-stepper-header>
      <v-stepper-step v-for="characterState in readCharacterStates()" :key="characterState.personality.level" :step="characterState.personality.level" editable @click="loadCharacterState(characterState.personality.level)">
        {{ 'Level ' + characterState.personality.level }}
      </v-stepper-step>
    </v-stepper-header>
  </v-stepper>
</template>

<script>
import { getStates, loadState } from '@/services/states.service'

export default {
  name: 'Levels',
  methods: {
    readCharacterStates() {
      return getStates()
    },
    loadCharacterState(level) {
      loadState(level)
      this.updateExperience()
    },
    levelToExperience(level) {
      let calculatedExperience = 0
      for (let i = 0; i < level; i++) {
        calculatedExperience = calculatedExperience + 1000 * i
      }
      return calculatedExperience
    },
    updateExperience() {
      this.$store.state.character.personality.experience = this.levelToExperience(this.$store.state.character.personality.level)
    }
  }
}
</script>
