import store from '../store'
import { getCurrentGender } from './gender.service'
import { getCurrentCulture } from './culture.service'
import { getCurrentClazz } from './clazz.service'
import { getCurrentCraft } from './craft.service'
import { getCurrentTalents } from './talent.service'
import { getCurrentSpells } from './spell.service'
import { getCurrentImpact } from './impact.service'
import { getCurrentRings, getCurrentAmulets, getCurrentArmor, getCurrentShield, getCurrentMeleeWeapons, getCurrentRangedWeapons, getCurrentItems } from './items.service'
import { getCurrentRunes } from './rune.service'
import { getCurrentEyeColor, getCurrentHairColor, getCurrentSkinColor } from './personality.service'
import { getCurrentLanguages } from './language.service'
function getBonusObjectByObjectNameAndAttributeNames(objectName, attributeNames) {
  const currentBonusObjects = getAllBonusObjectsByName(objectName)
  const bonusObject = {}
  attributeNames.forEach(attributeName => (bonusObject[attributeName] = 0))
  currentBonusObjects.forEach(currentObject => {
    if (currentObject[objectName] !== undefined) {
      currentObject[objectName].forEach(currentBonusObject => {
        const attributeName = currentBonusObject.name
        const attributeValue = currentBonusObject.value
        if (attributeNames.includes(attributeName)) {
          if (bonusObject[attributeName] === undefined) {
            bonusObject[attributeName] = parseInt(attributeValue)
          } else {
            bonusObject[attributeName] = bonusObject[attributeName] + parseInt(attributeValue)
          }
        }
      })
    } else {
      const currentBonusObject = currentObject
      const attributeName = currentBonusObject.name
      const attributeValue = currentBonusObject.value
      if (attributeNames.includes(attributeName)) {
        if (bonusObject[attributeName] === undefined) {
          bonusObject[attributeName] = parseInt(attributeValue)
        } else {
          bonusObject[attributeName] = bonusObject[attributeName] + parseInt(attributeValue)
        }
      }
    }
  })
  return bonusObject
}
function getAllBonusPointsByName(attributeName) {
  const bonusObjects = getAllBonusObjectsByName(attributeName)
  const bonusPoints = bonusObjects.length > 0 ? bonusObjects.map(bonusObject => bonusObject[attributeName]).reduce((accumulator, currentValue) => accumulator + currentValue) : 0
  return parseInt(bonusPoints)
}
function getAllBonusObjectsByName(attributeName) {
  return [
    ...provideBonusObjectsByName([getCurrentGender(), getCurrentCulture(), getCurrentClazz(), getCurrentCraft(), getCurrentHairColor(), getCurrentEyeColor(), getCurrentSkinColor()], attributeName),
    ...provideBonusObjectsByName(getCurrentLanguages(), attributeName),
    ...provideBonusObjectsByName(getCurrentTalents(), attributeName),
    ...provideBonusObjectsByName(getCurrentSpells(), attributeName),
    ...provideBonusObjectsByName(getCurrentRunes(), attributeName),
    ...provideBonusObjectsByName(getCurrentRings(), attributeName),
    ...provideBonusObjectsByName(getCurrentAmulets(), attributeName),
    ...provideBonusObjectsByName(getCurrentArmor(), attributeName),
    ...provideBonusObjectsByName(getCurrentShield(), attributeName),
    ...provideBonusObjectsByName(getCurrentMeleeWeapons(), attributeName),
    ...provideBonusObjectsByName(getCurrentRangedWeapons(), attributeName),
    ...provideBonusObjectsByName(getCurrentItems(), attributeName),
    ...provideBonusObjectsByName(getCurrentImpact(), attributeName)
  ]
}
function provideBonusObjectsByName(objects, attributeName) {
  return objects.length ? objects.flatMap(object => provideBonusObjectByName(object, attributeName)) : []
}
function provideBonusObjectByName(object, attributeName) {
  const bonusObject = hasObjectLevelBonus(object) && object.bonus[attributeName] !== undefined ? object.bonus[attributeName] : undefined
  const bonusLevelbasedObjects = hasObjectLevelBasedBonus(object) ? object.bonus.levelBased.filter(levelBased => levelBased[attributeName] !== undefined && levelBased.value <= parseInt(store.state.character.personality.level)) : []
  return createResultObject(bonusObject, bonusLevelbasedObjects, attributeName)
}
function createResultObject(bonusObject, bonusLevelbasedObjects, attributeName) {
  if (bonusObject !== undefined) {
    if (Object.keys(bonusObject).length === 0) {
      const wraperObj = {}
      wraperObj[attributeName] = bonusObject
      return [wraperObj, ...bonusLevelbasedObjects]
    }
    if (Array.isArray(bonusObject)) {
      return [...bonusObject, ...bonusLevelbasedObjects]
    }
    return [bonusObject, ...bonusLevelbasedObjects]
  }
  return bonusLevelbasedObjects
}
function hasObjectLevelBonus(object) {
  return object !== undefined && object.bonus !== undefined
}
function hasObjectLevelBasedBonus(object) {
  return hasObjectLevelBonus(object) && object.bonus.levelBased !== undefined
}
export { getAllBonusObjectsByName, getAllBonusPointsByName, getBonusObjectByObjectNameAndAttributeNames, provideBonusObjectsByName, provideBonusObjectByName }
