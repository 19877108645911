<template>
  <v-container v-if="$store.getters.configuration.showPrintView" fluid>
    <v-row>
      <v-col cols="12" md="12">
        <Preview :character="realTimeCharacter" />
      </v-col>
    </v-row>
  </v-container>
  <v-container v-else fluid>
    <v-overlay :value="$store.state.map">
      <Map />
    </v-overlay>
    <v-row v-if="false">
      <v-col cols="12" md="12">
        <Dice :dice="20" />
        <Dice :dice="10" />
        <Dice :dice="8" />
        <Dice :dice="6" />
        <Dice :dice="4" />
      </v-col>
    </v-row>
    <v-row v-if="$store.getters.configuration.showConfiguration">
      <v-col cols="12" md="12">
        <Configuration />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <Personalities />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <Attributes />
      </v-col>
      <v-col cols="12" md="6">
        <BaseValues />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <SkillPoints />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <SharedSkillPoints />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <WeaponSkills />
      </v-col>
      <v-col cols="12" md="4">
        <BodySkills />
      </v-col>
      <v-col cols="12" md="4">
        <MentalSkills />
      </v-col>
    </v-row>
    <v-row v-if="$store.getters.characterClazz === 'dilettanteMagicianWarrior' || $store.getters.characterClazz === 'dilettanteMagicianScout'">
      <v-col cols="12" md="12">
        <Triggermoments />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <Talents />
      </v-col>
    </v-row>
    <v-row v-if="$store.getters.characterClazz === 'runeFighter'">
      <v-col cols="12" md="12">
        <Runes />
      </v-col>
    </v-row>
    <v-row v-if="$store.getters.caster && $store.getters.characterClazz !== 'runeFighter'">
      <v-col cols="12" md="12">
        <Spells />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <Inventory />
      </v-col>
    </v-row>
    <v-snackbar v-model="$store.state.account.messages.registrationSuccess" :timeout="$store.state.account.messages.timeout">
      {{ $t('components.CharacterGenerator.account.messages.registrationSuccess') }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="$store.state.account.messages.registrationSuccess = false"> {{ $t('components.CharacterGenerator.account.messages.close') }} </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar v-if="$store.state.account.loggedIn" v-model="$store.state.account.messages.saveSuccess" :timeout="$store.state.account.messages.timeout">
      {{ $t('components.CharacterGenerator.account.messages.saveSuccess') }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="$store.state.account.messages.saveSuccess = false"> {{ $t('components.CharacterGenerator.account.messages.close') }} </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar v-if="$store.state.account.loggedIn" v-model="$store.state.account.messages.saveError" :timeout="$store.state.account.messages.timeout">
      {{ $t('components.CharacterGenerator.account.messages.saveError') }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="$store.state.account.messages.saveError = false"> {{ $t('components.CharacterGenerator.account.messages.close') }} </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import Personalities from '@/components/content/Personalities'
import Attributes from '@/components/content/Attributes'
import BaseValues from '@/components/content/BaseValues'
import SkillPoints from '@/components/content/SkillPoints'
import SharedSkillPoints from '@/components/content/SharedSkillPoints'
import WeaponSkills from '@/components/content/WeaponSkills'
import BodySkills from '@/components/content/BodySkills'
import MentalSkills from '@/components/content/MentalSkills'
import Triggermoments from '@/components/content/Triggermoments'
import Talents from '@/components/content/Talents'
import Runes from '@/components/content/Runes'
import Spells from '@/components/content/Spells'
import Inventory from '@/components/content/Inventory'
import Preview from '@/components/content/Preview'
import Configuration from '@/components/content/Configuration'
import Map from '@/components/content/Map'
import Dice from '@/components/content/Dice'
import { capitalize } from '@/services/objecthelper.service'
import { getCurrentPersonality } from '@/services/personality.service'
import { getCurrentAttributes } from '@/services/attribute.service'
import { getCurrentBaseValues } from '@/services/baseValue.service'
import { getCurrentWeaponSkills } from '@/services/weaponSkills.service'
import { getSpellForName } from '@/services/spell.service'
import { getRingForName, getAmuletForName, getArmorForName, getShieldForName, getMeleeWeaponForName, getRangedWeaponForName } from '@/services/items.service'
import { getWeaponSkillsFocus, getWeaponSkillsSpecialist, getBodySkillsFocus, getMentalSkillsFocus } from '@/services/talent.service'

export default {
  name: 'Content',
  components: {
    Dice,
    Configuration,
    Preview,
    Inventory,
    Spells,
    Runes,
    Triggermoments,
    Talents,
    WeaponSkills,
    BodySkills,
    MentalSkills,
    SkillPoints,
    SharedSkillPoints,
    Attributes,
    BaseValues,
    Personalities,
    Map
  },
  computed: {
    realTimeCharacter() {
      return {
        personality: getCurrentPersonality(),
        attributes: getCurrentAttributes(),
        baseValues: getCurrentBaseValues(),
        weaponSkills: getCurrentWeaponSkills(),
        weaponSkillsFocus: getWeaponSkillsFocus(),
        weaponSkillsSpecialist: getWeaponSkillsSpecialist(),
        bodySkills: this.$store.getters.characterBodySkills,
        additionalBodySkills: this.$store.getters.characterBodySkillsBonus,
        bodySkillsFocus: getBodySkillsFocus(),
        mentalSkills: this.$store.getters.characterMentalSkills,
        additionalMentalSkills: this.$store.getters.characterMentalSkillsBonus,
        mentalSkillsFocus: getMentalSkillsFocus(),
        talents: this.$store.getters.characterTalents,
        runes: this.$store.getters.characterRunes,
        kataSpells: this.$store.getters.characterSpells.filter(spell => getSpellForName(spell).type.startsWith('kata')).map(spell => this.updateSpell(spell)),
        controlSpells: this.$store.getters.characterSpells.filter(spell => getSpellForName(spell).type.startsWith('control')).map(spell => this.updateSpell(spell)),
        movementSpells: this.$store.getters.characterSpells.filter(spell => getSpellForName(spell).type.startsWith('movement')).map(spell => this.updateSpell(spell)),
        healSpells: this.$store.getters.characterSpells.filter(spell => getSpellForName(spell).type.startsWith('heal')).map(spell => this.updateSpell(spell)),
        clearanceSpells: this.$store.getters.characterSpells.filter(spell => getSpellForName(spell).type.startsWith('clearance')).map(spell => this.updateSpell(spell)),
        illusionSpells: this.$store.getters.characterSpells.filter(spell => getSpellForName(spell).type.startsWith('illusion')).map(spell => this.updateSpell(spell)),
        summoningSpells: this.$store.getters.characterSpells.filter(spell => getSpellForName(spell).type.startsWith('summoning')).map(spell => this.updateSpell(spell)),
        combatSpells: this.$store.getters.characterSpells.filter(spell => getSpellForName(spell).type.startsWith('combat')).map(spell => this.updateSpell(spell)),
        aliveTransformationSpells: this.$store.getters.characterSpells.filter(spell => getSpellForName(spell).type.startsWith('aliveTransformation')).map(spell => this.updateSpell(spell)),
        deathTransformationSpells: this.$store.getters.characterSpells.filter(spell => getSpellForName(spell).type.startsWith('deathTransformation')).map(spell => this.updateSpell(spell)),
        crafting: this.$store.getters.characterCraft,
        languages: this.$store.getters.characterLanguages,
        inventory: {
          weapons: {
            melee: this.$store.getters.characterWeapons.melee.filter(weapon => weapon !== '').map(weapon => getMeleeWeaponForName(weapon)),
            ranged: this.$store.getters.characterWeapons.ranged.filter(weapon => weapon !== '').map(weapon => getRangedWeaponForName(weapon))
          },
          armor: this.$store.getters.characterArmor.filter(armor => armor !== '').map(armor => getArmorForName(armor)),
          shield: this.$store.getters.characterShield.filter(shield => shield !== '').map(shield => getShieldForName(shield)),
          jewellery: {
            rings: this.$store.getters.characterRings.filter(ring => ring !== '').map(ring => getRingForName(ring)),
            amulets: this.$store.getters.characterAmulets.filter(amulet => amulet !== '').map(amulet => getAmuletForName(amulet))
          },
          money: this.$store.getters.characterMoney,
          items: this.$store.getters.characterItems
        }
      }
    }
  },
  methods: {
    updateSpell(spell) {
      const currentSpell = getSpellForName(spell)
      return {
        name: currentSpell.name,
        magicFocus: this.$store.getters.characterTalents.includes('magicFocus' + capitalize(currentSpell.type)),
        magicSpecialization: this.$store.getters.characterTalents.includes('magicSpecialization' + capitalize(currentSpell.type)),
        metaMagic: this.$store.getters.characterTalents.includes('metaMagic' + capitalize(currentSpell.name)),
        power:
          this.$store.getters.characterBaseValues.magicalPower +
          this.$store.getters.characterBaseValuesBonus.magicalPower +
          (this.$store.getters.characterTalents.includes('magicFocus' + capitalize(currentSpell.type)) ? this.$store.state.talents.find(talent => talent.name === 'magicFocus' + capitalize(currentSpell.type)).spellSchool.magicalPower : 0) +
          (this.$store.getters.characterTalents.includes('magicSpecialization' + capitalize(currentSpell.type))
            ? this.$store.state.talents.find(talent => talent.name === 'magicSpecialization' + capitalize(currentSpell.type)).spellSchool.magicalPower
            : 0) +
          (this.$store.getters.characterTalents.includes('metaMagic' + capitalize(currentSpell.name)) ? this.$store.state.talents.find(talent => talent.name === 'metaMagic' + capitalize(currentSpell.name)).spellSchool.magicalPower : 0)
      }
    }
  }
}
</script>

<style scoped></style>
