<template>
  <div>
    <h2>{{ $t('components.CharacterGenerator.inventory.shield') }}</h2>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              {{ $t('components.CharacterGenerator.items.shield.name') }}
            </th>
            <th class="text-left">
              {{ $t('components.CharacterGenerator.items.shield.attackReduction') }}
            </th>
            <th class="text-left">
              {{ $t('components.CharacterGenerator.items.shield.defenseBonus') }}
            </th>
            <th class="text-left">
              {{ $t('components.CharacterGenerator.items.shield.durability') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in shieldTableData" :key="row.name">
            <td>
              <v-autocomplete :items="currentAvailableShield" :value="$store.getters.characterShield[row.id]" outlined dense prepend-inner-icon="mdi-shield" class="shield" @change="valueChanged($event, row.id)" :disabled="!isActive">
                <template v-if="!$vuetify.breakpoint.xs" v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title>{{ data.item.text }}</v-list-item-title>
                      <v-list-item-subtitle>{{ data.item.specs }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </td>
            <td>{{ row.attackReduction }}</td>
            <td>{{ row.defenseBonus }}</td>
            <td>{{ row.durability }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { allDependenciesReached } from '@/services/dependency.service'
import { getShieldForIndex, setShield, getShieldForName } from '@/services/items.service'
import { createSelectByName } from '@/utils/ComponentUtils'

export default {
  name: 'Shield',
  data() {
    return {
      historyShield: {
        firstShield: null,
        secondShield: null
      }
    }
  },
  computed: {
    currentAvailableShield() {
      let options = createSelectByName(
        this.$store.getters.shield.filter(item => allDependenciesReached(item.dependencies)),
        'components.CharacterGenerator.items.shield.'
      )
      options = options.map(option => {
        const shield = getShieldForName(option.value)
        if (shield !== undefined) {
          if (!this.$vuetify.breakpoint.xs) {
            option.specs = 'Metalisch ' + (shield.metal ? 'Ja' : 'Nein') + ' | AN- ' + shield.attackReduction + ' | AB+ ' + shield.defenseBonus + ' | BW ' + shield.durability
          }
        }
        return option
      })
      return options
    },
    isActive() {
      return this.$store.getters.characterGender !== '' && this.$store.getters.characterCulture !== '' && this.$store.getters.characterClazz !== ''
    },
    shieldTableData() {
      const tableData = []
      if (this.$store.getters.characterShield[0] !== '') {
        tableData.push(this.tableRow(0))
      } else {
        tableData.push(this.emptyTableRow(0))
      }
      if (this.$store.getters.characterShield[1] !== '') {
        tableData.push(this.tableRow(1))
      } else {
        tableData.push(this.emptyTableRow(1))
      }
      return tableData
    }
  },
  methods: {
    emptyTableRow(index) {
      return {
        id: index,
        attackReduction: '',
        defenseBonus: '',
        durability: ''
      }
    },
    tableRow(index) {
      const shield = getShieldForIndex(index)
      return {
        id: index,
        attackReduction: shield.attackReduction,
        defenseBonus: shield.defenseBonus,
        durability: shield.durability
      }
    },
    getShieldForIndex(index) {
      return getShieldForIndex(index)
    },
    valueChanged(name, index) {
      setShield(name, index)
    }
  }
}
</script>
<style>
.shield {
  height: 60px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  width: 200px;
}
</style>
