<template>
  <div>
    <h2>{{ $t('components.CharacterGenerator.inventory.ranged') }}</h2>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              {{ $t('components.CharacterGenerator.items.weapons.name') }}
            </th>
            <th class="text-left">
              {{ $t('components.CharacterGenerator.items.weapons.type') }}
            </th>
            <th class="text-left">
              {{ $t('components.CharacterGenerator.items.weapons.dodgeMalus') }}
            </th>
            <th class="text-left">
              {{ $t('components.CharacterGenerator.items.weapons.muscleStrengthBonus') }}
            </th>
            <th class="text-left">
              {{ $t('components.CharacterGenerator.items.weapons.range') }}
            </th>
            <th class="text-left">
              {{ $t('components.CharacterGenerator.items.weapons.diceAndDamage') }}
            </th>
            <th class="text-left">
              {{ $t('components.CharacterGenerator.items.weapons.crit') }}
            </th>
            <th class="text-left">
              {{ $t('components.CharacterGenerator.items.weapons.durability') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in weaponTableData" :key="row.name">
            <td>
              <v-autocomplete
                :items="currentAvailableWeapons"
                :value="$store.getters.characterWeapons.ranged[row.id]"
                outlined
                dense
                prepend-inner-icon="mdi-bullseye-arrow"
                class="weapons_ranged"
                @change="valueChanged($event, row.id)"
                :disabled="!isActive"
              >
                <template v-if="!$vuetify.breakpoint.xs" v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title>{{ data.item.text }}</v-list-item-title>
                      <v-list-item-subtitle>{{ data.item.specs }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </td>
            <td>{{ row.type }}</td>
            <td>{{ row.dodgeMalus }}</td>
            <td>{{ row.muscleStrengthBonus }}</td>
            <td>{{ row.range }}</td>
            <td>{{ row.diceAndDamage }}</td>
            <td>{{ row.crit }}</td>
            <td>{{ row.durability }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { allDependenciesReached } from '@/services/dependency.service'
import { getRangedWeaponForIndex, setWeaponsRanged, getRangedWeaponForName } from '@/services/items.service'
import { createSelectByName } from '@/utils/ComponentUtils'

export default {
  name: 'WeaponsRanged',
  computed: {
    currentAvailableWeapons() {
      let options = createSelectByName(
        this.$store.getters.weapons.ranged.filter(item => allDependenciesReached(item.dependencies)),
        'components.CharacterGenerator.items.weapons.ranged.'
      )
      options = options.map(option => {
        const weapon = getRangedWeaponForName(option.value)
        if (weapon !== undefined) {
          if (!this.$vuetify.breakpoint.xs) {
            option.specs =
              this.$t('components.CharacterGenerator.weaponSkills.' + weapon.type) +
              ' | AW- ' +
              weapon.dodgeMalus +
              ' | MK+ ' +
              weapon.muscleStrengthBonus +
              ' | RW ' +
              weapon.range +
              ' | Schaden ' +
              weapon.dice +
              '+' +
              weapon.damage +
              ' | Krit ' +
              (weapon.critRange !== '-' ? 20 - weapon.critRange + '-20 x ' + weapon.critMultiplicator : '-') +
              ' | BW ' +
              weapon.durability
          }
        }
        return option
      })
      return options
    },
    isActive() {
      return this.$store.getters.characterGender !== '' && this.$store.getters.characterCulture !== '' && this.$store.getters.characterClazz !== ''
    },
    weaponTableData() {
      const tableData = []
      if (this.$store.getters.characterWeapons.ranged[0] !== '') {
        tableData.push(this.tableRow(0))
      } else {
        tableData.push(this.emptyTableRow(0))
      }
      if (this.$store.getters.characterWeapons.ranged[1] !== '') {
        tableData.push(this.tableRow(1))
      } else {
        tableData.push(this.emptyTableRow(1))
      }
      return tableData
    }
  },
  methods: {
    emptyTableRow(index) {
      return {
        id: index,
        type: '',
        dodgeMalus: '',
        muscleStrengthBonus: '',
        range: '',
        diceAndDamage: '',
        crit: '',
        durability: ''
      }
    },
    tableRow(index) {
      const weapon = getRangedWeaponForIndex(index)
      return {
        id: index,
        type: this.$t('components.CharacterGenerator.weaponSkills.' + weapon.type),
        dodgeMalus: weapon.dodgeMalus,
        muscleStrengthBonus: weapon.muscleStrengthBonus,
        range: weapon.range,
        diceAndDamage: weapon.dice + '+' + weapon.damage,
        crit: weapon.critRange !== '-' ? 20 - weapon.critRange + '-20 x ' + weapon.critMultiplicator : '-',
        durability: weapon.durability
      }
    },
    getRangedWeaponForIndex(index) {
      return getRangedWeaponForIndex(index)
    },
    valueChanged(name, index) {
      setWeaponsRanged(name, index)
    }
  }
}
</script>
<style>
.weapons_ranged {
  height: 60px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  width: 250px;
}
</style>
