import store from '../store'
import { getCurrentTalents } from './talent.service'
import { getStateForLevel } from './states.service'
import { getAllBonusPointsByName, getBonusObjectByObjectNameAndAttributeNames } from './bonus.service'
import { getAllMalusPointsByName, getMalusObjectByObjectNameAndAttributeNames } from './malus.service'
function getMentalSkillPoints() {
  return getStartMentaSkillPoints() + getSharedMentalSkillPoints() + getAdditionalMentalSkillPoints() - getUsedMentalSkillPoints()
}
function getStartMentaSkillPoints() {
  return parseInt(store.getters.configuration.mentalSkillsPoints)
}
function getSharedMentalSkillPoints() {
  const sharedSkillPoints = store.getters.characterSharedSkillPoints
  return (
    parseInt(sharedSkillPoints.weaponSkillsToMentalSkills) +
    parseInt(sharedSkillPoints.bodySkillsToMentalSkills) +
    parseInt(sharedSkillPoints.skillPointsToMentalSkills) -
    (parseInt(sharedSkillPoints.mentalSkillsToBodySkills) + parseInt(sharedSkillPoints.mentalSkillsToWeaponSkills) + parseInt(sharedSkillPoints.mentalSkillsToRunes))
  )
}
function getAdditionalMentalSkillPoints() {
  const bonusMentalSkillPoints = getAllBonusPointsByName('mentalSkillsPoints')
  const malusMentalSkillPoints = getAllMalusPointsByName('mentalSkillsPoints')
  return parseInt(bonusMentalSkillPoints) - parseInt(malusMentalSkillPoints)
}
function getUsedMentalSkillPoints() {
  const mentalSkills = store.getters.characterMentalSkills
  return (
    parseInt(mentalSkills.alleyKnowledge) +
    parseInt(mentalSkills.historicalKnowledge) +
    parseInt(mentalSkills.trading) +
    parseInt(mentalSkills.poisons) +
    parseInt(mentalSkills.magicScience) +
    parseInt(mentalSkills.religion) +
    parseInt(mentalSkills.persuation) +
    parseInt(mentalSkills.perception)
  )
}
function getMentalSkillPointsBonus() {
  const mentalSkills = store.getters.characterMentalSkills
  const mentalSkillPointsBonus = getBonusObjectByObjectNameAndAttributeNames('mentalSkills', Object.keys(mentalSkills))
  const mentalSkillPointsMalus = getMalusObjectByObjectNameAndAttributeNames('mentalSkills', Object.keys(mentalSkills))
  const calculatedMentalSkillPointsBonus = {}
  Object.keys(mentalSkills).forEach(mentalSkillName => {
    calculatedMentalSkillPointsBonus[mentalSkillName] = mentalSkillPointsBonus[mentalSkillName] - mentalSkillPointsMalus[mentalSkillName]
  })
  return calculatedMentalSkillPointsBonus
}
function getMentalSkillPointsMax() {
  const configuration = store.getters.configuration
  const currentTalents = getCurrentTalents()
  const mentalSkillsPointsMaxAlleyKnowledge = currentTalents.filter(talent => talent.mentalSkillsPointsMaxAlleyKnowledge !== undefined).map(talent => talent.mentalSkillsPointsMaxAlleyKnowledge)
  const mentalSkillsPointsMaxHistoricalKnowledge = currentTalents.filter(talent => talent.mentalSkillsPointsMaxHistoricalKnowledge !== undefined).map(talent => talent.mentalSkillsPointsMaxHistoricalKnowledge)
  const mentalSkillsPointsMaxTrading = currentTalents.filter(talent => talent.mentalSkillsPointsMaxTrading !== undefined).map(talent => talent.mentalSkillsPointsMaxTrading)
  const mentalSkillsPointsMaxPoisons = currentTalents.filter(talent => talent.mentalSkillsPointsMaxPoisons !== undefined).map(talent => talent.mentalSkillsPointsMaxPoisons)
  const mentalSkillsPointsMaxMagicScience = currentTalents.filter(talent => talent.mentalSkillsPointsMaxMagicScience !== undefined).map(talent => talent.mentalSkillsPointsMaxMagicScience)
  const mentalSkillsPointsMaxReligion = currentTalents.filter(talent => talent.mentalSkillsPointsMaxReligion !== undefined).map(talent => talent.mentalSkillsPointsMaxReligion)
  const mentalSkillsPointsMaxPersuation = currentTalents.filter(talent => talent.mentalSkillsPointsMaxPersuation !== undefined).map(talent => talent.mentalSkillsPointsMaxPersuation)
  const mentalSkillsPointsMaxPerception = currentTalents.filter(talent => talent.mentalSkillsPointsMaxPerception !== undefined).map(talent => talent.mentalSkillsPointsMaxPerception)
  const mentalSkillPointsMax = {
    alleyKnowledge:
      parseInt(store.state.character.personality.level) +
      (mentalSkillsPointsMaxAlleyKnowledge.length > 0 ? mentalSkillsPointsMaxAlleyKnowledge.reduce((accumulator, currentValue) => accumulator + currentValue) : parseInt(configuration.mentalSkillsPointsMax)),
    historicalKnowledge:
      parseInt(store.state.character.personality.level) +
      (mentalSkillsPointsMaxHistoricalKnowledge.length > 0 ? mentalSkillsPointsMaxHistoricalKnowledge.reduce((accumulator, currentValue) => accumulator + currentValue) : parseInt(configuration.mentalSkillsPointsMax)),
    trading:
      parseInt(store.state.character.personality.level) + (mentalSkillsPointsMaxTrading.length > 0 ? mentalSkillsPointsMaxTrading.reduce((accumulator, currentValue) => accumulator + currentValue) : parseInt(configuration.mentalSkillsPointsMax)),
    poisons:
      parseInt(store.state.character.personality.level) + (mentalSkillsPointsMaxPoisons.length > 0 ? mentalSkillsPointsMaxPoisons.reduce((accumulator, currentValue) => accumulator + currentValue) : parseInt(configuration.mentalSkillsPointsMax)),
    magicScience:
      parseInt(store.state.character.personality.level) +
      (mentalSkillsPointsMaxMagicScience.length > 0 ? mentalSkillsPointsMaxMagicScience.reduce((accumulator, currentValue) => accumulator + currentValue) : parseInt(configuration.mentalSkillsPointsMax)),
    religion:
      parseInt(store.state.character.personality.level) + (mentalSkillsPointsMaxReligion.length > 0 ? mentalSkillsPointsMaxReligion.reduce((accumulator, currentValue) => accumulator + currentValue) : parseInt(configuration.mentalSkillsPointsMax)),
    persuation:
      parseInt(store.state.character.personality.level) +
      (mentalSkillsPointsMaxPersuation.length > 0 ? mentalSkillsPointsMaxPersuation.reduce((accumulator, currentValue) => accumulator + currentValue) : parseInt(configuration.mentalSkillsPointsMax)),
    perception:
      parseInt(store.state.character.personality.level) + (mentalSkillsPointsMaxPerception.length > 0 ? mentalSkillsPointsMaxPerception.reduce((accumulator, currentValue) => accumulator + currentValue) : parseInt(configuration.mentalSkillsPointsMax))
  }

  const mentalSkills = store.getters.characterMentalSkills
  const mentalSkillPoints = store.getters.characterMentalSkillPoints
  Object.keys(mentalSkillPointsMax).forEach(skillName => {
    const realSkillPointsMaxValue = mentalSkills[skillName] + mentalSkillPoints
    if (mentalSkillPointsMax[skillName] > realSkillPointsMaxValue) {
      mentalSkillPointsMax[skillName] = realSkillPointsMaxValue
    }
  })
  return mentalSkillPointsMax
}
function getMentalSkillsPointsMin() {
  const characterState = getStateForLevel(store.getters.characterLevel - 1)
  const mentalSkillsPointsMinValue = store.getters.configuration.mentalSkillsPointsMin
  const mentalSkillsPointsMin = {}
  Object.keys(store.getters.characterMentalSkills).forEach(mentalSkillName => {
    if (characterState !== undefined) {
      const realSkillPointsMinValue = parseInt(characterState.mentalSkills[mentalSkillName])
      mentalSkillsPointsMin[mentalSkillName] = realSkillPointsMinValue > mentalSkillsPointsMinValue ? realSkillPointsMinValue : mentalSkillsPointsMinValue
    } else {
      mentalSkillsPointsMin[mentalSkillName] = mentalSkillsPointsMinValue
    }
  })
  return mentalSkillsPointsMin
}
function calculateValue(name, value) {
  const valueMin = store.getters.characterMentalSkillsMin[name]
  const valueMax = store.getters.characterMentalSkillsMax[name]
  let newValue = value
  if (value > valueMax) {
    newValue = valueMax
  } else if (value < valueMin) {
    newValue = valueMin
  }
  return newValue
}
function setMentalSkill(name, value) {
  const skills = store.getters.characterMentalSkills
  skills[name] = calculateValue(name, value)
  store.dispatch('setCharacterMentalSkills', skills)
}
export { getMentalSkillPoints, getMentalSkillPointsBonus, getMentalSkillPointsMax, getMentalSkillsPointsMin, setMentalSkill }
