<template>
  <div>
    <h2>{{ $t('components.CharacterGenerator.inventory.jewellery') }}</h2>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Ring</th>
            <th class="text-left">Ring</th>
            <th class="text-left">Amulet</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <v-autocomplete :items="currentAvailableRings" :value="$store.getters.characterRings[0]" outlined dense prepend-inner-icon="mdi-ring" class="jewellery" @change="ringValueChanged($event, 0)" :disabled="!isActive" />
            </td>
            <td>
              <v-autocomplete :items="currentAvailableRings" :value="$store.getters.characterRings[1]" outlined dense prepend-inner-icon="mdi-ring" class="jewellery" @change="ringValueChanged($event, 1)" :disabled="!isActive" />
            </td>
            <td>
              <v-autocomplete :items="currentAvailableAmulets" :value="$store.getters.characterAmulets[0]" outlined dense prepend-inner-icon="mdi-necklace" class="jewellery" @change="amuletValueChanged($event, 0)" :disabled="!isActive" />
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { allDependenciesReached } from '@/services/dependency.service'
import { setAmulet, setRing } from '@/services/items.service'
import { createSelectByName } from '@/utils/ComponentUtils'

export default {
  name: 'Jewellery',
  data() {
    return {
      historyAmulets: {
        firstAmulet: null
      }
    }
  },
  computed: {
    currentAvailableRings() {
      return createSelectByName(
        this.$store.getters.rings.filter(item => allDependenciesReached(item.dependencies)),
        'components.CharacterGenerator.items.jewellery.rings.'
      )
    },
    currentAvailableAmulets() {
      return createSelectByName(
        this.$store.getters.amulets.filter(item => allDependenciesReached(item.dependencies)),
        'components.CharacterGenerator.items.jewellery.amulets.'
      )
    },
    isActive() {
      return this.$store.getters.characterGender !== '' && this.$store.getters.characterCulture !== '' && this.$store.getters.characterClazz !== ''
    }
  },
  methods: {
    ringValueChanged(name, index) {
      setRing(name, index)
    },
    amuletValueChanged(name, index) {
      setAmulet(name, index)
    }
  }
}
</script>
<style>
.jewellery {
  height: 60px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  width: 250px;
}
</style>
