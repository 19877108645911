import { removeOldAndAddNewLanguagesByReference } from './language.service'
import { removeOldAndAddNewTalentsByReference } from './talent.service'
import { removeOldAndAddNewSpellsByReference } from './spell.service'
import { removeOldAndAddNewRunesByReference } from './rune.service'

function updateInputByOldAndNewReference(oldReference, newReference) {
  removeOldAndAddNewLanguagesByReference(oldReference, newReference)
  removeOldAndAddNewTalentsByReference(oldReference, newReference)
  removeOldAndAddNewSpellsByReference(oldReference, newReference)
  removeOldAndAddNewRunesByReference(oldReference, newReference)
}
export { updateInputByOldAndNewReference }
