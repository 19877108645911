import store from '../store'
import { getCurrentTalents } from './talent.service'
import { getStateForLevel } from './states.service'
import { getAllBonusPointsByName, getBonusObjectByObjectNameAndAttributeNames } from './bonus.service'
import { getAllMalusPointsByName, getMalusObjectByObjectNameAndAttributeNames } from './malus.service'
function getBodySkillPoints() {
  return getStartBodySkillPoints() + getSharedBodySkillPoints() + getAdditionalBodySkillPoints() - getUsedBodySkillPoints()
}
function getStartBodySkillPoints() {
  return parseInt(store.getters.configuration.bodySkillsPoints)
}
function getSharedBodySkillPoints() {
  const sharedSkillPoints = store.getters.characterSharedSkillPoints
  return (
    parseInt(sharedSkillPoints.weaponSkillsToBodySkills) +
    parseInt(sharedSkillPoints.mentalSkillsToBodySkills) +
    parseInt(sharedSkillPoints.skillPointsToBodySkills) -
    (parseInt(sharedSkillPoints.bodySkillsToWeaponSkills) + parseInt(sharedSkillPoints.bodySkillsToMentalSkills) + parseInt(sharedSkillPoints.bodySkillsToRunes))
  )
}
function getAdditionalBodySkillPoints() {
  const bonusBodySkillPoints = getAllBonusPointsByName('bodySkillsPoints')
  const malusBodySkillPoints = getAllMalusPointsByName('bodySkillsPoints')
  return parseInt(bonusBodySkillPoints) - parseInt(malusBodySkillPoints)
}
function getUsedBodySkillPoints() {
  const bodySkills = store.getters.characterBodySkills
  return (
    parseInt(bodySkills.athletics) +
    parseInt(bodySkills.acrobatics) +
    parseInt(bodySkills.craft) +
    parseInt(bodySkills.medicine) +
    parseInt(bodySkills.stealth) +
    parseInt(bodySkills.cooking) +
    parseInt(bodySkills.mechanics) +
    parseInt(bodySkills.wildLife)
  )
}
function getBodySkillPointsBonus() {
  const bodySkills = store.getters.characterBodySkills
  const bodySkillPointsBonus = getBonusObjectByObjectNameAndAttributeNames('bodySkills', Object.keys(bodySkills))
  const bodySkillPointsMalus = getMalusObjectByObjectNameAndAttributeNames('bodySkills', Object.keys(bodySkills))
  const calculatedBodySkillPointsBonus = {}
  Object.keys(bodySkills).forEach(bodySkillName => {
    calculatedBodySkillPointsBonus[bodySkillName] = bodySkillPointsBonus[bodySkillName] - bodySkillPointsMalus[bodySkillName]
  })
  return calculatedBodySkillPointsBonus
}
function getBodySkillPointsMax() {
  const configuration = store.getters.configuration
  const currentTalents = getCurrentTalents()
  const bodySkillsPointsMaxAthletics = currentTalents.filter(talent => talent.bodySkillsPointsMaxAthletics !== undefined).map(talent => talent.bodySkillsPointsMaxAthletics)
  const bodySkillsPointsMaxAcrobatics = currentTalents.filter(talent => talent.bodySkillsPointsMaxAcrobatics !== undefined).map(talent => talent.bodySkillsPointsMaxAcrobatics)
  const bodySkillsPointsMaxCraft = currentTalents.filter(talent => talent.bodySkillsPointsMaxCraft !== undefined).map(talent => talent.bodySkillsPointsMaxCraft)
  const bodySkillsPointsMaxMedicine = currentTalents.filter(talent => talent.bodySkillsPointsMaxMedicine !== undefined).map(talent => talent.bodySkillsPointsMaxMedicine)
  const bodySkillsPointsMaxStealth = currentTalents.filter(talent => talent.bodySkillsPointsMaxStealth !== undefined).map(talent => talent.bodySkillsPointsMaxStealth)
  const bodySkillsPointsMaxCooking = currentTalents.filter(talent => talent.bodySkillsPointsMaxCooking !== undefined).map(talent => talent.bodySkillsPointsMaxCooking)
  const bodySkillsPointsMaxMechanics = currentTalents.filter(talent => talent.bodySkillsPointsMaxMechanics !== undefined).map(talent => talent.bodySkillsPointsMaxMechanics)
  const bodySkillsPointsMaxWildLife = currentTalents.filter(talent => talent.bodySkillsPointsMaxWildLife !== undefined).map(talent => talent.bodySkillsPointsMaxWildLife)
  const bodySkillPointsMax = {
    athletics:
      parseInt(store.state.character.personality.level) + (bodySkillsPointsMaxAthletics.length > 0 ? bodySkillsPointsMaxAthletics.reduce((accumulator, currentValue) => accumulator + currentValue) : parseInt(configuration.bodySkillsPointsMax)),
    acrobatics:
      parseInt(store.state.character.personality.level) + (bodySkillsPointsMaxAcrobatics.length > 0 ? bodySkillsPointsMaxAcrobatics.reduce((accumulator, currentValue) => accumulator + currentValue) : parseInt(configuration.bodySkillsPointsMax)),
    craft: parseInt(store.state.character.personality.level) + (bodySkillsPointsMaxCraft.length > 0 ? bodySkillsPointsMaxCraft.reduce((accumulator, currentValue) => accumulator + currentValue) : parseInt(configuration.bodySkillsPointsMax)),
    medicine: parseInt(store.state.character.personality.level) + (bodySkillsPointsMaxMedicine.length > 0 ? bodySkillsPointsMaxMedicine.reduce((accumulator, currentValue) => accumulator + currentValue) : parseInt(configuration.bodySkillsPointsMax)),
    stealth: parseInt(store.state.character.personality.level) + (bodySkillsPointsMaxStealth.length > 0 ? bodySkillsPointsMaxStealth.reduce((accumulator, currentValue) => accumulator + currentValue) : parseInt(configuration.bodySkillsPointsMax)),
    cooking: parseInt(store.state.character.personality.level) + (bodySkillsPointsMaxCooking.length > 0 ? bodySkillsPointsMaxCooking.reduce((accumulator, currentValue) => accumulator + currentValue) : parseInt(configuration.bodySkillsPointsMax)),
    mechanics:
      parseInt(store.state.character.personality.level) + (bodySkillsPointsMaxMechanics.length > 0 ? bodySkillsPointsMaxMechanics.reduce((accumulator, currentValue) => accumulator + currentValue) : parseInt(configuration.bodySkillsPointsMax)),
    wildLife: parseInt(store.state.character.personality.level) + (bodySkillsPointsMaxWildLife.length > 0 ? bodySkillsPointsMaxWildLife.reduce((accumulator, currentValue) => accumulator + currentValue) : parseInt(configuration.bodySkillsPointsMax))
  }

  const bodySkills = store.getters.characterBodySkills
  const bodySkillPoints = store.getters.characterBodySkillPoints
  Object.keys(bodySkillPointsMax).forEach(skillName => {
    const realSkillPointsMaxValue = bodySkills[skillName] + bodySkillPoints
    if (bodySkillPointsMax[skillName] > realSkillPointsMaxValue) {
      bodySkillPointsMax[skillName] = realSkillPointsMaxValue
    }
  })
  return bodySkillPointsMax
}
function getBodySkillPointsMin() {
  const characterState = getStateForLevel(store.getters.characterLevel - 1)
  const bodySkillsPointsMinValue = store.getters.configuration.bodySkillsPointsMin
  const bodySkillsPointsMin = {}
  Object.keys(store.getters.characterBodySkills).forEach(bodySkillName => {
    if (characterState !== undefined) {
      const realSkillPointsMinValue = parseInt(characterState.bodySkills[bodySkillName])
      bodySkillsPointsMin[bodySkillName] = realSkillPointsMinValue > bodySkillsPointsMinValue ? realSkillPointsMinValue : bodySkillsPointsMinValue
    } else {
      bodySkillsPointsMin[bodySkillName] = bodySkillsPointsMinValue
    }
  })
  return bodySkillsPointsMin
}
function calculateValue(name, value) {
  const valueMin = store.getters.characterBodySkillsMin[name]
  const valueMax = store.getters.characterBodySkillsMax[name]
  let newValue = value
  if (value > valueMax) {
    newValue = valueMax
  } else if (value < valueMin) {
    newValue = valueMin
  }
  return newValue
}
function setBodySkill(name, value) {
  const skills = store.getters.characterBodySkills
  skills[name] = calculateValue(name, value)
  store.dispatch('setCharacterBodySkills', skills)
}
export { getBodySkillPoints, getBodySkillPointsBonus, getBodySkillPointsMax, getBodySkillPointsMin, setBodySkill }
