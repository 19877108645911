<template>
  <v-card outlined>
    <v-app-bar class="primary">
      <v-toolbar-title> Fertigkeitspunkte {{ '( FP / ' + $store.getters.characterSkillPoints + ' )' }} </v-toolbar-title>
    </v-app-bar>
    <v-container fluid>
      <v-row style="padding-top: 25px">
        <Points
          :title="$t('components.CharacterGenerator.weaponSkills.label')"
          :value="$store.getters.characterSharedSkillPoints.skillPointsToWeaponSkills"
          :valueName="'skillPointsToWeaponSkills'"
          :min="$store.getters.characterSharedSkillPointsMin.skillPointsToWeaponSkills"
          :max="$store.getters.characterSharedSkillPointsMax.skillPointsToWeaponSkills"
          @changed-value="valueChange"
        />
        <Points
          :title="$t('components.CharacterGenerator.bodySkills.label')"
          :value="$store.getters.characterSharedSkillPoints.skillPointsToBodySkills"
          :valueName="'skillPointsToBodySkills'"
          :min="$store.getters.characterSharedSkillPointsMin.skillPointsToBodySkills"
          :max="$store.getters.characterSharedSkillPointsMax.skillPointsToBodySkills"
          @changed-value="valueChange"
        />
        <Points
          :title="$t('components.CharacterGenerator.mentalSkills.label')"
          :value="$store.getters.characterSharedSkillPoints.skillPointsToMentalSkills"
          :valueName="'skillPointsToMentalSkills'"
          :min="$store.getters.characterSharedSkillPointsMin.skillPointsToMentalSkills"
          :max="$store.getters.characterSharedSkillPointsMax.skillPointsToMentalSkills"
          @changed-value="valueChange"
        />
        <Points
          v-if="$store.getters.characterClazz === 'runeFighter'"
          :title="$t('components.CharacterGenerator.runes.label')"
          :value="$store.getters.characterSharedSkillPoints.skillPointsToRunes"
          :valueName="'skillPointsToRunes'"
          :min="$store.getters.characterSharedSkillPointsMin.skillPointsToRunes"
          :max="$store.getters.characterSharedSkillPointsMax.skillPointsToRunes"
          @changed-value="valueChange"
        />
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import Points from '@/components/content/Points'
import { setSharedSkillPoint } from '@/services/skillpoint.service'

export default {
  name: 'SkillPoints',
  components: { Points },
  methods: {
    valueChange(skillPoint) {
      setSharedSkillPoint(skillPoint.name, skillPoint.value)
    }
  }
}
</script>
