import store from '../store'
import { getAllBonusPointsByName, provideBonusObjectByName } from './bonus.service'
import { getAllMalusPointsByName } from './malus.service'
import { capitalize } from './objecthelper.service'

function getRunePoints() {
  return getSharedRunePoints() + additionalRunePoints() - getUsedRunePoints()
}
function getUsedRunePoints() {
  return store.state.character.runes.map(rune => calculateRunePoints(rune)).reduce((accumulator, currentValue) => accumulator + currentValue)
}
function getSharedRunePoints() {
  return (
    parseInt(store.getters.characterSharedSkillPoints.weaponSkillsToRunes) +
    parseInt(store.getters.characterSharedSkillPoints.bodySkillsToRunes) +
    parseInt(store.getters.characterSharedSkillPoints.mentalSkillsToRunes) +
    parseInt(store.getters.characterSharedSkillPoints.skillPointsToRunes)
  )
}
function additionalRunePoints() {
  const bonusRunePoints = getAllBonusPointsByName('runePoints')
  const malusRunePoints = getAllMalusPointsByName('runePoints')
  return bonusRunePoints - malusRunePoints
}
function hasRuneFocus(rune) {
  return store.getters.characterTalents.includes('runeFocus' + capitalize(rune.name))
}
function hasRuneSpecialization(rune) {
  return store.getters.characterTalents.includes('runeSpecialization' + capitalize(rune.name))
}
function calculateRunePoints(rune) {
  let runeUsedPoints = 0
  for (let i = 0; i < rune.level; ++i) {
    runeUsedPoints = runeUsedPoints + i
  }
  return runeUsedPoints
}
function removeOldAndAddNewRunesByReference(oldReference, newReference) {
  if (oldReference !== null && oldReference !== undefined) {
    provideBonusObjectByName(oldReference, 'runes').forEach(runeName => removeRune(runeName))
  }
  if (newReference !== null && newReference !== undefined) {
    provideBonusObjectByName(newReference, 'runes').forEach(runeName => addRune(runeName))
  }
}
function getRuneForName(runeName) {
  return store.getters.runes.find(rune => rune.name === runeName)
}
function getCurrentRunes() {
  return store.getters.characterRunes.map(rune => getRuneForName(rune.name))
}
function removeRune(runeName) {
  store.dispatch('removeCharacterRune', runeName)
}
function addRune(runeName) {
  store.dispatch('addCharacterRune', runeName)
}

export { getRunePoints, hasRuneFocus, hasRuneSpecialization, calculateRunePoints, removeOldAndAddNewRunesByReference, getCurrentRunes }
