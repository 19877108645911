<template>
  <div>
    <v-chip label small outlined>
      {{ selectable }}
    </v-chip>
    &nbsp;
    <v-chip class="primary" label small>
      {{ selected }}
    </v-chip>
    &nbsp;
  </div>
</template>

<script>
export default {
  name: 'Availability',
  props: {
    selectable: {
      type: Number,
      default: () => 0
    },
    selected: {
      type: Number,
      default: () => 0
    }
  }
}
</script>
